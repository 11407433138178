import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
import { fbApp } from "../service/firebase";

/**
 * DeviceJobs contains functions that will call public PlayerServices APIs.
 * See https://github.com/skykit-dev/skykit-player-firebase/blob/master/functions/apidoc_public.yaml for details.
 *
 * Most of these methods take a "DeviceQuery" object to specify the desired devices for the job. Jobs will return a jobTraceId
 * that can then be used with a jobTraceStatus request to track the job. Jobs can be scheduled by including a schedule object
 * in the request.
 *
 * For example:
 *
 * const deviceQuery = {deviceQuery: { deviceKeys: [], deviceIds: ["tsmd-HDT6E9IBXM", "tsmd-SKPPROF09TSMD030"], serials: [], labelIds: []}
 * const schedule = {time: "2022-08-25T20:30:47.953Z"};
 *
 */
class DeviceJobs {

  constructor(jobId) {
    this.jobId = jobId
  }

  jobStatusRef() {
    return ref(getDatabase(fbApp),`groups/${this.jobId}`);
  }

  listenForJobStatusChanges(jobId, callback) {
    onValue(this.jobStatusRef(jobId), (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  /**
   * Get labels for a tenant.
   * @returns {Promise<Object>}
   */
  async getTenantLabels() {
    return this.postAPI("tenantLabels", {})
  }

  /**
   * Update Labels on many devices
   * @param {Object} deviceQuery
   * @param {Array<String>} addLabelIds
   * @param {Array<String>} removeLabelIds
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
   async editDeviceLabels(deviceQuery, addLabelIds, removeLabelIds, scheduleTime) {
    return this.postAPI("editDeviceLabels", {deviceQuery : deviceQuery,  addLabelIds: addLabelIds, removeLabelIds: removeLabelIds}, scheduleTime)
  }

  /**
   * Add Labels to devices
   * @param {Object} deviceQuery
   * @param {Array<String>} labelIds
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async addLabelsToDevices(deviceQuery, labelIds, scheduleTime) {
    return this.postAPI("addLabelsToDevices", {deviceQuery : deviceQuery, labelIds: labelIds}, scheduleTime)
  }

  /**
   * Remove Labels from devices
   * @param {Object} deviceQuery
   * @param {Array<String>} labelIds
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async removeLabelsFromDevices(deviceQuery, labelIds, scheduleTime) {
    return this.postAPI("removeLabelsFromDevices", {deviceQuery : deviceQuery, labelIds: labelIds}, scheduleTime)
  }

  /**
   * Add managed apps
   * @param {Object} deviceQuery
   * @param {Array<Object>} apps where each app eg: {package: "com.foo.bar", channel: "prod"}
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async addManagedApps(deviceQuery, apps, scheduleTime) {
    return this.postAPI("addManagedApps", {deviceQuery : deviceQuery, apps: apps}, scheduleTime)
  }

  /**
   * Remove managed apps
   * @param {Object} deviceQuery
   * @param {Array<String>} apps Array of app package strings
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async removeManagedApps(deviceQuery, apps, scheduleTime) {
    return this.postAPI("removeManagedApps", {deviceQuery : deviceQuery, apps: apps}, scheduleTime)
  }

  /**
   * Update device configs
   * @param {Object} deviceQuery
   * @param {Object} data Data to set in configuration. Use null value to remove an element. See apiDoc for allowed entries.
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async updateDeviceConfigs(deviceQuery, data, scheduleTime) {
    return this.postAPI("updateDeviceConfigs", {deviceQuery : deviceQuery, data: data}, scheduleTime)
  }

  /**
   * addDeviceWifi
   * @param {Object} deviceQuery
   * @param {Object} credential Credential data for a wifi connection. See apiDoc for allowed entries.
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async addDeviceWifi(deviceQuery, credential, scheduleTime) {
    return this.postAPI("addDeviceWifi", {deviceQuery : deviceQuery, credential: credential}, scheduleTime)
  }

  /**
   * removeDeviceWifi
   * @param {Object} deviceQuery
   * @param {Object} credential Credential with the ssid to be removed: eg {ssid: "myssid"}
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async removeDeviceWifi(deviceQuery, credential, scheduleTime) {
    return this.postAPI("removeDeviceWifi", {deviceQuery : deviceQuery, credential: credential}, scheduleTime)
  }

  /**
   * addDevicePeripheral
   * @param {Object} deviceQuery
   * @param {Object} peripheralData See apiDoc for allowed entries.
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async addDevicePeripheral(deviceQuery, peripheralData, scheduleTime) {
    return this.postAPI("addDevicePeripheral", {deviceQuery : deviceQuery, peripheralData: peripheralData}, scheduleTime)
  }

  /**
   * removeDevicePeripheral
   * @param {Object} deviceQuery
   * @param {Object} peripheralData only name required for removal: eg {name: "myssid"}
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async removeDevicePeripheral(deviceQuery, peripheralData, scheduleTime) {
    return this.postAPI("removeDevicePeripheral", {deviceQuery : deviceQuery, peripheralData: peripheralData}, scheduleTime)
  }

  /**
   * Send a command to a device. For a list of supported commands see:
   * https://sites.google.com/a/skykit.com/skykit/player/player-requests
   * @param {Object} deviceQuery
   * @param {String} command
   * @param {String} scheduleTime //optional schedule time in ISO8601 format
   * @returns {Promise<Object>}
   */
  async sendDeviceCommand(deviceQuery, command, directAppData, scheduleTime) {
    return this.postAPI(
      "sendDeviceCommands", {
        deviceQuery: deviceQuery, command: command, data: directAppData
      }, scheduleTime)
  }

  /**
   * Fetch information about a job.
   * @param {String} jobTraceId
   * @returns {Promise<Object>}
   */
  async jobTraceStatus(jobTraceId) {
    return this.postAPI("jobTraceStatus", {jobTraceId: jobTraceId})
  }

  /**
   * List all scheduled jobs.
   * @returns {Promise<Object>}
   */
  async listScheduledJobs() {
    return this.postAPI("listScheduledJobs", {})
  }

  /**
   * Cancel a schedule job and remove it.
   * @param {String} jobTraceId
   * @returns {Promise<Object>}
   */
  async cancelScheduledJobs(jobTraceId) {
    return this.postAPI("cancelScheduledJob", {jobTraceId: jobTraceId})
  }

  /**
   * Set current content.
   * @param {Object} deviceQuery
   * @param contentId The GID of the content.
   * @returns {Promise<any|undefined>}
   */
  async setPlayerMedia(deviceQuery, contentId) {
    return this.postAPI("setPlayerMedia", {deviceQuery : deviceQuery, contentId: contentId})
  }

  async getEventReport(reqData) {
    return this.postAPI("stateEventReport", reqData)
  }
  
  async generateDeviceReport(reqData) {
    return this.postAPI("deviceReports", reqData)
  }

  /**
   * Create or edit a notification group
   * @param {Object} notificationGroupModel // includes notificationGroupId (if editing exisiting group), name, list of emails, and description
   * @returns {Promise<Object>}
   */
  async saveAlertNotifactionGroup(notificationGroupModel) {
    return this.postAPI("saveNotificationGroup", notificationGroupModel)
  }
  
  /**
   * Delete a notification group
   * @param {String} notificationGroupId // group id
   * @returns {Promise<Object>}
   */
  async deleteAlertNotificationGroup(notificationGroupId) {
    return this.postAPI("removeNotificationGroup", { notificationGroupId })
  }

  /**
   * Set an alert on bulk devices, assiging an event that triggers the alert and the notification groups that will receive the alert
   * @param {Object} deviceQuery
   * @param {String} event What triggers the notification
   * @param {Array} notificationGroupIds 
   * @returns {Promise<any|undefined>}
   */
  async addAlertToDevices(deviceQuery, event, notificationGroupIds, schedule) {
    return this.postAPI("addAlertToDevices", { deviceQuery, event, notificationGroupIds, schedule })
  }  
  
  /**
   * Remove notification groups from receiving alerts for the defined event on selected devices
   * @param {Object} deviceQuery
   * @param {String} event What triggers the notification
   * @param {Array} notificationGroupIds 
   * @returns {Promise<any|undefined>}
   */
  async removeAlertFromDevices(deviceQuery, event, notificationGroupIds, schedule) {
    return this.postAPI("removeAlertFromDevices", { deviceQuery, event, notificationGroupIds, schedule })
  }  
  
  /**
   * Mute and unmute alerts for selected notification groups for the defined event on selected devices
   * @param {Object} deviceQuery
   * @param {String} event What triggers the notification
   * @param {Array} notificationGroupIds 
   * @param {String} muteDuration ISO Format, if empty string then alert will be unmuted
   * @returns {Promise<any|undefined>}
   */
  async setMuteAlert(deviceQuery, event, notificationGroupIds, muteUntil, schedule) {
    return this.postAPI("muteAlert", { deviceQuery, event, notificationGroupIds, muteUntil, schedule })
  }

  /**
   * Pre-register devices
   * @param {Object} options // prereg optional configurations
   * @param {Array} options // Device serials, name, and location (CSV options)
   * @returns {Promise<Object>}
   */
  async preRegisterDevices(options, devices) {
    const payload = {
      options,
      devices
    }
    return this.postAPI("deviceRegistration", payload)
  }
  
  /**
   * Update device name and location
   * @param {Array} options // Device serial*, name, and location
   * @returns {Promise<Object>}
   */
  async udpateDeviceProperties(options) {
    const payload = {
      ...options
    }
    return this.postAPI("updateDeviceProperty", payload)
  }
  
  /**
   * Delete devices from tenant
   * @param {Array} options // Device serials, deviceIds, or deviceKeys
   * @returns {Promise<Object>}
   */
  async deleteDevice(options) {
    const payload = {
      ...options
    }
    return this.postAPI("deleteDevice", payload)
  }
 
  /**
   * Delete app install status
   * @param {String} deviceId // Device Id
   * @param {String} appKey // appKey of installed/error app
   * @returns {Promise<Object>}
   */
  async deleteAppInstallStatus(deviceId, appKey) {
    return this.postAPI("deleteAppInstallStatus", deviceId, appKey)
  }

  async postAPI(apiCall, reqData, scheduleTime) {
    if (scheduleTime) {
      console.log(`adding schedule ${scheduleTime}`)
      reqData.schedule = {time: scheduleTime};
    }
    console.log(`reqData=${JSON.stringify(reqData)}`);
    try {
      const token = await getAuth(fbApp).currentUser.getIdToken(true);
      const options = {
        method: 'POST',
        headers: {'Authorization' : `${token}`, 'Content-Type':'application/json'},
        body: JSON.stringify(reqData)
      };
      const res = await fetch(`https://us-central1-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/${apiCall}`,options);
      if (res) {
        const text = await res.text();
        if (text) {
          return JSON.parse(text);
        } else {
          return res;
        }
      }
    } catch(err) {
      console.error(err)
      return JSON.parse(err)
    }
  }
}

export default new DeviceJobs();