<template>
  <router-view></router-view>
  <q-dialog v-model="forbiddenDialog" persistent @hide="forbiddenDialog = false">
    <q-card dark>
      <q-card-section>
        <div class="text-h6">Alert</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        The selected tenant does not have permission to access Control. Please select a tenant with the proper permissions.
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
            rounded
            text-color="black"
            label="OK"
            color="green-13"
            @click="logOut()"
          />
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="idleSessionModal">
    <q-card dark>
      <q-card-section class="q-pt-none">
        <h6 class="q-pa-md">You are currently inactive. <br/> You have {{idleTimerFormatDisplay}} remaining until your session ends.</h6>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { format } from 'date-fns'
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useQuasar } from 'quasar';
import DeviceServices from "./service/deviceServices";
import { version } from '../package.json';
import { LoginAuth, Cookie, IdleSessionTimeout } from '@skykit-dev/skykit_login_utils';
import {
  setTenantGid,
  setTenantCode,
  getTenantCode,
  setIsLoggedIn,
  setBeamToken,
  setPlayerToken,
  setUser,
  setAppDisableState,
  setAvailableProducts,
  resetAppStates,
  getIsLoggedIn,
} from '@/store';
import Permissions from './store/permissions'
import { ldInit } from 'launchdarkly-vue-client-sdk';
import { logOut } from '@/service/auth';
import { getAvailableProductNames } from '@/utils/products.helper';
import { hasMultipleTenants } from '@/utils/identity.helper';
import { getIdentity } from '@/service/identity';
import { getPermissions } from '@/utils/identity.helper.js';
import { getTenant } from '@/service/tenantService';

export default {
  setup() {
    const router = useRouter();
    const clientSideID = process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_ID
    const [ldReady, _ldClient] = ldInit({ clientSideID })
    const forbiddenDialog = ref(false);
    const $q = useQuasar();

    const idleSessionModal = ref(false);
    const idleTimerCountDown = ref(0);
    const idleTimerFormatDisplay = ref(null);
    const idleSessionInterval = ref(0);

    watch(getIsLoggedIn, async (cur, prev) => {
      if (cur && !prev) {
        try {
          const tenantData = await getTenant();
          initIdleSessionTimeout(tenantData);
        } catch(err) {
          console.error(`Unable to get tenant and start idle session timeout`);
        }
      }
    }, {
      immediate: true
    });

    watch(idleTimerCountDown, value => {
      if (value) {
        clearInterval(idleSessionInterval.value);
        idleTimerFormatDisplay.value = null
        idleSessionInterval.value = setInterval(() => {
          value -= 1000;
          idleTimerFormatDisplay.value = format(value, 'mm:ss');

          if (value <= 0) {
            clearInterval(idleSessionInterval.value);
          }
        }, 1000);
      }
    });

    function initIdleSessionTimeout(tenantData = null) {
      if (tenantData && tenantData.idleSessionTimeout) {
        const msRemaining = tenantData.idleSessionTimeout * 1000; // convert to ms based on BE value. BE returns sec.

        IdleSessionTimeout
          .setIdleTimeout(msRemaining)
          .onWarning((timeRemainingMs) => {
            idleTimerCountDown.value = timeRemainingMs;
            idleSessionModal.value = true;
          })
          .onUserEvent(() => {
            idleSessionModal.value = false;
            idleTimerCountDown.value = 0;
          })
          .onLogout(() => {
            idleSessionModal.value = false;
            idleTimerCountDown.value = 0;
            logOut();
          })
          .canActivateSession(!!tenantData.idleSessionTimeout)
          .start();
      }
    }

    // Call before verify session to ensure no stale data has been preserved
    resetAppStates();

    LoginAuth
      .verifySession({ cookie: document.cookie }, async (resp) => {
        try {
          let claims = await DeviceServices.getClaims();

          // if tenant has changed, take them back to the content page
          if (claims && claims?.tenantId !== resp?.tenantId && router?.currentRoute?.name !== 'DeviceList') {
            router.replace({path: '/'});
          }

          // check if tenant is different
          if (!claims || claims && claims.tenantId !== resp.tenantId) {
            // only allow turf user
            if (!resp.customToken["cui"]) {
              console.error(`User does not have cui claims`);
              resetAppStates();
              setAppDisableState(true);
              return;
            }
            await DeviceServices.signInWithToken(resp.customToken['cui']);

            claims = await DeviceServices.getClaims();
          }

          // Note: beam services allows player services jwt token
          const jwtToken = await DeviceServices.getJwtToken();
          const hasMultiTenants = hasMultipleTenants(await getIdentity(jwtToken, claims.tenantId));
          const permissions = getPermissions(await getIdentity(jwtToken, claims.tenantId));
          if (
            !permissions.includes('control-ui-access') && 
            !permissions.includes('control-ui-only') &&
            !permissions.includes('platform-admin-enabled')) {
            resetAppStates()
            forbiddenDialog.value = true;
          } else {
            Permissions.actions.setCuiAccess(permissions.includes('control-ui-access') || permissions.includes('control-ui-only'))
            Permissions.actions.setPlatformAdminCapable(permissions.includes('platform-admin-capable'))
            Permissions.actions.setPlatformAdminAccess(permissions.includes('platform-admin-enabled'))
            Permissions.actions.setChangeTenantsEnabled(hasMultiTenants)
            Permissions.actions.setFirmwareTabEnabled(permissions.includes('cui-firmware-access'))
  
            setAvailableProducts(getAvailableProductNames(resp.customToken, 'cui'));
            setAppDisableState(false);
            setPlayerToken(resp.customToken['cui'])
            setTenantGid(claims.tenantGid);
            setTenantCode(claims.tenantId);
            setUser({
              email: claims.email,
              picture: claims.picture
            });
            setBeamToken(jwtToken);
            initLaunchDarkly();
  
            // set log in state last to ensure other states are set first
            if (!getIsLoggedIn()) {
              setIsLoggedIn(true);
            }
          }
        } catch(err) {
          console.error(err?.message || err);

          $q.notify({
            type: 'negative',
            message: `Failed to load user (${err?.message || err})`,
            actions: [{ label: 'Refresh', color: 'white', handler: () => location.reload() }],
            timeout: 30000
          });

          resetAppStates();
        }
      }, async (err) => {
        const httpStatus = err?.response?.status;

        console.error(`Failed to verify (${httpStatus}): ${err}`);

        if (httpStatus === 401 || // 1.1 session cookie expired
            !getIsLoggedIn() && httpStatus === 403) { // 1.2 Not authenticated and no session cookie
          // adds referrer to catch redirect navigation to cui while unauthorized
          Cookie.create('referrer', JSON.stringify({
            redirectUrl: location.href,
            app: 'cui'
          }));
        }

        await logOut();
      });

      function initLaunchDarkly(timeoutId = 0) {
        if (ldReady) {
          console.debug(`LD is ready, identifying user now`);
          clearTimeout(timeoutId);

          _ldClient.identify({
            key: getTenantCode(),
            name: getTenantCode()
          }).catch(console.error)
        } else {
          console.debug(`Ld is not ready, retry in 5 seconds`);

          timeoutId = setTimeout(() => {
            initLaunchDarkly(timeoutId);
          }, 5000);
        }
      }

    return {
      drawer: ref(false),
      miniState: ref(true),
      version,
      router,
      logOut,
      forbiddenDialog,
      idleSessionModal,
      idleTimerFormatDisplay
    };
  }
}
</script>

<style>
body {
  background-color: rgb(18,18,18);
}
#nav-drawer-items {
  color: white;
  font-size: 1.2rem;
  font-weight: bolder;
  border-right: rgba(77, 77, 77, 0.7) 0.2px solid;
  background: #181818;
}
.views {
  background: #121212;
  text-align: center;
  height: 100%;
}
.router-links {
  text-decoration: none;
  color: inherit;
}
.q-btn {
  transition: background-color .2s ease-in;
}
.q-btn:disabled {
  background: #252525 !important;
  color: #696969 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
::-webkit-scrollbar {
   display: none;
}
.menu {
  font-weight: 500;
}
.alt-avatar-bg {
  height: 100%;
  width: 100%;
  background: #00e676;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.q-menu--dark {
  box-shadow: none !important;
  background-color: rgb(90,90,90)
}
</style>
