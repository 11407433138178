//TODO: Add new link to apollo client and pass as GQL

const permissionsQuery = `
query Identity($tenantLid: ID!) {
  identity {
    permissions(tenantKey: {lid: $tenantLid}) {
      lid
    }
    user {
      id
      permits(after: null, first: 10) { # added a quick check if user has one or more tenants
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            lid
          }
        }
      }
    }
  }
}
`;

export { permissionsQuery };
