import { ref } from "vue"
import useQuasar from 'quasar/src/composables/use-quasar.js';
import deviceJobs from "../service/deviceJobs"
import deviceServices from "../service/deviceServices"
import { useRouter } from "vue-router"

const selectedAlertGroups = ref([])
const showDialog = ref(false)

export default function useAlerts() {
  const $q = useQuasar()
  const router = useRouter()
  const showKnightRider = ref(false)
  const alertNotificationGroups = ref([])

  function fetchAlertNotificationGroups() {
    deviceServices.listenForNotificationGroups((data) => {
      alertNotificationGroups.value = []
      if (data && typeof data === 'object' && data !== 'noData') {
        Object.keys(data).forEach(i => {
          // set id as property on notify group object
          data[i]['notificationGroupId'] = i
          // set the emails as array of strings instead of objects, i.e. '[{'nKd4weu23hbn8': 'email@email.com'}, ...]  ->  ['email@email.com',...]
          if (data[i].emails) {
            data[i].emails = Object.values(data[i].emails)
          }
        })
        // check for duplicates before pushing to array
        Object.keys(data).forEach(i => {
          if (!alertNotificationGroups.value.some(el => el.notificationGroupId === i)) {
            alertNotificationGroups.value.push(data[i])
          }
        })
        showKnightRider.value = false
      } else {
        showKnightRider.value = false
      }
    })
  }

  async function saveNotificationGroup(model, isEdit) {
    // PS requires type String for this request
    if (!model.description) {
      model.description = ''
    }
    showDialog.value = false
    const notify = $q.notify({
      type: 'ongoing',
      message: `${isEdit ? 'Editing' : 'Creating'} notification group...`,
      spinner: true
    })
    try {
      const resp = await deviceJobs.saveAlertNotifactionGroup(model)
      if (resp.result === 'OK') {
        notify({
          type: 'positive',
          message: `Successfully ${isEdit ? 'edited' : 'created'} notification group`,
          spinner: false
        })
      } else {
        notify({
          type: 'negative',
          message: `Failed to ${isEdit ? 'edit' : 'create'} notification group`,
          spinner: false
        })
        console.error(resp)
      }
    } catch (err) {
      console.error(err)
      notify({
        type: 'negative',
        message: `Failed to ${isEdit ? 'edit' : 'create'} notification group`,
        spinner: false
      })
    }
  }
  
  async function deleteAlertNotificationGroup(notificationGroupId) {
    const notify = $q.notify({
      type: 'ongoing',
      message: 'Deleting notification group...',
      spinner: true
    })
    try {
      const resp = await deviceJobs.deleteAlertNotificationGroup(notificationGroupId)
      if (resp.result === 'OK') {
        notify({
          type: 'positive',
          message: 'Successfully deleted notification group',
          spinner: false
        })
        fetchAlertNotificationGroups()
      } else {
        notify({
          type: 'negative',
          message: 'Failed to delete notification group',
          spinner: false
        })
        console.error(resp)
      }
    } catch (err) {
      console.error(err)
      notify({
        type: 'negative',
        message: 'Failed to delete notification group',
        spinner: false
      })
    }
  }

  async function addAlertToDevices(deviceQuery, event, notificationGroupId, isBulk, isMultipleDevices) {
    const notificationGroupIds = notificationGroupId ? notificationGroupId : selectedAlertGroups.value.map(el => el.notificationGroupId)

    const notify = $q.notify({
      type: 'ongoing',
      message: `Adding notification group to ${isBulk ? 'selected devices' : 'device'}...`,
      spinner: true
    })
    try {
      const resp = await deviceJobs.addAlertToDevices(deviceQuery, event, notificationGroupIds)
      if (resp.jobTraceId) {
        deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
          if (data){
            if (data.status === 'incomplete') {
              return
            }
            if (data.status === 'complete' && !data.error) {
              notify({
                type: 'positive',
                message: `Successfully added notification group to ${isBulk ? 'selected devices' : 'device'}`,
                actions: setActions(isMultipleDevices),
                spinner: false
              })
              return
            }
            if (data.status === 'complete' && data.error) {
              notify({
                type: 'negative',
                message: `Failed to add notification group to ${isBulk ? 'selected devices' : 'device'}`,
                caption: data.error,
                spinner: false
              })
              return
            }
          }
        })
      } else {
        console.error(resp)
        notify({
          type: 'negative',
          message: `Failed to add notification group to ${isBulk ? 'selected devices' : 'device'}`,
          caption: resp,
          spinner: false
        })
      }
    } catch (err) {
      console.error(err)
      notify({
        type: 'negative',
        message: `Failed to add notification group to ${isBulk ? 'selected devices' : 'device'}`,
        caption: err,
        spinner: false
      })
    }
  }
  
  async function removeAlertFromDevices(deviceQuery, event, notificationGroupId, isBulk, isMultipleDevices) {
    const notificationGroupIds = notificationGroupId ? notificationGroupId : selectedAlertGroups.value.map(el => el.notificationGroupId)

    const notify = $q.notify({
      type: 'ongoing',
      message: `Removing alert from ${isBulk ? 'selected devices' : 'device'}...`,
      spinner: true
    })
    try {
      const resp = await deviceJobs.removeAlertFromDevices(deviceQuery, event, notificationGroupIds)
      if (resp.jobTraceId) {
        deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
          if (data){
            if (data.status === 'incomplete') {
              return
            }
            if (data.status === 'complete' && !data.error) {
              notify({
                type: 'positive',
                message: `Successfully removed alert from ${isBulk ? 'selected devices' : 'device'}`,
                actions: setActions(isMultipleDevices),
                spinner: false
              })
              return
            }
            if (data.status === 'complete' && data.error) {
              notify({
                type: 'negative',
                message: `Failed to remove notification group from ${isBulk ? 'selected devices' : 'device'}`,
                caption: data.error,
                spinner: false
              })
              return
            }
          }
        })
      } else {
        console.error(resp)
        notify({
          type: 'negative',
          message: `Failed to remove notification group from ${isBulk ? 'selected devices' : 'device'}`,
          caption: resp,
          spinner: false
        })
      }
    } catch (err) {
      console.error(err)
      notify({
        type: 'negative',
        message: `Failed to remove notification group from ${isBulk ? 'selected devices' : 'device'}`,
        caption: err,
        spinner: false
      })
    }
  }
  
  async function setMuteAlert(deviceQuery, event, notificationGroupId, muteDuration, isBulk, isMultipleDevices) {
    // if notificationGroupId is passed in (as a single item in an array) then it is from device detail and not a bulk selection
    const notificationGroupIds = notificationGroupId ? notificationGroupId : selectedAlertGroups.value.map(el => el.notificationGroupId)
    const isUnmuting = !muteDuration ? true : false
    const muteUntil = muteDuration ? getMuteUntilISO(muteDuration) : ''

    const notify = $q.notify({
      type: 'ongoing',
      message: `${isUnmuting ? 'Unmuting' : 'Muting'} notification ${isBulk ? 'groups' : 'group'}... `,
      spinner: true
    })
    try {
      const resp = await deviceJobs.setMuteAlert(deviceQuery, event, notificationGroupIds, muteUntil)
      if (resp.jobTraceId) {
        deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
          if (data){
            if (data.status === 'incomplete') {
              return
            }
            if (data.status === 'complete' && !data.error) {
              notify({
                type: 'positive',
                message: `Successfully ${isUnmuting ? 'unmuted' : 'muted'} notification ${isBulk ? 'groups' : 'group'}`,
                actions: setActions(isMultipleDevices),
                spinner: false
              })
              return
            }
            if (data.status === 'complete' && data.error) {
              notify({
                type: 'negative',
                message: `Failed to ${isUnmuting ? 'unmute' : 'mute'} notification ${isBulk ? 'groups' : 'group'}`,
                caption: data.error,
                spinner: false
              })
              return
            }
          }
        })
      } else {
        console.error(resp)
        notify({
          type: 'negative',
          message: `Failed to ${isUnmuting ? 'unmute' : 'mute'} notification ${isBulk ? 'groups' : 'group'}`,
          caption: resp,
          spinner: false
        })
      }
    } catch (err) {
      console.error(err)
      notify({
        type: 'negative',
        message: `Failed to ${isUnmuting ? 'unmute' : 'mute'} notification ${isBulk ? 'groups' : 'group'}`,
        caption: err,
        spinner: false
      })
    }
  }

  function getMuteUntilISO(muteDurationMs) {
    if (!muteDurationMs) return ''

    const date = Date.now()
    const muteUntilMs = (date + muteDurationMs)
    const muteUntilISO = new Date(muteUntilMs).toISOString()
    return muteUntilISO
  }

  function setActions(isBulkAction) {
    return isBulkAction ?
    [
      { label: 'View Job Status', color: 'white', handler: () => { router.push({name: 'JobStatus'}) } }
    ] 
    : []
  }

  return {
    alertNotificationGroups,
    showKnightRider,
    fetchAlertNotificationGroups,
    saveNotificationGroup,
    deleteAlertNotificationGroup,
    selectedAlertGroups,
    addAlertToDevices,
    removeAlertFromDevices,
    setMuteAlert,
    showDialog,
  }
}