const isLocalHost = () => location.hostname.includes("localhost") ? 'localhost' : false;

const getLocalhostUrl = () => {
  const isDev = location.hostname.includes("localhost");
  return isDev ? "https://localhost.skykit.com:3000" : null;
};

const getEnv = (projectId) => {
  switch (projectId) {
    case "skykit-player-int":
      return "int";
    case "skykit-player-qa":
      return "qa";
    case "skykit-player-stage":
      return "stage";
    case "skykit-player":
      return "prod";
  }
};

const productDomains = (p) => {
  let product = {
    imgUrl: '',
    url: '',
    name: ''
  };

  const env = isLocalHost() || getEnv(process.env.VUE_APP_PROJECT_ID)

  switch (p) {
    case "beam":
      switch (env) {
        case "int":
          product.url = "https://beam-int.skykit.com";
          break;
        case "qa":
          product.url = "https://beam-qa.skykit.com";
          break;
        case "stage":
          product.url = "https://beam-stage.skykit.com";
          break;
        case "prod":
          product.url = "https://beam.skykit.com";
          break;
        case 'localhost':
        default:
          product.url = 'https://localhost.skykit.com:7999';
          break;
      }
      break;
    case "turf":
      switch (env) {
        case "int":
          product.url = "https://turf-int.skykit.com/";
          break;
        case "qa":
          product.url = "https://turf-qa.skykit.com/";
          break;
        case "stage":
          product.url = "https://turf-stage.skykit.com/";
          break;
        case "prod":
          product.url = "https://turf.skykit.com/";
          break;
        case 'localhost':
        default:
          product.url = 'https://localhost.skykit.com:8081';
          break;
      }
      break;
  }

  if (p === 'beam') {
    product.imgUrl = '/beam_logo.svg';
    product.name = 'Skykit Beam';
  } else if (p === 'turf') {
    product.imgUrl = '/turf_icon_logo.svg';
    product.name = 'Skykit Turf';
  }

  return product;
};

export { getLocalhostUrl, getEnv, productDomains };
