<script setup>
import { ref, watch, computed} from 'vue'
import deviceServices from '../service/deviceServices'
import ReportsDialog from '../components/ReportsDialog.vue'
import { formatDateTimeString } from '../utils/dateUtils'
import { useQuasar } from 'quasar'
import { auth } from '@/store'

const $q = useQuasar()
const reports = ref([])
const showKnightRider = ref(true)
const hasNoReports = computed(() => {
  return !reports.value.length
})
const isDownloading = ref(false)
const downloadingJobTraceId = ref('')

watch(auth, (cur, prev) => {
  if (cur?.isLoggedIn && !prev?.isLoggedIn) {
    deviceServices.onAuthStateChanged((user) => {
      if (user) {
        fetchReports()
      } else {
        console.debug('Unable to fetch reports');
      }
    });
  }
}, {
  immediate: true,
  deep: true
})

function fetchReports() {
  deviceServices.listenForJobHistoryGroups((data) => {
    if (data && data !== 'noData') {
      const newArr = data.filter(el => el.jobName === 'device-reports')
      reports.value = newArr
    }
    showKnightRider.value = false
  })
}

async function downloadCsvFile(report) {
  isDownloading.value = true
  downloadingJobTraceId.value = report.jobTraceId
  try {
    const downloadUrl = await deviceServices.fetchFileDownloadUrl(report?.reportFile)
    if (downloadUrl) {
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = `Device Report: ${report.type}.csv`
      link.class = 'download-csv-anchor'
      document.body.appendChild(link)
      link.click()
    } else {
      console.warn('Unbale to get download URL')
      $q.notify({
        type: 'negative',
        message: 'Unable to download report'
      })
    }
  } catch (err) {
    console.error(err)
    $q.notify({
      type: 'negative',
      message: 'Unable to download report',
      caption: `${err}`
    })
  } finally {
    isDownloading.value = false
  }
}

function isProcessing(report) {
  if (report?.status !== 'complete' && !report.error) {
    return true
  }
  return false
}

function showDisabledState(report) {
  const processing = isProcessing(report)
  if (processing || (report.errorString && !hasNoDeviceError(report) )) {
    return true
  }
  return false
}

function getReportTypeString(string) {
  if (string && typeof string === 'string') {
    const position = string.lastIndexOf('/')
    const endPosition = string.substring(position + 1)
    const subString = endPosition.substring(0, endPosition.indexOf("-"));
  
    switch(subString) {
      case 'deviceState' :
        return 'Device State Report'
      case 'deviceConfig' :
        return 'Device Config Report'
      case 'beamState' :
        return 'Beam State Report'
      case 'beamConfig' :
        return 'Beam Config Report'
      case 'installedAppsReport' :
        return 'Installed Apps Report'
      default :
        return 'Report Type N/A'
    }
  } else {
    return 'Fetching report...'
  }
}

function hasNoDeviceError(report) {
  return report.errorString === 'No Devices'
}

</script>

<template>
  <q-page padding class="page">
    <div class="row">
      <div class="col text-left text-white">
        <h3 class="text-weight-bolder">Reports</h3>
      </div>

      <div
        class="col self-center text-right"
      >
        <reports-dialog data-test="reports-dialog-el"></reports-dialog>
      </div>
    </div>
    <q-card
      v-if="hasNoReports"
      class="full-width q-mt-lg q-px-lg q-py-sm rounded-borders"
      dark
      style="padding:27vh"
    >
      <span class="flex justify-center text-body1">
        Click on the&nbsp;
          <span class="text-bold"> GENERATE REPORT </span>
        &nbsp;button to create your first report
      </span>
    </q-card>
    <q-card
      v-else
      class="full-width q-mt-lg q-px-lg q-py-sm rounded-borders"
      dark
    >
      <div>
        <q-item class="q-pb-none text-left text-subtitle1 text-bold">
          <q-item-section class="col items-start">
            <span> Report Type </span>
          </q-item-section>
          <q-item-section class="col items-start">
            <span> Date Created </span>
          </q-item-section>
          <q-item-section class="col items-start">
            <span> Created By </span>
          </q-item-section>
          <q-item-section class="col items-center">
            <span> Number of Devices </span>
          </q-item-section>
          <q-item-section class="col items-start">
            <span> Job Trace ID </span>
          </q-item-section>
          <q-item-section class="col-1 items-end"> </q-item-section>
        </q-item>
      </div>

      <q-separator dark></q-separator>

      <div>
        <q-linear-progress
          v-if="showKnightRider"
          color="green-13"
          indeterminate
        ></q-linear-progress>
      </div>
        <div v-for="report in reports" :key="report.jobTraceId" class="report-rows">
          <q-item
            data-test="report"
            :class="[showDisabledState(report) ? 'text-grey' : '', 'q-py-md', 'report-item']"
          >
            <q-item-section class="col items-start">
              <span v-if="!report.errorString || hasNoDeviceError(report)" class="text-left">
                {{ getReportTypeString(report.reportFile) }}
              </span>
              <span v-else>
                Request failed. Please try again.
              </span>
            </q-item-section>
            <q-item-section class="col items-start">
              <span class="text-left">
                {{ formatDateTimeString(report.created) }}
              </span>
            </q-item-section>
            <q-item-section class="col items-start">
              <span class="text-left">
                {{ report.user }}
              </span>
            </q-item-section>
            <q-item-section class="col items-center">
              <span class="text-center">
                {{ `${report.numComplete || 0} / ${report.numDevices || 0}` }}
              </span>
            </q-item-section>
            <q-item-section class="col items-start">
              <span class="text-left text-no-wrap">
                {{ report.jobTraceId }}
              </span>
            </q-item-section>
            <q-item-section v-if="!isProcessing(report)" class="col-1 items-end">
              <q-spinner
                v-if="isDownloading && downloadingJobTraceId === report.jobTraceId"
                color="green-13"
                size="sm"
                :thickness="6"
                style="margin-right:12px;"
              />
              <q-icon
                v-else-if="!isDownloading && report.error"
                name="warning"
                color="red"
                size="md"
              >
              </q-icon>
              <q-btn
                v-else
                dense
                flat
                icon="o_file_download"
                @click="downloadCsvFile(report)"
                data-test="download-btn"
                :disabled="showDisabledState(report)"
              >
              </q-btn>
            </q-item-section>
            <q-item-section v-else class="col-1 items-end">
              <span class="text-caption"> In Progress...</span>
            </q-item-section>
          </q-item>
        </div>
    </q-card>
  </q-page>
</template>

<style>
.report-rows:nth-child(odd) {
  background-color: rgb(35, 35, 35);
}
</style>
