<script setup>
import { ref, onMounted } from "vue"
import { formatDateTimeString } from "../utils/dateUtils"
import { toTitleCase } from '../utils/stringUtils'

// eslint-disable-next-line
const props = defineProps({
  device: {
    type: Object,
    default: () => {}
  }
})
const events = ref({})
const eventType = 'control'

onMounted(() => {
  fetchDeviceEvents()
})

function fetchDeviceEvents() {
  props.device.listenToEvents(eventType, (data) => {
    try {
      if (data) {
        const validEvents = Object.keys(data).filter(el => el.startsWith('-')).map(i => i = data[i])
        if (Object.keys(validEvents).length) {
          events.value = validEvents.reverse()
        } else {
          events.value = 'noEvents'
        }
      }
    } catch (err) {
      console.error(err)
    }
  })
}

</script>

<template>
  <div>
    <q-item>
      <q-item-section class="col items-start q-ml-md">
        <span class="text-left text-bold">
          Event
        </span>
      </q-item-section>
      <q-item-section class="col items-start q-ml-md">
        <span class="text-left text-bold">
          Event Timestamp
        </span>
      </q-item-section>
      <q-item-section class="col items-center">
        <span class="text-bold">
          Storage
        </span>
      </q-item-section>
      <q-item-section class="col items-center">
        <span class="text-bold">
          Memory
        </span>
      </q-item-section>
      <q-item-section class="col items-center">
        <span class="text-bold">
          Data Usage <div class="text-caption">(In the last 24 hours)</div>
        </span>
      </q-item-section>
    </q-item>
    
    <q-separator dark></q-separator>
    <div v-if="events.length && events !== 'noEvents'">
      <div v-for="event in events" :key="event" class="event-rows">
        <q-item class="text-white" data-test="event">
          <q-item-section class="col items-start q-ml-md">
            <span class="text-left">
              {{ toTitleCase(event.event) }}
            </span>
          </q-item-section>
          <q-item-section class="col items-start q-ml-md">
            <span class="text-left">
              {{ formatDateTimeString(event.stateReceiveTime) }}
            </span>
          </q-item-section>
          <q-item-section class="col items-center">
            <span>
              {{ event.storage }}%
            </span>
          </q-item-section>
          <q-item-section class="col items-center">
            <span>
              {{ event.memory }}%
            </span>
          </q-item-section>
          <q-item-section class="col items-center">
            <span>
              {{ event.dataUseMb24h }} mb
            </span>
          </q-item-section>
        </q-item>
      </div>
    </div>
    <div v-else class="flex justify-center items-center" style="height:30vh;">
      <q-item>
        <span>
          No device events have been recorded 
        </span>
      </q-item>  
    </div>
  </div>
</template>


<style>
.event-rows:nth-child(even) {
  background-color: rgb(35, 35, 35);
}
</style>