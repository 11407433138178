<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import useLabelFilters from '../composables/useLabelFilters';

const props = defineProps({ //eslint-disable-line no-undef
  value: {
    default: () => ({}),
  },
  label: {
    type: String,
    default: '',
  },
  checked: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: '',
  },
  hidden: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits({ //eslint-disable-line no-undef
  labelCheckboxClicked: {
    default: ''
  }
});

onMounted(() => {
  getIcon()
})

onUnmounted(() => {
  interactiveIconClass.value = '';
})

// variables
const interactiveIconClass = ref('');

// computed
const iconClass = computed(() => {
  if (!props.value.queuedDisplays.length) {
    return 'unchecked';
  } else if ( 
    // Every display includes label
    props.value.queuedDisplays.every((display) =>
      {
        return display.labelGids.includes(props.value.label.id)
      }
    )
  ) {
    return 'checked';
  } else if ( 
    // Some but not all displays inlclude label
    props.value.queuedDisplays.some((display) =>
      display.labelGids.includes(props.value.label.id)
    ) &&
    !props.value.queuedDisplays.every((display) =>
      display.labelGids.includes(props.value.label.id)
    )
  ) {
    // populate indeterminateLabels array to ensure we don't add to all selected displays
    emit('labelCheckboxClicked', props.value.label, 'indeterminate')
    return 'indeterminate';
  } else { 
    // No displays include label
    return 'unchecked';
  }
});

const allowIndeterminateToggle = computed(() => {
  if (
    props.value.queuedDisplays.some((display) =>
      display.labelGids.includes(props.value.label.id)
    ) &&
    !props.value.queuedDisplays.every((display) =>
      display.labelGids.includes(props.value.label.id)
    )
  ) {
    return true
  } else {
    return false
  }
})

// methods
function handleClick(label) {
  if (!interactiveIconClass.value && iconClass.value === "unchecked") {
    interactiveIconClass.value = "checked";
    labelGidsHashObj[label.id] = 'add';
    emit('labelCheckboxClicked', label, 'checked')
  } else if (
    interactiveIconClass.value === "checked" ||
    (!interactiveIconClass.value && iconClass.value === "checked")
  ) {
    interactiveIconClass.value = "unchecked";
    labelGidsHashObj[label.id] = 'delete';
    emit('labelCheckboxClicked', label, 'unchecked')
  } else if (
    interactiveIconClass.value === "unchecked" ||
    (!interactiveIconClass.value && iconClass.value === "unchecked")
  ) {
    if (allowIndeterminateToggle.value) {
      interactiveIconClass.value = "indeterminate";
      emit('labelCheckboxClicked', label, 'indeterminate')
    } else {
      interactiveIconClass.value = "checked";
      labelGidsHashObj[label.id] = 'add';
      emit('labelCheckboxClicked', label, 'checked')
    }
  } else if (
    interactiveIconClass.value === "indeterminate" ||
    (!interactiveIconClass.value && iconClass.value === "indeterminate")
  ) {
    interactiveIconClass.value = "checked";
    labelGidsHashObj[label.id] = 'add';
    emit('labelCheckboxClicked', label, 'checked')
  }
}

function getIcon() {
    return interactiveIconClass.value || iconClass.value;
}

function getIconContainerClass() {
  return getIcon() !== 'checked' ? 'icon-container' : interactiveIconClass.value ? 'icon-container-checked-interactive' : 'icon-container-checked'
}

const {
  labelGidsHashObj
} = useLabelFilters()
</script>

<template>
  <div class="app-check-box" @click="handleClick(props.value?.label)" :id="props.value?.label?.id">
    <div :class="getIconContainerClass()">
      <!-- icon states start -->
      <span
        class="icon-checked"
        v-if="getIcon() === 'checked'"
      >
        <q-icon name="done" class="checked-icon"></q-icon>
      </span
      >
      <span
        class="icon-indeterminate"
        v-if="getIcon() === 'indeterminate'"
        >-</span
      >
      <span
        class="icon-unchecked"
        v-if="getIcon() === 'unchecked'"
      ></span>
      <!-- icon states end -->
    </div>
  </div>
</template>

<style scoped>
.icon-container {
  border: 2px solid;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 13px;
  height: 6px;
  width: 6px;
  border-radius: 2px;
  border-color: rgb(205,205,205);
  display: flex;
  justify-content: center;
  transform: scale(.9);
}
.icon-container-checked {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 11px;
  margin-top: 14px;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  display: flex;
  margin-left: 0px;
  justify-content: center;
  margin-left: 1px;
  background-color: rgb(170,170,170);
}
.icon-container-checked-interactive {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 11px;
  margin-top: 14px;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  display: flex;
  margin-left: 0px;
  justify-content: center;
  margin-left: 1px;
  background-color: rgb(48,211,116);
}
.checked-icon {
  transform: scale(1.3);
}
</style>