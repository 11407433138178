<template>
  <q-card dark class="card q-mt-md">
    <div class="q-pa-md row justify-content">
      <q-list class="col" dense>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Build Date
            </q-item-label>
          </q-item-section>
          <q-item-section class="stateValue text-right">
            {{ deviceState.buildDate }}
          </q-item-section>
        </q-item>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Build Id
            </q-item-label>
          </q-item-section>
          <q-item-section class="stateValue text-right">
            {{ deviceState.buildID }}
          </q-item-section>
        </q-item>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Build Type
            </q-item-label>
          </q-item-section>
          <q-item-section class="stateValue text-right">
            {{ deviceState.buildType }}
          </q-item-section>
        </q-item>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Build Flavor
            </q-item-label>
          </q-item-section>
          <q-item-section class="stateValue text-right">
            {{ deviceState.buildFlavor }}
          </q-item-section>
        </q-item>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Fingerprint
            </q-item-label>
          </q-item-section>
          <q-item-section class=" stateValue ellipsis text-right">
            {{ deviceState.fingerprint }}
          </q-item-section>
        </q-item>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              OS Build Number
            </q-item-label>
          </q-item-section>
          <q-item-section class=" stateValue ellipsis text-right">
            {{ deviceState.osBuildNumber }}
          </q-item-section>
        </q-item>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              OTA Downloaded
            </q-item-label>
          </q-item-section>
          <q-item-section class=" stateValue ellipsis text-right">
            {{ deviceState.otaDownloaded }}
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </q-card>

  <q-card dark class="card">
    <div class="q-pa-md row justify-content">
      <q-list class="col" dense>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              OTA Build Type
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-select
              dark
              outlined
              v-model="config.otaBuildType"
              :options="otaBuildTypeOptions"
              label="select build"
              color="white"
              options-selected-class="text-green-13"
              emit-value
              map-options
            >
              <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps" class="select-background">
                  <q-item-section>
                    <q-item-label>{{ scope.opt.label }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </q-item-section>
        </q-item>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              OTA Channel
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-select
              dark
              outlined
              v-model="config.otaChannel"
              :options="otaChannelOptions"
              label="select channel"
              color="white"
              options-selected-class="text-green-13"
              emit-value
              map-options
            >
              <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps" class="select-background">
                  <q-item-section>
                    <q-item-label>{{ scope.opt.label }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </q-item-section>
        </q-item>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Custom OTA Url
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-input
              v-model="config.otaUpdateUrl"
              class="col q-mt-sm"
              dark
              outlined
              label="enter custom ota url"
              color="white"
              :disable="isSavingDeviceConfig"
              :rules="[rules.otaUrlRule]"
              clearable
              no-error-icon
              hide-bottom-space
              @clear="config.otaUpdateUrl = ''"
            >
            </q-input>
          </q-item-section>
        </q-item>
        <q-item class="q-mb-lg">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Build Flavor
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-input
              v-model="config.otaBuildFlavor"
              class="col q-mt-sm"
              dark
              outlined
              color="white"
              :disable="isSavingDeviceConfig"
              no-error-icon
              hide-bottom-space
              readonly
            >
            </q-input>
          </q-item-section>
        </q-item>
        <q-item v-if="!deviceState.otaDownloaded" class="q-mb-md">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Download Firmware
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-3">
            <div class="text-right">
              <confirm-dialog
                title="Download Firmware"
                :isDisabled="isDisabled || isOffline"
                message="Are you sure you want to download firmware?"
                label="download"
                dialogType="command"
                value="downloadFirmware"
                @confirm-selection="sendCommand"
              >
              </confirm-dialog>
            </div>
          </q-item-section>
        </q-item>
        <q-item v-if="!deviceState.otaDownloaded" class="q-mb-md">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Download and Install Firmware
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-3">
            <div class="text-right">
              <confirm-dialog
                title="Download and Install Firmware"
                :isDisabled="isDisabled || isOffline"
                message="Are you sure you want to download and install firmware?"
                label="download & install"
                dialogType="command"
                value="downloadInstallFirmware"
                @confirm-selection="sendCommand"
              >
              </confirm-dialog>
            </div>
          </q-item-section>
        </q-item>
        <q-item v-if="deviceState.otaDownloaded" class="q-mb-md">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Install Firmware
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-3">
            <div class="text-right">
              <confirm-dialog
                title="Install Firmware"
                :isDisabled="isDisabled || isOffline"
                message="Are you sure you want to install firmware?"
                label="install"
                dialogType="command"
                value="installFirmware"
                @confirm-selection="sendCommand"
              >
              </confirm-dialog>
            </div>
          </q-item-section>
        </q-item>
        <q-item v-if="deviceState.otaDownloaded" class="q-mb-md">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Delete Firmware
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-3">
            <div class="text-right">
              <confirm-dialog
                title="Delete Firmware"
                :isDisabled="isDisabled || isOffline"
                message="Are you sure you want to delete firmware?"
                label="delete"
                dialogType="command"
                value="deleteFirmware"
                @confirm-selection="sendCommand"
              >
              </confirm-dialog>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </q-card>
</template>

<script>
import { ref, toRef, watch, computed } from "vue";
import { channelOptions, otaBuildTypeOptions } from "../constants/constants";
import ConfirmDialog from "./ConfirmDialog.vue";
import useCommands from '../composables/useCommands';
import useDeviceConfig from '../composables/useDeviceConfig';
import regexRules from "../constants/regex";

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    state: Object,
    deviceConfig: Object,
    device: Object
  },
  emits: ["device-config-updated"],
  setup(props, { emit }) {
    const deviceState = toRef(props, "state");
    const config = ref(props.deviceConfig);
    const isOffline = computed(() => {
      return deviceState.value.isOffline
    })
    const rules = ref({
      otaUrlRule: v => !!(v && v.match(regexRules.otaUrlRegex)) || !v || 'Field does not meet required format',
    })

    watch(config.value, (cur) => {
      emit("device-config-updated", cur);
    }, { deep: true });

    async function sendCommand(val) {
      let command = "";
      switch (val) {
        case "downloadFirmware":
          command = "ota/download";
          break;
        case "downloadInstallFirmware":
          command = "ota/update";
          break;
        case "installFirmware":
          command = "ota/install";
          break;
        case "deleteFirmware":
          command = "ota/delete";
          break;
      }
      const deviceId = props.device.deviceId;
      const deviceQuery = {
        deviceIds: [deviceId],
      };
      sendDeviceCommand(deviceQuery, command)
    }

    const {
      isDisabled,
      sendDeviceCommand
    } = useCommands()

    const {
      isSavingDeviceConfig
    } = useDeviceConfig()

    return {
      deviceState,
      config,
      isDisabled,
      isOffline,
      isSavingDeviceConfig,
      otaBuildTypeOptions,
      otaChannelOptions: channelOptions,
      sendCommand,
      rules,
    };
  },
};
</script>

<style lang="scss">
.card {
  text-align: left;
  color: white;
  background-color: rgb(30, 30, 30);
  margin-top: 1rem;
}
.stateValue {
  font-size: 1.2em;
}
.select-background {
  background-color:rgb(25,25,25);
}
.q-field--outlined.q-field--readonly .q-field__control:before {
  border: solid 1px rgba(255, 255, 255, .6) !important;
}
</style>
