<script setup>
import { ref, computed, onBeforeUnmount } from 'vue'
import AppBtn from './AppBtn'
import { alertActionOptions, alertEventTypes, alertMuteDurationMsOptions } from '../constants/constants'
import AlertNotificationGroupList from './AlertNotificationGroupList.vue'
import useAlerts from '../composables/useAlerts'
import useDeviceQuery from '../composables/useDeviceQuery'

//eslint-disable-next-line no-undef
const props = defineProps({
  queuedDisplays: {
    type: Array,
    default: () => []
  },
  activeLabelFilters: {
    type: Array,
    default: () => []
  },
  activeSearchString: {
    type: String,
    default: ''
  },
  isAllInTenantSelected: {
    type: Boolean,
    default: false
  }
})

onBeforeUnmount(() => {
  resetBulkAlertModel()
})

const {
  selectedAlertGroups,
  addAlertToDevices,
  removeAlertFromDevices,
  setMuteAlert
} = useAlerts()

const {
  getDeviceQueryObject,
} = useDeviceQuery()

const bulkAlertModel = ref({
  selectedAction: null,
  event: null,
  muteDuration: null
})
const step = ref(1)
const isMuteAction = computed(() => {
  return bulkAlertModel.value?.selectedAction === 'muteAlerts'
})

function onClickNext() {
  if (step.value === 3) {
    completeBulkAlertAction()
    step.value = 1
  } else {
    step.value++
  }
}

function onClickBack() {
  step.value--
}

const nextBtnLabel = computed(() => {
  return step.value === 3 ? 'finish' : 'next'
})

const nextBtnIcon = computed(() => {
  return  step.value === 3 ? '' : 'arrow_forward'
})

const isNextDisabled = computed(() => {
  let isDisabled = true;

  if (step.value === 1) {
    if (
      bulkAlertModel.value?.selectedAction === 'addNotificationGroups' ||
      bulkAlertModel.value?.selectedAction === 'removeNotificationGroups' ||
      bulkAlertModel.value?.selectedAction === 'unmuteAlerts'
    ) {
      isDisabled = false;
    }
    if (bulkAlertModel.value?.selectedAction === 'muteAlerts') {
      if (bulkAlertModel.value?.muteDuration) {
        isDisabled = false
      }
    }
  }
  if (step.value === 2 && selectedAlertGroups.value.length) {
    isDisabled = false
  }
  if (step.value === 3 && !!bulkAlertModel.value?.event) {
    isDisabled = false
  }

  return isDisabled
})

function getStepTwoCaption() {
  switch(bulkAlertModel.value?.selectedAction) {
    case 'addNotificationGroups' :
      return 'Select the alert notification groups you would like to add to your devices'
    case 'removeNotificationGroups' :
      return 'Select the alert notification groups you would like to remove from your devices'
    case 'muteAlerts' :
      return 'Select the alert notification groups you would like to mute'
    case 'unmuteAlerts' :
      return 'Select the alert notification groups you would like to unmute'
  }
}

function getStepThreeCaption() {
  switch(bulkAlertModel.value?.selectedAction) {
    case 'addNotificationGroups' :
      return 'Select an alert for when these notification groups should be notified'
    case 'removeNotificationGroups' :
      return 'Select the alert you want to remove notification groups from'
    case 'muteAlerts' :
      return 'Select the alert you want these notification groups to be muted from'
    case 'unmuteAlerts' :
      return 'Select the alert you want these notification groups to be unmuted from'
  }
}

function completeBulkAlertAction() {
  const isBulk = true
  const isMultipleDevices = props.queuedDisplays.length > 1
  const deviceQuery = getDeviceQueryObject(
    props.queuedDisplays,
    props.isAllInTenantSelected,
    props.activeSearchString,
    props.activeLabelFilters
  )

  switch(bulkAlertModel.value.selectedAction) {
    case 'addNotificationGroups' :
      addAlertToDevices(deviceQuery, bulkAlertModel.value.event, null, isBulk, isMultipleDevices)
      break
    case 'removeNotificationGroups' :
      removeAlertFromDevices(deviceQuery, bulkAlertModel.value.event, null, isBulk, isMultipleDevices)
      break
    case 'muteAlerts' :
      setMuteAlert(deviceQuery, bulkAlertModel.value.event, null, bulkAlertModel.value.muteDuration, isBulk, isMultipleDevices)
      break
    case 'unmuteAlerts':
      setMuteAlert(deviceQuery, bulkAlertModel.value.event, null,  bulkAlertModel.value.muteDuration, isBulk, isMultipleDevices)
      break
  }

  resetBulkAlertModel()
}

function resetBulkAlertModel() {
  selectedAlertGroups.value = []
  bulkAlertModel.value = {
    selectedAction: null,
    event: null,
    muteDuration: null
  }
}

</script>

<template>
  <q-card dark class="q-px-lg q-pt-lg full-height position-relative">
    <q-card-section class="q-mb-sm">
      <span class="text-h6">Alerts</span>
    </q-card-section>

    <q-card-section 
      v-if="step === 1"
      class="q-py-none"
    >
      <q-item-label class="text-bold">Select an action</q-item-label>
      <q-item class="q-mb-xl q-px-none">
        <q-select
          dense
          label="Choose an action from the list"
          v-model="bulkAlertModel.selectedAction"
          class="q-mt-sm full-width"
          :options="alertActionOptions"
          dark
          outlined
          color="white"
          :popup-content-style="{ backgroundColor:'rgb(40,40,40)' }"
          map-options
          emit-value
        >
        </q-select>
      </q-item>

      <div v-show="isMuteAction">
        <q-item-label class="text-bold">How long do you want to mute alerts for these notification groups?</q-item-label>
        <q-item class="q-pl-none">
          <q-select
            dense
            label="Choose a duration from the list"
            v-model="bulkAlertModel.muteDuration"
            class="q-mt-sm col-6"
            :options="alertMuteDurationMsOptions"
            dark
            outlined
            color="white"
            :popup-content-style="{ backgroundColor:'rgb(40,40,40)' }"
            map-options
            emit-value
          >
          </q-select>
        </q-item>
      </div>
    </q-card-section>
    
    <q-card-section
      v-show="step === 2"
      class="q-py-none"
    >
      <q-item-label class="text-bold">{{ getStepTwoCaption() }}</q-item-label>
      <div class="">
        <alert-notification-group-list sourceLocation="bulkAlerts">
        </alert-notification-group-list>
      </div>
    </q-card-section>

    <q-card-section
      v-show="step === 3"
      class="q-py-none"
    >
      <q-item-label class="text-bold">{{ getStepThreeCaption() }}</q-item-label>
      <q-item class="q-mb-lg q-px-none">
        <q-select
          dense
          label="Choose an alert from the list"
          v-model="bulkAlertModel.event"
          class="q-mt-sm full-width"
          :options="alertEventTypes"
          dark
          outlined
          color="white"
          :popup-content-style="{ backgroundColor:'rgb(40,40,40)' }"
          map-options
          emit-value
        >
          <template v-slot:option="{ itemProps, opt }">
            <q-item v-bind="itemProps">
              <q-item-section>
                <q-item-label class="text-bold text-body1">{{ opt.label }}</q-item-label>
                <q-item-label class="text-caption">{{ opt.description }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </q-item>
    </q-card-section>

    <div class="absolute-bottom">
      <q-separator dark></q-separator>
      <q-item class="q-py-lg">
        <app-btn
          v-if="step > 1"
          :isSecondary="true"
          label="back"
          @click="onClickBack"
          icon="arrow_back"
        >
        </app-btn>
        <q-space></q-space>
        <app-btn
          :isDisabled="isNextDisabled"
          :label="nextBtnLabel"
          @click="onClickNext"
          :iconRight="nextBtnIcon"
        >
        </app-btn>
      </q-item>
    </div>
  </q-card>
</template>