<template>
  <div class="add-labels-card" style="background-color:rgb(30,30,30)">
    <q-card dark class="q-mt-none">
      <q-item class="items-center q-pr-none q-pb-none">
        <q-item-label class="text-subtitle1 text-left text-bold">
          Labels
        </q-item-label>
        <q-space></q-space>
        <q-btn round class="q-mr-none">
          <q-icon
            size="xs"
            name="close"
            @click="emit('close-labels-drawer')"
          ></q-icon>
        </q-btn>
      </q-item>
      <q-item class="items-center justify-center">
        <q-btn-dropdown
          outline
          class="q-mb-sm labels-btn"
          rounded
          dropdown-icon="none"
          menu-anchor="top right"
        >
          <template v-slot:label>
            <q-icon
              name="add"
              style="margin-left:-7px;"
            >
            </q-icon>
            <span class="text-bold">
              CREATE
            </span>
          </template>
          <q-list>
            <q-item dark clickable class="create-dropdown-items" @click="openLabelsDialog('category')">
              <q-item-section class="col-2 q-mr-sm">
                <div class="overlap-label-btn-wrapper">
                  <q-icon
                    class="label-btn base-label-btn"
                    color="grey-8"
                    name="local_offer"
                    size="sm"
                  >
                  </q-icon>
                  <q-icon
                    class="overlap-label-btn"
                    color="grey"
                    name="local_offer"
                    size="sm"
                  >
                  </q-icon>
                </div>
              </q-item-section>
              <q-item-section>
                <q-item-label>Label Category</q-item-label>
              </q-item-section>
            </q-item>

            <q-separator color="grey-9" />

            <div :class="{ 'cursor-not-allowed': !canCreateLabels }" >  
              <q-item 
                dark 
                clickable 
                @click="openLabelsDialog()" 
                :class="['create-dropdown-items', { 'disable-create-labels': !canCreateLabels }]"
              >
                <q-item-section class="col-2 q-mr-sm">
                  <q-icon
                    class="label-btn q-mr-md"
                    :color="!canCreateLabels ? 'grey-14' : 'grey'"
                    name="local_offer"
                    size="sm"
                  >
                  </q-icon>
                </q-item-section>
                <q-item-section>
                  <q-item-label 
                    :class="!canCreateLabels ? 'text-grey-14' : 'text-white'"
                  >
                    Label
                  </q-item-label>
                </q-item-section>
              </q-item>
            </div>

          </q-list>
        </q-btn-dropdown>
      </q-item>
    </q-card>

    <q-separator dark></q-separator>

    <q-scroll-area
      :style="showLabelCheckbox ? 'height:388px;' : 'height:445px;'"
      :visible="false"
    >
      <div 
        v-for="(category, idx) in labelCategories"
        :key="category"
        class="label-card no-border-radius items-center"
      >
        <q-expansion-item
          dark
          dense-toggle
          expand-separator
          switch-toggle-side
          class="text-left"
          style="max-width:196px;"
          @mouseover="showMenuBtn(null, category, idx)" 
          @mouseleave="categoryHoverMenu.idx = null"
        >
          <template #header>
            <div class="text-subtitle1 text-bold ellipsis" style="width:85%;">
              {{ category.name }}
              <q-tooltip :delay="1000">
                {{ category.name }}
              </q-tooltip>
            </div>
            <div>
              <q-btn 
                @click.stop
                flat
                size="sm"
                icon="more_vert"
                style="width:10px;"
                v-show="categoryHoverMenu.idx === idx && labelHoverMenu.idx === null"
              >
                <q-menu transition-duration="0" anchor="top right" self="top left">
                  <q-list dark>
                    <q-item
                      style="background:rgb(50,50,50)"
                      clickable
                      class="items-center"
                      @click="openEditDialog(category)"
                    >
                      <q-icon
                        size="sm"
                        class="q-mr-md"
                        name="edit"
                        color="grey"
                      ></q-icon>
                      <q-item-label>
                        Edit
                      </q-item-label>
                    </q-item>
                    <q-item 
                      style="background:rgb(50,50,50)" 
                      clickable 
                      class="items-center"
                      @click="openDeleteDialog(category)"
                    >
                      <q-icon
                        size="sm"
                        class="q-mr-md"
                        name="delete"
                        color="grey"
                      ></q-icon>
                      <q-item-label>
                        Delete
                      </q-item-label>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </div>
          </template>
          <q-list 
            v-for="(label, idx) in category.labels"
            :key="label"
            @mouseover="showMenuBtn(label, category, null)" 
            @mouseleave="labelHoverMenu.idx = null" 
          >
            <q-item 
              dark  
              class="q-py-none label-card"
              clickable
            >
              <app-check-box v-if="queuedDisplays.length" @label-checkbox-clicked="handleLabelSelect" :label="label.name" :value="{label, queuedDisplays}"></app-check-box>
              <q-item-section>
                <q-item-label class="text-body1 ellipsis">
                  {{ label.name }}
                  <q-tooltip :delay="1000">
                    {{ label.name }}
                  </q-tooltip>
                </q-item-label> 
              </q-item-section>
              <q-btn
                v-show="labelHoverMenu.idx === idx && labelHoverMenu.categoryId === category.id"
                icon="more_vert"
                color="none"
                size="sm"
                style="width:10px;"
              >
                <q-menu 
                  transition-duration="0" 
                  anchor="top right" self="top left"
                >
                  <q-list dark>
                    <q-item
                      style="background:rgb(50,50,50)"
                      clickable
                      class="items-center"
                      @click="openEditDialog(label)"
                    >
                      <q-icon
                        size="sm"
                        class="q-mr-md"
                        name="edit"
                        color="grey"
                      ></q-icon>
                      <q-item-label>
                        Edit
                      </q-item-label>
                    </q-item>

                    <q-item 
                      style="background:rgb(50,50,50)" 
                      clickable 
                      class="items-center"
                      @click="openDeleteDialog(label)"
                    >
                      <q-icon
                        size="sm"
                        class="q-mr-md"
                        name="delete"
                        color="grey"
                      ></q-icon>
                      <q-item-label>
                        Delete
                      </q-item-label>
                    </q-item>

                  </q-list>
                </q-menu>
              </q-btn>
              <div class="color-tag">&nbsp;</div>
            </q-item>
          </q-list>
        </q-expansion-item>
      </div>
    </q-scroll-area>

    <q-separator v-if="showLabelCheckbox" dark></q-separator>
    <q-card
      dark 
      v-if="showLabelCheckbox"
      class="q-pa-md"
    >
      <q-btn
        rounded
        :disabled="disableApplyBtn"
        label="apply"
        color="green-13"
        class="labels-btn"
        text-color="black"
        @click="onClickApplyLabels"
      >
      </q-btn>
    </q-card>

    <!-- Add label/category dialog -->
    <q-dialog v-model="createDialog" persistent
    >
      <q-card 
        v-if="categorySelected"
        dark
        style="width: 500px;
        max-width: 70vw;"
      >
        <q-card-section>
          <div class="text-h6">Create a Label Category</div>
        </q-card-section>

        <q-card-section class="q-pt-none q-mb-xl">
          <q-item-label class="text-bold q-mb-sm">Category name</q-item-label>
          <q-input
            dark
            label="Enter category name"
            v-model="createCategoryModel"
            bg-color="black"
            color="white"
            filled
            autofocus
          >
          </q-input>
        </q-card-section>

        <q-separator dark />

        <q-card-actions align="right" class="q-py-md">
          <q-btn 
            flat 
            label="cancel" 
            v-close-popup 
            @click="closeLabelDialog"
          />
          <q-btn
            label="create label category"
            rounded
            color="green-13"
            text-color="black"
            :disabled="!createCategoryModel"
            @click="onClickAddLabelCategory"
            v-close-popup
          >
          </q-btn>
        </q-card-actions>
      </q-card>

      <q-card v-else dark style="width: 500px; max-width: 70vw;">
        <q-card-section>
          <div class="text-h6">Create a Label</div>
        </q-card-section>

        <q-card-section class="q-pt-none q-mb-xl">
          <q-item-label class="text-bold q-mb-sm">Label name</q-item-label>
          <q-input
            dark
            label="Enter label name"
            v-model="createLabelModel.name"
            bg-color="black"
            color="white"
            filled
            autofocus
            class="q-mb-lg q-mt-sm"
          >
          </q-input>
          <q-item-label class="text-bold q-mb-sm">Nest under</q-item-label>
          <q-select
            dark
            outlined
            label="Select a category"
            v-model="createLabelModel.nestedCategory"
            :options="categories"
            color="white"
            filled
            bg-color="black"
          />
        </q-card-section>

        <q-separator dark />

        <q-card-actions align="right" class="q-py-md">
          <q-btn flat label="cancel" v-close-popup @click="closeLabelDialog"/>
          <q-btn
            label="create label"
            rounded
            color="green-13"
            text-color="black"
            :disabled="!createLabelModel.name || !createLabelModel.nestedCategory"
            @click="onClickAddLabel"
            v-close-popup
          >
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Edit label/category dialog -->
    <q-dialog v-model="editDialog" persistent @hide="closeLabelDialog">
      <q-card 
        dark
        style="width:500px;max-width:70vw;"
      >
        <q-card-section>
          <div class="text-h6">Edit {{editModel.editType === 'label' ? 'Label' : 'Category'}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none q-mb-xl">
          <q-item-label class="text-bold  q-mb-sm"> name </q-item-label>
          <q-input
            dark
            :label="`Enter ${editModel.editType === 'label' ? 'Label' : 'Category'} name`"
            v-model="editModel.name"
            bg-color="black"
            color="white"
            filled
            autofocus
          >
          </q-input>
        </q-card-section>

        <q-separator dark />

        <q-card-actions align="right" class="q-py-md">
          <q-btn 
            flat 
            label="cancel" 
            v-close-popup
          />
          <q-btn
            label="Edit"
            rounded
            color="green-13"
            text-color="black"
            :disabled="disableEditBtn"
            v-close-popup
            @click="editModel.editType === 'label' ? onClickEditLabel() : onClickEditCategory()"
          >
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Delete label/category dialog -->
    <q-dialog v-model="deleteDialog" persistent @hide="closeLabelDialog">
      <q-card 
        dark
        style="width: 500px;
        max-width: 70vw;"
      >
        <q-card-section>
          <div class="text-h6">Delete this {{ deleteModel.editType === 'label' ? 'label' : 'label category' }}?</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          You are about to permanently delete the <span class="text-subtitle1">
          <b>{{deleteModel.name}}</b></span> {{ deleteModel.editType === 'label' ? 'label' : 'label category' }}. 
          {{ deleteModel.editType === 'category' ? 'This will delete all of the labels in this category.' : ''}} 
          Are you sure you want to continue?
        </q-card-section>

        <q-separator dark></q-separator>

        <q-card-actions align="right" class="q-pa-md">
          <q-btn 
            flat
            label="cancel"
            text-color="white"
            v-close-popup
          />
          <q-btn 
            :label="`yes, delete this ${deleteModel.editType}`"
            rounded color="red"
            v-close-popup
            @click="deleteModel.editType === 'label' ? onClickDeleteLabel() : onClickDeleteCategory()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Move label dialog -->
    <q-dialog v-model="moveDialog" persistent>
      <q-card  dark style="width: 500px; max-width: 70vw;">
        <q-card-section>
          <div class="text-h6">Move label</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <span class="text-subtitle1">
          the <b>{{moveModel.name}}</b></span> label will be permanently removed from all devices before being moved
          to new category. Are you sure you want to continue?
        </q-card-section>

        <q-card-section class="q-pt-none q-mb-xl">
          <q-item-label class="text-bold q-mb-sm">Move to </q-item-label>
          <q-select
            dark
            outlined
            label="Select a category"
            v-model="moveModel.moveToCategory"
            :options="categories"
            color="white"
            filled
            bg-color="black"
          />
        </q-card-section>

        <q-separator dark />

        <q-card-actions align="right" class="q-py-md">
          <q-btn flat label="cancel" v-close-popup @click="closeLabelDialog"/>
          <q-btn
            label="move label"
            rounded
            color="green-13"
            text-color="black"
            :disabled="!moveModel.moveToCategory"
            @click="onClickMoveLabel"
            v-close-popup
          >
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted, onMounted } from 'vue';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { 
  addLabelCategory,
  addLabelToCategory,
  updateLabel,
  deleteLabel,
  updateCategory,
  deleteCategory
} from '../service/tenantService';
import { 
  getCachedLabelCategory,
  getCachedLabel,
  getLabelCategories,
  setCachedLabelCategory,
  setCachedLabel,
  setLabelCategories,

} from '../store';
import { fetchLabelCategories } from '@/service/tenantService';
import { useQuasar } from 'quasar';
import AppCheckBox from '@/components/AppCheckBox';

export default {
  props: {
    showLabelCheckbox: Boolean,
    queuedDisplays: Array,
    displays: Array
  },
  components: {
    AppCheckBox
  },
  setup(props, { emit }) {
    const $q = useQuasar();
    const labelCategories = computed(() => {
      return getLabelCategories();
    });
    const allLabels = computed(() => {
      const labelsArr = [];
      labelCategories.value?.forEach(cat => {
        labelsArr.push(...cat?.labels);
      })
      return labelsArr
    })
    const createDialog = ref(false);
    const editDialog = ref(false);
    const deleteDialog = ref(false);
    const moveDialog = ref(false);
    const createCategoryModel = ref('');
    const createLabelModel = ref({});
    const editModel = ref({});
    const deleteModel = ref({});
    const moveModel = ref({});
    const categorySelected = ref(false);
    const categories = computed(() => {
      const names = [];
      labelCategories.value?.forEach((el) => {
        let name = el.name
        names.push(name)
      });
      return names
    })
    const canCreateLabels = computed(() => {
      return labelCategories.value?.length > 0;
    })
    const labelHoverMenu = ref({
      idx: null,
      categoryId: null
    });
    const categoryHoverMenu = ref({
      idx: null
    });
    const queuedLabels = ref([]);
    const indeterminateLabels = ref([]);
    const interactiveLabels = ref([]);
    const interactiveVal = ref('');
    const disableApplyBtn = computed(() => {
      if (!interactiveVal.value) {
        return true
      } else {
        return false
      }
    })
    const disableEditBtn = ref(true);
 
    watch(() => props.queuedDisplays, (cur, prev) => {
      if (!cur.length) {
        // Resets the logic for disabling the apply button
        interactiveVal.value = '';
        queuedLabels.value = [];
        indeterminateLabels.value = [];
        interactiveLabels.value = [];
      }
      if (cur && !isEqual(cur, prev)) {
        allLabels.value?.forEach(label => {
          if (cur.length && cur.every(el => el?.labelGids?.includes(label.id))) {
            handleLabelSelect(label, 'staticChecked');
          }
          else if (cur.length && cur.some(el => el?.labelGids?.includes(label.id))) {
            handleLabelSelect(label,'staticIndeterminate');
          }
        })
      } else {
        queuedLabels.value = [];
      }
    })

    // Checks if label category name or label name has changed in edit modal to enable apply btn
    watch(() => editModel.value.name, (cur) => {
      const cachedLabelCategory = getCachedLabelCategory();
      const cachedLabel = getCachedLabel();
      if (cachedLabelCategory?.name) {
        if (cur !== cachedLabelCategory?.name) {
          disableEditBtn.value = false;
        } else {
          disableEditBtn.value = true;
        }
      }
      if (cachedLabel?.name) {
        if (cur !== cachedLabel?.name) {
          disableEditBtn.value = false;
        } else {
          disableEditBtn.value = true;
        }
      }
    })

    onMounted(() => {
      setQueuedLabels()
    })

    function setQueuedLabels() {
      allLabels.value?.forEach(label => {
        if (props?.queuedDisplays?.length && props?.queuedDisplays?.every(el => el?.labelGids?.includes(label.id))) {
          handleLabelSelect(label, 'staticChecked');
        }
        else if (props?.queuedDisplays?.length && props?.queuedDisplays?.some(el => el?.labelGids?.includes(label.id))) {
          handleLabelSelect(label,'staticIndeterminate');
        }
      })
    }

    onUnmounted(() => {
      queuedLabels.value = [];
      interactiveVal.value = '';
      indeterminateLabels.value = [];
      interactiveLabels.value = [];
    });

    function openLabelsDialog(labelType) {
      if (labelType === 'category') {
        categorySelected.value = true;
        createDialog.value = true;
      } else {
        createDialog.value = true;
      }
    }

    async function onClickAddLabelCategory() {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Creating label category...',
        spinner: true
      });
      try {
        const resp = await addLabelCategory(createCategoryModel.value)
        closeLabelDialog();
        if (resp) {
          const resp = await fetchLabelCategories();
          setLabelCategories(resp);
          notify({
            type: 'positive',
            message: 'Successfully created label category',
            spinner: false
          });
        }
      } catch (err) {
        const errString = JSON.stringify(err);
        const errObj = JSON.parse(errString);
        if (errObj?.graphQLErrors[0]?.extensions?.error_code === 'SKCM_ErrBadRequest') {
          notify({
          type: 'negative',
          message: errObj.message,
          spinner: false
        });
        } else {
          notify({
            type: 'negative',
            message: `Unable to create label ${err}`,
            spinner: false
          });
        }
        console.warn(err);
        closeLabelDialog();
      }
    }

    async function onClickAddLabel() {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Creating label...',
        spinner: true
      });
      try {
        createDialog.value = false;
        const selectedCategory = labelCategories.value?.filter(el => el?.name === createLabelModel.value?.nestedCategory);
        const resp = await addLabelToCategory(createLabelModel.value?.name, selectedCategory[0].id)
        closeLabelDialog();

        if (resp) {
          const resp = await fetchLabelCategories();
          setLabelCategories(resp);
          notify({
            type: 'positive',
            message: 'Successfully created label',
            spinner: false
          });
        }
      } catch (err) {
        const errString = JSON.stringify(err);
        const errObj = JSON.parse(errString);
        const errorMessage = errObj?.graphQLErrors[0]?.extensions?.debug?.message
        if (errorMessage.startsWith('Duplicate')) {
          notify({
          type: 'negative',
          message: `${errorMessage}`,
          caption: 'A label with this name already exists in this label category. Please ensure new labels are unique.',
          spinner: false
        });
        } else {
          notify({
            type: 'negative',
            message: `Unable to create label`,
            spinner: false
          });
        }
        closeLabelDialog();
        console.error(errObj);
      }
    }

    function openEditDialog(obj) {
      setCachedLabelCategory({});
      setCachedLabel({});
      if (obj.__typename === 'LabelCategory') {
        Object.assign(editModel.value, obj);
        editModel.value['editType'] = 'category';
        setCachedLabelCategory(obj);
      } else {
        Object.assign(editModel.value, obj);
        editModel.value['editType'] = 'label';
        setCachedLabel(obj);
      }
      editDialog.value = true;
    }

    async function onClickEditLabel() {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Updating label...',
        spinner: true
      });
      try {
        const resp = await updateLabel(editModel?.value.name, editModel.value?.id, editModel.value?.categoryGid)
        if (resp) {
          notify({
            type: 'positive',
            message: 'Successfully updated label',
            spinner: false
          });
          const resp = await fetchLabelCategories()
          setLabelCategories(resp);
        }
      } catch (err) {
        console.warn(err);
        notify({
          type: 'negative',
          message: `Unable to update label ${err}`,
          spinner: false
        });
      }
    }

    async function onClickEditCategory() {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Updating category...',
        spinner: true
      });
      try {
        const resp = await updateCategory(editModel.value?.name, editModel.value?.id)
        if (resp) {
          notify({
            type: 'positive',
            message: 'Successfully updated catergory',
            spinner: false
          });
          const resp = await fetchLabelCategories()
          setLabelCategories(resp);
        }
      } catch (err) {
        console.warn(err);
        notify({
          type: 'negative',
          message: `Unable to update label ${err}`,
          spinner: false
        });
      }
    }

    function openDeleteDialog(obj) {
      if (obj.__typename === 'Label') {
        Object.assign(deleteModel.value, obj);
        deleteModel.value['editType'] = 'label';
      } else {
        Object.assign(deleteModel.value, obj);
        deleteModel.value['editType'] = 'category';
      }
      deleteDialog.value = true;
    }

    async function onClickDeleteLabel() {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Deleting label...',
        spinner: true
      });
      try {
        const resp = await deleteLabel(deleteModel.value?.id)
        if (resp) {
          notify({
            type: 'positive',
            message: 'Successfully deleted label',
            spinner: false
          });
          const resp = await fetchLabelCategories()
          setLabelCategories(resp);
        }
      } catch (err) {
        console.warn(err);
        notify({
          type: 'negative',
          message: `Unable to delete label ${err}`,
          spinner: false
        });
      }
      editModel.value = {
        name: '',
        nestedCategory: ''
      }
    }

    async function onClickDeleteCategory() {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Deleting label category...',
        spinner: true
      });
      try {
        const resp = await deleteCategory(deleteModel.value?.id)
        if (resp) {
          notify({
            type: 'positive',
            message: 'Successfully deleted label category',
            spinner: false
          });
          const resp = await fetchLabelCategories()
          setLabelCategories(resp);
        }
      } catch (err) {
        console.warn(err);
        notify({
          type: 'negative',
          message: `Unable to delete label category ${err}`,
          spinner: false
        });
      }
      deleteModel.value = {}
    }

    function openMoveDialog(label) {
      moveModel.value = pick(label, ['id', 'name', 'categoryGid'])
      moveDialog.value = true;
    }

    async function onClickMoveLabel() {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Removing label from current category...',
        spinner: true
      });
      try {
        const deleteResp = await deleteLabel(moveModel.value?.id)
        if (deleteResp) {
          notify({
            type: 'ongoing',
            message: 'Adding label to new category...',
            spinner: true
          });

          const selectedCategory = labelCategories.value?.filter(el => el.name ===  moveModel.value?.moveToCategory);
          const resp = await addLabelToCategory(moveModel.value?.name, selectedCategory[0].id)
          if (resp) {
            notify({
              type: 'positive',
              message: 'Successfully moved label',
              spinner: false
            });
            const resp = await fetchLabelCategories()
            setLabelCategories(resp);
          }
        }
      } catch (err) {
        console.warn(err);
        notify({
          type: 'negative',
          message: `Unable to move label ${err}`,
          spinner: false
        });
      }
      deleteModel.value = {}
    }

    function showMenuBtn(label, category, idx) {
      if (label) {
        const labelIdx = category.labels.map( el => el.name ).indexOf(label.name)
        labelHoverMenu.value.idx = labelIdx;
        labelHoverMenu.value.categoryId = category.id;
      } else {
        categoryHoverMenu.value.idx = idx;
      }
    }

    function closeLabelDialog() {
      createDialog.value = false;
      editDialog.value = false;
      deleteDialog.value = false;
      createCategoryModel.value = '';
      categorySelected.value = false;
      createLabelModel.value = {};
      editModel.value = {};
      moveModel.value = {};
    }

    function onClickApplyLabels() {
      const queuedLabelGids = queuedLabels.value?.map(el => el.id);
      const uniqueLabelGids = [...new Set(queuedLabelGids)]
      const indeterminateLabelGids = indeterminateLabels.value?.map(el => el.id);

      emit('onClickApplyLabels', uniqueLabelGids, indeterminateLabelGids)
      
      queuedLabels.value = [];
      indeterminateLabels.value = [];
      interactiveLabels.value = [];
    }

    function handleLabelSelect(label, status) {
      if (status === 'checked' || status === 'unchecked') {
        interactiveVal.value = status;
      }
      const queuedLabelIdx = queuedLabels.value?.indexOf(label);
      const indeterminateLabelIdx = indeterminateLabels.value?.indexOf(label);
      if (status === 'checked') {
        if (!queuedLabels.value?.includes(label)) {
          queuedLabels.value?.push(label);
          interactiveLabels.value?.push(label);
        }
        if (indeterminateLabels.value?.includes(label)) {
          indeterminateLabels.value?.splice(indeterminateLabelIdx, 1);
        }
      }
      if (status === 'staticChecked') {
        if (interactiveLabels.value?.findIndex(el => el.id === label.id) !== -1) {
          return
        }
        if (!queuedLabels.value?.includes(label)) {
          queuedLabels.value?.push(label);
        }
        if (indeterminateLabels.value?.includes(label)) {
          indeterminateLabels.value?.splice(indeterminateLabelIdx, 1);
        }
      }
      if (status === 'unchecked') {
        if (queuedLabels.value?.includes(label)) {
          queuedLabels.value?.splice(queuedLabelIdx, 1);
          interactiveLabels.value?.push(label);
        }
        if (indeterminateLabels.value?.includes(label)) {
          indeterminateLabels.value?.splice(indeterminateLabelIdx, 1);
        }
      }
      if (status === 'indeterminate') {
        if (!indeterminateLabels.value?.includes(label)) {
          indeterminateLabels.value?.push(label);
        }
        if (queuedLabels.value?.includes(label)) {
          queuedLabels.value?.splice(queuedLabelIdx, 1);
        }
      }
      if (status === 'staticIndeterminate') {
        if (interactiveLabels.value?.findIndex(el => el.id === label.id) !== -1) {
          return
        }
        if (!indeterminateLabels.value?.includes(label)) {
          indeterminateLabels.value?.push(label);
        }
        if (queuedLabels.value?.includes(label)) {
          queuedLabels.value?.splice(queuedLabelIdx, 1);
        }
      }
    }
  
    return {
      labelCategories,
      createDialog,
      editDialog,
      deleteDialog,
      moveDialog,
      createCategoryModel,
      createLabelModel,
      editModel,
      deleteModel,
      moveModel,
      categorySelected,
      categories,
      canCreateLabels,
      disableApplyBtn,
      disableEditBtn,
      labelHoverMenu,
      categoryHoverMenu,
      queuedLabels,
      allLabels,
      openLabelsDialog,
      onClickAddLabelCategory,
      onClickAddLabel,
      closeLabelDialog,
      openEditDialog,
      onClickEditLabel,
      onClickEditCategory,
      openDeleteDialog,
      onClickDeleteCategory,
      onClickDeleteLabel,
      openMoveDialog,
      onClickMoveLabel,
      showMenuBtn,
      onClickApplyLabels,
      handleLabelSelect,
      emit
    };
  },
};
</script>

<style >
.q-card--dark {
  box-shadow: none !important;
}
.q-btn__content.text-center.col.items-center.q-anchor--skip.justify-center.row
  > i {
  margin-right: 3px;
}
.q-btn__content.text-center.col.items-center.q-anchor--skip.justify-center.row {
  text-align: left;
}
.label-card {
  overflow: auto;
}
.label-card:nth-child(even) {
  background-color: rgb(35, 35, 35);
}
.color-tag {
  width:7px;
  min-width:7px;
  height:80%;
  background-color: #2bc96d;
  position: absolute;
  right: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: none;
}
/* .add-labels-card {
  height:552px;
  width: 200px;
  background-color: rgb(25, 25, 25)
} */
.example::-webkit-scrollbar {
  display: none;
}
.q-btn .q-focus-helper {
  display: none;
}
.label-btn {
  transform: rotate(135deg);
}
.overlap-label-btn-wrapper {
  position: absolute;
  top: 29px;
  left: 12px;
}
.overlap-label-btn {
  transform: rotate(135deg);
  position: absolute;
  top: -11px;
  left: -18px;
  z-index: 999;
}
.base-label-btn {
  position: absolute;
  top: -18px;
  left: 3px;
}
.create-dropdown-items {
  height:4em;
  background: rgb(50, 50, 50);
}
#app > div > div.q-page-container.views > main > div:nth-child(3) > div.add-labels-card.q-mr-sm > div.q-card.q-card--dark.q-dark.q-mt-none > div.q-item.q-item-type.row.no-wrap.items-center.q-px-none > button > span.q-btn__content.text-center.col.items-center.q-anchor--skip.justify-center.row.no-wrap.text-no-wrap > i.notranslate.material-icons.q-icon.on-left {
  margin-left: 20px;
}
div.q-item.q-item-type.row.no-wrap.q-item--dark.q-item--clickable.q-link.cursor-pointer.q-focusable.q-hoverable > div.q-item__section.column.q-item__section--side.justify-center.q-item__section--avatar.q-focusable.relative-position.cursor-pointer.items-end {
  margin-left: -40px;
  padding-right: 0px;
  margin-right: 6px;
}
.disable-create-labels {
  cursor: not-allowed;
  pointer-events: none;
}
.q-btn-dropdown--simple * + .q-btn-dropdown__arrow {
  display: none;
}
.labels-btn {
  min-height: 50px !important;
  min-width: 160px;
  padding: 0 30px;
}
</style>
