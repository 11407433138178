<template>
    <q-layout view="hHh Lpr lff" class="shadow-2 rounded-borders">
      <q-header elevated  class="q-py-sm" style="background:rgb(35,35,35)">
        <q-toolbar>
          <q-item class="row justify-between q-py-none" style="width: 100%">
              <div class="row">
                <q-item-section avatar class="column">
                  <q-icon style="margin-left:-10px;" size="lg" name="img:/control_icon_logo.svg" />
                </q-item-section>
                <q-item-section avatar>
                  <img src="/control_text_logo.svg" />
                </q-item-section>
              </div>
              <div class="flex items-center">
                <div class="q-mr-md" v-if="getAvailableProducts() && getAvailableProducts().length">
                  <q-btn size="lg" flat icon="apps">
                    <q-menu auto-close>
                      <div class="row no-wrap q-pa-md product-selection">
                        <div class="row justify-center text-center">
                          <div style="width:150px;" v-for="productName in getAvailableProducts()" :key="productName">
                            <q-icon size="lg" class="cursor" @click="navigateToProduct(productName)">
                              <img :src="productDomains(productName)?.imgUrl" />
                            </q-icon>
                            <div class="text-weight-medium q-mt-md product-name">{{productDomains(productName)?.name}}</div>
                          </div>
                        </div>
                      </div>
                    </q-menu>
                  </q-btn>
                </div>
                <section :class="hasPlatformAdminAccess ? 'q-mr-xl q-ml-md' : ''">
                  <q-avatar style="cursor:pointer">
                    <img v-if="getUserInfo()?.picture" :src="getUserInfo()?.picture">
                    <span v-else class="alt-avatar-bg">{{getUserInfo()?.avatarIcon}}</span>
                    <q-menu dark auto-close class="menu">
                      <q-list style="min-width: 100px">
                        <q-item>
                          <q-item-section>
                            LOGGED IN AS <br/>
                            {{getUserInfo()?.email}}
                          </q-item-section>
                        </q-item>
                        <q-item>
                          <q-item-section>
                            TENANT <br/>
                            {{ currentTenant?.name }}
                          </q-item-section>
                        </q-item>
                        <q-item clickable @click="changeTenant" v-if="enableChangeTenants">
                          <q-item-section>
                            <span style="margin-top: 10px;">CHANGE TENANT</span>
                          </q-item-section>
                        </q-item>
                        <q-item clickable @click="navigateToPASettings" v-if="isPlatformAdminCapable">
                          <q-item-section>
                            <span style="margin-top: 10px;">PA SETTINGS</span>
                          </q-item-section>
                        </q-item>
                        <q-item clickable>
                          <q-item-section @click="logOut()">
                            <span style="margin-top: 10px;">LOG OUT</span>
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-avatar>
                  <div v-if="hasPlatformAdminAccess" class="platform-admin-banner">
                    <span style="--i:1">P</span>
                    <span style="--i:2">L</span>
                    <span style="--i:3">A</span>
                    <span style="--i:4">T</span>
                    <span style="--i:5">F</span>
                    <span style="--i:6">O</span>
                    <span style="--i:7">R</span>
                    <span style="--i:8">M</span>
                    <span style="--i:9">-</span>
                    <span style="--i:10">A</span>
                    <span style="--i:11">D</span>
                    <span style="--i:12">M</span>
                    <span style="--i:13">I</span>
                    <span style="--i:14">N</span>
                    <span style="--i:15">-</span>
                  </div>
                </section>
              </div>
            </q-item>
        </q-toolbar>
      </q-header>
  
      <q-drawer
        elevated
        v-model="drawer"
        show-if-above
        :mini="miniState"
        @mouseover="miniState = false"
        @mouseout="miniState = true"
        mini-to-overlay
        :breakpoint="500"
      >
        <q-scroll-area class="fit" id="nav-drawer-items">
          <q-list padding>
            <router-link :to="{path: '/devices'}" class="router-links" v-if="getIsLoggedIn">
              <q-item clickable v-ripple>
                <q-item-section avatar>
                  <q-icon size="sm">
                    <img src="../../public/devices_icon@1x.svg" />
                  </q-icon>
                </q-item-section>
                <q-item-section>
                  Devices
                </q-item-section>
              </q-item>
            </router-link>
  
            <router-link :to="{path: '/apps'}" class="router-links" v-if="getIsLoggedIn">
              <q-item clickable v-ripple>
                <q-item-section avatar>
                  <q-icon size="sm">
                    <img src="../../public/apps_icon@1x.svg" />
                  </q-icon>
                </q-item-section>
                <q-item-section>
                  Apps
                </q-item-section>
              </q-item>
            </router-link>

            <router-link :to="{path: '/jobStatus'}" class="router-links" v-if="getIsLoggedIn">
              <q-item clickable v-ripple>
                <q-item-section avatar>
                  <q-icon size="sm">
                    <img src="../../public//bulk_status_icon@1x.svg" />
                  </q-icon>
                </q-item-section>
                <q-item-section>
                  Job Status
                </q-item-section>
              </q-item>
            </router-link>
            
            <router-link :to="{path: '/reports'}" class="router-links" v-if="getIsLoggedIn && hasReportsFF">
              <q-item clickable v-ripple>
                <q-item-section avatar>
                  <q-icon size="sm">
                    <img src="../../public/reports_icon@1x.svg" />
                  </q-icon>
                </q-item-section>
                <q-item-section>
                  Reports
                </q-item-section>
              </q-item>
            </router-link>
            
            <router-link :to="{path: '/notificationGroups'}" class="router-links" v-if="getIsLoggedIn && hasAlertsFF">
              <q-item clickable v-ripple>
                <q-item-section avatar>
                  <q-icon class="text-white" size="sm">
                    <img src="../../public/alerts_icon.svg" />
                  </q-icon>
                </q-item-section>
                <q-item-section>
                  Notification Groups
                </q-item-section>
              </q-item>
            </router-link>
  
            <q-item class="absolute-bottom">
              <q-item-section class="full-width text-left">
                <span>{{`Control v${version}`}}</span>
              </q-item-section>
            </q-item>
            
          </q-list>
        </q-scroll-area>
      </q-drawer>
  
      <q-page-container class="views">
        <router-view></router-view>
      </q-page-container>
    </q-layout>
    <q-dialog v-model="disableApp" persistent>
      <q-card dark id="dialogCard">
        <q-card-section class="row items-center">
          <span class="q-ml-lg text-body1">You do not have permissions to access Skykit Control UI. Please contact your administrator.</span>
        </q-card-section>
        <q-card-actions class="q-mb-lg" align="right">
          <q-btn
            flat
            label="Close"
            color="green-13"
            v-close-popup
            @click="logOut()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </template>
  
  <script>
  import { ref, computed, watch } from "vue";
  import { version } from '../../package.json';
  import { 
    getIsLoggedIn, 
    getUserInfo, 
    getAvailableProducts, 
    getDisableAppState,
    setIsLoggedIn,
    auth
  } from '@/store';
  import { Cookie } from '@skykit-dev/skykit_login_utils';
  import { onRedirect } from '../utils/redirect';
  import { logOut } from '@/service/auth';
  import {
    
  } from '@/store';
  import Permissions from '../store/permissions'
  import { productDomains } from '@/configs/domain';
  import { getTenant } from '../service/tenantService';
  import { clearPersistedState } from '@/store'
  import useFeatureFlags from '@/composables/useFeatureFlags'

  export default {
    setup() {
      const enableChangeTenants = computed(() => {
        return Permissions.getters.hasChangeTenantsEnabled();
      })
      const isPlatformAdminCapable = computed(() => {
        return Permissions.getters.isPlatformAdminCapable()
      })
      const hasPlatformAdminAccess = computed(() => {
        return Permissions.getters.hasPlatformAdminAccess()
      })
      const {
        hasReportsFF,
        hasAlertsFF,
      } = useFeatureFlags()
      const currentTenant = ref({})

      watch(auth, (cur, prev) => {
        if (cur?.isLoggedIn && !prev?.isLoggedIn) {
          try {
            getCurrentTenant()
          } catch (err) {
            console.error(err)
          }
        }
      }, {
        immediate: true,
        deep: true
      })

      async function getCurrentTenant() {
        try {
          const tenant = await getTenant()
          if (!tenant) {
            currentTenant.value = 'N/A'
            console.warn('Unable to get current tenant from tenant service')
            return
          }
          currentTenant.value = tenant
        } catch (error) {
          console.error(error)
        }
      }

      async function changeTenant() {
        // set to false to re-trigger gql calls on redirect to cui
        setIsLoggedIn(false);

        // clear persisted search/sort values when changing tenant
        clearPersistedState()

        Cookie.create('referrer', JSON.stringify({
        // Get top level path only incase another tenant is unable to access detail pages
            redirectUrl: `${window.location.protocol}//${window.location.host}/devices`,
            app: 'cui'
        }));

        onRedirect(true);
      }

      function navigateToProduct(productName) {
        location.href=productDomains(productName)?.url
      }

      const disableApp = computed(() => getDisableAppState());

      function navigateToPASettings() {
        window.open(process.env.VUE_APP_PLATFORM_ADMIN_URL)
      }
  
      return {
        drawer: ref(false),
        miniState: ref(true),
        logOut,
        version,
        getIsLoggedIn,
        getUserInfo,
        changeTenant,
        disableApp,
        getAvailableProducts,
        productDomains,
        navigateToProduct,
        navigateToPASettings,
        enableChangeTenants,
        hasReportsFF,
        hasAlertsFF,
        isPlatformAdminCapable,
        hasPlatformAdminAccess,
        currentTenant
      };
    }
  }
  </script>
  
  <style lang="scss">
  .views {
    background: #121212;
    text-align: center;
    height: 100%;
  }
  
  .q-btn {
    transition: background-color .2s ease-in;
  }
  .q-btn:disabled {
    background: #252525 !important;
    color: #696969 !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
  .menu {
    font-weight: 500;
  }
  .alt-avatar-bg {
    height: 100%;
    width: 100%;
    background: #00e676;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
  }
  .cursor {
    cursor: pointer !important;
  }
  .product-selection {
    color: #fff;
    background: var(--q-dark);
    border-radius: 0;
    border: 4px solid var(--q-dark);

    .product-name {
      font-size: 1rem;
    }
  }
  #historyIcon {
    font-size: 13px;
    position: absolute;
    left: 25px;
    top: 20px;
  }
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
  }
  .platform-admin-banner {
    transform-style: preserve-3d;
    animation: animateText 10s linear infinite;
  }
  .platform-admin-banner span {
    position: absolute;
    top: 0px;
    right: 0px;
    background: rebeccapurple;
    color: white;
    font-size: .5em;
    font-weight: bolder;
    transform-origin: center;
    transform-style: preserve-3d;
    padding: 1px 10px;
    transform: rotateY(calc(var(--i) * calc(360deg / 15))) 
    translateZ(41px);
    border-top: white 1px solid;
    border-bottom: white 1px solid;
  }
  .platform-admin-banner span::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rebeccapurple;
    transform: translateZ(-5px);
  }
  @keyframes animateText {
    0% {
      transform: perspective(1000px) rotateY(360deg) rotateX(15deg)
      translateY(-30px);
    }
    100% {
      transform: perspective(1000px) rotateY(0deg) rotateX(15deg)
      translateY(-30px);
    }
  }

  </style>
  