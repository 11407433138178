<script setup>
import { ref, onMounted, computed } from 'vue'
import CommandComponent from '../components/CommandComponent.vue'
import BulkConfigComponent from '@/components/BulkConfigComponent'
import useCommands from '../composables/useCommands'
import useBulkConfig from '../composables/useBulkConfig'
import BulkAlertsComponent from './BulkAlertsComponent.vue'
import { useQuasar } from 'quasar'
import AppBtn from '../components/AppBtn'
import { bulkActionsList } from '../constants/bulkActions'
import useFeatureFlags from '@/composables/useFeatureFlags'

//eslint-disable-next-line no-undef
const props = defineProps({ 
  activeLabelFilters: {
    type: Array,
    default: () => []
  },
  activeSearchString: {
    type: String,
    default: ''
  },
  isAllInTenantSelected: {
    type: Boolean,
    default: false
  },
  queuedDisplays: {
    type: Array,
    default: () => []
  }
});

const $q = useQuasar()
const bulkActionsDialog = ref(false)
const drawerActions = [{id: 'sendCommand', name: 'Send Command'}]
const commandsList = ref({})
const selectedAction = ref(drawerActions[0].id)
const queuedAction = ref(null)
const hoveredActionIdx = ref(null)
const hasDisplaysSelected = computed(() => {
  return props.queuedDisplays?.length >= 1
})
const confirmDialog = ref(false)
const isCloseDialog = ref(false)
const {
  hasFeatureFlag
} = useFeatureFlags()

function hasBulkActionFeatureFlag(bulkAction) {
  if (bulkAction.featureFlag) {
    return hasFeatureFlag(bulkAction.featureFlag).value
  } else {
    return true;
  }
}

async function fetchCommandsList() {
  try {
    const resp = await fetchDeviceCommands();
  
    // Filter commands that aren't intended for bulk actions. 
    commandsList.value = resp.filter(el => el.public)
    
  } catch (err) { 
    console.error(err)
    $q.notify({
      type: 'negative',
      message: 'Unable to fetch commands list'
    })
  }
}

function onClickSelectAction(action) {
  // if a config value has been updated but not saved show a confirm modal to prevent navigation away
  if (!configItemVal.value) {
    selectAction(action)
  }
  else {
    queuedAction.value = action
    confirmDialog.value = true
  }
}

function selectAction(action) {
  if (isCloseDialog.value) {
    bulkActionsDialog.value = false
    isCloseDialog.value = false
    confirmDialog.value = false
  }
  if (!action && queuedAction.value) {
    selectedAction.value = queuedAction.value.id
    confirmDialog.value = false
  } else if (action) {
    selectedAction.value = action.id
  } else {
    selectedAction.value = drawerActions[0].id
  }
  selectedConfigOption.value = null
}

function closeDialog() {
  if (configItemVal.value) {
    isCloseDialog.value = true
    confirmDialog.value = true
  } else {
    bulkActionsDialog.value = false
  }
}

onMounted(() => {
  fetchCommandsList()
})

const {
  fetchDeviceCommands
} = useCommands()

const {
  selectedConfigOption,
  configItemVal
} = useBulkConfig()
</script>

<template>
  <div>
    <app-btn
      label="bulk action"
      :isSecondary="true"
      @click="bulkActionsDialog = true"
      :disable="!hasDisplaysSelected"
    >
    </app-btn>
    <q-dialog v-model="bulkActionsDialog" persistent>
      <q-card id="dialogCard" dark>
        <q-toolbar id="bulkActionsHeader">
          <q-toolbar-title class="text-bold q-pa-md q-ml-sm">Bulk Action</q-toolbar-title>
          <q-btn flat @click="closeDialog" round dense icon="close" />
        </q-toolbar>
        
        <q-separator dark></q-separator>

        <q-card-section class="q-pa-none" id="bulkActionsBody">
          <div class="row full-height">
            <div class="col-auto">
              <div v-for="(action, idx) in bulkActionsList" :key="action" class="action-container">
                <div 
                  :class="['flex', 'items-center', 'cursor-pointer', 'no-wrap',hoveredActionIdx === idx ? 'hovered-action' : '']"
                  @click="onClickSelectAction(action)"
                  @mouseover="hoveredActionIdx = idx" 
                  @mouseleave="hoveredActionIdx = null"
                  v-if="hasBulkActionFeatureFlag(action)"
                >
                  <div v-visible="selectedAction === action.id" class="option-tag q-pa-none q-ma-none"></div>
                  <span class="q-pa-sm q-mx-sm">{{ action.name }}</span>
                </div>
              </div>
            </div>

            <q-separator class="q-pa-none" vertical dark></q-separator>

            <div class="col relative-position">
              <command-component
                v-if="selectedAction === 'sendCommand'"
                :commandsList="commandsList"
                :activeLabelFilters="props.activeLabelFilters"
                :activeSearchString="activeSearchString"
                :isAllInTenantSelected="props.isAllInTenantSelected"
                :queuedDisplays="props.queuedDisplays"
              >
              </command-component>
              <bulk-config-component
                v-if="selectedAction === 'updateConfigs'"
                :activeLabelFilters="props.activeLabelFilters"
                :activeSearchString="activeSearchString"
                :isAllInTenantSelected="props.isAllInTenantSelected"
                :queuedDisplays="props.queuedDisplays"
              >
              </bulk-config-component>
              <bulk-alerts-component
                v-if="selectedAction === 'alerts'"
                :activeLabelFilters="props.activeLabelFilters"
                :activeSearchString="activeSearchString"
                :isAllInTenantSelected="props.isAllInTenantSelected"
                :queuedDisplays="props.queuedDisplays"
              >
              </bulk-alerts-component>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="confirmDialog">
      <q-card dark id="confirmDialogCard" class="q-pa-sm">
        <div class="row">
          <q-space></q-space>
          <q-btn v-close-popup flat>
            <q-icon size="sm" name="close"></q-icon>
          </q-btn>
        </div>
        <q-card-section class="row q-pb-xs q-pt-none">
          <span class="text-h6">Are you sure you want to navigate away?</span>
        </q-card-section>

        <q-card-section class="row items-center q-mb-lg">
          <span class="text-body1">Your changes will not be saved</span>
        </q-card-section>

        <q-separator dark></q-separator>

        <q-card-actions class="q-py-md q-pl-xl" align="right">
          <q-btn class="q-mr-xl" flat label="Cancel" color="white" v-close-popup />
          <app-btn
            color="red"
            text-color="white"
            label="Yes, continue to navigate away"
            @click="selectAction"
          ></app-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped>
#bulkActionsHeader {
  height: 50px;
}
#bulkActionsBody {
  height: calc(100% - 50px)
}
.option-tag {
  background: rgb(48,211,116);
  width: 5px;
  min-height: 42px;
  display: inline-block;
  padding: none;
}
.hovered-action {
  background-color: rgb(70,70,70);
}
#dialogCard {
  width:80%;
  max-width:1100px;
  height:65vh;
}
#confirmDialogCard {
  height: auto;
  width: fit-content;
}
.hovered-action {
  background-color: rgb(70,70,70);
}
#dialogCard {
  width:80%;
  max-width:800px;
  height:65vh;
}
#confirmDialogCard {
  height: auto;
  width: fit-content;
}
</style>
