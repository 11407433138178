<script setup>
import { configurableItems } from '../constants/bulkActions'
import useBulkConfig from '../composables/useBulkConfig'
import useDeviceQuery from '../composables/useDeviceQuery'
import ConfigProperty from './ConfigProperty.vue'
import Permissions from '../store/permissions'
import { useQuasar } from 'quasar'

//eslint-disable-next-line no-undef
const props = defineProps({
  queuedDisplays: {
    type: Array,
    default: () => []
  },
  activeLabelFilters: {
    type: Array,
    default: () => []
  },
  activeSearchString: {
    type: String,
    default: ''
  },
  isAllInTenantSelected: {
    type: Boolean,
    default: false
  }
});

const $q = useQuasar()

function onClickUpdateConfigs(val) {
  try {
    const isMultipleDevices = props.queuedDisplays.length > 1
    const deviceQuery = getDeviceQueryObject(
      props.queuedDisplays,
      props.isAllInTenantSelected,
      props.activeSearchString,
      props.activeLabelFilters
    )
    if (deviceQuery) {
      onUpdateConfigs(deviceQuery, val, isMultipleDevices)
    } else {
      console.error('Unable to create device query for bulk config update')
      $q.notify({
        type: 'negative',
        message: 'Unable to update the configuration on selected devices'
      })
    }
  } catch(err) {
    console.error(err)
    $q.notify({
      type: 'negative',
      message: 'Unable to update the configuration on selected devices'
    })
  }
}

function showConfigItem(item) {
  if (item.isPlatformAdmin) {
    return Permissions.getters.hasPlatformAdminAccess()
  }
  return true
}

const {
  selectedConfigOption,
  onUpdateConfigs
} = useBulkConfig()

const {
  getDeviceQueryObject
} = useDeviceQuery()
</script>

<template>
  <q-card dark class="full-width">
    <div v-if="!selectedConfigOption" class="q-mx-lg">
      <q-card-section>
        <div class="q-pt-lg">
          <span class="text-h6">Settings and Configurations</span>
        </div>
      </q-card-section>

      <q-card-section class="q-pa-md config-items-wrapper">
        <div class="">
          <div
            v-for="item in configurableItems"
            :key="item.id"
            class="config-items"
          >
            <q-item 
              v-if="showConfigItem(item)"
              class="config-item items-center relative-position"
              clickable
              @click="selectedConfigOption = item.id"
            >
              <span v-if="item.isPlatformAdmin" class="absolute pa-icon">PA</span>
              <q-item-section>
                <span class="text-body2 q-pl-md">
                  {{ item.label }}
                </span>
              </q-item-section>
              <q-space></q-space>
              <q-icon name="navigate_next" size="md" color="grey-6"></q-icon>
            </q-item>
          </div>
        </div>
      </q-card-section>
    </div>
    <config-property
      :property="selectedConfigOption"
      @on-update-configs="onClickUpdateConfigs"
      v-if="selectedConfigOption"
    >
    </config-property>
  </q-card>
</template>

<style>
.config-items-wrapper {
  height: calc(65vh * .7);
  overflow-y: auto;
}
.config-item {
  border: 1px solid rgb(42,42,42);
}
.config-items:nth-child(odd) {
  background-color: rgb(35,35,35);
}
.config-items:nth-child(even) {
  background-color: rgb(37,37,37);
}
.pa-icon {
  color: rebeccapurple;
  font-size: 12px;
  font-weight: bold;
  transform: rotate(-25deg);
  left: 7px;
  top: 7px;
}
</style>
