import '@/service/firebase';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import { getEnv } from './configs/domain';
import { LoginAuth } from '@skykit-dev/skykit_login_utils';
import VueEasyLightbox from 'vue-easy-lightbox';
import { LDPlugin } from 'launchdarkly-vue-client-sdk';
import { createGtm } from '@gtm-support/vue-gtm';

LoginAuth.setup({ env: getEnv(process.env.VUE_APP_PROJECT_ID) });

const app = createApp(App);
app.use(Quasar, quasarUserOptions);
app.use(router);
app.use(VueEasyLightbox);
app.use(LDPlugin, { deferInitialization: true })

app.use(
  createGtm({
    id: process.env.VUE_APP_GTM_ID,
    vueRouter: router,
    enabled: !window.location.host.includes('localhost')
  })
)

app.directive('visible', (el, binding) => {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
})

app.config.globalProperties.$filters = {
  toTitleCase(value) {
    return value.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
};

app.mount('#app');
