<template>
  <q-layout view="hHh Lpr lff" class="shadow-2 rounded-borders loadingPage">
    <div class="row">
      <q-item-section avatar class="column">
        <q-icon
          style="margin-left: -10px"
          size="xl"
          name="img:/control_icon_logo.svg"
        />
      </q-item-section>
      <q-item-section avatar>
        <img src="/control_text_logo.svg" height="60" />
      </q-item-section>
    </div>
    <div class="row">
      <q-circular-progress
        indeterminate
        size="30px"
        color="white"
        class="q-ma-md"
      />
    </div>
    <q-dialog v-model="disableApp" persistent>
      <q-card dark id="dialogCard">
        <q-card-section class="row items-center">
          <span class="q-ml-lg text-body1"
            >You do not have permissions to access Skykit Control UI. Please
            contact your administrator.</span
          >
        </q-card-section>
        <q-card-actions class="q-mb-lg" align="right">
          <q-btn
            flat
            label="Close"
            color="green-13"
            v-close-popup
            @click="logOut()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-layout>
</template>
  
<script setup>
import { computed, watch } from "vue";
import { useRouter } from 'vue-router';
import { logOut } from "@/service/auth";
import { getDisableAppState, auth } from "@/store";

const router = useRouter();
const disableApp = computed(() => getDisableAppState());

watch(auth, (cur) => {
  if (cur?.isLoggedIn && router.currentRoute.value.path === '/') {
    router.push('/devices');
  }
}, {
  immediate: true,
  deep: true
})


</script>
  
  <style lang="scss" scoped>
.loadingPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(35, 35, 35);
}
</style>
  