import { reactive } from 'vue';

const state = reactive({
  cuiAccess: false,
  isPlatformAdminCapable: false,
  isPlatformAdmin: false,
  changeTenantsEnabled: false,
  firmwareTabAccess: false
});

const getters = {
  hasCuiAccess: () => state.cuiAccess,
  isPlatformAdminCapable: () => state.isPlatformAdminCapable,
  hasPlatformAdminAccess: () => state.isPlatformAdmin,
  hasChangeTenantsEnabled: () => state.changeTenantsEnabled,
  hasFirmwareTabAccess: () => state.firmwareTabAccess,
};

const mutations = {
  setCuiAccess: (val) => (state.cuiAccess = val),
  setPlatformAdminCapable: (val) => (state.isPlatformAdminCapable = val),
  setPlatformAdminAccess: (val) => (state.isPlatformAdmin = val),
  setChangeTenantsEnabled: (val) => (state.changeTenantsEnabled = val),
  setFirmwareTabEnabled: (val) => (state.firmwareTabAccess = val),
};

const actions = {
  setCuiAccess: (val) => {
    mutations.setCuiAccess(val);
  },
  setPlatformAdminCapable: (val) => {
    mutations.setPlatformAdminCapable(val);
  },
  setPlatformAdminAccess: (val) => {
    mutations.setPlatformAdminAccess(val);
  },
  setChangeTenantsEnabled: (val) => {
    mutations.setChangeTenantsEnabled(val);
  },
  setFirmwareTabEnabled: (val) => {
    mutations.setFirmwareTabEnabled(val);
  },
};

export default { state, getters, mutations, actions };
