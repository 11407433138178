import apolloClient from '@/lib/apolloClient';
import { orderBy } from 'lodash';
import {
  getTenantQuery,
  getLabelCategoriesQuery,
  createLabelCategory,
  createLabel,
  putLabel,
  deleteLabelFromCategory,
  putLabelCategory,
  deleteLabelCategory
} from '@/apollo/queries/tenant';
import { getTenantGid } from '@/store';
import { formatLabelCategoryResponse } from '../utils/labelUtils';

async function getTenant() {
  return await apolloClient.query({
      query: getTenantQuery,
      fetchPolicy: 'network-only',
      context: { clientName: 'tenant' },
      variables: {
        id: getTenantGid()
      }
    })
    .then(result => {
      return result?.data?.node;
    })
    .catch(error => {
      console.warn(error);
      throw error
    });
}

async function fetchLabelCategories() {
  return await apolloClient.query({
    query: getLabelCategoriesQuery,
    fetchPolicy: 'network-only',
    context: { clientName: 'tenant' },
    variables: {
      tenantGid: getTenantGid(),
    }
  })
  .then(resp => {
    if (resp) {
      const result = formatLabelCategoryResponse(resp);
      
      return orderBy(result, "node.name", "asc")
    }
  })
  .catch(error => {
    console.warn(error);
  });
}

async function addLabelCategory(name) {
  return await apolloClient.mutate({
    mutation: createLabelCategory,
    fetchPolicy: 'network-only',
    context: { clientName: 'tenant' },
    variables: {
      input: {
        tenantGid: getTenantGid(),
        name: name,
      }
    }
  })
  .then(result => {
    return result;
  })
  .catch(error => {
    console.warn(error)
    throw(error);
  });
}

async function addLabelToCategory(name, categoryGid) {
  return await apolloClient.mutate({
    mutation: createLabel,
    fetchPolicy: 'network-only',
    context: { clientName: 'tenant' },
    variables: {
      input: {
        tenantGid: getTenantGid(),
        name,
        categoryGid
      }
    }
  })
  .then(result => {
    return result;
  })
  .catch(error => {
    console.warn(error)
    throw(error);
  });
}

async function updateLabel(name, gid, categoryGid) {
  return await apolloClient.mutate({
    mutation: putLabel,
    fetchPolicy: 'network-only',
    context: { clientName: 'tenant' },
    variables: {
      input: {
        name,
        gid,
        categoryGid
      }
    }
  })
  .then(result => {
    return result;
  })
  .catch(error => {
    console.warn(error)
    throw(error);
  });
}

async function deleteLabel(gid) {
  return await apolloClient.mutate({
    mutation: deleteLabelFromCategory,
    fetchPolicy: 'network-only',
    context: { clientName: 'tenant' },
    variables: {
      input: {
        gid
      }
    }
  })
  .then(result => {
    return result;
  })
  .catch(error => {
    console.warn(error)
    throw(error);
  });
}

async function updateCategory(name, gid) {
  return await apolloClient.mutate({
    mutation: putLabelCategory,
    fetchPolicy: 'network-only',
    context: { clientName: 'tenant' },
    variables: {
      input: {
        name,
        gid
      }
    }
  })
  .then(result => {
    return result;
  })
  .catch(error => {
    console.warn(error)
    throw(error);
  });
}

async function deleteCategory(gid) {
  return await apolloClient.mutate({
    mutation: deleteLabelCategory,
    fetchPolicy: 'network-only',
    context: { clientName: 'tenant' },
    variables: {
      input: {
        gid
      }
    }
  })
  .then(result => {
    return result;
  })
  .catch(error => {
    console.warn(error)
    throw(error);
  });
}

export {
  getTenant,
  fetchLabelCategories,
  addLabelCategory,
  addLabelToCategory,
  updateLabel,
  deleteLabel,
  updateCategory,
  deleteCategory
}