<script setup>
import { ref } from "vue"
import useCommands from '../composables/useCommands'
import useLabelFilters from "../composables/useLabelFilters"
import AppBtn from '../components/AppBtn'

//eslint-disable-next-line no-undef
const props = defineProps({ 
  commandsList: {
    type: Object,
    default: () => {}
  },
  queuedDisplays: {
    type: Array,
    default: () => []
  },
  activeLabelFilters: {
    type: Array,
    default: () => []
  },
  activeSearchString: {
    type: String,
    default: ''
  },
  isAllInTenantSelected: {
    type: Boolean,
    default: false
  }
});

const commandOptions = ref(props.commandsList);
const commandSelect = ref(null)
const commandString = ref("")

// send command to device
async function sendCommand(cmdString, cmdSelect) {
  if (cmdSelect) {
    cmdString = cmdSelect.value
  }
  const deviceQuery = getDeviceQueryObject()
  const bulkCommandObj = {
    isBulkCommand: true,
    isMultipleDevices: props.queuedDisplays.length > 1
  }
  sendDeviceCommand(deviceQuery, cmdString, bulkCommandObj)
  
  commandSelect.value = null
  commandString.value = ""
}

function getDeviceQueryObject() {
  let deviceQuery = {}
  if (props.isAllInTenantSelected && !props.activeSearchString && !props.activeLabelFilters.length) {
    deviceQuery = {
      searchText: 'skAllDisplays'
    };
  } else if (props.isAllInTenantSelected && (props.activeSearchString || props.activeLabelFilters)) {
    const labelIds = getActiveLabelIds(props.activeLabelFilters)
    deviceQuery = {
      searchText: props.activeSearchString,
      labelIds
    };
  } else {
    const displayIds = getQueuedDisplayIds()
    deviceQuery = {
      deviceKeys: displayIds
    };
  }
  return deviceQuery
}

function getQueuedDisplayIds() {
 if (props.queuedDisplays) {
  const arr = props.queuedDisplays.map(display =>  display.displayId)
  return arr
 }
 return []
}

const {
  isDisabled,
  sendDeviceCommand
} = useCommands()

const {
  getActiveLabelIds
} = useLabelFilters()
</script>

<template>
  <div>
    <q-card dark class="q-px-lg q-pt-lg">
      <q-card-section>
        <span class="text-h6">Send a Command</span>
      </q-card-section>

      <q-card-section>
        <q-item-label class="text-bold">Choose a command</q-item-label>
        <q-item class="q-mb-lg q-px-none">
          <q-select
            dense
            label="Choose command to send to device"
            v-model="commandSelect"
            class="q-mt-sm full-width"
            :options="commandOptions"
            dark
            outlined
            color="white"
            :disable="(!!commandString || isDisabled)"
            input-class="text-body1"
            clearable
          >
            <template v-slot:label>
              <div class="row items-center all-pointer-events">
                <span class="text-body2">Choose command to send to device</span>
              </div>
            </template>
          </q-select>
        </q-item>
  
        <div class="row items-center q-mb-lg">
          <q-separator dark style="height:1px;" class="col"></q-separator> <span class="text-center q-px-sm">or</span> <q-separator dark style="height:1px;" class="col"></q-separator>
        </div>
  
        <q-item-label class="text-bold">Enter a custom command</q-item-label>
        <q-item class="text-center full-width q-px-none">
          <q-item-section>
            <q-input
              label=""
              v-model="commandString"
              class="q-mt-sm"
              :options="commandOptions"
              dark
              outlined
              color="white"
              :disable="(!!commandSelect || isDisabled)"
              input-class="text-body1"
              clearable
            >
              <template v-slot:label>
                <div class="row items-center all-pointer-events">
                  <span class="text-body2">Enter a custom command; i.e /to/something</span>
                </div>
              </template>
            </q-input>
          </q-item-section>
        </q-item>
      </q-card-section>
    </q-card>

    <div class="absolute-bottom">
      <q-separator dark></q-separator>
      <q-item class="q-py-lg">
        <q-space></q-space>
        <app-btn
          :isDisabled="!commandString && !commandSelect"
          label="Send Command"
          @click="sendCommand(commandString, commandSelect)"
        >
        </app-btn>
      </q-item>
    </div>
  </div>
</template>

<style lang="scss" >
.item-label {
  font-weight: bolder;
  font-size: 1.2rem;
  color: white;
  min-width: 9rem;
}
</style>
  