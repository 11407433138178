import { ref } from 'vue'
import deviceJobs from '../service/deviceJobs'
import deviceServices from '../service/deviceServices'
import useQuasar from 'quasar/src/composables/use-quasar.js';

export default function useEvents() {
  const $q = useQuasar()
  const loading = ref(false)

  async function downloadEventCsv(reqObj, deviceId) {
    const notify = $q.notify({
      type: 'ongoing',
      message: 'Downloading report...'
    })
    try {
      const resp = await deviceJobs.getEventReport(reqObj)
      if (resp) {
        deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
          if (data){
            if (data.status === 'incomplete') {
              return
            }
            if (data.status === 'complete' && data.artifacts) {
              const reference = data.artifacts[deviceId]
              const downloadUrl = await deviceServices.fetchFileDownloadUrl(reference)
              if (downloadUrl.name === 'FirebaseError') {
                notify({
                  type: 'negative',
                  message: downloadUrl.name,
                  caption: downloadUrl.code,
                })
                loading.value = false
                return
              }
              const link = document.createElement('a')
              link.href = downloadUrl
              link.download = `Event Report: ${Date.now()}`
              document.body.appendChild(link)
              link.click()

              loading.value = false
              notify()
            }
            if (data.status === 'complete' && data.numErrors) {
              const error = await deviceServices.fetchJobError(resp.jobTraceId, deviceId)
              notify({
                type: 'negative',
                message: setErrorMessage(error),
                spinner: false
              })
              loading.value = false
              return
            }
          }
        })
      }
    } catch(err) {
      console.error(err)
      notify({
        type: 'negative',
        message: 'Unable to send command',
        spinner: false,
        caption: err
      })
    }
  }

  function setErrorMessage(err) {
    if (err === 'No Content') {
      return 'We couldn\'t find any events within the specified date range. Please adjust the date range and try again.'
    }
    return err
  }

  return {
    downloadEventCsv,
    loading
  }
}
