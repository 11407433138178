<script setup>
import { ref, computed, onBeforeMount, onBeforeUnmount } from 'vue'
import useBulkConfig from '@/composables/useBulkConfig'
import ConfigFooter from './ConfigFooter.vue'
import regexRules from '../constants/regex'
import {
  rotationOptions,
  densityOptions,
  heartbeatOptions,
  logLevelOptions,
  otaBuildTypeOptions,
  channelOptions
} from "../constants/constants";
import TimezoneSelect from './TimezoneSelect'
import RestartDayTime from './RestartDayTime'

const { 
  configItemVal,
  getConfigPropObj
} = useBulkConfig()

// eslint-disable-next-line
const emit = defineEmits(['on-update-configs'])

// eslint-disable-next-line
const props = defineProps({
  property: {
    type: String,
    default: '',
  },
})
let configProp = ref(null)
let scheduledReboot = ref({
  restartDay: null,
  restartTime: null
})

const rules = ref({
  frameBufferRule: v => !!(v && v.match(regexRules.frameBufferRegex)) || !v || 'Field does not meet required format (e.g. 1920x1080@60)',
  applicationRule: v => !!(v && v.match(regexRules.packageNameRegex)) || !v || 'Field does not meet required format (e.g. com.skykit.home)',
  otaUrlRule: v => !!(v && v.match(regexRules.otaUrlRegex)) || !v || 'Field does not meet required format',
})

onBeforeMount(()=> {
  configProp.value = getConfigPropObj(props.property)
})

onBeforeUnmount(()=> {
  configProp.value = {}
  configItemVal.value = null
})

function getInputRules(configProp) {
  if (configProp && (configProp === 'homeApp' || configProp === 'kioskApp')) {
    return rules.value.applicationRule
  }
  if (configProp && configProp === 'frameBuffer') {
    return rules.value.frameBufferRule
  }
  if (configProp && configProp === 'otaUpdateUrl') {
    return rules.value.otaUrlRule
  }
  return []
}

const isValidInput = computed(() => {
  let isValid = false
  if (configProp.value.id === 'homeApp' || configProp.value.id === 'kioskApp' ) {
    isValid = regexRules.packageNameRegex.test(configItemVal.value)
  } else if (configProp.value.id === 'frameBuffer') {
    isValid = regexRules.frameBufferRegex.test(configItemVal.value)
  } else if (configProp.value.id === 'otaUpdateUrl') {
    isValid = regexRules.otaUrlRegex.test(configItemVal.value)
  } else if (configProp.value.id === 'scheduledReboot') {
    if (configItemVal.value === false) {
      isValid = true
    } else if ((scheduledReboot.value.restartTime !== null && scheduledReboot.value.restartTime !== undefined)
      && (scheduledReboot.value.restartDay !== null && scheduledReboot.value.restartDay !== undefined)) {
      isValid = true
    } else {
      isValid = false
    }
  } else {
    isValid = true
  }
  return isValid
}) 

function handleTimezoneUpdate(val) {
  configItemVal.value = val
}

function getToggleStatus() {
  let notSetlabel

  switch(configProp.value.label) {
    case 'Wifi Enabled' :
      notSetlabel = 'Wifi'
      break
    case 'Bluetooth Enabled' :
      notSetlabel = 'Bluetooth'
      break
    case 'Location Enabled' :
      notSetlabel = 'Location'
      break
    default :
      notSetlabel = configProp.value.label
  }
  
  if (configItemVal.value === null || configItemVal.value === undefined) {
    return `${notSetlabel} is not set`
  } else if (configItemVal.value === true) {
    return configProp.value.enabledStatus
  } else if (configItemVal.value === false) {
    return configProp.value.disabledStatus
  } else {
    return ''
  }
}

function getSelectOptions() {
  if (configProp.value.id === 'density') {
    return densityOptions
  } else if (configProp.value.id === 'rotation') {
    return rotationOptions
  } else if (configProp.value.id === 'heartbeatIntervalMinutes') {
    return heartbeatOptions
  } else if (configProp.value.id === 'logLevel' || configProp.value.id === 'remoteLogLevel') {
    return logLevelOptions
  } else if (configProp.value.id === 'otaBuildType') {
    return otaBuildTypeOptions
  } else if (configProp.value.id === 'otaChannel') {
    return channelOptions
  }
  return []
}

function handleScheduledRebootUpdate(val) {
  scheduledReboot.value = val  
}

function handleUpdateConfigs() {
  if (scheduledReboot.value?.restartDay !== undefined) {
    emit('on-update-configs', scheduledReboot.value)
  } else {
    emit('on-update-configs')
  }
}

</script>

<template>
  <div class="q-pa-md relative-position" id="configPropertyWrapper">
    <div class="q-pa-lg">
      <div class="q-mb-md">
        <span class="text-h6">
          {{ configProp.label }}
        </span>
      </div>
      <div class="q-mb-xl">
        <span class="text-body2">
          {{ configProp.caption }}
        </span>
      </div>
      <div v-if="configProp.component === 'slider'">
        <q-slider
          dark
          v-model="configItemVal"
          :min="0"
          :max="100"
          label
          color="green-13"
        />
      </div>
      <div v-if="configProp.component === 'toggle'">
        <div class="row items-center q-mb-lg">
          <div class="col-auto">
            <q-toggle
              size="xl"
              v-model="configItemVal"
              checked-icon="check"
              color="green-13"
              class="toggle"
            />
          </div>
          <div class="col q-ml-lg">
            <span class="text-body1">{{ getToggleStatus() }}</span>
          </div>
        </div>
        <div
          v-show="configProp.id === 'scheduledReboot' && configItemVal === true"
          class="row justify-start"
        >
          <restart-day-time @scheduledRebootUpdated="handleScheduledRebootUpdate"></restart-day-time>
        </div>
      </div>
      <div v-if="configProp.component === 'input'">
        <q-input
          v-model="configItemVal"
          dark
          outlined
          dense
          :label="configProp.inputLabel"
          color="white"
          :rules="[getInputRules(configProp.id)]"
          clearable
          no-error-icon
          hide-bottom-space
          lazy-rules
        >
        </q-input>
      </div>
      <div v-if="configProp.component === 'select' && configProp.id !== 'timezone'">
        <q-select
          dark
          outlined
          dense
          v-model="configItemVal"
          :options="getSelectOptions()"
          :label="configProp.inputLabel"
          color="white"
          map-options
          emit-value
        >
        <template v-slot:option="scope">
          <q-item
            v-bind="scope.itemProps"
            style="background-color:rgb(45,45,45)"
            class="items-center"
          >
            <span>{{ scope.opt.label }}</span>
          </q-item>
        </template>
        </q-select>
      </div>
      <div v-else-if="configProp.component === 'select' && configProp.id === 'timezone'">
        <timezone-select @tz-updated="handleTimezoneUpdate"></timezone-select>
      </div>
      <div class="absolute-bottom">
        <config-footer
          :isValidInput="isValidInput"
          @on-update-configs="handleUpdateConfigs"
        ></config-footer>
      </div>
    </div>
  </div>
</template>

<style>
.toggle {
  margin-left: -16px;
}
#configPropertyWrapper {
  height: calc(65vh - 50px)
}
</style>
