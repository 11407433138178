<script setup>
import { ref, computed } from 'vue'
import DeviceEvents from './DeviceEvents.vue'
import playerEvents from './PlayerEvents.vue'
import CommandsEvents from './CommandsEvents.vue'
import DownloadEventReportComponent from './DownloadEventReportComponent.vue'
import { getAvailableProducts } from '../store'

// eslint-disable-next-line
const props = defineProps({
  device: {
    type: Object,
    default: () => {},
  },
})

const device = ref(props.device)
const hasBeamPermission = computed(() => {
  return getAvailableProducts().includes('beam')
})

function showDownloadCsvBtn(tab) {
  return tab === 'commandEvents' ? 'invisible' : ''
}

const tab = ref('deviceEvents')
</script>

<template>
  <q-card class="q-mt-md q-mb-xl q-pa-lg" dark>
    <div class="row q-mb-xl">
      <div class="col-auto items-center q-pa-none">
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="green-13"
          indicator-color="green-13"
          align="left"
        >
          <q-tab default="true" name="deviceEvents" label="Device Events" />
          <q-tab v-if="hasBeamPermission" name="playerEvents" label="Beam Events" />
          <q-tab name="commandEvents" label="Command Events" />
        </q-tabs>

        <div class="row">
          <div class="col">
            <q-separator dark />
          </div>
        </div>
      </div>

      <q-space></q-space>
    
      <div class="col">
        <download-event-report-component 
          :class="[showDownloadCsvBtn(tab)]"
          :device="device"
          :activeTab="tab"
        >
        </download-event-report-component>
      </div>
    </div>

    <q-tab-panels
      v-model="tab"
      keep-alive
      animated
      class="tabPanel"
      style="background: none;"
    >
      <q-tab-panel dark name="deviceEvents" class="q-pa-none panel">
        <device-events :device="device"></device-events>
      </q-tab-panel>
      <q-tab-panel dark name="playerEvents" class="q-pa-none panel">
        <player-events :device="device"></player-events>
      </q-tab-panel>
      <q-tab-panel dark name="commandEvents" class="q-pa-none panel">
        <commands-events :device="device"></commands-events>
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
</template>

<style lang="scss" scoped>
.card {
  text-align: left;
  color: white;
  background-color: rgb(30, 30, 30);
  margin-top: 1rem;
  padding-top:40px;
}
.q-tab__label {
  font-size: 13px !important;
}
</style>
