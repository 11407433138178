<script setup>
import { ref, computed, onMounted } from 'vue'
import useAlerts from '../composables/useAlerts'
import deviceServices from '../service/deviceServices'
import deviceJobs from "../service/deviceJobs"
import { alertMuteDurationMsOptions } from "../constants/constants"
import { formatDateTimeString } from '../utils/dateUtils'
import AppBtn from '../components/AppBtn'
import AlertNotificationGroupList from "./AlertNotificationGroupList.vue"
import { useQuasar } from 'quasar'
import { getTenantCode } from '@/store'
import useFeatureFlags from '@/composables/useFeatureFlags'

// eslint-disable-next-line
const props = defineProps({
  device: {
    type: Object,
    default: () => {}
  }
})

const $q = useQuasar()
const isSmScreen = computed(() => {
  return $q.screen.lt.xs || $q.screen.lt.sm || $q.screen.lt.md
})
const {
  hasAlertsFF
} = useFeatureFlags()
const addNotificationGroupDialog = ref(false)
const removeNotificationGroupDialog = ref(false)
const muteDurationDialog = ref(false)
const muteDuration = ref(null)
const recipientsDialog = ref(false)
const selectedGroup = ref(null)
const selectedEvent = ref(null)
const confirmUnmuteDialog = ref(false)
const deviceAlerts = ref({
// Removing for initial release. Will be re-added in v2

  // boot: {
  //   code: 'boot',
  //   label: 'Device: Boot',
  //   description: 'Alert when device is booted',
  //   notificationGroups: []
  // },
  offline: {
    code: 'offline',
    label: 'Device: Offline',
    description: 'Notify users when devices go offline past the Offline Status Threshold.',
    notificationGroups: []
  },
  online: {
    code: 'online',
    label: 'Device: Online',
    description: 'Notify users when devices come back online.',
    notificationGroups: []
  },
  // os: {
  //   code: 'os',
  //   label: 'Firmware Change',
  //   description: 'Alert when a the firmware changes on the device',
  //   notificationGroups: []
  // },
  // highDataUse: {
  //   code: 'highDataUse',
  //   label: 'High Data Use',
  //   description: 'Alert when device uses more than 1G of data in a 24 hour period',
  //   notificationGroups: []
  // },
  highMemory: {
    code: 'highMemory',
    label: 'Memory Storage',
    description: 'Notify users when memory storage is 85% full.',
    notificationGroups: []
  },
  // network: {
  //   code: 'network',
  //   label: 'Network Change',
  //   description: 'Alert when the network type changes or the wifi ssid changes',
  //   notificationGroups: []
  // },
  // timezone: {
  //   code: 'timezone',
  //   label: 'Timezone Change',
  //   description: 'Alert when device timezone changes',
  //   notificationGroups: []
  // },
})

onMounted(() => {
  props.device.listenToDeviceAlerts((data) => {
    try {
      getNotificationGroupsById(data)
    } catch(err) {
      console.error(err)
    }
  })
})

function showNotificationGroupListDialog(alert) {
  selectedEvent.value = alert.code
  addNotificationGroupDialog.value = true
}

function showRecipientsDialog(notifyGroup) {
  selectedGroup.value = notifyGroup
  recipientsDialog.value = true
}

function onClickAddNotificationGroup() {
  const deviceQuery = {
    deviceKeys: [props?.device?.deviceKey]
  }
  const event = selectedEvent.value
  addAlertToDevices(deviceQuery, event)
  closeNotificationGroupListDialog()
}

function onClickRemoveNotificationGroup(notifyGroup, event) {
  selectedGroup.value = notifyGroup
  removeNotificationGroupDialog.value = true
  selectedEvent.value = event
}

function removeAlertNotificationGroup() {
  const deviceQuery = {
    deviceKeys: [props?.device?.deviceKey]
  }
  const event = selectedEvent.value
  const notificationGroupId = [selectedGroup.value.notificationGroupId]
  removeAlertFromDevices(deviceQuery, event, notificationGroupId)
  removeNotificationGroupDialog.value = false
}

function closeNotificationGroupListDialog() {
  addNotificationGroupDialog.value = false
  selectedAlertGroups.value = []
  selectedEvent.value = null
}

function onClickMuteNotificationGroup(notifyGroup, event) {
  selectedGroup.value = notifyGroup
  selectedEvent.value = event
  muteDurationDialog.value = true
}

function muteNotificationGroup() {
  const deviceQuery = {
    deviceKeys: [props?.device?.deviceKey]
  }
  const event = selectedEvent.value
  const notificationGroupId = [selectedGroup.value?.notificationGroupId]
  setMuteAlert(deviceQuery, event, notificationGroupId, muteDuration.value)
  muteDurationDialog.value = false
}

async function getNotificationGroupsById(alertData) {
  // fixes bug to clear last notification group for the event if it was deleted and it was the only one
  Object.keys(deviceAlerts.value).forEach(el => {
    if (!alertData || !alertData[el]) {
      deviceAlerts.value[el].notificationGroups = []
    }
  })

  try {
    if (alertData) {
      const tenantCode = getTenantCode()
      Object.keys(alertData).forEach( async (alert) => {
        const event = alert
        if (deviceAlerts.value[alert]) {
          deviceAlerts.value[alert].notificationGroups = []
          
          Object.keys(alertData[alert]).forEach(async groupId => {
            const notifyGroup = await deviceServices.getNotificationGroupById(groupId, tenantCode)
        
            // if a notify group exists on the device but not in tenant notification groups, it was likely deleted and should be removed from device
            if (!notifyGroup.name) {
              const deviceQuery = {
                deviceKeys: [props?.device?.deviceKey],
              }
              const notificationGroupdIds = [groupId]
              deviceJobs.removeAlertFromDevices(deviceQuery, event, notificationGroupdIds)
              return
            }
        
            notifyGroup['dateAdded'] = alertData[alert][groupId]?.dateAdded
            notifyGroup['notificationGroupId'] = groupId
            deviceAlerts.value[alert]['event'] = event
            if (alertData[alert][groupId]?.muteUntil) {
              const currentTimestamp = Date.now()
              const muteUntil = new Date(alertData[alert][groupId]?.muteUntil)
              const muteUntilTimestamp = muteUntil.getTime()
  
              // if the muteUntil timestamp has expired remove it from the notifyGroup
              if (currentTimestamp > muteUntilTimestamp) {
                notifyGroup['muteUntil'] = null
              } else {
                notifyGroup['muteUntil'] = alertData[alert][groupId]?.muteUntil
              }
            }
        
            if (!deviceAlerts.value[alert]?.notificationGroups?.some(el => el.notificationGroupId === notifyGroup.notificationGroupId)) {
              deviceAlerts.value[alert]?.notificationGroups?.push(notifyGroup)
            }
          })
        }
      })
    }
  } catch(err) {
    console.error(err)
  }
}

function onClickUnmuteAlert(group, event) {
  selectedEvent.value = event
  selectedGroup.value = group
  confirmUnmuteDialog.value = true
}

function muteAlert() {
  confirmUnmuteDialog.value = false
  const deviceKey = props.device.deviceKey
  const deviceQuery = {
    deviceKeys: [deviceKey],
  }
  const notificationGroupIds = [selectedGroup.value.notificationGroupId]
  const muteUntil = ''
  setMuteAlert(deviceQuery, selectedEvent.value, notificationGroupIds, muteUntil)
}

const {
  addAlertToDevices,
  selectedAlertGroups,
  removeAlertFromDevices,
  setMuteAlert
} = useAlerts()
</script>

<template>
  <q-card v-if="hasAlertsFF" dark class="card q-pa-md q-pb-xl">
    <q-card-section class="q-py-sm">
      <span class="text-h6 text-bold">Alerts</span>
    </q-card-section>

    <q-card-section class="q-pa-md">
      <div
        v-for="alert in deviceAlerts"
        :key="alert"
        class="full-width"
      >
        <q-list bordered class="rounded-borders">
          <q-expansion-item
            expand-separator
            :label="alert.label"
            :caption="alert.description"
            dark
            class="bg-grey-layer-1"
            header-class="text-body1 text-bold"
          >
            <div class="bg-grey-layer-1 q-px-lg q-py-md">
              <div class="bg-grey-layer-2">
                <div class="row items-center q-pa-lg">
                  <span class="text-body1 text-bold">Alert Notification Groups</span>
                  <q-space></q-space>
                  <app-btn
                    label="notification groups"
                    icon="add"
                    @click="showNotificationGroupListDialog(alert)"
                  ></app-btn>
                </div>
                <div class="q-px-lg q-pb-lg">
                  <q-list>
                    <q-item class="row text-bold q-pb-none items-center">
                      <span class="col-2 ellipsis q-mr-sm">
                        Group Name
                      </span>                        
                      <span v-if="!isSmScreen" class="col-2 ellipsis q-mr-sm">
                        Date Added
                      </span>                        
                      <span class="col-2 text-center ellipsis q-mr-sm">
                        Number of Recipients
                      </span>                        
                      <span class="col-2 ellipsis q-mr-sm">
                        Description
                      </span>
                    </q-item>
                  </q-list>

                  <q-separator dark></q-separator>
                  
                  <div v-if="alert.notificationGroups?.length">
                    <q-list v-for="group in alert.notificationGroups" :key="group" class="notify-group-list">
                      <q-item class="row items-center">
                        <span class="col-2 ellipsis q-mr-sm">
                          {{ group.name }}
                          <q-tooltip :delay="500" anchor="center middle" self="center middle">{{ group.name }}</q-tooltip>
                        </span>                        
                        <span v-if="!isSmScreen" class="col-2 ellipsis q-mr-sm">
                          {{ formatDateTimeString(group.dateAdded) }}
                        </span>                        
                        <span
                          v-if="group.emails"
                          class="col-2 items-center text-center ellipsis q-mr-sm"
                        >
                          <span @click="showRecipientsDialog(group)" class="text-green-13 cursor">
                            {{ Object.keys(group.emails).length || 0 }}
                          </span>
                        </span>                        
                        <span class="col-2 ellipsis q-mr-sm">
                          {{ group.description }}
                          <q-tooltip :delay="500" anchor="center middle" self="center middle">{{ group.description }}</q-tooltip>
                        </span>                        
                        <div class="col items-center q-mr-md">
                          <q-chip
                            v-if="group.muteUntil"
                            outline
                            color="white"
                            text-color="white"
                            removable
                            @remove="onClickUnmuteAlert(group, alert.event)"
                            class="ellipsis"
                            :label="`Muted until ${formatDateTimeString(group.muteUntil)}`"
                          >
                            <q-tooltip>
                              <span>Muted until {{ formatDateTimeString(group.muteUntil) }}</span>
                            </q-tooltip>
                          </q-chip>
                        </div>
                        <q-space></q-space>                      
                        <q-item-section class="col-1">
                          <q-btn-dropdown
                            flat
                            round
                            dense
                            icon="more_horiz"
                          >
                            <q-card dark>
                              <q-list>
                                <q-item clickable v-close-popup @click="onClickMuteNotificationGroup(group, alert.event)">
                                  <q-item-section>
                                    <q-item-label>Mute alert Notification group</q-item-label>
                                  </q-item-section>
                                </q-item>
                                
                                <q-item clickable v-close-popup @click="onClickRemoveNotificationGroup(group, alert.event)">
                                  <q-item-section>
                                    <q-item-label>Remove alert notification group</q-item-label>
                                  </q-item-section>
                                </q-item>
                              </q-list>
                            </q-card>
                          </q-btn-dropdown>
                        </q-item-section>                        
                      </q-item>
                    </q-list>
                  </div>
                  <div v-else>
                    <div class="flex justify-center items-center q-pa-xl">
                      <span>Click the <b> <q-icon class="q-pb-xs q-pl-none" size="sm" name="add"></q-icon> NOTIFICATION GROUP </b> &nbsp;button to add your first group</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-expansion-item>
        </q-list>
      </div>
    </q-card-section>
  </q-card>

  <q-dialog v-model="recipientsDialog">
    <q-card
      class="q-py-md"
      style="width:40%;max-width:400px;"
      dark
    >
      <q-item-label class="text-h6 q-px-lg text-bold q-mb-sm">Recipients</q-item-label>
      <q-separator dark class="q-mb-xs"></q-separator>
      <div id="recipientsWrapper">
        <div v-if="selectedGroup.numRecipients > 0">
          <div
            v-for="email in selectedGroup?.emails" :key="email"
            class="block q-px-lg q-py-sm q-pb-sm recipient"
          >
            <span>{{ email }}</span>
          </div>
        </div>
        <div v-else class="block q-pa-xl q-pb-sm recipient">
          <span>This notification group has no emails associated with it. You can click the 3 dot menu and edit this group to add your first recipients.</span>
        </div>
      </div>
    </q-card>
  </q-dialog>

  <q-dialog v-model="addNotificationGroupDialog">
    <q-card 
      dark
      style="width:80%;max-width:900px;"
    >
      <q-toolbar class="q-px-lg">
        <q-toolbar-title class="text-bold q-pa-md q-ml-sm">Select Notification Groups</q-toolbar-title>
        <q-btn flat @click="closeNotificationGroupListDialog" round dense icon="close" />
      </q-toolbar>

      <q-separator dark></q-separator>
      
      <div class="q-px-xl q-pt-lg">
        <alert-notification-group-list sourceLocation="deviceDetailDialog"></alert-notification-group-list>
      </div>
      <q-separator dark></q-separator>
      <q-card-actions align="right" class="q-pa-md">
        <app-btn
          label="select notification groups"
          @click="onClickAddNotificationGroup"
          :isDisabled="!selectedAlertGroups?.length"
        ></app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="removeNotificationGroupDialog">
    <q-card dark class="confirm-dialog">
      <div class="row">
        <q-space></q-space>
        <q-btn flat v-close-popup round dense icon="close" />
      </div>
      <q-card-section class="q-px-lg q-pb-md q-pt-none">
        <span class="text-h6">Remove this alert notification group?</span>
      </q-card-section>

      <q-separator color="grey-9"></q-separator>

      <q-card-section class="row items-center q-pa-lg">
        <div class="q-mb-md">
          <span class="text-body1">Are you sure you want to remove this alert notification group?</span>
        </div>
        <div>
          <span class="text-body1 text-bold">{{ selectedGroup.name }}</span>
        </div>
      </q-card-section>

      <q-separator color="grey-9"></q-separator>

      <q-card-actions class="q-px-lg q-py-md" align="right">
        <q-btn flat label="Cancel" color="white" v-close-popup />
        <app-btn
          color="red"
          text-color="white"
          label="Remove Notification Group"
          @click="removeAlertNotificationGroup()"
        ></app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="muteDurationDialog" @hide="muteDuration = null">
    <q-card dark class="confirm-dialog">
      <div class="row">
        <q-space></q-space>
        <q-btn flat v-close-popup round dense icon="close" />
      </div>
      <q-card-section class="q-px-lg q-pb-md q-pt-none">
        <span class="text-h6">Mute Alert Notification Group</span>
      </q-card-section>

      <q-separator color="grey-9"></q-separator>

      <q-card-section class="items-center q-pa-lg q-px-xl">
        <div class="q-mb-sm">
          <span class="text-body1">You are about to mute this alert notification group?</span>
        </div>
        <div class="q-mb-lg">
          <span class="text-body1 text-bold">{{ selectedGroup.name }}</span>
        </div>
        <div class="q-mb-md">
          <span class="text-body1">How long do you want to mute this group for?</span>
        </div>
        <div class="full-width">
          <q-select
            dense
            label="Choose a duration from the list"
            v-model="muteDuration"
            class="q-mt-sm col-6"
            :options="alertMuteDurationMsOptions"
            dark
            outlined
            color="white"
            :popup-content-style="{ backgroundColor:'rgb(40,40,40)' }"
            map-options
            emit-value
          >
          </q-select>
        </div>
      </q-card-section>

      <q-separator color="grey-9"></q-separator>

      <q-card-actions class="q-px-lg q-py-md" align="right">
        <q-btn flat label="Cancel" color="white" v-close-popup />
        <app-btn
          color="red"
          text-color="white"
          label="mute group"
          @click="muteNotificationGroup"
          :isDisabled="!muteDuration"
        ></app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="confirmUnmuteDialog">
    <q-card dark class="confirm-dialog">
      <div class="row">
        <q-space></q-space>
        <q-btn flat v-close-popup round dense icon="close" />
      </div>
      <q-card-section class="q-px-lg q-pb-md q-pt-none">
        <span class="text-h6">Unmute this alert notification group?</span>
      </q-card-section>

      <q-separator color="grey-9"></q-separator>

      <q-card-section class="items-center q-pa-lg q-px-xl">
        <div class="q-mb-md">
          <span class="text-body1">Are you sure you want to unmute this alert notification group?</span>
        </div>
        <div>
          <span class="text-body1 text-bold">{{ selectedGroup.name }}</span>
        </div>
      </q-card-section>

      <q-separator color="grey-9"></q-separator>

      <q-card-actions class="q-px-lg q-py-md" align="right">
        <q-btn flat label="Cancel" color="white" v-close-popup />
        <app-btn
          label="unmute group"
          @click="muteAlert()"
        ></app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>
.confirm-dialog {
  width: 60%;
  max-width: 600px;
}
</style>
