import regexRules from '@/constants/regex';

export const rules = {
    firebaseRule: v => !!(v && v.match(regexRules.firebaseRegex)) || 'Field should not include restricted characters',
    commandRule: v => !!(v && v.match(regexRules.commandRegex)) || 'Field should not be empty or include restricted characters',
    numRule: v => !!(v && v.match(regexRules.numRegex)) || 'Field should not include characters that are not numbers',
    required: v => !!v || v === 0 || 'This is a required field',
    maxLengthRule: v => !!(v.length < 65) || 'The maximum allowed number of characters is 64',
    frameBufferRule: v => !!(v && v.match(regexRules.frameBufferRegex)) || !v || 'Field does not meet required format (e.g. 1920x1080@60)',
    applicationRule: v => !!(v && v.match(regexRules.packageNameRegex)) || !v || 'Field does not meet required format (e.g. com.skykit.home)',
    displayDimensionsRule: v => !!(v && v >= 200 && v <= 4096) || !v || 'Display width and height must be between 200 and 4096',
    maxNetworkUrlCharsRule: v => !!(v && v.length < 257 || 'The maximum number of characters is 256'),
    appReceiverKeyRule: v => !!(v && v.length > 4 && v.length < 65 || 'This field must be between 4 and 64 characters'),
  }