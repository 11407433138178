<script setup>
import { ref, watch } from 'vue'
import { rebootOptions } from '../constants/constants'
import { formatTwentyFourHourString } from '../utils/dateUtils'

// eslint-disable-next-line
const emit = defineEmits([
  'scheduled-reboot-updated'
])

const model = ref({
  restartDay: null,
  restartTime: null
})

watch(model.value, (cur) => {
  if (cur && cur.restartDay || cur.restartTime) {
    emit('scheduled-reboot-updated', cur)
  }
})
</script>

<template>
  <div style="width:50%;">
    <div class="q-mb-md">
      <q-select
        dense
        v-model="model.restartDay"
        :options="rebootOptions"
        class="col q-mt-sm"
        dark
        outlined
        :label="!model.day ? 'Set a reboot day' : null"
        color="white"
        emit-value
        map-options
      >
        <template v-slot:option="scope">
          <div v-bind="scope.itemProps" class="reboot-options q-pa-md cursor-pointer">
            <span>
              {{ scope.opt.label }}
            </span>
          </div>
          <q-separator v-if="scope.opt.value === 0" dark></q-separator>
        </template>
      </q-select>
    </div>

    <div>
      <q-btn-dropdown
        dark
        dense
        align="left"
        outline
        cover
        :label="formatTwentyFourHourString(model.restartTime) || 'Set a reboot time'"
        padding="8px 12px"
        no-caps
        color="grey-5"
        class="full-width"
      >
        <q-time
          color="green-13"
          dark
          v-model="model.restartTime"
          :minute-options="[0,5,10,15,20,25,30,35,40,45,50,55]"
          mask="HH:mm"
          class="full-width"
        >
          <div class="row items-center justify-end">
            <q-btn v-close-popup label="Close" flat />
          </div>
        </q-time>
      </q-btn-dropdown>
    </div>
  </div>
</template>