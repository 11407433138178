import { createGlobalState, useStorage, useSessionStorage } from '@vueuse/core'

/////////////////////////////////////
////             Auth            ////
export const useGlobalAuth = createGlobalState(
  () => useStorage('auth', {
    user: {
      name: '',
      email: '',
      picture: ''
    },
    tenantGid: '',
    tenantCode: '',
    isLoggedIn: false,
    beamToken: '',
    playerToken: '',
    disableApp: false,
    availableProducts: [],
    isPlatformAdmin: false,
    hasCuiAccess: false,
  }),
)

// expose so components are able to watch for store changes
export const auth = useGlobalAuth() // for local use

export const setTenantGid = function (value) {
  auth.value.tenantGid = value
}

export const getTenantGid = function () {
  return auth.value.tenantGid
}

export const setTenantCode = function (value) {
  auth.value.tenantCode = value
}

export const getTenantCode = function () {
  return auth.value.tenantCode
}

export const setUser = function (value) {
  auth.value.user = value
}

export const setBeamToken = function (value) {
  auth.value.beamToken = value
}

export const getBeamToken = function () {
  return auth.value.beamToken
}

export const setPlayerToken = function (value) {
  auth.value.playerToken = value
}

export const getPlayerToken = function () {
  return auth.value.playerToken
}

export const setIsLoggedIn = function (value) {
  auth.value.isLoggedIn = value
}

export const getIsLoggedIn = function () {
  return auth.value.isLoggedIn
}

export const getUserInfo = () => {
  return {
    email: auth.value.user?.email,
    picture: auth.value.user?.picture,
    avatarIcon: auth.value.user?.email ? auth.value.user?.email.substring(0,1).toUpperCase() : 'S',
    tenantCode: auth.value?.tenantCode
  }
}

export const setAppDisableState = val => auth.value.disableApp = val;
export const getDisableAppState = () => auth.value.disableApp;
export const setAvailableProducts = val => auth.value.availableProducts = val;
export const getAvailableProducts = () => auth.value.availableProducts;

/////////////////////////////////////
////           Labels            ////
export const useGlobalLabels = createGlobalState(
  () => useStorage('labels', {
    labelCategories: [],
    cachedQueuedLabels: [],
    cachedLabelCategory: {},
    cachedLabel: {}
  }),
)
const labels = useGlobalLabels()

export const getLabelCategories = function () {
  return labels.value.labelCategories;
}

export const setLabelCategories = function (value) {
  labels.value.labelCategories = value;
}

export const getCachedQueuedLabels = function () {
  return labels.value.cachedQueuedLabels
}

export const setCachedQueuedLabels = function (value) {
  labels.value.cachedQueuedLabels = value
}

export const getCachedLabelCategory = function () {
  return labels.value.cachedLabelCategory
}

export const setCachedLabelCategory = function (value) {
  labels.value.cachedLabelCategory = value
}

export const getCachedLabel = function () {
  return labels.value.cachedLabel
}

export const setCachedLabel = function (value) {
  labels.value.cachedLabel = value
}

export const resetAppStates = () => {
  setIsLoggedIn(false);
  setTenantGid(null);
  setTenantCode(null);
  setBeamToken(null);
  setPlayerToken(null);
  setAppDisableState(false);
  setAvailableProducts([]);
  setUser({
    name: '',
    email: '',
    picture: ''
  });
}

/////////////////////////////////////
////           Tenant            ////
export const useGlobalTenant = createGlobalState(
  () => useStorage('tenant', {
    tenantApks: {}
  }),
)
const tenant = useGlobalTenant()

export const setTenantApks = (value) => {
  tenant.value.tenantApks = value;
}

export const getTenantApks = () => {
  return tenant.value.tenantApks
}

/////////////////////////////////////
////     preserve list view     /////
const useGlobalPersistedState = createGlobalState(
  () => useSessionStorage('persistedState', {
    searchState: null,
    filterState: [],
    sortState: {
      sortType: {},
      sortField: {}
    }
  }),
)

export const persistedState = useGlobalPersistedState()

export const setPersistedState = (filters, sortType, sortField) => {
  persistedState.value.filterState = filters ? filters : persistedState.value.filterState
  persistedState.value.sortState.sortType = sortType ? sortType : persistedState.value.sortState.sortType
  persistedState.value.sortState.sortField = sortField ? sortField : persistedState.value.sortState.sortField
}

export const clearPersistedState = () => {
  persistedState.value.searchState = null
  persistedState.value.filterState = []
  persistedState.value.sortState.sortType = {}
  persistedState.value.sortState.sortField = {}
}