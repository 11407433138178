import { useLDFlag } from "launchdarkly-vue-client-sdk"
import featureFlags from "../constants/featureFlags"

export default function useFeatureFlags() {
  
  function hasFeatureFlag(string) {
    return useLDFlag(string)
  }

  const hasDeleteDeviceFF = useLDFlag(featureFlags.deleteDevice)
  const hasAlertsFF = useLDFlag(featureFlags.alerts)
  const hasEventsFF = useLDFlag(featureFlags.cuiEvents)
  const hasReportsFF = useLDFlag(featureFlags.reports)

  return {
    hasFeatureFlag,
    hasDeleteDeviceFF,
    hasAlertsFF,
    hasEventsFF,
    hasReportsFF
  }
}