<script setup>
import { ref, computed, onMounted } from 'vue';
import AppBtn from '@/components/AppBtn.vue';
import deviceServices from '../service/deviceServices';
import useCommands from '../composables/useCommands';

// eslint-disable-next-line no-undef
const props = defineProps({
  deviceId: String,
  isOffline: Boolean,
});

const { sendDeviceCommand } = useCommands();

const installedApps = ref({});
const showSystemApps = ref(false);
const confirmDialog = ref(false);
const confirmActionConfig = ref({
  title: '',
  message: '',
  buttonText: '',
  action: '',
  appPkg: '',
});

onMounted(() => {
  try {
    deviceServices.listenToInstalledApps(props.deviceId, (data) => {
      let arr = [];
      for (let key in data) {
        arr.push(data[key]);
      }
      installedApps.value = arr.sort((a, b) => b.order - a.order);
    });
  } catch (err) {
    console.error(err);
  }
});

function onConfirmAction(action, app) {
  switch (action) {
    case 'reset':
      confirmActionConfig.value.title = 'Reset this app?';
      confirmActionConfig.value.message = `You are about to <b>reset</b> this app: <br class="q-mb-xl"> ${app.appName} <br><br> Do you wish to continue?`;
      confirmActionConfig.value.buttonText = action;
      confirmActionConfig.value.action = action;
      confirmActionConfig.value.appPkg = app.pkg;
      break;
    case 'uninstall':
      confirmActionConfig.value.title = 'Delete this app?';
      confirmActionConfig.value.message = `You are about to <b>uninstall</b> this app: <br> ${app.appName} <br><br> Do you wish to continue?`;
      confirmActionConfig.value.buttonText = action;
      confirmActionConfig.value.action = action;
      confirmActionConfig.value.appPkg = app.pkg;
      break;
  }
  confirmDialog.value = true;
}

function confirmAction() {
  try {
    confirmDialog.value = false;
    const command = `${confirmActionConfig.value.action}/${confirmActionConfig.value.appPkg}`;
    const deviceId = props.deviceId;
    const deviceQuery = {
      deviceIds: [deviceId],
    };
    sendDeviceCommand(deviceQuery, command);
  } catch (err) {
    console.error(err);
  }
}

function showApp(app) {
  return showSystemApps.value ? true : app.type !== 'system';
}

const filteredApps = computed(() => {
  const managedApps = Object.keys(installedApps.value).filter(
    (el) => installedApps.value[el].type !== 'system'
  ).map((el) => installedApps.value[el])
  return showSystemApps.value ? installedApps.value : managedApps;
});
</script>

<template>
  <div>
    <q-card dark class="q-pa-lg q-px-lg q-mb-lg">
      <q-card dark class="q-px-lg q-py-md grey-1">
        <q-card-section class="q-px-none">
          <div class="row items-center">
            <span class="text-h6"> All Installed Apps </span>
            <q-space></q-space>
            <span class="text-subtitle1"> Show All System Apps </span>
            <q-toggle color="green-13" v-model="showSystemApps"></q-toggle>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="row text-left text-bold">
            <div class="col q-pl-lg">
              <span>App Name</span>
            </div>
            <div class="col q-pl-md">
              <span>Package</span>
            </div>
            <div class="col text-center">
              <span>Version</span>
            </div>
            <div class="col text-center">
              <span>Version Code</span>
            </div>
            <div class="col-1">
              <span>Type</span>
            </div>
            <div class="col-1"></div>
          </div>
        </q-card-section>
        <q-separator dark></q-separator>
        <q-card-section class="q-px-none">
          <div
            v-for="(app, idx) in filteredApps"
            :key="idx"
            :class="[
              showSystemApps
                ? 'installed-app-list-item'
                : 'installed-app-list-item',
            ]"
          >
            <div v-show="showApp(app)" class="row text-left q-py-md q-px-md">
              <div class="col q-pl-lg ellipsis">
                <span>{{ app.appName }}</span>
              </div>
              <div class="col q-pl-md ellipsis">
                <span>{{ app.pkg }}</span>
              </div>
              <div class="col text-center">
                <span>{{ app.versionName }}</span>
              </div>
              <div class="col text-center">
                <span>{{ app.versionCode }}</span>
              </div>
              <div class="col-1">
                <span>{{ app.type }}</span>
              </div>
              <div class="col-1 text-right">
                <q-btn
                  @click.stop
                  flat
                  size="sm"
                  icon="more_vert"
                  v-if="app.type !== 'system'"
                  :disable="isOffline"
                >
                  <q-tooltip
                    class="text-subtitle2"
                    :delay="1000"
                    anchor="top middle"
                    self="bottom middle"
                    v-if="isOffline"
                    ><span
                      >Apps can not be updated on devices that are offline</span
                    ></q-tooltip
                  >
                  <q-menu
                    transition-duration="0"
                    anchor="top right"
                    self="top left"
                  >
                    <q-list dark>
                      <q-item
                        clickable
                        @click="onConfirmAction('reset', app)"
                        class="items-center grey-2"
                      >
                        <q-item-label> Reset App </q-item-label>
                      </q-item>
                      <q-item
                        clickable
                        @click="onConfirmAction('uninstall', app)"
                        class="items-center grey-2"
                      >
                        <q-item-label> Uninstall App </q-item-label>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-btn>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-card>

    <q-dialog v-model="confirmDialog" persistent>
      <q-card dark class="q-pa-sm" style="width: 40%; max-width: 600px">
        <q-card-section class="q-pb-xs">
          <span class="text-h6">{{ confirmActionConfig.title }}</span>
        </q-card-section>

        <q-card-section class="row items-center q-mb-lg">
          <span v-html="confirmActionConfig.message" class="text-body1"></span>
        </q-card-section>

        <q-separator dark></q-separator>

        <q-card-actions class="q-pa-md" align="right">
          <q-btn flat label="Cancel" color="white" v-close-popup />
          <app-btn
            color="red"
            text-color="white"
            :label="confirmActionConfig.buttonText"
            @click="confirmAction"
          >
          </app-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style>
.grey-1 {
  background-color: rgb(35, 35, 35) !important;
}
.grey-2 {
  background-color: rgb(45, 45, 45) !important;
}
.grey-3 {
  background-color: rgb(55, 55, 55) !important;
}
.installed-app-list-item:nth-child(even) {
  background-color: rgb(45, 45, 45);
}
</style>
