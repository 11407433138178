<script setup>
import { ref } from 'vue'
import CreateAlertNotificationGrougDialog from '../components/CreateAlertNotificationGrougDialog.vue'
import AlertNotificationGroupList from '../components/AlertNotificationGroupList.vue'
import AppBtn from '../components/AppBtn'

const showNotifyDialog = ref(false)

</script>

<template>
  <q-page padding class="page">
    <div class="row">
      <div class="col text-left text-white">
        <h3 class="text-weight-bolder">Notification Groups</h3>
      </div>

      <div class="col self-center text-right">
        <app-btn
          @click="showNotifyDialog = true"
          label="notification group"
          icon="add"
        >
        </app-btn>
        <create-alert-notification-groug-dialog :showDialog="showNotifyDialog" @close-dialog="showNotifyDialog = false" :isEdit="false"></create-alert-notification-groug-dialog>
      </div>
    </div>
    <div class="q-px-lg q-py-sm alerts-wrapper">
      <alert-notification-group-list sourceLocation="alertsListView"></alert-notification-group-list>
    </div>
  </q-page>
</template>

<style scoped>
.alert-rows:nth-child(even) {
  background-color: rgb(35,35,35);
}
#recipientsWrapper {
  max-height: 60vh;
  overflow-y: scroll;
}
.recipient:nth-child(even) {
  background-color: rgb(35,35,35);
}
.alerts-wrapper {
  background-color: rgb(29,29,29);
}
</style>
