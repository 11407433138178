import script from "./PeripheralCreateForm.vue?vue&type=script&setup=true&lang=js"
export * from "./PeripheralCreateForm.vue?vue&type=script&setup=true&lang=js"

import "./PeripheralCreateForm.vue?vue&type=style&index=0&id=1adcbf40&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QTooltip,QDialog,QCard,QCardSection,QSpace,QIcon,QSeparator,QForm,QItemLabel,QInput,QSelect,QItem,QCardActions});qInstall(script, 'directives', {ClosePopup});
