
import './styles/quasar.scss';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import Notify from 'quasar/src/plugins/Notify.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    notify: {
      position: 'bottom-left',
      actions: [
        { label: 'Dismiss', color: 'white' }
      ]
    },
    brand: {
      primary: '#663399',
      secondary: '#26A69A',
      accent: '#9C27B0',

      'green-1': '#084728',

      dark: '#1d1d1d',
      'dark-page': '#121212',

      positive: '#21BA45',
      negative: '#C10015',
      info: '#31CCEC',
      warning: '#F2C037'
    }
  },
  plugins: {
    Notify
  },
}
  