import apolloClient from '@/lib/apolloClient';
import { updateDisplayMutation, listDisplays, findDisplayListQuery } from '@/apollo/queries/displays';
import { getTenantGid } from "../store/index.js";

async function fetchDisplays(sortString = null, endCursor) {
  return await apolloClient.query({
    query: listDisplays,
    fetchPolicy: 'network-only',
    context: { clientName: 'displays' },
    variables: {
      tenantGid: getTenantGid(),
      first: 25,
      sort: sortString,
      after: endCursor
    }
  })
  .then(result => {
    return result;
    
  })
  .catch(error => {
    console.error(error)
  });
}

async function findDisplays(sortString, findDisplayEndCursor, searchInput, labelGids) {
  return await apolloClient.query({
    query: findDisplayListQuery,
    fetchPolicy: 'network-only',
    context: { clientName: 'displays' },
    variables: {
      after: findDisplayEndCursor,
      tenantGid: getTenantGid(),
      query: searchInput,
      sort: sortString,
      first: 25,
      labelGids: labelGids
    }
  })
  .then(result => {
    return result
  })
  .catch(error => {
    console.warn(error);
  });
}

const updateDisplay = async (displayGid, labelGids) => {
  return await apolloClient
    .mutate({
      mutation: updateDisplayMutation,
      fetchPolicy: "network-only",
      context: { clientName: "displays" },
      variables: {
        input: {
          displayGid,
          labelGids
        }
      },
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.warn(error);
    });
};

export { fetchDisplays, findDisplays, updateDisplay };
