<template>
  <div>
    <q-page v-if="isLoading || displayName === null || !deviceConfig">
      <q-linear-progress indeterminate color="green-13"/>
    </q-page>
    <q-page v-else padding class="page">
      <div v-if="device">
        <div class="row q-mt-lg q-mb-md">
          <div class="col-auto q-pt-sm">
            <q-icon
              clickable
              @click="goBack"
              color="white"
              size="sm"
              class="text-left cursor-pointer q-mr-lg"
              name="arrow_back_ios_new"
            >
            </q-icon>
          </div>
          <div class="col text-left text-white">
            <div>
              <q-input
                v-model="displayName"
                class="ellipsis display-details-input col-auto"
                input-class="text-h4 ellipsis text-bold q-pa-none"
                input-style="margin-left:-2px;"
                borderless
                no-error-icon
                hide-bottom-space
                :rules="[rules.required]"
                lazy-rules
                :debounce="1000"
                @change="$value => updateDisplayNameAndLocation($value, 'name')"
              >
                <q-tooltip
                  class="text-subtitle2"
                  :delay="1000"
                >
                  {{ displayName }}
                </q-tooltip>
              </q-input>
            </div>
            <div class="q-mb-sm">
              <span class="text-h6 text-weight-regular q-mt-none q-mb-sm">{{ state.serialNumber }}</span> <span> {{ getModelAndManufacturer() }}</span>
            </div>
            <div class="q-mb-sm row">
              <span class="text-weight-bolder text-body1"> Location: </span>
              <q-input
                dense
                v-model="displayLocation"
                label-color="white"
                class="ellipsis display-details-input col-4 q-ml-sm"
                input-class="text-body1 text-weight-regular ellipsis text-bold"
                borderless
                no-error-icon
                hide-bottom-space
                :debounce="1000"
                @change="$value => updateDisplayNameAndLocation($value, 'location')"
              >
                <q-tooltip
                  v-if="displayLocation"
                  class="text-subtitle2"
                  :delay="1000"
                >
                  {{ displayLocation }}
                </q-tooltip>
              </q-input>
            </div>
            <div>
              <span class="q-mt-none items-center row">
                <q-icon
                  name="fiber_manual_record"
                  :color="state.isOffline ? 'red' : 'green'"
                  class="q-mr-xs"
                  style="margin-top:2px;"
                >
                </q-icon>
                <span>{{ getOfflineStatus(state) }}</span>
              </span>
            </div>
          </div>
          <div class="col-auto text-right items-right">
            <app-btn
              @click="onClickSaveDeviceConfig"
              :isDisabled="isDisabled"
              label="Save Configuration"
            >
            </app-btn>
          </div>
        </div>

      <!-- Tabs -->
      <q-tabs
        v-model="tab"
        dense
        class="text-grey text-h1"
        active-color="green-13"
        indicator-color="green-13"
        align="justify"
      >
        <q-tab default="true" name="status" label="Status" />
        <q-tab name="network" label="Network" />
        <q-tab v-if="hasFirmwareAccess" name="firmware" label="Firmware" />
        <q-tab name="apps" label="Apps" />
        <q-tab v-if="hasEventsFF" name="eventReports" label="Event History" />
        <q-tab name="peripherals" label="Peripherals" />
        <q-tab name="screenshots" label="Screenshots" />
        <q-tab v-if="hasPlatformAdminAccess" name="skykit" label="Skykit" />
      </q-tabs>

        <q-separator dark class="q-mb-lg" />

        <q-tab-panels
          v-model="tab"
          keep-alive
          animated
          class="tabPanel"
          style="background: none;"
        >
          <!-- Status Tab -->
          <q-tab-panel dark name="status" class="q-pa-none panel">
            <div class="tab-scroll-area">
              <status-component
                v-model:deviceConfig="deviceConfig"
                :state="state"
                :device="device"
                @device-config-updated="onChangeDeviceConfig"
              ></status-component>
            </div>
          </q-tab-panel>
    
          <!-- Network Tab -->
          <q-tab-panel dark name="network" class="q-pa-none panel">
            <div class="tab-scroll-area">
              <network-component
                v-model:deviceConfig="deviceConfig"
                :state="state"
                :device="device"
                @device-config-updated="onChangeDeviceConfig"
              ></network-component>
            </div>
          </q-tab-panel>

          <!-- Firmware Tab -->
          <q-tab-panel dark name="firmware" class="q-pa-none panel">
            <div class="tab-scroll-area">
              <firmware-component
                v-model:deviceConfig="deviceConfig"
                :state="state"
                :device="device"
                @device-config-updated="onChangeDeviceConfig"
              ></firmware-component>
            </div>
          </q-tab-panel>

          <!-- Apps Tab -->
          <q-tab-panel dark name="apps" class="q-pa-none panel">
            <div class="tab-scroll-area">
              <apps-component
                v-model:deviceConfig="deviceConfig"
                :state="state"
                :device="device"
                :managedApps="managedApps"
              ></apps-component>
            </div>
          </q-tab-panel>
          
          <!-- Event Reports Tab -->
          <q-tab-panel dark name="eventReports" class="q-pa-none panel">
            <div class="tab-scroll-area">
              <event-reports-component
                :device="device"
              ></event-reports-component>
            </div>
          </q-tab-panel>

          <!-- peripherals Tab -->
          <q-tab-panel dark name="peripherals" class="q-pa-none panel">
            <div class="tab-scroll-area">
              <peripherals-component
                v-model:deviceConfig="deviceConfig"
                :state="state"
                :device="device"
              ></peripherals-component>
            </div>
          </q-tab-panel>
          
          <!-- screenshots Tab -->
          <q-tab-panel dark name="screenshots" class="q-pa-none panel">
            <div class="tab-scroll-area">
              <screenshots-component
                v-model:deviceConfig="deviceConfig"
                :state="state"
                :device="device"
                @show-screenshot="showImg"
              ></screenshots-component>
            </div>
          </q-tab-panel>
          
          <!-- Skykit Tab -->
          <q-tab-panel dark name="skykit" class="q-pa-none panel">
            <div class="tab-scroll-area">
              <skykit-component
                v-model:deviceConfig="deviceConfig"
                :state="state"
                :device="device"
                @device-config-updated="onChangeDeviceConfig"
                :deviceCommandsList="deviceCommandsList"
              ></skykit-component>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </div>
    </q-page>

    <q-dialog v-model="errorDialog" persistent>
      <q-card dark>
        <q-card-section>
          <div class="text-h6">Can't find device details</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          This device either doesn't belong to this tenant, or isn't compatible with the Control UI application
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="green-13" v-close-popup @click="goBack" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- screenshot lightbox-->
    <vue-easy-lightbox
      :visible="visibleRef"
      :imgs="imgs"
      :index="indexRef"
      @hide="onHide"
    >
      <template v-slot:toolbar> </template>
    </vue-easy-lightbox>
  </div>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import SkykitComponent from "../components/SkykitComponent.vue";
import StatusComponent from "../components/StatusComponent.vue";
import NetworkComponent from "../components/NetworkComponent.vue";
import FirmwareComponent from "../components/FirmwareComponent.vue";
import AppsComponent from "../components/AppsComponent.vue";
import EventReportsComponent from "../components/EventReportsComponent.vue";
import deviceServices from "../service/deviceServices";
import { useRouter, useRoute } from 'vue-router';
import { useQuasar } from 'quasar';
import PeripheralsComponent from "../components/PeripheralsComponent.vue";
import ScreenshotsComponent from "../components/ScreenshotsComponent.vue";
import { getDisplayDetailQuery } from "../apollo/queries/displayDetail.js";
import apolloClient from '../lib/apolloClient';
import { get } from 'lodash';
import useCommands from '../composables/useCommands';
import Permissions from '../store/permissions';
import AppBtn from '@/components/AppBtn';
import deviceStore from '../store/device';
import useDeviceConfig from '../composables/useDeviceConfig';
import { auth } from '../store';
import deviceJobs from '../service/deviceJobs';
import useFeatureFlags from '@/composables/useFeatureFlags'

export default {
  components: {
    SkykitComponent,
    StatusComponent,
    NetworkComponent,
    FirmwareComponent,
    AppsComponent,
    EventReportsComponent,
    PeripheralsComponent,
    ScreenshotsComponent,
    AppBtn,
  },
  props: [
    'deviceId',
    'deviceGid'
  ],
  emits: [
    'enable-save'
  ],
  setup(props) {
    const $q = useQuasar();
    const deviceKey = ref(props.deviceId);
    const displayName = ref(null);
    const displayLocation = ref(null);
    const state = ref({});
    let deviceConfig = reactive({});
    const isLoading = ref(true);
    const managedApps = ref({});
    const deviceCommandsList = ref({});
    const device = ref(null);
    const router = useRouter();
    const route = useRoute()
    const deviceLocation = route.params.location
    const errorDialog = ref(false);
    let encodedDisplayId = null;
    const {
      hasEventsFF
    } = useFeatureFlags()
    const hasPlatformAdminAccess = computed(() => {
      return Permissions.getters.hasPlatformAdminAccess()
    })
    const hasFirmwareAccess = computed(() => {
      return Permissions.getters.hasFirmwareTabAccess() || hasPlatformAdminAccess.value
    })
    const rules = ref({
      required: v => v.length >= 1 || 'This is a required field'
    })

    watch(auth, (cur) => {
      if (cur.isLoggedIn) {
        deviceServices.onAuthStateChanged((user) => {
          if (user) {
            loadDevice();
          } else {
            console.debug('Device page failed to load, user is not ready');
          }
        });
      }
    }, { immediate: true, deep: true })

    async function loadDevice() {
      try {
        device.value = await deviceServices.fetchDeviceByDeviceKey(deviceKey.value);
        device.value['deviceKey'] = deviceKey.value;
        state.value = await device.value.fetchDeviceState();
        let data = await device.value.fetchDeviceConfig();
        data = setDeviceConfigDefaults(data);
        // Cache initial value of the device config to compare against for changes
        deviceStore.actions.setCachedDeviceConfig(data);
        managedApps.value = await deviceServices.fetchManagedAppsList();
        deviceCommandsList.value = await fetchDeviceCommands();
        Object.assign(deviceConfig, data);
        
        device.value.listenToDeviceState( async (data) => {
          if (data.systemStartTime) {
            data.systemStartTimeLocal = new Date(
                data.systemStartTime
            ).toLocaleString();
          }
          if (data.deviceUpdateTime) {
            data.deviceUpdateTimeLocal = new Date(
                data.deviceUpdateTime
            ).toLocaleString();
          }
          state.value = data;
          encodedDisplayId = window.btoa(`Display:${deviceConfig.deviceKey}`);
          await fetchDisplayDetails()
        });
        isLoading.value = false;
      } catch (err) {
        if (err.toString() === 'Error: Permission denied') {
          isLoading.value = false
          errorDialog.value = true;
        }
        console.warn(err);
      }
    }

    function goBack() {
      router.push('/devices');
    }

    async function updateDisplayNameAndLocation(val, key) {
      isDisabled.value = true;
      const notify = $q.notify({
        type: 'ongoing',
        message: `Saving device ${key}...`,
        spinner: true
      })

      try {
        if (key === 'name' && val === '') return
        const data = {
          serial: state.value.serialNumber.trim(),
          [key]: val.trim()
        }
        
        const resp = await deviceJobs.udpateDeviceProperties(data)
        if (resp.status === 'OK') {
          notify({
            type: 'positive',
            message: `Updated device ${key}`,
            spinner: false
          })
        } else {
          console.error(resp)
          notify({
          type: 'negative',
          message: `failed to update device ${key}`,
          spinner: false
        })
        }
      } catch(err) {
        console.error(err)
        notify({
          type: 'negative',
          message: `failed to update device ${key}`,
          spinner: false
        })
      }
    }

    async function fetchDisplayDetails() {
      isDisabled.value = true;
      return await apolloClient.query({
        query: getDisplayDetailQuery,
        fetchPolicy: 'network-only',
        context: { clientName: 'displays' },
        variables: {
          id: encodedDisplayId,
        }
      })
      .then(result => {
        displayName.value = get(result, 'data.node.name') || '';
        displayLocation.value = get(result, 'data.node.location') || '';
        isLoading.value = false;
        isDisabled.value = true;
      })
      .catch(error => {
        displayName.value = 'N/A';
        console.warn(error)
        isLoading.value = false;
      });
    }

    function getModelAndManufacturer() {
      const model = state.value.model ? state.value.model : ''
      const manufacturer = state.value.manufacturer ? state.value.manufacturer : ''
      if (model || manufacturer) {
        return `(${model} ${manufacturer})`
      }
      return ''
    }

    // Screenshot Lightbox data and functions
    const visibleRef = ref(false);
    const indexRef = ref(0);
    const imgs = ref([]);
    async function showImg(scr) {
      const downloadUrl = await deviceServices.fetchFileDownloadUrl(scr?.reference)
      if (downloadUrl?.name === 'FirebaseError') {
        console.warn(downloadUrl?.message)
        $q.notify({
          type: 'negative',
          message: 'Error: Unable to fetch image'
        })
      } else {
        imgs.value = [];
        imgs.value.push(downloadUrl);
        indexRef.value = 0;
        visibleRef.value = true;
      }
    }
    const onHide = () => (visibleRef.value = false);

    function onClickSaveDeviceConfig() {
      saveDeviceConfig(device)
    }

    const {
      fetchDeviceCommands
    } = useCommands()
    
    const {
      isDisabled,
      isSavingDeviceConfig,
      setDeviceConfigDefaults,
      updatedDeviceConfigProperties,
      onChangeDeviceConfig,
      saveDeviceConfig,
      getOfflineStatus
    } = useDeviceConfig()

    return {
      tab: ref("status"),
      state: state,
      displayName,
      displayLocation,
      isDisabled,
      isSavingDeviceConfig,
      isLoading: isLoading,
      deviceConfig,
      managedApps,
      deviceCommandsList,
      onChangeDeviceConfig,
      updatedDeviceConfigProperties,
      onClickSaveDeviceConfig,
      device: device,
      router,
      errorDialog,
      goBack,
      hasEventsFF,
      hasPlatformAdminAccess,
      showImg,
      onHide,
      visibleRef,
      indexRef,
      imgs,
      rules,
      getOfflineStatus,
      hasFirmwareAccess,
      deviceLocation,
      updateDisplayNameAndLocation,
      getModelAndManufacturer
    };
  },
};
</script>

<style>
.page {
  width: 86%;
  margin-left: 7%;
}
.panel {
  margin-bottom: 3em;
}
.q-tab__label {
  font-size: 18px !important;
}
.display-details-input {
  background: inherit;
  border: .5px transparent solid;;
  border-radius: 5px;
  color: white;
  margin-top: -10px;
  padding-left: .5px;
  transition: all .2s ease-in-out;
  width: 80%;
}
.display-details-input:hover {
  border: .5px white solid;
  padding-left: 14px;
  width: 80%;
}
.display-details-input:focus,
select:focus {
  border: .5px white solid;
  padding-left: 14px;
  width: 80%;
}
.display-details-input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.display-details-input::-webkit-input-placeholder {
    font-size: 25px;
    transform:translate3d(0,-8px,0)
}
.tab-scroll-area .q-scrollarea__thumb {
    display: none !important;
}
.tab-scroll-area {
  height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
