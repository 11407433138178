export const bulkActionsList = [
  { id: 'sendCommand', name: 'Send Command' },
  { id: 'updateConfigs', name: 'Settings and Configurations' },
  { id: 'alerts', name: 'Alerts', featureFlag: 'cui-alerting' },
]

export const configurableItems = [
  {
    id: 'volume',
    label: 'Volume',
    caption: 'Set the volume on the selected devices',
    component: 'slider',
  },
  {
    id: 'brightness',
    label: 'Brightness',
    caption: 'Set the brightness on the selected devices',
    component: 'slider',
  },
  {
    id: 'timezone',
    label: 'Timezone',
    caption: 'Set the timezone on the selected devices',
    component: 'select',
  },
  {
    id: 'homeApp',
    label: 'Home / Launcher Application',
    caption: 'Set the home app on selected devices',
    component: 'input',
    inputLabel: 'enter home application package name'
  },
  {
    id: 'kioskApp',
    label: 'Kiosk Application',
    caption: 'Set the kiosk application on the selected devices',
    component: 'input',
    inputLabel: 'enter kiosk application package name'
  },
  {
    id: 'kiosk',
    label: 'Kiosk Lock Mode',
    caption: 'Put selected devices into kiosk lock mode',
    component: 'toggle',
    enabledStatus: 'Kiosk lock mode is enabled',
    disabledStatus: 'Kiosk lock mode is disabled',
  },
  {
    id: 'hideNavBar',
    label: 'Hide Navigation Bar',
    caption: 'Hide or show the navigation bar on the selected devices',
    component: 'toggle',
    enabledStatus: 'Hide Navigation Bar',
    disabledStatus: 'Show Navigation Bar',

  },
  {
    id: 'rotation',
    label: 'Rotation',
    caption: 'Set the screen rotation on the selected devices',
    component: 'select',
    inputLabel: 'select screen rotation'
  },
  {
    id: 'ignoreAccelerometer',
    label: 'Ignore Accelerometer',
    caption: 'Ignore the accelerometer on the selected devices. The accelerometer allows devices to switch orientation.',
    component: 'toggle',
    enabledStatus: 'Accelerometer will be ignored',
    disabledStatus: 'Accelerometer will not be ignored',
  },
  {
    id: 'unifiedDesktop',
    label: 'Unified View',
    caption: 'Enable Unified View on the selected devices',
    component: 'toggle',
    enabledStatus: 'Unified view is enabled',
    disabledStatus: 'Unified view is disabled',
  },
  {
    id: 'disallowUsb',
    label: 'Disallow USB Usage',
    caption: 'Prevent USB usage on the selected devices',
    component: 'toggle',
    enabledStatus: 'USB Usage is disabled',
    disabledStatus: 'USB Usage is enabled',
  },
  {
    id: 'density',
    label: 'Device Density',
    caption: 'Set the pixel density on the selected devices',
    component: 'select',
    inputLabel: 'select pixel density'
  },
  {
    id: 'frameBuffer',
    label: 'Device Frame Buffer',
    caption: 'Set resolution on the selected devices',
    component: 'input',
    inputLabel: 'enter device frame buffer'
  },
  {
    id: 'adbEnabled',
    label: 'Android Debug Bridge (ADB)',
    caption: 'Enable/disable ADB on the selected devices',
    component: 'toggle',
    enabledStatus: 'ADB is enabled',
    disabledStatus: 'ADB is disabled',
  },
  {
    id: 'scheduledReboot',
    label: 'Scheduled Reboot',
    caption: 'Enable repeated reboot on the selected devices',
    component: 'toggle',
    enabledStatus: 'Scheduled reboot is enabled',
    disabledStatus: 'Scheduled reboot is disabled',
  },
  {
    id: 'autoLaunchHome',
    label: 'Auto Launch Control Home App',
    caption: 'Automatically launch Control Home app on the selected devices',
    component: 'toggle',
    enabledStatus: 'Auto launch enabled',
    disabledStatus: 'Auto launch disabled',
  },
  {
    id: 'heartbeatIntervalMinutes',
    label: 'Offline Status Threshold',
    caption: 'Set how long after a device goes offline before receiving an alert',
    component: 'select',
    inputLabel: 'select device heartbeat interval'
  },
  {
    id: 'wifiEnabled',
    label: 'Wifi Enabled',
    caption: 'Enable Wifi on selected devices',
    component: 'toggle',
    enabledStatus: 'Wifi is enabled',
    disabledStatus: 'Wifi is disabled',
  },
  {
    id: 'bluetoothEnabled',
    label: 'Bluetooth Enabled',
    caption: 'Enable bluetooth on the selected devices',
    component: 'toggle',
    enabledStatus: 'Bluetooth is enabled',
    disabledStatus: 'Bluetooth is disabled',
  },
  {
    id: 'locationEnabled',
    label: 'Location Enabled',
    caption: 'Enable device location on selected devices',
    component: 'toggle',
    enabledStatus: 'Location is enabled',
    disabledStatus: 'Location is disabled',
  },
  {
    id: 'logLevel',
    label: 'Local Logging Level',
    caption: 'Set local logging level on selected devices',
    component: 'select',
    inputLabel: 'select device logging level',
    isPlatformAdmin: true
  },
  {
    id: 'remoteLogLevel',
    label: 'Remote Logging Level',
    caption: 'Enable/disable location on selected devices',
    component: 'select',
    inputLabel: 'select device logging level',
    isPlatformAdmin: true
  },
  {
    id: 'allowRecreate',
    label: 'Allow Auto Recreate',
    caption: 'Enable/disable auto recreate on selected devices',
    component: 'toggle',
    isPlatformAdmin: true,
    enabledStatus: 'Allow Auto Recreate is enabled',
    disabledStatus: 'Allow Auto Recreate is disabled',
  },
  {
    id: 'otaBuildType',
    label: 'OTA Build Type',
    caption: 'Select OTA build type on selected devices',
    component: 'select',
    inputLabel: 'select OTA build type',
    isPlatformAdmin: true
  },
  {
    id: 'otaChannel',
    label: 'OTA Channel',
    caption: 'Select OTA channel on selected devices',
    component: 'select',
    inputLabel: 'select OTA Channel',
    isPlatformAdmin: true
  },
  {
    id: 'otaUpdateUrl',
    label: 'Custom OTA Url',
    caption: 'Enter custom OTA Url on selected devices',
    component: 'input',
    inputLabel: 'enter OTA Url',
    isPlatformAdmin: true
  },
]
