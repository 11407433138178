<template>
  <q-page padding class="page">

    <q-card class="card q-mt-md" bordered>

      <div class="q-pa-md row justify-center">

          <q-spinner
          color="green-13"
          size="3em"
          :thickness="10"
        />

      </div>


      <div class="q-pa-md row justify-center">
        <div class="item-label" >Authenticating...</div>
      </div>

    </q-card>

    <!-- Tabs -->
    </q-page>
</template>

<script>
  import { ref, onMounted } from "vue";
  import { useRouter } from 'vue-router'
  import deviceServices from "../service/deviceServices"

export default {
  props: [
    'token'
  ],
  setup(props) {

    //const urlParams = new URLSearchParams(window.location.search);
    //const token = props.token;
    const router = useRouter();
    const loading = ref(false);

    async function signIn() {
      loading.value = true;
      try {
        if(await deviceServices.signInWithToken(props.token)) {
          router.replace('/');
        } else {
          router.replace('/login');
        }
      } catch (e) {
        console.warn(e);
        router.replace('/login');
      }
      loading.value = false;
    }

    onMounted(async () => {
      if(props.token) {
        await  signIn()
      } else {
        console.warn(`No token in query string`);
        router.replace('/login');
      }
    });

    return {
      loading: loading
    };
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 86%;
  margin-left: 7%;
}
.page h3 {
  text-align: left;
  color: white;
  font-weight: bolder;
}
.card {
  text-align: left;
  color: white;
  background-color: rgb(30, 30, 30);
  margin-top: 1rem;
}
.card {
  text-align: left;
  color: white;
  background-color: rgb(30, 30, 30);
  margin-top: 1rem;
}
.item-label {
  font-weight: bolder;
  font-size: 1.2rem;
  color: white;
  min-width: 9rem;
}

</style>
