<template>
  <app-btn
    @click="layout = true"
    :label="label"
  >
  </app-btn>

  <q-dialog v-model="layout"  @hide="layout = false" persistent>
    <q-card id="appsDialog" dark rounded class="q-pa-md">
      <q-card-section>
        <div class="text-h5"><b>Upload App</b></div>
      </q-card-section>

      <q-card-section style="height:70%;">
        <div 
          @drop.prevent="handlePickedFiles" 
          @dragover.prevent 
          class="dropZone"
          style="height:100%;"
        >
          <div v-if="queuedApps.length === 0" class="row justify-center items-center text-center" style="height:100%;">
            <h5  class="q-pa-md">
              Drag &amp; Drop or
              <label class="manual-file-input-container">
                <input
                  type="file"
                  class="manual-file-input"
                  @change="handlePickedFiles"
                  accept="application/vnd.android.package-archive, .apk"
                >
                browse</label><br>
              to upload your file
            </h5>
          </div>
          
          <div v-else v-for="app in queuedApps" :key="app" style="width:100%;" class="q-pa-sm">
            <q-list class="">
              <q-item class="row items-center q-py-none q-ma-sm queued-app-container">
                <q-icon name="description" size="sm" class="q-mr-sm vertical-middle"></q-icon>
                <q-item-label class="col">
                  {{ app.name }}
                </q-item-label>
                <q-space></q-space>
                <q-icon @click="removeFiles" name="close"></q-icon>
              </q-item>
            </q-list>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="q-px-md">
        <q-btn flat label="Cancel" color="green-13" @click="closeAppsDialog" />
        <app-btn
          label="Upload"
          @click="addApk"
          :isDisabled="!queuedApps.length"
        >
        </app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue';
import { useQuasar } from 'quasar';
import DeviceServices from "../service/deviceServices";
import AppBtn from '@/components/AppBtn';

export default {
  components: {
    AppBtn
  },
  props: {
    label: String,
    icon: String
  },
  emits: ['apks-updated'],
  setup(props ,{ emit }) { //eslint-disable-line no-unused-vars
    const $q = useQuasar();
    const layout = ref(false);
    const queuedApps = ref([]);

    function handlePickedFiles(event) {
      const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
      const ext = getFileExt(file.name)
      if (file.type !== 'application/vnd.android.package-archive' && ext !== 'apk') {
        $q.notify({
          type: 'negative',
          message: 'File type not supported. The file extension must be .apk'
        })
        return
      }
      if (queuedApps.value.length > 0) {
        $q.notify({
          type: 'negative',
          message: 'Only one file can be uploaded at a time'
        })
        return
      }

      queuedApps.value.push(file)
    }

    function getFileExt(fileName) {
      return fileName.split('.').pop();
    }

    function removeFiles() {
      queuedApps.value = [];
    }

    function closeAppsDialog() {
      layout.value = false;
      queuedApps.value = [];
    }

    async function addApk() {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Uploading APK...',
        spinner: true
      })
      const file = queuedApps.value[0];
      // If uploaded in Safari the event.target.files is missing the
      // file type and we need to assign it here
      if (!file.type) {
        Object.defineProperty(file, "type", {
          value: "application/vnd.android.package-archive"
        })
      }
      const uploadId = await DeviceServices.addUpload(file.name)
      DeviceServices.listenForUploadChanges(uploadId, (data) => {
        Object.assign(file, data);
        if (data.status === 'incomplete') {
          return
        }
        if (data.errorMessage) {
          notify({
            type: 'negative',
            message: 'Unable to upload APK',
            caption: data.errorMessage,
            spinner: false
          })
          console.error(data.errorMessage)
        }
        if (data.status === 'complete' && !data.errorMessage) {
          notify({
            type: 'positive',
            message: 'APK uploaded successfully',
            spinner: false
          })
          queuedApps.value = [];
        }
        if (file.appKey && (data.status !== 'uploaded' && data.status !== 'processing' && data.status !== 'error') ) {
          DeviceServices.listenForTenantApkChanges((data) => {
            emit('apks-updated', {data: data, appKey: file.appKey})
          })
        }
      })
      await DeviceServices.addApkToStorage(uploadId, file);
      closeAppsDialog()
    }
 
    return {
      layout,
      queuedApps,
      handlePickedFiles,
      removeFiles,
      closeAppsDialog,
      addApk
    }
  },
}
</script>

<style scoped>
#appsDialog {
  width:80%;
  max-width:800px;
  height:60%;
  border-radius: 10px;
}
.dropZone {
  border: white 1px dashed;
  background: rgb(40,40,40);
}
.manual-file-input-container {
  border-bottom: 2px solid #ffffff;
  cursor: pointer;
  font-weight: bolder;
}
.manual-file-input {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}
.queued-app-container {
  border: #ffffff solid 1px;
}
</style>