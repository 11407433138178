function toTitleCase(string) {
  if (string && typeof string === 'string') {
    return string
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }
  return
}

function capitalizeFirstLetter(string) {
  if (string && typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return
}

export {
  toTitleCase,
  capitalizeFirstLetter
}