import { ref, watch } from 'vue'
import deviceJobs from '../service/deviceJobs'
import deviceServices from '../service/deviceServices'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import { configurableItems } from '../constants/bulkActions'
import { useRouter } from 'vue-router'

let selectedConfigOption = ref(null)
let configItemVal = ref(null)
let isDisabled = ref(true)
let isSaving = ref(false)

export default function useBulkConfig() {
  const $q = useQuasar()
  const router = useRouter()

  watch(() => configItemVal.value, (cur) => {
    if (cur !== null && cur !== undefined) {
      isDisabled.value = false
    } else {
      isDisabled.value = true
    }
  })

  function onUpdateConfigs(deviceQuery, scheduledRebootObj, isMultipleDevices) {
    const key = selectedConfigOption.value
    let updatedConfigProp = {
      [key]: configItemVal.value,
    }
    if (scheduledRebootObj.restartTime || scheduledRebootObj.restartDay) {
      updatedConfigProp = {
        [key]: configItemVal.value,
        ...scheduledRebootObj
      }
    }
    saveBulkDeviceConfigs(deviceQuery, updatedConfigProp, isMultipleDevices)
  }

  async function saveBulkDeviceConfigs(deviceQuery, configProp, isMultipleDevices) {
    const propObj = getConfigPropObj(configProp)
    const notify = $q.notify({
      type: 'ongoing',
      message: `<span>Updating <span class="text-body2"><b>${propObj.label}</b></span> on selected displays...</span>`,
      html: true,
      spinner: true
    })
    try {
      const resp = await deviceJobs.updateDeviceConfigs(deviceQuery, configProp)
      if (resp.jobTraceId) {
        deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
          if (data){
            if (data.status === 'incomplete') {
              return
            }
            if (data.status === 'complete' && !data.error) {
              notify({
                type: 'positive',
                message: `<span> <span class="text-body2"><b>${propObj.label}</b></span> update successfully sent</span>`,
                actions: setActions(isMultipleDevices),
                html: true,
                spinner: false
              })
              return
            }
            if (data.status === 'complete' && data.error) {
              notify({
                type: 'negative',
                message: `<span<span class="text-body2"><b>${propObj.label}</b></span> update failed to send</span>`,
                html: true,
                spinner: false
              })
              return
            }
          }
        })
      } else {
        notify({
          type: 'negative',
          message: `<span<span class="text-body2"><b>${propObj.label}</b></span> update failed to send</span>`,
          html: true,
          spinner: false
        })
        console.error(resp)
      }
    } catch (err) {
      console.error(err)
      notify({
        type: 'negative',
        message: `<span<span class="text-body2"><b>${propObj.label}</b></span> update failed to send</span>`,
        html: true,
        spinner: false
      })
    }
  }

  function setActions(isMultipleDevices) {
    return isMultipleDevices ?
    [
      { label: 'View Job Status', color: 'white', handler: () => { router.push({name: 'JobStatus'}) } }
    ] 
    : []
  }

  function goBack() {
    selectedConfigOption.value = null
    configItemVal.value = null
  }

  function getConfigPropObj(prop) {
    if (prop && typeof prop === 'object') {
      const key = Object.keys(prop)[0]
      if (key) {
        return configurableItems.filter(el => el.id === key)[0]
      }
      return {}
    } else if (prop && typeof prop === 'string') {
      return configurableItems.filter(el => el.id === prop)[0]
    }
  }

  return {
    isDisabled,
    isSaving,
    selectedConfigOption,
    configItemVal,
    goBack,
    onUpdateConfigs,
    getConfigPropObj
  }
}
