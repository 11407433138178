import script from "./RestartDayTime.vue?vue&type=script&setup=true&lang=js"
export * from "./RestartDayTime.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QTime from 'quasar/src/components/time/QTime.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QSeparator,QBtnDropdown,QTime,QBtn});qInstall(script, 'directives', {ClosePopup});
