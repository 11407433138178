<template>
  <div>
    <q-page v-if="isPageLoading">
      <q-linear-progress indeterminate color="green-13"/>
    </q-page>
    <q-page v-else padding class="page">
      <div class="row q-mt-lg items-center cursor-pointer q-mb-md" @click="goBack">
        <q-icon
          @click="goBack"
          color="white"
          size="xs"
          class="text-left "
          name="arrow_back_ios"
        >
        </q-icon>
        <span class="text-white text-subtitle1">Apps</span>
      </div>
      <div class="text-left text-white">
        <span class="text-bold text-h3 ellipsis" style="max-width: 70%;">{{ model.name }}</span>
        <div>
          <span class="text-subtitle1">
            {{ model.alpha?.fileName}}
          </span>
        </div>
      </div>

      <!-- Will be re-added in V2 -->
      <!-- <div class="q-mb-lg" style="width:50%;">
        <q-item-label class="text-white text-left text-bold q-mb-sm">Description</q-item-label>
        <q-input
          v-model="model.description"
          type="textarea"
          dark
          label="Enter description"
          dense
          square
          filled
          color="green-13"
        />
      </div> -->

      <div>
        <q-card bordered class="q-pa-lg q-mb-xl card">
          <p class="text-bold text-h5">Release Channel</p>
          <div class="row">
            <q-card class="col q-pa-lg q-ma-sm shadow-15 inner-card">
              <div class="row q-mb-lg">
                <span class="text-bold text-h5">
                  Alpha
                </span>
                <q-space></q-space>

                <add-apps-dialog @apks-updated="updateApk" label="replace" icon="file_upload"></add-apps-dialog>

              </div>
              <div class="q-mb-xl">
                <q-list>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        File Name
                      </q-item-label>
                      <q-item-label class="text-h6 ellipsis">
                        {{ model.alpha?.originalFileName }}
                        <q-tooltip :delay="300">
                          <span>
                            {{ model.alpha?.originalFileName }}
                          </span>
                        </q-tooltip>
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Release Date
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ formatDateString(model.alpha?.releaseDate) }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Date Uploaded
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ formatDateString(model.alpha?.releaseDate) }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Version
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ model.alpha?.version }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Version Code
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ model.alpha?.versionCode }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Minimum Version Code
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ model.alpha?.minSdkVersion || 'N/A'}}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Target Version Code
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ model.alpha?.targetSdkVersion || 'N/A' }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Signature
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ model.alpha?.signature }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
              <div class="q-mt-xl" style="height:30px;">
                
              </div>
              <div class="row absolute-bottom promote-btn-wrapper">
                <q-btn
                  color="green-13"
                  rounded
                  label="promote to beta"
                  text-color="black"
                  class="promote-btn"
                  :disabled="canPromote('alpha')"
                  @click="onClickPromote('alpha')"
                >
                </q-btn>
              </div>
            </q-card>

            <q-card class="col q-pa-lg q-ma-sm shadow-15 inner-card">
              <div v-if="isLoading && promoteFrom === 'alpha'" class="full-height">
                <span class="text-bold text-h6">Beta</span>
                <div class="row items-center text-center full-height">
                  <q-spinner
                    color="green-13"
                    size="8em"
                    :thickness="3"
                    class="col"
                  />
                </div>
              </div>
              <div v-else-if="Object.keys(model).includes('beta')">
                <div class="row q-mb-lg">
                  <span class="text-bold text-h5">
                    Beta
                  </span>
                </div>
                <div class="q-mb-xl">
                  <q-list>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          File Name
                        </q-item-label>
                        <q-item-label class="text-h6 ellipsis">
                          {{ model.beta?.originalFileName }}
                          <q-tooltip :delay="300">
                            <span>
                              {{ model.beta?.originalFileName }}
                            </span>
                          </q-tooltip>
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          Release Date
                        </q-item-label>
                        <q-item-label class="text-h6">
                          {{ formatDateString(model.beta?.releaseDate) }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          Version
                        </q-item-label>
                        <q-item-label class="text-h6">
                          {{ model.beta?.version }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          Version Code
                        </q-item-label>
                        <q-item-label class="text-h6">
                          {{ model.beta?.versionCode }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Minimum Version Code
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ model.beta?.minSdkVersion || 'N/A' }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Target Version Code
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ model.beta?.targetSdkVersion || 'N/A' }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          Signature
                        </q-item-label>
                        <q-item-label class="text-h6">
                          {{ model.beta?.signature }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
                <div class="row absolute-bottom promote-btn-wrapper">
                  <q-btn
                    color="green-13"
                    rounded
                    label="promote to Production"
                    outlined
                    text-color="black"
                    class="promote-btn"
                    :disabled="canPromote('beta')"
                    @click="onClickPromote('beta')"
                  >
                  </q-btn>
                </div>
              </div>

              <div v-else class="row full-height text-center">
                <div class="row q-mb-lg">
                  <span class="text-bold text-h6">
                    Beta
                  </span>
                </div>
                <span class="text-h6 full-width">No versions currently in Beta</span>
              </div>
            </q-card>
            
            <q-card class="col q-pa-lg q-ma-sm shadow-15 inner-card">
              <div v-if="isLoading && promoteFrom === 'beta'" class="full-height">
                <span class="text-bold text-h6">Production</span>
                <div class="row items-center text-center full-height">
                  <q-spinner
                    color="green-13"
                    size="8em"
                    :thickness="3"
                    class="col"
                  />
                </div>
              </div>
              <div v-else-if="Object.keys(model).includes('prod')">
                <div class="row q-mb-lg">
                  <span class="text-bold text-h5">
                    Production
                  </span>
                </div>
                <div class="q-mb-xl">
                  <q-list>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          File Name
                        </q-item-label>
                        <q-item-label class="text-h6 ellipsis">
                          {{ model.prod?.originalFileName }}
                          <q-tooltip :delay="300">
                            <span>
                              {{ model.prod?.originalFileName }}
                            </span>
                          </q-tooltip>
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          Release Date
                        </q-item-label>
                        <q-item-label class="text-h6">
                          {{ formatDateString(model.prod?.releaseDate) }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          Version
                        </q-item-label>
                        <q-item-label class="text-h6">
                          {{ model.prod?.version }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          Version Code
                        </q-item-label>
                        <q-item-label class="text-h6">
                          {{ model.prod?.versionCode }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Minimum Version Code
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ model.prod?.minSdkVersion || 'N/A' }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item class="q-px-none">
                    <q-item-section>
                      <q-item-label class="text-subtitle2">
                        Target Version Code
                      </q-item-label>
                      <q-item-label class="text-h6">
                        {{ model.prod?.targetSdkVersion || 'N/A' }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                    <q-item class="q-px-none">
                      <q-item-section>
                        <q-item-label class="text-subtitle2">
                          Signature
                        </q-item-label>
                        <q-item-label class="text-h6">
                          {{ model.prod?.signature }}
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>

              <div v-else class="row full-height text-center">
                <div class="row q-mb-lg">
                  <span class="text-bold text-h6">
                    Production
                  </span>
                </div>
                <span class="text-h6 full-width">No versions currently in Production</span>
              </div>
            </q-card>
          </div>
        </q-card>
      </div>
    </q-page>

    <q-dialog v-model="promoteDialog" persistent>
      <q-card id="appsDialog" dark rounded class="q-pb-md full-width">
        <q-card-section class="q-pb-none">
          <div class="text-h5 q-mb-sm">Promote this version to <b>{{ nextRelease.label }}</b>?</div>
          <div class="text-subtitle1">You are about to promote this version to <b>{{ nextRelease.label }}</b>.
            Do you wish to continue?
          </div>
        </q-card-section>

        <q-card-section>
          <q-card class="inner-card q-pa-md">
            <q-list>
              <q-item class="q-pl-none">
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    File Name
                  </q-item-label>
                  <q-item-label class="text-h6">
                    {{ model[promoteFrom].fileName}}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item class="q-pl-none">
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    Version
                  </q-item-label>
                  <q-item-label class="text-h6">
                    {{ model[promoteFrom].version }}
                  </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-subtitle2">
                    Version Code
                  </q-item-label>
                  <q-item-label class="text-h6">
                    {{ model[promoteFrom].versionCode }}
                  </q-item-label>
                </q-item-section>
                <q-item-section></q-item-section>
              </q-item>
            </q-list>
          </q-card>
          
        </q-card-section>

        <q-card-actions align="right" class="q-px-md">
          <q-btn flat label="Cancel" color="green-13" @click="promoteDialog = false"/>
          <app-btn
            text-color="black"
            :label="`promote to ${nextRelease.label}`"
            color="green-13"
            @click="promoteApk(nextRelease.id)"
          >
          </app-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import AddAppsDialog from '@/components/AddAppsDialog';
import DeviceServices from '../service/deviceServices';
import { formatDateString } from '../utils/dateUtils';
import { setTenantApks } from '../store/index';
import AppBtn from '../components/AppBtn';

export default {
  components: {
    AddAppsDialog,
    AppBtn,
  },
  setup() {
    const isPageLoading = ref(false);
    const isLoading = ref(false);
    const router = useRouter();
    const route = useRoute();
    const model = ref({});
    const promoteFrom = ref('');
    const promoteDialog = ref(false);
    const nextRelease = ref({
      id: null,
      label: null
    });
    const packageName = route.params.apkKey;

    onMounted(() => {
      isPageLoading.value = true
      DeviceServices.onAuthStateChanged(async (user) => {
        if (user) {
          model.value = await DeviceServices.fetchApk(packageName)
          isPageLoading.value = false;
        } else {
          DeviceServices.signOut()
        }
      });
      
    })

    function onClickPromote(currentRelease) {
      promoteFrom.value = currentRelease;
      nextRelease.value = currentRelease === 'alpha' ? {id: 'beta', label: 'Beta'} : {id: 'prod', label: 'Production'};
      promoteDialog.value = true
    }

    async function promoteApk(promoteTo) {
      isLoading.value = true;
      promoteDialog.value = false;
      try {
        await DeviceServices.promoteApk(packageName, promoteTo)
        DeviceServices.listenForApkChanges(packageName, (data) => {
          model.value = data;
          isLoading.value = false;
        })
        // Update APK details to state so it's available immediately in the apps tab
        const tenantApks = await DeviceServices.fetchTenantApks();
        if (tenantApks) {
          setTenantApks(tenantApks)
        } else {
          console.error('Unable to fetch tenant apks, Promoted channel won\'t be available in the apps tab until the page is refreshed')
        }
      } catch (error) {
        console.error(error)
        isLoading.value = false;
      }
    }

    function updateApk() {
      DeviceServices.listenForApkChanges(packageName, (data) => {
        model.value = data;
      })
    }

    function getApkRelease(app = {}) {
      if (Object.keys(app).includes('prod')) {
        return 'prod'
      }
      if (Object.keys(app).includes('beta')) {
        return 'beta'
      }
      return 'alpha'
    }

    function canPromote(promoteFrom) {
      let isPromotable;
      if (promoteFrom === 'alpha') {
        if (model.value.beta) {
          isPromotable = model.value.alpha.version === model.value.beta.version ? false : true;
        } else {
          isPromotable = true;
        }
      }
      if (promoteFrom === 'beta') {
        if (model.value.prod) {
          isPromotable = model.value.beta.version === model.value.prod.version ? false : true;
        } else {
          isPromotable = true;
        }
      }
      return !isPromotable
    }

    function goBack() {
      router.push({path: '/apps'})
    }

    return {
      isPageLoading,
      isLoading,
      router,
      promoteFrom,
      promoteDialog,
      nextRelease,
      model,
      onClickPromote,
      promoteApk,
      getApkRelease,
      formatDateString,
      canPromote,
      updateApk,
      goBack
    }
  },
}
</script>
<style scoped>
.page {
  width: 86%;
  margin-left: 7%;
}
textarea {
  height: 80px;
}
.card {
  background-color: rgb(30, 30, 30) !important;
  text-align: left;
  color: white;
  margin-top: 2rem;
}
.inner-card {
  background-color: rgb(40, 40, 40) !important;
}
.promote-btn-wrapper {
  width: 100%;
  margin-left: 7.5%;
  margin-bottom: 30px;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.promote-btn {
  width:85%;
  height:50px;
}
</style>