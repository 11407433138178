import script from "./CommandsEvents.vue?vue&type=script&setup=true&lang=js"
export * from "./CommandsEvents.vue?vue&type=script&setup=true&lang=js"

import "./CommandsEvents.vue?vue&type=style&index=0&id=6b858f3e&lang=css"

const __exports__ = script;

export default __exports__
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QSeparator});
