import { gql } from 'apollo-boost';

const getDisplayDetailQuery = gql`
  query GetDisplayDetail($id: ID!) {
    node(id: $id) {
      ... on Display {
        name
        location
      }
    }
  }
`;

const putDisplayDetailMutation = gql`
  mutation PutDisplayDetail($data: UpdateDisplayInput!) {
    updateDisplay(input: $data) {
      fallbackMode
      id
      location
      name
      serialNumber
      unmanaged
      labelGids
      tenantGid
      status {
        id
        heartbeatIntervalMs
        playingMode
        stateUpdateTime
        isOffline
        controlVersionCode
        controlVersionName
        playerVersion
        playerVersionCode
        playingContentGid
        model
        manufacturer
      }
    }
  }
`;

export {
    getDisplayDetailQuery,
    putDisplayDetailMutation
}