import script from "./JobStatus.vue?vue&type=script&setup=true&lang=js"
export * from "./JobStatus.vue?vue&type=script&setup=true&lang=js"

import "./JobStatus.vue?vue&type=style&index=0&id=4b18efe3&lang=css"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QTabs,QTab,QSeparator,QTabPanels,QTabPanel,QCard,QItem,QItemSection,QLinearProgress,QSpinner,QBtn,QDialog,QSpace});
