import { fbApp } from "@/service/firebase";
import { getDatabase, ref, get, set, onValue, query, orderByChild, limitToLast, push } from "firebase/database";
import { getAuth, signInWithCustomToken, onAuthStateChanged, signOut } from "firebase/auth";
import { getStorage, ref as storeRef, getDownloadURL } from "firebase/storage";
import { uploadBytes } from "firebase/storage";
import { setPlayerToken, getBeamToken } from '@/store/index'
import { getTenantGid, getTenantCode } from "../store/index.js";

// For individual device
class ControlDevice {

  constructor(deviceId) {
    this.deviceId = deviceId;
    this.lastState = null;
    this.lastStatus = null;
    this.tenant = getTenantCode();
  }

  configRef() {
    return ref(getDatabase(fbApp),`controls/configs/${this.deviceId}`);
  } 

  stateRef() {
    return ref(getDatabase(fbApp),`controls/states/${this.deviceId}`);
  }

  statusRef() {
    return ref(getDatabase(fbApp),`controls/status/${this.deviceId}`);
  }

  statusRefForApp(appKey) {
    return ref(getDatabase(fbApp),`controls/status/${this.deviceId}/appInstallStatus/${appKey}`);
  }

  filesRef() {
    return ref(getDatabase(fbApp),`tenants/files/${this.tenant}/${this.deviceId}`);
  }

  fileRef(fileId) {
    return ref(getDatabase(fbApp),`tenants/files/${this.tenant}/${this.deviceId}/${fileId}`);
  }

  historyRef() {
    return ref(getDatabase(fbApp),`controls/history/${this.deviceId}`);
  }

  controlEventsRef() {
    return ref(getDatabase(fbApp),`/controls/events/${this.deviceId}`)
  }

  playerEventsRef() {
    return ref(getDatabase(fbApp),`/players/events/${this.deviceId}`)
  }

  commandsEventsRef() {
    return ref(getDatabase(fbApp),`/controls/history/${this.deviceId}`)
  }
  
  deviceAlertsRef() {
    return ref(getDatabase(fbApp),`/controls/alerts/${this.deviceId}`)
  }

  listenToDeviceAlerts(callback) {
    onValue(this.deviceAlertsRef(), (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  async fetchDeviceConfig() {
    const snapshot = await get(this.configRef());
    if(snapshot.exists()) {
      return snapshot.val();
    }
    throw `Missing device config`;
  }

  async fetchScreenshot(fileId) {
    const snapshot = await get(this.fileRef(fileId));
    if(snapshot.exists()) {
      return snapshot.val();
    }
    throw `Missing device config`;
  }

  async saveDeviceConfig(config)  {
    console.log(JSON.stringify(config));
    await set(this.configRef(),config);
  }

  async fetchDeviceState() {
    if(this.lastState) {
      return this.lastState;
    }
    const snapshot = await get(this.stateRef());
    if(snapshot.exists()) {
      this.lastState = this.normalizeState(snapshot.val());
      return this.lastState;
    }
    throw `Missing device state`;
  }

  normalizeState(state) {
    if (state.isOffline === undefined) {
      state.isOffline = true
    }
    return state
  }

  async fetchDeviceStatus() {
    if(this.lastStatus) {
      return this.lastStatus;
    }
    const snapshot = await get(this.statusRef());
    if(snapshot.exists()) {
      this.lastStatus = snapshot.val();
      return this.lastStatus;
    }
    throw `Missing device status`;
  }

  listenToEvents(type, callback) {
    let ref;
    let orderBy;
    switch(type) {
      case 'player' :
        ref = this.playerEventsRef()
        orderBy = 'stateReceiveTime'
        break
      case 'control' :
        ref = this.controlEventsRef()
        orderBy = 'stateReceiveTime'
        break
      case 'commands' :
        ref = this.commandsEventsRef()
        orderBy = 'datetime'
        break
    }
    
    const recentRef = query(ref, orderByChild(orderBy), limitToLast(25));
    onValue(recentRef, (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  listenToDeviceState(callback) {
    onValue(this.stateRef(), (snapshot) => {
      const data = snapshot.val();
      this.lastState = this.normalizeState(data);
      callback(data);
    });
  }

  listenToDeviceStatus(callback) {
    onValue(this.statusRef(), (snapshot) => {
      const data = snapshot.val();
      this.lastStatus = data;
      callback(data);
    });
  }

  listenForAppStatusChanges(appKey, callback) {
    onValue(this.statusRefForApp(appKey), (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  async sendDeviceCommand(code,command) {
    const options = {
      method: 'POST',
      body: JSON.stringify({intent: decodeURIComponent(command), deviceId: this.deviceId, deviceP: code}),
      headers: {'Authorization': code, 'Content-Type': 'application/json'}
    };
    const res = await fetch(`https://us-central1-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/sendControlCommand`, options);
    if (res.ok) {
      return await res.text();
    } else {
      throw await res.text();
    }
  }

  listenForFiles(callback) {
    return this.listenForDateTimeRecords(this.filesRef(),callback)
  }

  listenForHistory(callback) {
    return this.listenForDateTimeRecords(this.historyRef(),callback)
  }

  listenForDateTimeRecords(dbRef,callback,limit=20) {
    const recentRef = query(dbRef, orderByChild('datetime'), limitToLast(limit));
    onValue(recentRef, (snapshot) => {
      const data = snapshot.val();
      const rows = [];
      if (data) {
        const keys = Reflect.ownKeys(data);
        for (let key of keys) {
          rows.push(data[key]);
        }
        callback(rows.reverse());
      }
    });
  }

  static async deviceIdFromDeviceKey(deviceKey) {
    const snapshot = await get(ref(getDatabase(fbApp),`deviceKeys/${deviceKey}`));
    if(snapshot.exists()) {
      const data = snapshot.val();
      return data.deviceId ? data.deviceId : "";
    }
    return "";
  }

}

class DeviceServices {

  constructor() {
    this.uid = null;
    this.user = null;
    this.tenant = getTenantCode();
  }

  fetchDevice(deviceId) {
    return new ControlDevice(deviceId);
  }

  async fetchDeviceByDeviceKey(deviceKey) {
    console.log(`fetching ${deviceKey}`)
    const deviceId = await ControlDevice.deviceIdFromDeviceKey(deviceKey);
    if(deviceId) {
      return new ControlDevice(deviceId);
    }
    throw `No device found for ${deviceKey}`;
  }

  async signInWithDeviceP(code,serialNumber) {
    const options = {
      method: 'POST',
      body: JSON.stringify({code:code,serialNumber:serialNumber}),
      headers: {'Authorization' : code, 'Content-Type':'application/json'}
    };
    const res = await fetch(`https://us-central1-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/singleDeviceToken`,options);
    if(res.ok) {
      const token = await res.text();
      return this.signInWithToken(token);
    } else {
      throw await res.text();
    }
  }

  async signInWithTenant(token,tenantGid,tenantCode) {
    const options = {
      method: 'POST',
      body: JSON.stringify({tenantCode:tenantCode,tenantGid:tenantGid}),
      headers: {'Authorization' : `Bearer ${token}`, 'Content-Type':'application/json'}
    };
    const res = await fetch(`https://us-central1-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/tenantToken`,options);
    if(res.ok) {
      const token = await res.text();
      setPlayerToken(token)
      return this.signInWithToken(token);
    } else {
      throw await res.text();
    }
  }


  async signInWithToken(token) {
    try {
      return await signInWithCustomToken(getAuth(fbApp),token);
    } catch (e) {
      console.warn(e);
      throw new Error(e && e.message);
    }
  }

  async getCurrentUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        getAuth(fbApp),
        (user) => {
          unsubscribe();
          resolve(user);
        },
        reject
      );
    });
  }

  async getClaims() {
    const tokenResult = await getAuth(fbApp)?.currentUser?.getIdTokenResult();
    return tokenResult?.claims;
  }

  async getJwtToken() {
    const tokenResult = await getAuth(fbApp)?.currentUser?.getIdToken();
    return tokenResult;
  }

  async signOut() {
    try {
      console.warn("forced signOut");
      await signOut(getAuth(fbApp));
      this.user = null;
      this.uid = null;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  async fetchCommands() {
    const snapshot = await get(ref(getDatabase(fbApp),`serverConfigs/commands/control`));
    if(snapshot.exists()) {
      const data = snapshot.val();
      return { data }
    }
    throw `Missing commands`;
  }

  async fetchManagedAppsList() {
    const res = await fetch(`https://us-central1-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/apkDownloads?tenantCode=${this.uid}`);
    if(res.ok) {
      return await res.json();
    } else {
      throw 'failed to fetch managed apps';
    }
  }

  async fetchFileDownloadUrl(dbReference) {
    try {
      const storage = getStorage();
      const url = await getDownloadURL(storeRef(storage, dbReference));
      return url
    } catch (err) {
      return err
    }
  }

  onAuthStateChanged(callback) {
    onAuthStateChanged(getAuth(fbApp), async (user) => {
      if (user) {
        console.log(`${user.uid} logged in`);
        this.user = user;
        this.uid = user.uid;
      } else {
        console.log(`logged out`);
        this.user = null;
        this.uid = null;
      }
      callback(this.user)
    })
  }

  apkRef(apkKey) {
    return ref(getDatabase(fbApp),`tenants/apks/${this.uid}/${apkKey}`)
  }

  tenantApkRef() {
    return ref(getDatabase(fbApp),`tenants/apks/${this.uid}`);
  }

  uploadsRef(uploadId="") {
    uploadId = uploadId ? `/${uploadId}` : "";
    return ref(getDatabase(fbApp),`tenants/uploads/${this.uid}${uploadId}`);
  }

  jobRef(jobTraceId, tenantCode) {
    return ref(getDatabase(fbApp),`tenants/groupSummary/${tenantCode}/${jobTraceId}`);
  }

  artifactsRef() {
    return ref(getDatabase(fbApp),`tenants/artifacts/${this.uid}`);
  }

  jobHistoryGroupsRef() {
    return ref(getDatabase(fbApp),`tenants/groupSummary/${this.tenant}`);
  }

  jobErrorRef(jobTraceId, deviceId) {
    return ref(getDatabase(fbApp),`groups/${jobTraceId}/errors/${deviceId}`)
  }

  notificationGroupRef(groupId, tenantCode) {
    return ref(getDatabase(fbApp), `tenants/notifyGroups/${tenantCode}/${groupId}`)
  }

  notificationGroupsRef(tenantCode) {
    return ref(getDatabase(fbApp), `tenants/notifyGroups/${tenantCode}`)
  }

  deviceModelsRef() {
    return ref(getDatabase(fbApp),`serverConfigs/controlConfigs/models`)
  }
  
  deviceFlavorsRef() {
    return ref(getDatabase(fbApp),`serverConfigs/controlConfigs/flavors`)
  }

  installedAppsRef(deviceId) {
    return ref(getDatabase(fbApp),`controls/status/${deviceId}/appsInstalled`);
  }

  listenToInstalledApps(deviceId, callback) {
    onValue(this.installedAppsRef(deviceId), (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  async fetchJobError(jobTraceId, deviceId) {
    const snapshot = await get(this.jobErrorRef(jobTraceId, deviceId));
    if(snapshot.exists()) {
      return snapshot.val();
    }
    throw `No error found for this job`;
  }
  
  async addApkToStorage(uploadId, file) {
    const storage = getStorage();
    const storageRef = storeRef(storage, `/tenants/apks/${this.uid}/${uploadId}.apk`)
    uploadBytes(storageRef, file).then(() => {
      console.log(`Added APK: ${uploadId}`);
    });
  }

  async fetchApk(apkKey) {
    const snapshot = await get(this.apkRef(apkKey));
    if(snapshot.exists()) {
      return snapshot.val();
    }
    return {};
  }

  async fetchTenantApks() {
    const snapshot = await get(this.tenantApkRef());
    if(snapshot.exists()) {
      return snapshot.val();
    }
    return {};
  }

  async fetchDeviceModels(callback) {
    const snapshot = await get(this.deviceModelsRef());
    if(snapshot.exists()) {
      const data = snapshot.val();
      const rows = [];
      if (data) {
        const keys = Object.keys(data);
        for (let key of keys) {
          if (!data[key].isFlavor) {
            rows.push(key)
          }
        }
        callback(rows);
      }
    }
  }

  async fetchDeviceFlavors(callback) {
    const snapshot = await get(this.deviceFlavorsRef());
    if(snapshot.exists()) {
      const data = snapshot.val();
      const rows = [];
      if (data) {
        const keys = Object.keys(data);
        for (let key of keys) {
          if (!data[key].isFlavor) {
            rows.push(key)
          }
        }
        callback(rows);
      }
    }
  }
  
  async getNotificationGroupById(groupId, tenantCode) {
    const snapshot = await get(this.notificationGroupRef(groupId, tenantCode));
    if(snapshot.exists()) {
      return snapshot.val();
    }
    return {};
  }

  listenForTenantApkChanges(callback) {
    onValue(this.tenantApkRef(), (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  listenForJobHistoryGroups(callback) {
    try {
    this.tenant = getTenantCode()
    const recentRef = query(this.jobHistoryGroupsRef(), orderByChild('datetime'), limitToLast(50));
      onValue(recentRef, (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          const rows = [];
          if (data) {
            const keys = Object.keys(data);
            for (let key of keys) {
              data[key]['jobTraceId'] = key;
              rows.push(data[key]);
            }
            callback(rows.reverse());
          }
        } else {
          callback('noData')
        }
      });
    } catch(err) {
      console.warn(err)
    }
  }

  listenForApkChanges(appKey, callback) {
    onValue(this.apkRef(appKey), (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  async addUpload(originalFileName) {
    const newRef = await push(this.uploadsRef());
    await set(newRef, {originalFileName: originalFileName});
    return newRef.key;
  }

  listenForJobChanges(jobTraceId, callback) {
    const tenantCode = getTenantCode()
    onValue(this.jobRef(jobTraceId, tenantCode), (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  listenForUploadChanges(uploadId, callback) {
    onValue(this.uploadsRef(uploadId), (snapshot) => {
      const data = snapshot.val();
      callback(data);
    });
  }

  listenForNotificationGroups(callback) {
    const tenantCode = getTenantCode()
    onValue(this.notificationGroupsRef(tenantCode), (snapshot) => {
      let data = snapshot.val()
      if (!data) {
        data = 'noData'
      }
      callback(data)
    })
  }

  async promoteApk(appKey, channelTo) {
    const options = {
      method: 'POST',
      body: JSON.stringify({
        tenantGid: getTenantGid(),
        tenantCode: this.uid,
        channelTo: channelTo,
        appKey: appKey
      }),
      headers: {'Authorization' : `Bearer ${getBeamToken()}`, 'Content-Type':'application/json'}
    };
    const res = await fetch(`https://us-central1-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/promoteTenantApk`,options);
    if(res.ok) {
      console.log(`${appKey} successfully promoted `);
    } else {
      throw await res.text();
    }
  }
}



export default new DeviceServices();