import get from 'lodash/get'

export const formatLabelCategoryResponse = (data) => {
  const labelCategories = get(data, 'data.labelCategories.edges', [])
 .map((edge) => {
      if (edge?.node && edge?.node?.labels && edge?.node?.labels?.edges) {
        const labels = edge.node.labels.edges
       .map((labelEdge) => ({
         ...labelEdge.node,
            category: {
           ...labelEdge.node.category,
              id: labelEdge.node.categoryGid,
              lid: labelEdge.node.categoryGid,
            },
          }));
        return {
       ...edge.node,
          labels,
        };
      }
      return edge;
    });

  return labelCategories;
};