<template>
  <q-card class="card q-mt-md q-mb-xl" dark>
    <div class="q-pa-md">
      <q-list dense>
        <q-item>
          <q-item-section>
            <q-select
              label="Choose command to send to device"
              v-model="commandSelect"
              class="q-mt-sm"
              :options="commandOptions"
              dark
              outlined
              clearable
              color="white"
              :disable="commandString.length > 0 || isDisabled || isOffline"
              :popup-content-style="{ height:'36vh', backgroundColor:'rgb(40,40,40)' }"
              emit-value
              map-options
            >
              <template v-slot:option="{ itemProps, opt }">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label class="text-bold text-body1" v-html="opt.name" />
                    <q-item-label class="text-caption" v-html="opt.description" />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </q-item-section>
        </q-item>
        <q-item class="text-center text-h4 q-ma-md">
          <q-item-section>
            <q-item-label dark>
              OR:
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item class="text-center q-mb-md">
          <q-item-section>
            <q-input
              label="Enter a custom command; i.e /to/something"
              v-model="commandString"
              class="q-mt-sm"
              :options="commandOptions"
              dark
              outlined
              color="white"
              :disable="commandSelect !== null || isDisabled || isOffline"
            >
            </q-input>
          </q-item-section>
        </q-item>
        <q-item class="justify-center">
          <app-btn
            :isDisabled="!commandString && !commandSelect || isDisabled || isOffline"
            label="Send Command"
            @click="sendCommand(commandString, commandSelect)"
          >
          </app-btn>
        </q-item>
      </q-list>
    </div>
  </q-card>

  <q-form class="q-gutter-md">
    <!-- logging levels -->
    <q-card class="card" bordered>
      <div class="q-pa-md row justify-content">
        <q-list class="col" dense>
          <q-item>
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Local Logging Level
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-select
                ref="logLevelRef"
                class="col q-mt-sm"
                v-model="config.logLevel"
                :options="logLevelOptions"
                filled
                dark
                color="green-13"
                :disable="isSavingDeviceConfig"
                emit-value
                map-options
              >
              <template v-slot:option="{ itemProps, opt }">
                <q-item v-bind="itemProps">
                  <q-item-section>
                    <q-item-label class="text-bold text-body1" v-html="opt.label" />
                    <q-item-label class="text-caption" v-html="opt.description" />
                  </q-item-section>
                </q-item>
              </template>
              </q-select>
            </q-item-section>
          </q-item>
        </q-list>
        <q-list class="col" dense>
          <q-item>
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Remote Logging Level
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-select
                ref="logLevelRef"
                class="col q-mt-sm"
                v-model="config.remoteLogLevel"
                :options="logLevelOptions"
                filled
                dark
                color="green-13"
                :disable="isSavingDeviceConfig"
                emit-value
                map-options
              >
                <template v-slot:option="{ itemProps, opt }">
                  <q-item v-bind="itemProps">
                    <q-item-section>
                      <q-item-label class="text-bold text-body1"> {{ opt.label }} </q-item-label>
                      <q-item-label class="text-caption"> {{ opt.description }} </q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </q-item-section>
          </q-item>
        </q-list>
      </div>

      <div class="q-pa-md row justify-content">
        <q-list>
          <q-item tag="label">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Allow Auto Recreate
              </q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-toggle
                v-model="config.allowRecreate"
                checked-icon="check"
                color="green-13"
                size="lg"
                :disable="isSavingDeviceConfig"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-card>
  </q-form>
</template>

<script>
import { ref, toRef, watch, computed } from "vue";
import { logLevelOptions } from "../constants/constants";
import useCommands from '../composables/useCommands';
import AppBtn from '@/components/AppBtn';
import useDeviceConfig from '../composables/useDeviceConfig';

export default {
  components: {
    AppBtn
  },
  props: {
    deviceConfig: Object,
    state: Object,
    deviceCommandsList: Object,
    device: Object
  },
  emits: ["device-config-updated"],
  setup(props, { emit }) {
    const config = ref(props.deviceConfig);
    const deviceState = toRef(props, "state");
    const logLevelRef = ref(null);
    const commandOptions = ref(props.deviceCommandsList);
    const commandSelect = ref(null);
    const commandString = ref("");
    const isOffline = computed(() => {
      return deviceState.value.isOffline
    })

    watch(
      config,
      (cur) => {
        emit("device-config-updated", cur);
      },
      { deep: true }
    );

    // send command to device
    async function sendCommand(cmdString, cmdSelect) {
      try {
        if (cmdSelect) {
          cmdString = cmdSelect
        }
        const deviceId = props.device.deviceId;
        const deviceQuery = {
          deviceIds: [deviceId],
        };
        sendDeviceCommand(deviceQuery, cmdString)
        commandSelect.value = null;
        commandString.value = "";
      } catch (e) {
        console.warn(e);
      }
    }

    const {
      isDisabled,
      sendDeviceCommand
    } = useCommands()

    const {
      isSavingDeviceConfig
    } = useDeviceConfig()

    return {
      logLevelRef,
      config,
      deviceState,
      logLevelOptions,
      commandSelect,
      commandString,
      commandOptions,
      isDisabled,
      isOffline,
      isSavingDeviceConfig,
      sendCommand,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
  color: white;
  background-color: rgb(30, 30, 30);
  margin-top: 1rem;
}
.item-label {
  font-weight: bolder;
  font-size: 1.2rem;
  color: white;
  min-width: 9rem;
}
</style>
