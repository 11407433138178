<template>
  <div>
    <!--Commands-->
    <q-card dark class="card q-mt-md">
      <div class="q-pa-md row justify-content">
        <q-list class="col" dense>
          <!-- System Start Time -->
          <q-item class="q-mb-sm">
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                System Start Time
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 q-pl-md">
              <q-item-section class="stateValue text-left">
                <q-item-label>
                  {{ deviceState.systemStartTimeLocal }}
                </q-item-label>
              </q-item-section>
            </q-item-section>
            <q-item-section class="col-1"></q-item-section>
            <q-item-section class="col-3 q-pr-sm">
              <div class="text-right">
                <confirm-dialog
                  message="Are you sure you want to reboot the system?"
                  label="reboot"
                  dialogType="command"
                  value="reboot"
                  @confirm-selection="sendCommand"
                  :isDisabled="(isOffline || isDisabled)"
                  title="Reboot System"
                ></confirm-dialog>
              </div>
            </q-item-section>
          </q-item>
          <q-item class="q-mb-md">
            <q-item-section class="col-4">
              <q-item-label class="item-label">
                Device Password
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 q-pl-md">
              <q-input
                class="text-h6"
                v-model="deviceState.deviceP"
                readonly
                dark
                borderless
                :type="isPwd ? 'password' : 'text'"
              >
                <template v-slot:append>
                  <q-icon
                    :name="!isPwd ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer q-mr-xl"
                    @click="isPwd = !isPwd"
                  />
                </template>
              </q-input>
            </q-item-section>
            <q-item-section class="col-1 "></q-item-section>
            <q-item-section class="col-3 q-pr-sm q-mt-sm">
              <div class="text-right">
                <confirm-dialog
                  message="Are you sure you want to reset the device password?"
                  label="reset"
                  title="Reset Password"
                  dialogType="command"
                  value="resetPassword"
                  @confirm-selection="sendCommand"
                  :isDisabled="isOffline || isDisabled"
                >
                </confirm-dialog>
              </div>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-card>

    <!--Configuration-->
    <q-card dark class="card q-mt-md">
      <div class="q-pa-md row justify-content">
        <q-list class="col" dense>
          <q-item class="q-mb-sm">
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Settings
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4">
              <q-item-label
                class="text-weight-bold on-right text-h6"
              >
                Reported
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Configure
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-separator dark class="q-mb-lg" />
          
          <!-- volume -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="item-label q-pl-none" header>
                Volume
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4">
              <q-item>
                <q-item-section class="stateValue text-left">
                  {{ deviceState.volume }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-slider
                dark
                v-model="config.volume"
                :min="0"
                :max="100"
                label
                color="green-13"
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>

          <!-- Brightness -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="item-label q-pl-none" header>
                Brightness
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4">
              <q-item>
                <q-item-section class="stateValue text-left">
                  {{ deviceState.brightness }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-slider
                dark
                v-model="config.brightness"
                :min="0"
                :max="100"
                label
                color="green-13"
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>

          <!-- Timezone -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="item-label">
                Timezone
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.timezone }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-select
                @filter="filterTimezones"
                use-input
                fill-input
                hide-selected
                dark
                outlined
                v-model="config.timezone"
                :options="filteredList"
                label="Select timezone"
                color="white"
                option-label="label"
                option-value="tzCode"
                emit-value
                map-option
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>

          <!-- Network Time Server -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="item-label">
                Network Time Server
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.ntpServer }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-input
                v-model="config.ntpServer"
                class="col q-mt-sm"
                dark
                outlined
                label="Enter a Network Time Server URL"
                color="white"
                :disable="isSavingDeviceConfig || isDisabled"
                :rules="[rules.maxNetworkUrlCharsRule]"
                clearable
                no-error-icon
                hide-bottom-space
                @clear="config.ntpServer = ''"
              >
              </q-input>
            </q-item-section>
          </q-item>

          <!-- Home app -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="item-label">
                Home/Launcher Application
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.homeApp }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-input
                v-model="config.homeApp"
                class="col q-mt-sm"
                dark
                outlined
                label="Enter home application package name"
                color="white"
                :disable="isSavingDeviceConfig || isDisabled"
                :rules="[rules.applicationRule]"
                clearable
                no-error-icon
                hide-bottom-space
                @clear="config.homeApp = ''"
              >
              </q-input>
            </q-item-section>
          </q-item>

          <!-- App Receiver Key -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="item-label">
                App Receiver Key
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-input
                v-model="config.appReceiverKey"
                class="col q-mt-sm"
                dark
                outlined
                label="Enter app receiver key"
                color="white"
                :disable="isSavingDeviceConfig || isDisabled"
                :rules="[rules.appReceiverKeyRule]"
                clearable
                no-error-icon
                hide-bottom-space
                @clear="config.appReceiverKey = ''"
              >
              </q-input>
            </q-item-section>
          </q-item>

          <!-- Kiosk app -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="item-label">
                Kiosk Application
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.foregroundApp }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-input
                v-model="config.kioskApp"
                class="col q-mt-sm"
                dark
                outlined
                label="Enter kiosk application package name"
                color="white"
                :disable="isSavingDeviceConfig || isDisabled"
                :rules="[rules.applicationRule]"
                clearable
                no-error-icon
                hide-bottom-space
                @clear="config.kioskApp = ''"
              >
              </q-input>
            </q-item-section>
          </q-item>

          <!--  Kiosk mode -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="item-label">Kiosk Lock Mode</q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">{{ deviceState.kioskLockMode }}</q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section avatar class="col-4">
              <q-toggle
                size="lg"
                v-model="config.kiosk"
                checked-icon="check"
                color="green-13"
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>

          <!-- Navbar -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Hide Navigation Bar
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">{{ deviceState.hideNavBar }}</q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section avatar class="col-4">
              <q-toggle
                size="lg"
                v-model="config.hideNavBar"
                checked-icon="check"
                color="green-13"
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>

          <!-- Display Width -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Display Width
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">{{ deviceState.displayWidth }}</q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-input
                v-model.number="config.displayWidth"
                type="number"
                class="q-mt-sm"
                dark
                outlined
                label="Enter display width (200 to 4096)"
                color="white"
                :rules="[rules.displayDimensionsRule]"
                :disable="isSavingDeviceConfig || isDisabled"
                no-error-icon
                hide-bottom-space
                :lazy-rules="true"
                clearable
                @clear="config.displayWidth = ''"
              >
              </q-input>
            </q-item-section>
          </q-item>

          <!-- Display Height -->
          <q-item class="q-mb-sm">
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Display Height
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">{{ deviceState.displayHeight }}</q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-input
                v-model.number="config.displayHeight"
                type="number"
                class="q-mt-sm"
                dark
                outlined
                label="Enter display height (200 to 4096)"
                color="white"
                :rules="[rules.displayDimensionsRule]"
                :disable="isSavingDeviceConfig || isDisabled"
                no-error-icon
                hide-bottom-space
                :lazy-rules="true"
                clearable
                @clear="config.displayHeight = ''"
              >
              </q-input>
            </q-item-section>
          </q-item>

          <q-item class="q-mb-lg">
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Rotation
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.rotation }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-select
                dark
                outlined
                v-model="config.rotation"
                :options="rotationOptions"
                label="Select rotation"
                color="white"
                :disable="isSavingDeviceConfig || isDisabled"
                map-options
                emit-value
              />
            </q-item-section>
          </q-item>

          <q-item class="q-mb-md">
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Ignore Accelerometer
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
            </q-item-section>
            <q-item-section avatar class="col-4">
              <q-toggle
                size="lg"
                v-model="config.ignoreAccelerometer"
                checked-icon="check"
                color="green-13"
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>

          <q-item class="q-mb-md">
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Unified View
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.unifiedDesktop }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section avatar class="col-4">
              <q-toggle
                size="lg"
                v-model="config.unifiedDesktop"
                checked-icon="check"
                color="green-13"
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>
          
          <q-item class="q-mb-md">
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Disallow USB Usage
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.disallowUsb }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section avatar class="col-4">
              <q-toggle
                size="lg"
                v-model="config.disallowUsb"
                checked-icon="check"
                color="green-13"
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>

          <q-item class="q-mb-lg">
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Device Density
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ `${deviceState.density} dpi` }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-select
                dark
                outlined
                v-model="config.density"
                :options="densityOptions"
                label="Select pixel density"
                color="white"
                :disable="isSavingDeviceConfig || isDisabled"
                map-options
                emit-value
              />
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Device Frame Buffer
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.frameBufferMain }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-4">
              <q-input
                v-model="config.frameBuffer"
                dark
                outlined
                label="Enter device frame buffer"
                color="white"
                :disable="isSavingDeviceConfig || isDisabled"
                :rules="[rules.frameBufferRule]"
                clearable
                no-error-icon
                hide-bottom-space
                @clear="config.frameBuffer = ''"
              >
              </q-input>
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6"
                >Adb Enabled</q-item-label
              >
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.adbEnabled }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section avatar class="col-4">
              <q-toggle
                size="lg"
                v-model="config.adbEnabled"
                checked-icon="check"
                color="green-13"
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>
          
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Scheduled Reboot
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
              </q-item>
            </q-item-section>
            <q-item-section avatar class="col-4">
              <q-toggle
                size="lg"
                v-model="config.scheduledReboot"
                checked-icon="check"
                color="green-13"
                :disable="isSavingDeviceConfig || isDisabled"
              />
            </q-item-section>
          </q-item>

          <!-- Reboot Day -->
          <q-item v-if="config.scheduledReboot" class="q-mb-md">
            <q-item-section class="col-4">
              <q-item-label class="item-label">
                Reboot Day
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-5 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.rebootDuration ? `reboot in: ${deviceState.rebootDuration}` : '' }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-3">
              <q-select
                v-model="config.restartDay"
                :options="rebootOptions"
                class="col q-mt-sm reboot-day"
                dark
                outlined
                :label="'Set a reboot day'"
                color="white"
                emit-value
                map-options
                :disable="isSavingDeviceConfig || isDisabled"
              >
                <template v-slot:option="scope">
                  <div v-bind="scope.itemProps" class="reboot-options q-pa-md cursor-pointer">
                    <span>
                      {{ scope.opt.label }}
                    </span>
                  </div>
                  <q-separator v-if="scope.opt.value === 0" dark></q-separator>
                </template>
              </q-select>
            </q-item-section>
          </q-item>

          <!-- Reboot Time -->
          <q-item v-if="config.scheduledReboot">
            <q-item-section class="col-4">
              <q-item-label class="item-label">
                Reboot Time
              </q-item-label>
            </q-item-section>
            <q-item-section class="col-5 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.rebootDuration ? `reboot in: ${deviceState.rebootDuration}` : '' }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section class="col-3">
              <q-input
                readonly
                label="Set a reboot time"
                dark outlined color="white"
                :model-value="formatTwentyFourHourString(config.restartTime)"
                :disable="isSavingDeviceConfig || isDisabled"
                class="cursor-pointer"
              >
                <q-popup-proxy style="background:black;" cover transition-show="scale" transition-hide="scale">
                  <q-time
                    dark
                    color="green-13"
                    v-model="defaultRebootTime"
                    class="full-width"
                    :minute-options="[0,5,10,15,20,25,30,35,40,45,50,55]"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" flat />
                    </div>
                  </q-time>
                </q-popup-proxy>
                <template v-slot:append>
                  <q-icon name="access_time" class="cursor-pointer"></q-icon>
                </template>
              </q-input>
            </q-item-section>
          </q-item>

          <!-- Auto Launch Home -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6">
                Auto Launch Home
                </q-item-label>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
              <q-item>
                <q-item-section class="stateValue">
                  {{ deviceState.autolaunchHome }}
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item-section avatar class="col-4">
              <div>
                <q-toggle
                  size="lg"
                  v-model="config.autoLaunchHome"
                  checked-icon="check"
                  color="green-13"
                  :disable="isSavingDeviceConfig || isDisabled"
                />
                <q-tooltip
                  class="text-subtitle2"
                  :delay="400"
                  anchor="top right"
                  self="bottom right"
                  max-width="300px"
                >
                  <span>
                    When enabled, <b>AutoLaunchHome</b> will launch Skykit Home at
                    system boot. This may be needed where normal app launchers are
                    not supported like Android TV devices.
                  </span>
                </q-tooltip>
              </div>
            </q-item-section>
          </q-item>

          <!-- Offline Threshold -->
          <q-item>
            <q-item-section class="col-4">
              <q-item-label class="text-weight-bold text-h6 q-mb-xs">
                Offline Status Threshold
              </q-item-label>
              <div class="text-caption">Skykit Control will use this value to determine if the device is offline</div>
            </q-item-section>
            <q-item-section class="col-4 ellipsis">
            </q-item-section>
            <q-item-section class="col-4">
              <q-select
                dark
                outlined
                v-model="config.heartbeatIntervalMinutes"
                :options="heartbeatOptions"
                label="Select device heartbeat interval"
                color="white"
                :disable="isSavingDeviceConfig || isDisabled"
                map-options
                emit-value
              />
            </q-item-section>
          </q-item>

        </q-list>
      </div>
    </q-card>

    <!-- State -->
    <q-card dark class="card q-my-md">
      <div class="q-pa-md row justify-content">
        <q-list class="col" dense>
          <q-item>
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Property
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section
                  class="text-weight-bold text-h6 text-right"
                >
                  Reported
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <q-separator dark class="q-mb-sm" />

          <!-- Serial Number -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Serial Number
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.serialNumber }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <!-- Device Update Time -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Last Heartbeat
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.deviceUpdateTimeLocal }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <!-- Memory -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Memory
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.memory }}%
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <!-- Storage -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Storage
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.storage }}%
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <!-- Device Owner -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Device Owner
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.deviceOwner }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <!-- Manufacturer -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Manufacturer
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.manufacturer }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <!-- Model -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Model
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.model }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <!-- OS Version -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                OS Version
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.osVersion }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <!-- build type -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Build Type
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.buildType }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>

          <!-- Web View Version Code -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Web View Version Code
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.webViewVersionCode }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>
          <!-- Web View Version Name -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Web View Version Name
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.webViewVersionName }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>
          <!-- Web View Version Package -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Web View Version Package
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.webViewVersionPackage }}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>
          <!-- HDMI Connection -->
          <q-item class="q-mb-sm">
            <q-item-section>
              <q-item-label class="text-weight-bold text-h6">
                Has HDMI Connection
              </q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item>
                <q-item-section class="stateValue text-right">
                  {{ deviceState.isHdmiConnected || 'N/A'}}
                </q-item-section>
              </q-item>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-card>

    <ConnectedDevices
      :usbDevices="state.usbDevices || {}"
    ></ConnectedDevices>

    <div class="q-mb-lg">
      <alerts-detail :device="props.device"></alerts-detail>
    </div>
    <app-btn
      label="delete device"
      color="red"
      textColor="white"
      :isDisabled="isDisabled"
      @click="deleteDeviceDialog = !deleteDeviceDialog"
      v-if="hasDeleteDeviceFF"
    >
    </app-btn>
     <q-dialog v-model="deleteDeviceDialog" persistent @hide="deleteDeviceDialog = false">
      <q-card class="q-pa-sm" dark>
        <q-card-section>
          <div class="text-h6">Delete this device?</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          You are about to delete this device from this tenant. Do you wish to continue?
        </q-card-section>

        <q-card-actions align="right">
          <q-btn class="q-mr-xl" flat label="Cancel" color="white" v-close-popup />
          <app-btn
            text-color="white"
            label="yes, delete device"
            color="red"
            @click="deleteDevice"
          >
          </app-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref, toRef, watch, computed } from "vue";
import timezones from "timezones-list";
import { 
  rotationOptions,
  rebootOptions,
  densityOptions,
  heartbeatOptions
} from "../constants/constants";
import useCommands from '../composables/useCommands';
import useDeviceConfig from '../composables/useDeviceConfig';
import { formatTwentyFourHourString } from '../utils/dateUtils';
import { rules } from '@/utils/rulesUtils';

import ConfirmDialog from "./ConfirmDialog.vue";
import AlertsDetail from './AlertsDetail.vue';
import AppBtn from '@/components/AppBtn.vue';
import ConnectedDevices from '@/components/ConnectedDevices';

import deviceJobs from '../service/deviceJobs';
import deviceServices from '../service/deviceServices'
import { useQuasar } from 'quasar';
import { useRouter } from 'vue-router';
import useFeatureFlags from '@/composables/useFeatureFlags';

export default {
  components: {
    ConfirmDialog,
    AlertsDetail,
    AppBtn,
    ConnectedDevices,
  },
  props: {
    state: Object,
    deviceConfig: Object,
    device: Object
  },
  emits: ["device-config-updated"],
  setup(props, { emit }) {
    const $q = useQuasar();
    const router = useRouter()
    const deviceState = toRef(props, "state");
    const config = ref(props.deviceConfig);
    const isPwd = ref(true);
    const timezonesList = ref(timezones);
    const filteredList = ref([]);
    const isOffline = computed(() => {
      return deviceState.value.isOffline
    })
    const defaultRebootTime = ref(config.value.restartTime || '24:00')
    const deleteDeviceDialog = ref(false)
    const {
      hasDeleteDeviceFF
    } = useFeatureFlags()
    
    const {
      isDisabled,
      sendDeviceCommand
    } = useCommands()

    const {
      isSavingDeviceConfig,
    } = useDeviceConfig()

    watch(defaultRebootTime, (cur) => {
      config.value.restartTime = cur
    })

    watch(config.value, (cur) => {
      if (cur) {
        emit("device-config-updated", cur);
      }
    }, { deep: true });
    
    async function sendCommand(val) {
      let command = "";
      switch (val) {
        case "reboot":
          command = "reboot";
          break;
        case "resetPassword":
          command = "resetpw";
          break;
      }
      const deviceId = props.device.deviceId;
      const deviceQuery = {
        deviceIds: [deviceId],
      };
      sendDeviceCommand(deviceQuery, command)
    }

    function filterTimezones (val, update) {
      update(() => {
        const filterString = val.toLowerCase()
        filteredList.value = timezonesList.value.filter(v => v.label.toLowerCase().indexOf(filterString) > -1)
      })
    }

    function cancelClearScheduledReboot() {
      config.value.scheduledReboot = true
    }

    async function deleteDevice() {
      deleteDeviceDialog.value = false
      isDisabled.value = true
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Deleting this device from this tenant...',
        spinner: true
      })
      try {
        const deviceQuery = {
          deviceKeys: [ props.device.deviceKey ]
        }
        const resp = await deviceJobs.deleteDevice({ deviceQuery })
        if (resp.jobTraceId) {
          deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
            if (data){
              if (data.status === 'incomplete') {
                return
              }
              if (data.status === 'complete' && !data.error) {
                isDisabled.value = false
                notify({
                  type: 'positive',
                  message: 'Sucessfuly deleted this device from this tenant',
                  spinner: false
                })
                router.push('/devices')
              }
              if (data.status === 'complete' && data.error) {
                isDisabled.value = false
                notify({
                  type: 'negative',
                  message: 'Failed to delete this device',
                  spinner: false
                })
                return
              }
            }
          })
        } else {
          notify({
            type: 'negative',
            message: 'Failed to delete this device from this tenant',
            spinner: false
          })
          isDisabled.value = false
        }
      } catch(err) {
        console.error(err)
        notify({
          type: 'negative',
          message: 'Failed to delete this device from this tenant',
          spinner: false
        })
        isDisabled.value = false
      }
    }

    return {
      rotationOptions,
      deviceState,
      config,
      timezonesList,
      isPwd,
      filteredList,
      isOffline,
      isDisabled,
      sendCommand,
      filterTimezones,
      rebootOptions,
      defaultRebootTime,
      cancelClearScheduledReboot,
      isSavingDeviceConfig,
      rules,
      densityOptions,
      heartbeatOptions,
      formatTwentyFourHourString,
      props,
      deleteDeviceDialog,
      deleteDevice,
      hasDeleteDeviceFF
    };
  },
};
</script>

<style lang="scss">
.card {
  text-align: left;
  color: white;
  background-color: rgb(30, 30, 30);
  margin-top: 1rem;
}
.bg-grey-layer-1 {
  background-color: rgb(35,35,35);
}
.bg-grey-layer-2 {
  background-color: rgb(45,45,45);
}
.bg-grey-layer-3 {
  background-color: rgb(55,55,55);
}
.item-label {
  font-weight: bolder;
  font-size: 1.2rem;
  color: white;
  min-width: 9rem;
}
.item-label.q-item__label--header {
  font-weight: bolder;
  font-size: 1.2rem;
  color: white;
}
.stateValue {
  font-size: 1.2em;
}
.command-button {
  height: 10px !important;
}
.reboot-options:hover {
  background-color: rgb(70,70,70);
}
.reboot-time > span > span {
  font-size: 16px !important;
  font-weight: 400 !important;
}
div.q-time__header  {
  background-color: rgb(45, 45, 45) !important;
}
.notify-group-list:nth-child(even) {
  background-color: rgb(55,55,55);
}
.q-field--readonly.q-field--float .q-field__native {
  cursor: pointer !important;
}
</style>
