<template>

    <!-- delete confirm -->
    <q-btn flat v-if="dialogType === 'delete'" @click="layout = true" round>
      <q-icon name="delete_outline" size="sm">
        <q-tooltip
          class="text-subtitle2"
          :delay="1000"
          anchor="top middle"
          self="bottom middle"
        >
          <span>{{ tooltip }}</span>
        </q-tooltip>
      </q-icon>
    </q-btn>

    <!-- send command confrim -->
    <app-btn
      v-if="dialogType === 'command'"
      :isDisabled="isDisabled"
      :label="label"
      @click="layout = true"
    >
    </app-btn>

    <!-- reset managed app confrim -->
    <q-btn
      size="md" 
      no-wrap
      unelevated 
      icon="restart_alt" 
      @click="layout = true"
      :disabled="isDisabled"
      v-if="dialogType === 'resetApp'"
      :label="label"
    >
    </q-btn>

    <!-- reinstall managed app confrim -->
    <q-btn
      size="md" 
      no-wrap
      unelevated 
      icon="reset_tv"
      @click="layout = true"
      :disabled="isDisabled"
      v-if="dialogType === 'reinstallApp'"
      :label="label"
    >
    </q-btn>

    <!-- uninstall app confirm -->
    <q-btn
      size="md" 
      no-wrap
      unelevated 
      icon="remove_circle_outline" 
      @click="layout = true"
      :disabled="isDisabled"
      v-if="dialogType === 'uninstallApp'"
      :label="label"
    >
    </q-btn>

    <!-- launch app confirm -->
    <q-btn
      size="md" 
      no-wrap
      unelevated 
      icon="launch" 
      @click="layout = true"
      :disabled="isDisabled"
      v-if="dialogType === 'launchApp'"
      :label="label"
    >
    </q-btn>

    <!-- update all apps confirm -->
    <app-btn
      :isSecondary="true"
      icon="update" 
      @click="layout = true"
      v-if="dialogType === 'updateAllApps'"
      :label="label"
      :isDisabled="isDisabled"
    >
    </app-btn>

    <q-dialog v-model="layout">
      <q-card dark id="dialogCard" class="q-pa-sm">
        <q-card-section class="q-pb-xs">
          <span class="text-h6">{{ title }}</span>
        </q-card-section>

        <q-card-section class="row items-center q-mb-lg">
          <span v-html="message" class="text-body1"></span>
        </q-card-section>

        <q-separator dark></q-separator>

        <q-card-actions class="q-pa-md" align="right">
          <q-btn flat label="Cancel" color="white" v-close-popup />
          <app-btn
            v-if="dialogType === 'delete'"
            color="red"
            text-color="white"
            :label="buttonText || 'yes'"
            @click="confirmSelection"
          >

          </app-btn>
          <app-btn
            v-else
            :label="buttonText || 'yes'"
            @click="confirmSelection"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
</template>

<script setup>
import { ref } from "vue";
import AppBtn from "@/components/AppBtn";

// eslint-disable-next-line
const props = defineProps({
  buttonText: {
    type: String,
    required: false,
  },
  dialogType: {
    type: String,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  label: {
    type: String,
    required: false,
  },
  message: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  tooltip: {
    type: String,
    required: false,
  },
  value: {
    type: String,
    required: true,
  },
});

// eslint-disable-next-line
const emit = defineEmits(
  ['confirm-selection']
)

const layout = ref(false)

function confirmSelection() {
  emit("confirm-selection", props.value)
  layout.value = false
}

</script>

<style lang="scss" scoped>
#dialogCard {
  min-width: 30rem;
}
</style>
