const state = ({
  cachedDeviceConfig: {},
});

const getters = {
  getCachedDeviceConfig: () => state.cachedDeviceConfig,
};

const mutations = {
  setCachedDeviceConfig: (val) => (state.cachedDeviceConfig = val),
};

const actions = {
  setCachedDeviceConfig: (val) => {
    mutations.setCachedDeviceConfig(val);
  },
};

export default { state, getters, mutations, actions };
