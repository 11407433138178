<script setup>
import { ref, reactive, computed, watch } from 'vue';
import DeviceJobs from '../service/deviceJobs';
import { useQuasar } from 'quasar';
import DeviceServices from '../service/deviceServices';
import { formatDateTimeString } from '../utils/dateUtils';
import _ from 'lodash';
import AppBtn from '@/components/AppBtn';

// eslint-disable-next-line
const props = defineProps({
  device: {
    type: Object,
  },
  state: {
    type: Object,
  },
});

// eslint-disable-next-line
const emit = defineEmits({
  showScreenshot: {
    default: ''
  }
})

const $q = useQuasar();
let screenshots = reactive([]);
const errorState = ref({});

// Lightbox data and functions
async function showImg(scr) {
  emit('showScreenshot', scr)
}

let isDisabled = ref(false)
const isOffline = computed(() => {
  const val = props.state.isOffline
  return val
})

watch(() => _.cloneDeep(screenshots), (cur, prev) => {
  if (prev[0]?.uploaded === false && cur[0]?.uploaded === true ) {
    $q.notify({
      type: 'positive',
      message: 'Successfully took screenshot'
    })
  }
});

// Populates listview and handles changes to screenshots data
props.device.listenForFiles((rows) => {
  if (rows) {
    rows.forEach(async scr => {
      scr.name = `screen-${scr.fileId}.png`

      // If uploaded state isn't true within 60 seconds of request it failed and should show error state
      const diff = Math.abs(Date.parse(new Date()) - Date.parse(scr.datetime))
      if (scr.uploaded) {
        return
      } else if (diff > (60 * 1000) && !scr.uploaded) {
        errorState.value[scr.fileId] = true;
      } else {
        setTimeout(async () => {
          const scrNode = await props.device.fetchScreenshot(scr.fileId)
          if (!scrNode.uploaded) {
            errorState.value[scrNode.fileId] = true;
            isDisabled.value = false
          } else {
            delete errorState.value[scr.fileId]
            isDisabled.value = false
          }
        }, (60 * 1000))
      }
    })
    Object.assign(screenshots, rows)
        
    // Enable screenshots button once the last screenshot is uploaded or it is an an error state
    const lastScr = screenshots[0]
    if (lastScr.uploaded || errorState.value[lastScr.fileId]) {
      isDisabled.value = false
    }
  }
})

function onClickTakeScreenshot() {
  isDisabled.value = true;
  const command = 'screenshot'
  sendCommand(command)
}

async function sendCommand(command) {
  const notify = $q.notify({
    type: 'ongoing',
    message: 'Taking Screenshot',
    spinner: true
  })
  try {
    const deviceId = props.device.deviceId;
    const deviceQuery = {
      deviceIds: [deviceId],
    };
    const resp = await DeviceJobs.sendDeviceCommand(deviceQuery, command);
    if (resp) {
      DeviceServices.listenForJobChanges(resp.jobTraceId, (data) => {
        if (data) {
          if (data.status === 'complete' && !data.error) {
            notify()
          }
          if (data.status === 'complete' && data.error) {
            notify({
              type: 'negative',
              message: 'Unable to capture screenshot',
              caption: `${data.error}`,
              spinner: false
            })
            isDisabled.value = false;
          }
        }
      })
      setTimeout(() => {
        if (screenshots[0] && !screenshots[0].uploaded) {
          $q.notify({
            type: 'negative',
            message: 'Unable to capture screenshot',
            caption: screenshots[0].errors
          })
        }
      }, 60 * 1000)
    }
    
  } catch(e) {
    console.warn(e)
    notify({
      type: 'negative',
      message: 'Unable to capture screenshot',
      caption: `${e}`,
      spinner: false
    })
    isDisabled.value = false;
  }
}

async function downloadScreenshot(scr) {
  const downloadUrl = await DeviceServices.fetchFileDownloadUrl(scr?.reference)
  if (downloadUrl.name === 'FirebaseError') {
    console.warn(downloadUrl.message)
    $q.notify({
      type: 'negative',
      message: 'Error: Unable to download screenshot'
    })
  } else {
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = scr.name
    document.body.appendChild(link)
    link.click()
  }
}
</script>

<template>
  <div>
    <div class="flex justify-end q-pb-lg">
      <app-btn
        :isSecondary="true"
        @click="onClickTakeScreenshot"
        :isDisabled="isDisabled || isOffline"
        label="Take Screenshot"
      >
      </app-btn>
    </div>

    <q-card dark class="q-px-lg q-pt-sm">
      <q-list>
        <q-item>
          <q-item-section class="col-5 q-pl-lg">
            <q-item-label class="flex justify-start text-bold">
              Screenshot
            </q-item-label>
          </q-item-section>
          <q-item-section class="col">
            <q-item-label class="flex justify-start text-bold">
              Date Requested
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-separator dark></q-separator>

        <div v-if="screenshots.length">
          <div v-for="scr in screenshots" :key="scr" class="screenshot-row">
            <q-item class="items-center q-py-md">

              <q-item-section class="col-5 q-pl-lg">
                <q-item-label v-if="!errorState[scr.fileId]" class="flex justify-start">
                  {{ scr.name }}
                </q-item-label>
                <q-item-label v-else class="flex justify-start">
                  Request failed. Please try again.
                </q-item-label>
              </q-item-section>

              <q-item-section class="col">
                <q-item-label class="flex justify-start">
                  {{ formatDateTimeString(scr.datetime) }}
                </q-item-label>
              </q-item-section>

              <q-space></q-space>

              <div v-if="!errorState[scr.fileId] && scr.uploaded">
                <button :disabled="!scr.uploaded" class="img-action-btn" @click="showImg(scr)"> VIEW </button>
                <span class="q-mr-md"> | </span>
                <button :disabled="!scr.uploaded" class="img-action-btn" @click="downloadScreenshot(scr)"> DOWNLOAD </button>
              </div>
              <div v-else-if="!errorState[scr.fileId] && !scr.uploaded" class="q-mr-lg">
                <div>
                  <span class="q-mr-xs"> Processing... </span>
                  <q-spinner
                    color="white"
                    size="1em"
                    :thickness="2"
                  />
                </div>
              </div>
              <div v-else class="q-mr-lg">
                <q-icon
                  name="warning"
                  color="red"
                  size="md"
                ></q-icon>
              </div>
            </q-item>
          </div>
        </div>
        <div v-else>
          <q-item-label class="q-pa-xl text-white">There are no screenshots for this device</q-item-label>
        </div>
      </q-list>
    </q-card>
  </div>
</template>

<style scoped>
.screenshot-row:nth-child(even) {
  background-color: rgb(35, 35, 35);
}
.img-action-btn {
  color: white;
  background:none;
  border:none;
  margin:0;
  padding:0;
  cursor: pointer;
  margin-right: 20px;
  text-decoration: none;
}
</style>
