<script setup>
import { ref, computed } from 'vue';
import { rules } from '@/utils/rulesUtils';
import { parityOptions, flowControlOptions } from '@/constants/constants'
import AppBtn from '@/components/AppBtn';

// eslint-disable-next-line no-undef
const emit = defineEmits(['on-create'])

// eslint-disable-next-line no-undef
const props = defineProps({
    model: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      validator: (value) => {
          // must be array of objects
          return ['create', 'edit'].includes(value)
      },
      default: 'create',
      required: true,
    },
})

const peripheralModel = ref({
  name: '',
  parity: null,
  vendorId: null,
  baudRate: null,
  flowControl: null,
  stopBit: null
});
const showDialog = ref(false);
const isDisabled = ref(false);
const isEdit = ref(!!props.model?.name)
const isValidForm = computed(() => {
  return !!(
    peripheralModel.value.name &&
    peripheralModel.value.parity !== null &&
    peripheralModel.value.vendorId &&
    peripheralModel.value.baudRate &&
    peripheralModel.value.flowControl !== null &&
    peripheralModel.value.stopBit
  )
});
const buttonLabel = computed(() => {
  return props.type === 'create' ? 'add peripheral' : 'edit peripheral';
})

function onClickEditPeripheral() {
  Object.assign(peripheralModel.value, props.model)
  showDialog.value = true;
}

function handleSubmitForm() {
  emit('on-create', peripheralModel.value, isEdit.value);
  showDialog.value = false;
  resetModel();
}

function resetModel() {
  peripheralModel.value = {
    name: '',
    parity: null,
    vendorId: null,
    baudRate: null,
    flowControl: null,
    stopBit: null
  };
}

const helpMessage = 'The Vendor ID must match the reported value of the attached device. See “Attached Devices” in the status page.';
</script>

<template>
  <app-btn
    v-if="props.type === 'create'"
    icon="add"
    @click="showDialog = true"
    label="peripheral"
    :isDisabled="isDisabled"
  >
  </app-btn>
  <q-btn
    v-else-if="props.type === 'edit'"
    no-hover
    round
    size="md" 
    no-wrap
    unelevated 
    icon="edit" 
    @click="onClickEditPeripheral"
  > 
    <q-tooltip
      class="text-subtitle2"
      :delay="1000"
      anchor="top middle"
      self="bottom middle"
    >
      <span>Edit {{ model?.name }}</span>
    </q-tooltip>
  </q-btn>

  <q-dialog
    persistent
    v-model="showDialog"
  >
    <q-card 
      dark 
      style="width:80%;max-width:600px;"
    >
      <q-card-section class="flex q-px-md">
        <span v-if="!isEdit" class="text-h6 on-right text-bold">
          Add a new peripheral
        </span>
        <span v-else class="text-h6 on-right text-bold">
          Edit {{peripheralModel.name}}
        </span>
        <q-space></q-space>
        <q-icon
          name="close"
          size="x-large"
          @click="showDialog = false"
          class="cursor-pointer"
        >
        </q-icon>
      </q-card-section>
      <q-separator dark></q-separator>


      <q-card-section
        tag="form"
        class="q-px-xl q-py-lg"
      >
        <q-form @submit="handleSubmitForm('submit')" id="peripheral-form">
          <!-- Name -->
          <q-item-label class="q-mb-sm">Enter a peripheral name</q-item-label>
          <q-input
            outlined
            dense
            dark
            v-model.trim="peripheralModel.name"
            color="green-13"
            label="Name"
            type="text"
            :rules="[rules.required, rules.maxLengthRule]"
            :readonly="isEdit"
            :disable="isEdit"
          >
          </q-input>

          <!-- Vendor Id -->
          <div class="flex justify-between">
            <q-item-label class="q-mb-sm">Enter Vendor Id</q-item-label>
            <q-icon style="font-size: 20px;" color="green-13" name="help">
              <q-tooltip
                class="text-subtitle2"
                :delay="200"
                anchor="top middle"
                self="bottom middle"
                max-width="300px"
              >
                <span>{{ helpMessage }}</span>
              </q-tooltip>
            </q-icon>
          </div>
          <q-input
            outlined
            dense
            dark
            v-model.trim="peripheralModel.vendorId"
            color="green-13"
            label="Vendor Id"
            type="text"
            :rules="[rules.required, rules.maxLengthRule]"
          >
          </q-input>

          <!-- Baud Rate -->
          <q-item-label class="q-mb-sm">Enter a Baud Rate</q-item-label>
          <q-input
            outlined
            dense
            dark
            v-model.number.trim="peripheralModel.baudRate"
            color="green-13"
            label="Baud Rate"
            type="number"
            :rules="[rules.required]"
          >
          </q-input>

          <!-- Stop Bit -->
          <q-item-label class="q-mb-sm">Enter Stop Bit</q-item-label>
          <q-select
            outlined
            dense
            dark
            v-model.number="peripheralModel.stopBit"
            color="green-13"
            label="Stop Bit"
            :options="[1, 2, 3]"
            class="col" 
            :rules="[rules.required]"
          >
            <template v-slot:option="{ itemProps, opt }">
              <q-item
                v-bind="itemProps"
                clickable
                class="select-background"
              >
                <span class="text-bold">
                  {{ opt }}
                </span>
              </q-item>
            </template>
          </q-select>        

          <!-- Flow Control -->
          <q-item-label class="q-mb-sm">Enter flow control</q-item-label>
          <q-select
            outlined
            dense
            dark
            v-model.number="peripheralModel.flowControl"
            color="green-13"
            label="Flow Control"
            :options="flowControlOptions"
            type="number"
            class="col"
            :rules="[rules.required]"
            emit-value
            map-options
          >
            <template v-slot:option="{ itemProps, opt }">
              <q-item
                v-bind="itemProps"
                clickable
                class="select-background"
              >
                <span class="text-bold">
                  {{ opt.label }}
                </span>
              </q-item>
            </template>
          </q-select>

          <!-- Parity -->
          <q-item-label class="q-mb-sm">Enter parity</q-item-label>
          <q-select
            outlined
            dense
            dark
            v-model.number="peripheralModel.parity"
            color="green-13"
            label="Parity"
            :options="parityOptions"
            type="number"
            :rules="[rules.required]"
            emit-value
            map-options
          >
          </q-select>
        </q-form>  
      </q-card-section>

      <q-separator dark></q-separator>

      <q-card-actions align="right" class="q-px-xl q-py-md actions">
        <q-btn form="peripheral-form" flat dark label="Cancel" v-close-popup />
        <q-btn
          form="peripheral-form"
          :label="buttonLabel"
          type="submit"
          rounded
          color="green-13"
          text-color="black"
          :disable="!isValidForm"
          class="confirm-btn"
        >
        </q-btn>
      </q-card-actions> 
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
.actions .confirm-btn {
  min-width: 160px !important;
  padding: 0 30px !important;
}
</style>
