<template>
  <q-page v-if="isPageLoading">
    <q-linear-progress indeterminate color="green-13"/>
  </q-page>
  <q-page v-else padding class="page">
    <div class="row">
      <div class="col text-left text-white">
        <h3 class="text-weight-bolder">Devices</h3>
      </div>
      <div class="col self-center text-right">
        <span>
          <preregister-dialog v-if="isPlatformAdmin" @refresh-device-list="findDevices"></preregister-dialog>
        </span>
        <span class="q-ml-md">
          <app-btn
            label="Pair Device"
            @click="pairDialog = true"
          >
          </app-btn>
        </span>
      </div>
    </div>

    <q-card dark class="q-pa-md q-mb-sm">
      <div class="row grid-gutter-md items-center">
        <div>
          <q-btn flat>
            <q-icon
              @click="showLabelsDrawer = !showLabelsDrawer"
              id="labelBtn"
              name="local_offer"
              :color="showLabelsDrawer ? 'green-13' : 'white'"
              size="md"
              class="q-mr-lg"
            >
            </q-icon>
            <q-tooltip
              class="text-subtitle2"
              :delay="500"
            >
              <span>Labels</span>
            </q-tooltip>
          </q-btn>
        </div>
        <div class="col-4 q-mr-md">
          <q-input
            dense
            dark
            color="white"
            bg-color="black"
            borderless
            label="Search Devices"
            v-model="persistedState.searchState"
            @keydown.enter.prevent="findDevices"
            :disable="loading"
            clearable
          >
            <template v-slot:prepend>
              <q-icon class="q-ml-sm" name="search" color="white"/>
            </template>
          </q-input>
        </div>
        <div class="self-center">
          <q-btn
            class="on-left shadow-7"
            dark
            text-color="white"
            label="Search"
            @click="findDevices"
            :disable="loading"
          />
        </div>
        <filter-label-dialog :loading="loading" @filter-by-labels="filterDisplaysByLabel"></filter-label-dialog>
        <div class="self-center q-mr-md">
          <q-select
            dense
            class="shadow-7"
            dark
            color="white"
            borderless
            :options="sortFields"
            option-label="name"
            option-value="value"
            v-model="sortField"
            :disable="loading"
          >
            <template v-slot:selected>
              <span :class="[loading ? 'text-grey-8' : 'text-white', 'text-subtitle2', 'bg-dark', 'q-pl-md']">
                {{ sortField.name }}
              </span>
            </template>
          </q-select>
        </div>
        <div class="self-center">
          <q-select
            dense
            class="shadow-7"
            dark
            color="white"
            borderless
            :options="sortTypes" 
            option-label="name"
            option-value="value"
            v-model="sortType" 
            @update:model-value="findDevices"
            :disable="loading"
          >
            <template v-slot:selected>
              <span :class="[loading ? 'text-grey-8' : 'text-white', 'text-subtitle2', 'bg-dark', 'q-pl-md']"> 
                {{ sortType.name }}
              </span>
            </template>
          </q-select>
        </div>
        <q-space></q-space>
        <div class="self-center">
          <bulk-actions-dialog
            :activeLabelFilters="activeLabelFilters"
            :activeSearchString="persistedState.searchState"
            :isAllInTenantSelected="isAllInTenantSelected"
            :queuedDisplays="queuedDisplays"
          >
          </bulk-actions-dialog>
        </div>
      </div>
      <div class="row full-width items-center"  v-if="activeLabelFilters.length">
        <active-label-filter-component 
          class="q-mt-sm" 
          @label-filter-removed="handleLabelFilterRemoved" 
          :active-label-filters="activeLabelFilters"
        >
        </active-label-filter-component>
      </div>
    </q-card>
    <q-linear-progress v-if="loading" indeterminate color="green-13"></q-linear-progress>
    <div class="row">
      <labels-drawer 
        v-if="showLabelsDrawer" 
        @close-labels-drawer="onCloseLabelsDrawer"
        @on-click-apply-labels="applyLabelsToDisplay"
        :showLabelCheckbox="queuedDisplays.length > 0"
        :queuedDisplays="queuedDisplays"
        :displays="displays"
        class="q-mr-sm"
      >
      </labels-drawer>
      <div class="col q-px-lg" style="background-color: rgb(30,30,30);">
        <div class="display-line-item-header">
          <q-item
            class="text-left q-pt-sm row items-center"
            dark
            v-if="isAllInTenantSelected"
          >
          <div
              class="q-ml-lg q-mr-lg"
              style="width:20px;"
            >
            </div>
            <div>
              <span class="q-mr-md">{{ getSelectAllString()}}</span>
              <span class="q-mr-xs">|</span>
              <span 
                @click="clearSelectedDisplays"
                class="text-green-13 q-pa-sm clear-selection"
              >Clear Selection</span>
            </div>
          </q-item>
          <q-item class="text-left q-pb-none q-pt-sm row" dark>
            <div
              class="q-ml-lg q-mr-lg"
              style="width:20px;"
            >
              <select-all-checkbox
                :displays="displays"
                :queuedDisplays="queuedDisplays"
                @select-all-clicked="handleSelectAll"
                :class="isAllInTenantSelected ? 'all-displays-checkbox' : ''"
              >
              </select-all-checkbox>
            </div>
            <div class="col items-center flex">
              <span class="text-subtitle1 text-bold ellipsis">Device Name</span>
            </div>
            <div class="col items-center flex">
              <span class="text-subtitle1 text-bold ellipsis">Serial Number</span>
            </div>
            <div class="col items-center flex">
              <span class="text-subtitle1 text-bold ellipsis">Model</span>
            </div>
            <div class="col items-center flex">
              <span class="text-subtitle1 text-bold ellipsis">Location</span>
            </div>
            <div class="col items-center flex">
              <span class="text-subtitle1 text-bold ellipsis">Status</span>
            </div>
          </q-item>
        </div>

        <q-separator dark></q-separator>
        
        <div id="scroll-target-id" style="max-height: 496px; overflow: auto;">
          <q-infinite-scroll @load="fetchMoreDevices" :offset="500" scroll-target="#scroll-target-id">
            <div
              dark
              v-bind:key="key"
              v-for="(display, key) in displays"
              class="display-line-item"
              @click="onSelectDevice(display)"
            >
              <q-item class="text-left row q-px-md q-pb-xs items-center" dark>
                <div class="q-ml-lg q-mr-lg" style="width:20px;">
                  <q-checkbox 
                    :val="display"
                    dark
                    color="green-13"
                    v-model="queuedDisplays"
                    class="q-mt-sm"
                    size="sm"
                  />
                </div>
                <span class="col q-mt-sm ellipsis">{{ display.name }}</span>
                <span class="col q-mt-sm ellipsis">{{ display.serialNumber }}</span>
                <span class="col q-mt-sm ellipsis">{{ display.model }}</span>
                <span class="col q-mt-sm ellipsis">{{ display.location || '' }}</span>
                <span class="col q-mt-sm ellipsis">
                  <q-icon
                    name="fiber_manual_record"
                    :color="display.isOffline ? 'red' : 'green'"
                    style="margin-top:-2px;"
                    class="q-mr-xs"
                  >
                  </q-icon>
                  <span class="ellipsis">{{ display.isOffline ? 'Offline' : 'Online' }}</span>
                  
                </span>
              </q-item>
              <div class="row q-px-md q-pb-sm text-left">
                <div class="q-ml-lg q-mr-lg full-height" style="width:20px;"></div>
                <div class="col">
                  <q-chip
                    v-for="label in getLabelsAssignedToDisplay(display)" :key="label"
                    dense
                    outline
                    square
                    color="green-13"
                    :label="getLabelName(label)"
                    class="q-ml-none q-mr-sm ellipsis"
                    style="max-width:150px;"
                  />
                </div>
              </div>
            </div>
            <template v-slot:loading>
              <div class="row justify-center q-my-md">
                <q-spinner-dots color="green-13" size="40px" />
              </div>
            </template>
          </q-infinite-scroll>
        </div>
      </div>
    </div>
  </q-page>

  <q-dialog v-model="pairDialog" @hide="clearPairingCodeForm">
    <q-card
      class="q-px-md q-py-md"
      dark
      style="min-width:50%;"
    >
      <q-card-section>
        <div class="text-h5 text-weight-bold">Pair Device</div>
      </q-card-section>

      <q-card-section>
        <div class="text-subtitle2 text-weight-bold q-mb-xs">Name of Device</div>
        <q-input
          dark
          dense
          outlined
          color="green-13"
          label="Enter Name"
          v-model="pairingCode.name"
          maxlength="250"
        >
        </q-input>
      </q-card-section>
      
      <q-card-section>
        <div class="text-subtitle2 text-weight-bold q-mb-xs">Pairing Code</div>
        <q-input
          dark
          dense
          outlined
          color="green-13"
          label="Enter Code"
          v-model="pairingCode.code"
          maxlength="8"
          :rules="[ 
            val => !!val || 'Field is required',
            val => val.length > 7 || 'Pairing codes must be 8 characters' 
          ]"
          lazy-rules
        >
        </q-input>
      </q-card-section>

      <q-card-actions class="q-px-md" align="right">
        <q-btn
          flat
          label="Cancel"
          color="white"
          v-close-popup
        />
        <app-btn
          label="Pair Device"
          @click="onClickPairDevice"
          :isDisabled="!isValid"
        >
        </app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref, reactive, watch } from 'vue';
import { 
  getTenantGid,
  getBeamToken,
  setLabelCategories,
  persistedState,
  setPersistedState,
  getLabelCategories,
  getIsLoggedIn
} from '@/store';
import { useRouter } from "vue-router";
import { useQuasar } from 'quasar';
import { get } from 'lodash';
import LabelsDrawer from '@/components/labelsDrawer.vue';
import FilterLabelDialog from '@/components/FilterLabelDialog.vue';
import ActiveLabelFilterComponent from '../components/ActiveLabelFilterComponent.vue';
import { fetchLabelCategories } from '@/service/tenantService';
import { fetchDisplays, findDisplays, updateDisplay } from '@/service/displaysService';
import useLabelFilters from '../composables/useLabelFilters';
import useDevices from '../composables/useDevices';
import { logOut } from '@/service/auth';
import DeviceServices from "@/service/deviceServices";
import SelectAllCheckbox from '../components/SelectAllCheckbox.vue';
import BulkActionsDialog from '../components/BulkActionsDialog.vue';
import deviceJobs from '../service/deviceJobs';
import AppBtn from '../components/AppBtn';
import PreregisterDialog from '../components/PreregisterDialog';
import Permissions from '../store/permissions';
import deviceServices from '../service/deviceServices';

export default {
  components: { 
    AppBtn,
    LabelsDrawer,
    FilterLabelDialog,
    ActiveLabelFilterComponent,
    SelectAllCheckbox,
    BulkActionsDialog,
    PreregisterDialog
  },
  setup() {
    const $q = useQuasar();
    const router = useRouter();
    const loading = ref(false);
    const isPlatformAdmin = computed(() => {
      return Permissions.state.isPlatformAdmin
    })
    let displays = ref([]);
    const hasDisplays = computed(() => {
      return rows.value.length > 0;
    })
    const sortFields = ref([ 
      { name: 'Name', value: 'name' } 
    ]);
    const sortTypes = ref([
      {name: 'A - Z', value: ''}, 
      {name: 'Z - A', value: '-'}
    ]);
    const sortType = ref({name:'A - Z', value: ''});
    const sortField = ref({name: 'Name', value: 'name'});
    const pagination = ref({
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 0
    });
    const rows = ref([]);
    const columns = [
      {
        name: 'name',
        required: true,
        label: 'Device Name',
        align: 'left',
        field: row => row.name,
        format: val => `${val}`,
      },
      { name: 'serial', label: 'Serial Number', align: 'left', field: 'serial' },
      { name: 'model', label: 'Model', align: 'left', field: 'model' },
      { name: 'status', label: 'Status', align: 'left', field: 'status'}
    ];
    const pairDialog = ref(false);
    const pairingCode = reactive({
      name: '',
      code: ''
    });
    const isValid = computed(() => {
      return pairingCode.name.length < 251 && pairingCode.code.length === 8
    })
    let isPageLoading = ref(true);
    let endCursor = ref(null);
    let findDisplayEndCursor = ref(null)
    let hasNextPage = ref(false);
    let calledFetchDisplays = ref(true);
    const showLabelsDrawer = ref(false);
    const queuedDisplays = ref([]);
    const isSelected = ref(false);
    const isAllInTenantSelected = ref(false);
    const userIsLoggedIn = computed(() => {
      return getIsLoggedIn()
    })

    const {
      displaysResponseHelper
    } = useDevices()

    const {
      activeLabelFilters,
      getLabelsAssignedToDisplay,
      getActiveLabelIds,
      getBulkAddLabelGids,
      getBulkDeleteLabelGids,
      clearLabelHashObj,
    } = useLabelFilters()

    watch(() => userIsLoggedIn.value, (cur, prev) => {
      if (cur && !prev) {
        deviceServices.onAuthStateChanged(async (user) => {
          if (user) {
            try {
              await loadLabels()
              if (persistedState.value.filterState) {
                activeLabelFilters.value = persistedState.value.filterState
              }
              if (persistedState.value.sortState.sortType.value || persistedState.value.sortState.sortField.value) {
                sortType.value = persistedState.value.sortState.sortType
                sortField.value = persistedState.value.sortState.sortField
              }
              loadDeviceList()
            } catch (err) {
              $q.notify({
                type: 'negative',
                message: `Failed to load devices (${err.message})`,
                caption: `${err}`
              })
            }
          }
        })
      } else {
        // If not logged in redirect to root URL to fire verifySession
        router.push('/')
      }
    }, {
      immediate: true,
      deep: true
    })

    async function loadLabels() {
      try {
        const resp = await fetchLabelCategories();
        setLabelCategories(resp);
      } catch(err) {
        $q.notify({
          type: 'negative',
          message: 'Failed to fetch labels',
          caption: `${err}`
        })
        console.warn(err);
      }
    }

    async function loadDeviceList() {
      // if search or label filters are saved to state use the find displays query instead
      if (persistedState.value.searchState || activeLabelFilters.value.length) {
        findDevices()
        isPageLoading.value = false;
        return
      }
      try {
        const sortString = `${sortType.value.value}${sortField.value.value}`;
        const resp = await fetchDisplays(sortString, null);
        if (resp) {
          displays.value = [];
          const filteredArr = displaysResponseHelper(resp);
          displays.value.push(...filteredArr)
          if (isAllInTenantSelected.value) {
            queuedDisplays.value = displays.value
          }
          endCursor.value = get(resp, 'data.displays.pageInfo.endCursor');
          hasNextPage.value = get(resp, 'data.displays.pageInfo.hasNextPage');
          setPersistedState(activeLabelFilters.value, sortType.value, sortField.value)
          loading.value = false;
        }
        isPageLoading.value = false;
      } catch(err) {
        isPageLoading.value = false;
        console.warn(err)
      }
    }

    async function fetchMoreDevices(index, done) {
      const sortString = `${sortType.value.value}${sortField.value.value}`;
      if (hasNextPage.value) {
        if (calledFetchDisplays.value  && !activeLabelFilters.value.length && !persistedState.value.searchState) {
          const resp = await fetchDisplays(sortString, endCursor.value);
          if (resp) {
            const filteredArr = displaysResponseHelper(resp);
            displays.value.push(...filteredArr)
            if (isAllInTenantSelected.value) {
              queuedDisplays.value = displays.value
            }
            endCursor.value = get(resp, 'data.displays.pageInfo.endCursor');
            hasNextPage.value = get(resp, 'data.displays.pageInfo.hasNextPage');
            done()
          }
          isPageLoading.value = false;
        } else {
          const labelGids = getLabelGids(activeLabelFilters.value);
          const resp = await findDisplays(sortString, findDisplayEndCursor.value, persistedState.value.searchState, labelGids);
          if (resp) {
            hasNextPage.value = get(resp, 'data.findDisplays.pageInfo.hasNextPage');
            findDisplayEndCursor.value = get(resp, 'data.findDisplays.pageInfo.endCursor');
            const filteredArr = displaysResponseHelper(resp);
            displays.value.push(...filteredArr)
            if (isAllInTenantSelected.value) {
              queuedDisplays.value = displays.value
            }
            done()
          } 
        }
      }
      done()
    }

    function onSelectDevice(display) {
      const encodedLocation = display.location ? encodeURIComponent(display.location) : ''
      router.push({path: `/devices/${display.displayId}/${encodedLocation}`});
    }

    async function onClickPairDevice() {
      pairDialog.value = false;

      if (pairingCode.code) {
        const notify = $q.notify({
          type: 'ongoing',
          message: 'Pairing device...',
          spinner: true
        })
        const options = {
          method: 'POST',
          body: JSON.stringify({
            tenantGid: getTenantGid(),
            name: pairingCode.name,
            pairingCode: pairingCode.code
          }),
          headers: {
            'Authorization': `Bearer ${getBeamToken()}`, 
            'Content-Type': 'application/json'
          }
        }

        const res = await fetch(`https://us-central1-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/pairDevice`, options);
        const status = res.status;
        switch (status) {
          case 200 :
            loading.value = true;
            notify({
              type: 'positive',
              message: 'Successfully paired device',
              spinner: false
            })
            setTimeout( async () => {
              endCursor.value = null;
              const sortString = `${sortType.value.value}${sortField.value.value}`;
              const resp = await fetchDisplays(sortString, null);
              if (resp) {
                displays.value = [];
                const filteredArr = displaysResponseHelper(resp);
                displays.value.push(...filteredArr)
                endCursor.value = get(resp, 'data.displays.pageInfo.endCursor');
                hasNextPage.value = get(resp, 'data.displays.pageInfo.hasNextPage');
                loading.value = false;
              }
              isPageLoading.value = false;
                loading.value = false;
              }, 2000)
            break
          case 409 :
            notify({
              type: 'negative',
              message: 'Device already exists/is active',
              caption: `Error Code: ${res.status}`,
              spinner: false
            })
            break
          case 410 :
            notify({
              type: 'negative',
              message: `Invalid pairing code`,
              caption: `Error Code: ${res.status}`,
              spinner: false
            })
            break
          default :
            notify({
              type: 'negative',
              message: 'Could not pair device',
              caption: `Error Code: ${res.status}`,
              spinner: false
            })
        }
      }
    }

    function clearPairingCodeForm() {
      pairingCode.code = '';
      pairingCode.name = '';
    }

    async function findDevices() {
      loading.value = true
      displays.value = [];
      const sortString = `${sortType.value.value}${sortField.value.value}`;
      if (persistedState.value.searchState || activeLabelFilters.value.length) {
        try {
          // delete end cursor for findDevices as it will always be a search with updated search, filter, or sort
          findDisplayEndCursor.value = null

          const labelGids = getLabelGids(activeLabelFilters.value);
          
          const resp = await findDisplays(sortString, findDisplayEndCursor.value, persistedState.value.searchState, labelGids);
          if (resp) {
            hasNextPage.value = get(resp, 'data.findDisplays.pageInfo.hasNextPage');
            findDisplayEndCursor.value = get(resp, 'data.findDisplays.pageInfo.endCursor');
            const filteredArr = displaysResponseHelper(resp);
            displays.value.push(...filteredArr);
            if (isAllInTenantSelected.value) {
              queuedDisplays.value = displays.value
            }

            setPersistedState(activeLabelFilters.value, sortType.value, sortField.value)
            loading.value = false;
          }
        } catch (err) {
          console.warn(err)
          loading.value = false;
        }
      } else {
        endCursor.value = null;
        calledFetchDisplays.value = true;
        const sortString = `${sortType.value.value}${sortField.value.value}`;
        const resp = await fetchDisplays(sortString, endCursor.value);
        if (resp) {
          displays.value = [];
          const filteredArr = displaysResponseHelper(resp);
          displays.value.push(...filteredArr)
          if (isAllInTenantSelected.value) {
            queuedDisplays.value = displays.value
          }
          endCursor.value = get(resp, 'data.displays.pageInfo.endCursor');
          hasNextPage.value = get(resp, 'data.displays.pageInfo.hasNextPage');
          setPersistedState(activeLabelFilters.value, sortType.value, sortField.value)
          loading.value = false;
        }
        isPageLoading.value = false;
      }
    }

    function getLabelName(gid) {
      if (!gid) return
      const allLabels = [];
      const labelCategories = getLabelCategories();
      if (labelCategories) {
        labelCategories.forEach((cat) => {
          allLabels.push( ...cat?.labels )
        })
      }
      
      const matchingLabel = allLabels.filter(el => el.id === gid)
      return matchingLabel[0]?.name;
    }

    async function applyLabelsToDisplay(queuedLabelGids, indeterminateLabelGids) {
      // Handle bulk add/remove labels
      if (isAllInTenantSelected.value) {
        const notify = $q.notify({
          type: 'ongoing',
          message: 'Updating labels on all selected displays',
          spinner: true
        })
        try {
          let deviceQuery = {};
          if (!persistedState.value.searchState && !activeLabelFilters.value.length) {
            deviceQuery = {
              searchText: 'skAllDisplays',
            }
          } else {
            const labelIds = getActiveLabelIds(activeLabelFilters.value);
            deviceQuery = {
              searchText: persistedState.value.searchState,
              labelIds
            };
          }

          let addLabelGids = getBulkAddLabelGids();
          let deleteLabelGids = getBulkDeleteLabelGids();
          isAllInTenantSelected.value = false;
          clearLabelHashObj();
          
          const resp = await deviceJobs.editDeviceLabels(deviceQuery, addLabelGids, deleteLabelGids);
          if (resp) {
            DeviceServices.listenForJobChanges(resp.jobTraceId, (data) => {
              if (data?.status === 'complete' && !data?.error) {
                notify()
                findDevices()
              }
            })
          } else {
            notify({
              type: 'negative',
              message: 'Unable to add/remove labels from selected devices',
              spinner: false
            })
          }
        } catch (err) {
          notify({
            type: 'negative',
            message: 'Unable to update displays',
            spinner: false,
            caption: `${err}`
          })
          console.error(err)
        }
      } else {
        queuedDisplays.value.forEach(async (display) => {
          let variables = [];
          // Only add labels to displays that already had that label if it is in indeterminate state
          indeterminateLabelGids.forEach((el) => {
            if (display.labelGids.includes(el)) {
              variables.push(el);
            }
          })
          const displayIdx = displays.value.findIndex(el => el?.id === display?.id)
          try {
            const resp = await updateDisplay(display?.id, [...queuedLabelGids, ...variables]);
            displays.value[displayIdx] = resp?.data?.updateDisplay;
            variables = [];
          } catch(err) {
            $q.notify({
              type: 'negative',
              message: `Failed to add label to device: ${err}`
            })
            console.warn(err);
          }
        })
      }

      queuedDisplays.value = [];
    }

    function onCloseLabelsDrawer() {
      showLabelsDrawer.value = false;
    }

    async function filterDisplaysByLabel(labels) {
      displays.value = [];
      loading.value = true;
      if (!labels.length && !persistedState.value.searchState) {
        activeLabelFilters.value = []
        loadDeviceList()
        return
      }
      try {
        const labelGids = getLabelGids(labels)
        const sortString = `${sortType.value.value}${sortField.value.value}`;
        const resp = await findDisplays(sortString, null, persistedState.value.searchState, labelGids);
        if (resp) {
          hasNextPage.value = get(resp, 'data.findDisplays.pageInfo.hasNextPage');
          findDisplayEndCursor.value = get(resp, 'data.findDisplays.pageInfo.endCursor');
          const filteredArr = displaysResponseHelper(resp);
          displays.value.push(...filteredArr)
          if (isAllInTenantSelected.value) {
            queuedDisplays.value = displays.value
          }
          loading.value = false;
          activeLabelFilters.value = labels;
          setPersistedState(activeLabelFilters.value, sortType.value, sortField.value)
        }
      } catch (err) {
        console.warn(err)
        loading.value = false;
      }
    }

    function handleLabelFilterRemoved(label) {
      const idx = activeLabelFilters.value.indexOf(label);
      activeLabelFilters.value.splice(idx, 1);
      filterDisplaysByLabel(activeLabelFilters.value)
    }

    function getLabelGids(labelsArray) {
      const labelGids = [];
      labelsArray.forEach(label => {
        if (label.id) {
          labelGids.push(label.id)
        }
        if (label.labelId) {
          labelGids.push(label.labelId)
        }
      })

      return labelGids
    }

    function handleSelectAll(selectAll) {
      if (selectAll === 'checked') {
        queuedDisplays.value = displays.value;
        isAllInTenantSelected.value = true;
      } else if (selectAll === 'unchecked') {
        queuedDisplays.value = [];
        isAllInTenantSelected.value = false;
      } else if (selectAll === 'indeterminate') {
        isAllInTenantSelected.value = false;
      }
    }

    function clearSelectedDisplays() {
      queuedDisplays.value = [];
      isAllInTenantSelected.value = false
    }

    function getSelectAllString() {
      if (persistedState.value.searchState && !activeLabelFilters.value.length) {
        return 'All devices in tenant have been selected that match this search criteria'
      }
      if (!persistedState.value.searchState && activeLabelFilters.value.length) {
        return 'All devices in tenant have been selected that match this filter criteria'
      }
      if (persistedState.value.searchState && activeLabelFilters.value.length) {
        return 'All devices in tenant have been selected that match this search and filter criteria'
      }
      return 'All devices in this tenant have been selected'
    }

    return {
      displays,
      pagination,
      rows,
      columns,
      loading,
      hasDisplays,
      sortTypes,
      sortFields,
      sortField,
      sortType,
      pairDialog,
      pairingCode,
      isValid,
      isPageLoading,
      endCursor,
      showLabelsDrawer,
      queuedDisplays,
      isSelected,
      activeLabelFilters,
      isAllInTenantSelected,
      onSelectDevice,
      onClickPairDevice,
      clearPairingCodeForm,
      logOut,
      fetchMoreDevices,
      findDevices,
      getLabelName,
      applyLabelsToDisplay,
      onCloseLabelsDrawer,
      filterDisplaysByLabel,
      handleLabelFilterRemoved,
      getLabelGids,
      getLabelsAssignedToDisplay,
      handleSelectAll,
      clearSelectedDisplays,
      getSelectAllString,
      persistedState,
      loadDeviceList,
      isPlatformAdmin
    }
  },
};
</script>

<style>
.page {
  width: 86%;
  margin-left: 7%;
}
.display-line-item:nth-child(even) {
  background-color: rgb(35, 35, 35)
}
.display-line-item:nth-child(odd) {
  background-color: rgb(30, 30, 30)
}
.display-line-item:hover {
  cursor: pointer;
}
.display-line-item-header {
  position: sticky;
}
#labelBtn {
  transform: rotate(135deg);
}
#labelsDrawerWrapper {
  background: rgb(25, 25, 25);
}
.all-displays-checkbox {
  margin-top: -50px;
}
.clear-selection {
  cursor: pointer;
}
input, select {
  color: white !important;
  -webkit-text-fill-color: white !important;
  -webkit-background-clip: text !important;
  background-clip:  text !important;
}
button.q-icon {
  padding-right: 5px !important;
}
</style>
