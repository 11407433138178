import { format, parseISO } from 'date-fns'

function formatDateString(dateString) {
  return dateString ? format(parseISO(dateString), 'MM-dd-yyyy') : ''
}

function formatDateTimeString(dateTimeString) {
  return dateTimeString ? format(parseISO(dateTimeString), 'Pp') : ''
}

function formatTwentyFourHourString(fourDigitTime) {
  if (fourDigitTime) {
    const hours24 = parseInt(fourDigitTime.substring(0,2))
    const hours = ((hours24 + 11) % 12) + 1
    const amPm = hours24 > 11 ? ' pm' : ' am'
    const minutes = fourDigitTime.substring(2)
  
    return hours + minutes + amPm
  }
  return
}


export {
  formatDateString,
  formatDateTimeString,
  formatTwentyFourHourString
}