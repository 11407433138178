<script setup>
import { ref, watch, onMounted } from 'vue'
import { formatDateTimeString } from '../utils/dateUtils'
import deviceServices from '../service/deviceServices'
import deviceJobs from '../service/deviceJobs'
import _ from 'lodash'
import { auth } from '@/store'
import { useQuasar } from 'quasar'

const $q = useQuasar()
const isLoading = ref(false)
const isDownloading = ref(false)
const downloadingJobTraceId = ref('')
const tab = ref('jobHistory')
const jobs = ref([])
const jobStatusDialog = ref(false)
const jobDetail = ref ({})
const hasErrors = ref(false)
const showKnightRider = ref(true)
const hasNoJobs = ref(false)
watch(auth, (cur, prev) => {
  if (cur?.isLoggedIn && !prev?.isLoggedIn && cur?.tenantCode) {
    deviceServices.onAuthStateChanged((user) => {
      if (user) {
        fetchJobHistory()
      } else {
        console.debug('Unable to fetch bulk jobs');
      }
    });
  } 
}, {
  immediate: true,
  deep: true
})

onMounted(() => {
  isLoading.value = true
})

function fetchJobHistory() {
  deviceServices.listenForJobHistoryGroups((data) => {
    if (data && data !== 'noData') {
      // Only show jobs that occurred for more than one device (bulk job)
      const newArr = data.filter(el => el.numDevices > 1)
      jobs.value = newArr
    } else {
      hasNoJobs.value = true
    }
    showKnightRider.value = false
  })
}

function getJobErrors(numErrors) {
  return `${!numErrors ? 0 : numErrors} Devices Failed`
}

async function showJobDetailDialog(jobTraceId) {
  jobStatusDialog.value = true
  isLoading.value = true
  const data = await deviceJobs.jobTraceStatus(jobTraceId)
  jobDetail.value = data
  if (jobDetail.value?.errors && Object.keys(jobDetail.value?.errors).length) {
    hasErrors.value = true
  } else {
    hasErrors.value = false
  }
  isLoading.value = false
}

function closeJobDetailDialog() {
  jobDetail.value = {}
  jobStatusDialog.value = false
}

function formatJobStatusString(job) {
  if (job.status === 'complete') {
    return 'Complete'
  } else {
    return `In Progress ${job.numComplete}/${job.numDevices}`
  }
}

async function downloadCsvFile(jobTraceId) {
  isDownloading.value = true
  downloadingJobTraceId.value = jobTraceId
  try {
    const data = await deviceJobs.jobTraceStatus(jobTraceId)
    if (data) {
      const downloadUrl = await deviceServices.fetchFileDownloadUrl(data?.csvFile)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.download = `Error Report: ${data.jobName}.csv`
      document.body.appendChild(link)
      link.click()
    } else {
      console.warn('Unbale to fetch Job Trace Status')
      $q.notify({
        type: 'negative',
        message: 'Unable to download error report'
      })
    }
  } catch (err) {
    console.error(err)
    $q.notify({
      type: 'negative',
      message: 'Unable to download error report',
      caption: `${err}`
    })
  } finally {
    isDownloading.value = false
  }
}

</script>

<template>
  <q-page padding class="page">
    <div class="row">
      <div class="col text-left text-white">
        <h3 class="text-weight-bolder">Bulk Job View</h3>
      </div>
    </div>

    <q-tabs
      v-model="tab"
      dense
      class="text-grey text-h1"
      active-color="green-13"
      indicator-color="green-13"
      align="left"
    >
      <q-tab default="true" name="jobHistory" label="job history" />
    </q-tabs>
    
    <q-separator dark></q-separator>

    <q-tab-panels
        v-model="tab"
        keep-alive
        animated
        class="tabPanel"
        style="background: none;"
      >
        <q-tab-panel dark name="jobHistory" class="q-pa-none panel">
          <q-card class="full-width q-mt-lg q-px-lg q-py-sm rounded-borders" dark>
            <div>
              <q-item class="q-pb-none text-left text-subtitle1 text-bold">
                <q-item-section class="col items-start">
                 <span> Event Timestamp </span>
                </q-item-section>
                <q-item-section class="col-2 items-start q-pl-sm">
                  <span> Job </span>
                </q-item-section>
                <q-item-section class="col items-start q-pl-sm">
                  <span> Job Trace ID</span>
                </q-item-section>
                <q-item-section class="col text-center">
                  <span> Number of Devices </span>
                </q-item-section>
                <q-item-section class="col-2 items-start q-pl-lg">
                  <span> Status </span>
                </q-item-section>
                <q-item-section class="col-1 items-start">
                  <span> Errors </span>
                </q-item-section>
                <q-item-section class="col-1 items-end">
                </q-item-section>
              </q-item>
            </div>

            <q-separator dark></q-separator>
            
            <div>
              <q-linear-progress indeterminate v-if="showKnightRider"  color="green-13"></q-linear-progress>
            </div>
            <div>
              <div v-if="!hasNoJobs">
                <div v-for="job in jobs" :key="job" class="job-rows">
                  <q-item class="q-py-md">
                    <q-item-section class="col items-start">
                      <span class="text-left">
                        {{ formatDateTimeString(job.created) }}
                      </span>
                    </q-item-section>
                    <q-item-section class="col-2 items-start q-pl-sm">
                      <span class="text-left">
                        {{ _.replace(job.jobName, new RegExp('-','g'), ' ') }}
                      </span>
                    </q-item-section>
                    <q-item-section class="col items-start">
                      <span class="text-no-wrap">
                        {{ job.jobTraceId }}
                      </span>
                    </q-item-section>
                    <q-item-section class="col">
                      <span>
                        {{ job.numDevices }}
                      </span>
                    </q-item-section>
                    <q-item-section class="col-2 items-start q-pl-lg">
                      <span>
                        {{ formatJobStatusString(job) }}
                      </span>
                    </q-item-section>
                    <q-item-section class="col-1 items-start">
                      <span 
                        v-if="job.numErrors > 0"
                        @click="showJobDetailDialog(job.jobTraceId)"
                        class="text-green-13 text-left job-errors-link"
                      >
                        {{ getJobErrors(job.numErrors) }}
                      </span>
                      <span
                        v-else
                        class="text-green-13 text-left"
                      >
                        0 Devices Failed
                      </span>
                    </q-item-section>
                    <q-item-section class="col-1 items-end">
                      <div>
                        <q-spinner
                          v-if="isDownloading && downloadingJobTraceId === job.jobTraceId"
                          color="green-13"
                          size="sm"
                          :thickness="6"
                          style="margin-right: 10px;"
                        />
                        <q-btn
                          v-else
                          dense
                          flat
                          icon="o_file_download"
                          @click="downloadCsvFile(job.jobTraceId)"
                        >
                        </q-btn>
                      </div>
                      
                    </q-item-section>
                  </q-item>
                </div>
              </div>
              <div v-else>
                <div class="row justify-center items-center" style="height:500px;">
                  <span class="text-h6 justify-center"> There is no bulk job history for this tenant </span>
                </div>
              </div>
            </div>
          </q-card>
        </q-tab-panel>
      </q-tab-panels>

      <q-dialog v-model="jobStatusDialog">
        <q-card 
          class="full-width q-mt-lg q-px-lg q-py-sm rounded-borders"
          dark
          style="width:70%;max-width:800px;height:80%;"
        >
          <div class="row">
            <span class="text-h6 text-bold"> Errors </span>
            <q-space></q-space>
            <q-btn
              @click="closeJobDetailDialog"
              size="sm"
              round
              dense
              flat
              icon="close"
            >
            </q-btn>
          </div>
          <div>
            <q-item class="q-pb-none text-left">
              <q-item-section class="text-bold items-start col">
                <span> Device Serial </span>
              </q-item-section>
              <q-item-section class="text-bold items-start col">
                <span> Model </span>
              </q-item-section>
              <q-item-section class="text-bold col">
                <span> Error </span>
              </q-item-section>
            </q-item>
          </div>

          <q-separator dark></q-separator>

          <div v-if="isLoading" class="row justify-center items-center" style="height:500px;">
            <q-spinner size="6em" :thickness="3" color="green-13"></q-spinner>
          </div>
          <div v-else>
            <div v-if="hasErrors">
              <div v-for="item in jobDetail.errors" :key="item" class="job-rows">
                <q-item >
                  <q-item-section class="items-start col">
                    <span>
                      {{ item.serialNumber }}
                    </span>
                  </q-item-section>
                  <q-item-section class="items-start col">
                    <span>
                      {{ item.model }}
                    </span>
                  </q-item-section>
                  <q-item-section class="col">
                    <span>
                      {{ item.message }}
                    </span>
                  </q-item-section>
                </q-item>
              </div>
            </div>
            <div v-else>
              <div class="row justify-center items-center" style="height:500px;">
                <span class="text-h6 justify-center"> There are no errors for this job </span>
              </div>
            </div>
          </div>
        </q-card>
      </q-dialog>
    
  </q-page>
</template>

<style>
.job-rows:nth-child(even) {
  background-color: rgb(35, 35, 35);
}
.job-errors-link {
  cursor: pointer;
}
</style>
