import axios from "axios";
import get from "lodash/get";
import { permissionsQuery } from "../apollo/queries/identity";

const getIdentity = async (authToken, tenantLid) => {
  try {
    const response = await axios({
      url: process.env.VUE_APP_AUTH_URL,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        query: permissionsQuery,
        variables: {
          tenantLid,
        },
      },
    });

    return get(response, 'data.data');
  } catch (err) {
    console.error(`Permission service failed, ${err.message}`);
    throw new Error(err.message);
  }
};

export { getIdentity };
