import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    from
  } from "@apollo/client/core";
import { setContext } from "apollo-link-context";
import { onError } from "@apollo/client/link/error";
import { getBeamToken } from '@/store';
import { ApolloLink } from "apollo-boost";
// import { getPlayerToken } from "../store/index.js";

const displaysHttpLink = new HttpLink({
  uri: process.env.VUE_APP_DISPLAYS_URL,
  fetch
});

const tenantsHttpLink = new HttpLink({
  uri: process.env.VUE_APP_TENANTS_V2_ENDPOINT,
  fetch
});

const authLink = setContext(async (_, { headers }) => {
  const token = getBeamToken()
  
  return {
    headers: {
      ...headers,
      'authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      // If BE services return 'Unauthorized' the Beam token is expired and user needs to re-login
      switch (err.message) {
        case 'Unauthorized':
          console.log(
            `[GraphQL error]: Message: ${err.message}`,
          )
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const tenantLink = from([errorLink, authLink.concat(tenantsHttpLink)]);
const displaysLink =  from([errorLink, authLink.concat(displaysHttpLink)]);
  
const client = new ApolloClient({
  link: ApolloLink.split(
    operation => operation.getContext().clientName === "displays",
    displaysLink,
    ApolloLink.split(
      operation => operation.getContext().clientName === "tenant",
      tenantLink,
    ),
  ),
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors'
  }
});

export default client