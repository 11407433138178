import { gql } from 'apollo-boost';

const getTenantQuery = gql`
  query getTenant($id: ID!) {
    node(id: $id) {
      id
      ... on Tenant {
        id
				lid
        name		
        intacctId
        idleSessionTimeout
        addons {
					gidV3
					id
					lid
				}
      }
    }
  }
`;

const getLabelCategoriesQuery = gql`
  query getLabelCategories($tenantGid: ID!, $after: String) {
    labelCategories(tenantGid: $tenantGid, first: 1000, after: $after) {
      edges {
        node {
          id
          lid
          name
          labels(first: 1000) {
            edges {
              node {
                category {
                  id
                  lid
                  name
                }
                categoryGid
                createdAt
                id
                lid
                name
                updatedAt

              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

const createLabelCategory = gql`
  mutation createLabelCategory($input: CreateLabelCategoryInput!) {
    createLabelCategory(input: $input) {
      createdAt
      id
      lid
      name
      tenantGid
      updatedAt
      labels {
        edges {
          node {
            categoryGid
            createdAt
            id
            lid
            name
            updatedAt
          }        
        }
      }
    }
  }
`;

const createLabel = gql`
  mutation createLabel($input: CreateLabelInput!) {
    createLabel(input: $input) {
      category {
        id
        lid
        name
      }
      categoryGid
      createdAt
      id
      lid
      name
      updatedAt
    }
  }
`;

const putLabel = gql`
  mutation updateLabel($input: UpdateLabelInput!) {
    updateLabel(input: $input) {
      id
      name
      categoryGid
    }
  }
`;

const deleteLabelFromCategory = gql`
  mutation deleteLabel($input: DeleteLabelInput!) {
    deleteLabel(input: $input) {
      gid
    }
  }
`;

const putLabelCategory = gql`
  mutation updateLabelCategory($input: UpdateLabelCategoryInput!) {
    updateLabelCategory(input: $input) {
      createdAt
      id
      lid
      name
      tenantGid
      updatedAt
      labels {
        edges {
          node {
            categoryGid
            createdAt
            id
            lid
            name
            updatedAt
          }        
        }
      }
    }
  }
`;

const deleteLabelCategory = gql`
  mutation deleteLabelCategory($input: DeleteLabelCategoryInput!) {
    deleteLabelCategory(input: $input) {
      gid
    }
  }
`;

export {
  getTenantQuery,
  getLabelCategoriesQuery,
  createLabelCategory,
  createLabel,
  putLabel,
  deleteLabelFromCategory,
  putLabelCategory,
  deleteLabelCategory
}