import { ref } from 'vue'
import deviceStore from '../store/device'
import { getObjectDiff } from '../utils/objectUtils'
import deviceJobs from '../service/deviceJobs'
import deviceServices from '../service/deviceServices'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import regexRules from '../constants/regex'

const isDisabled = ref(true)
const isSavingDeviceConfig = ref(false)

export default function useDeviceConfig(deviceConfig) {
  const $q = useQuasar()
  let updatedDeviceConfigProperties = {}

  function onChangeDeviceConfig(config) {
    const isValid = isValidConfig(config)
    if (isValid) {
      // Check for changes in deviceConfig and add only the updated properties to the save queue
      const cachedConfig = deviceStore.getters.getCachedDeviceConfig()
      const diff = getObjectDiff(cachedConfig, config)

      // Save time/day whenever scheduledReboot is set to true
      if (diff.scheduledReboot) {
        diff.restartDay = config.restartDay
        diff.restartTime = config.restartTime
      }
  
      if (Object.keys(diff).length) {
        isDisabled.value = false
        updatedDeviceConfigProperties = diff;
      } else {
        isDisabled.value = true;
        updatedDeviceConfigProperties = {};
      }
    } else {
      isDisabled.value = true
    }
  }

  async function saveDeviceConfig(device) {
    isDisabled.value = true
    isSavingDeviceConfig.value = true
    const notify = $q.notify({
      type: 'ongoing',
      message: 'Saving device configuration...',
      spinner: true
    })

    try {
      const reqData = sanitizeUpdatedProperties(updatedDeviceConfigProperties)
      const deviceQuery = {
        deviceKeys: [device.value?.deviceKey]
      }
      const resp = await deviceJobs.updateDeviceConfigs(deviceQuery, reqData)
      if (resp.jobTraceId) {
        deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
          if (data){
            if (data.status === 'incomplete') {
              return
            }
            if (data.status === 'complete' && !data.error) {
              notify({
                type: 'positive',
                message: 'Successfully updated the device configuration',
                spinner: false
              })
              // Fetch newly saved device config, update the config ref, and cache it to compare against for new changes
              const resp = await device.value.fetchDeviceConfig()
              deviceConfig = setDeviceConfigDefaults(resp)
              deviceStore.actions.setCachedDeviceConfig(deviceConfig)
              isSavingDeviceConfig.value = false
              return
            }
            if (data.status === 'complete' && data.error) {
              notify({
                type: 'negative',
                message: 'Failed to update the device configuration',
                spinner: false
              })
              isSavingDeviceConfig.value = false
              return
            }
          }
        })
      } else {
        notify({
          type: 'negative',
          message: 'Failed to update the device configuration',
          spinner: false
        })
        isSavingDeviceConfig.value = false
        console.error(resp)
      }
    } catch (err) {
      console.error(err)
      notify({
        type: 'negative',
        message: 'Failed to update the device configuration',
        spinner: false
      })
      isSavingDeviceConfig.value = false
    }
  }

  function sanitizeUpdatedProperties(config) {
    if (config.rotation) {
      config.rotation = parseInt(config.rotation)
    }
    if (config.frameBuffer === null) {
      config.frameBuffer = ''
    }
    if (config.kioskApp === null) {
      config.kioskApp = ''
    }
    if (config.homeApp === null) {
      config.homeApp = ''
    }
    if (config.otaUpdateUrl === null) {
      config.otaUpdateUrl = ''
    }
    // TODO: Remove after PS allows build lavor to be updated in CUI
    if (config.otaBuildFlavor) {
      delete config.otaBuildFlavor
    }
    if (config.displayWidth === '') {
      config.displayWidth = -1
    }
    if (config.displayHeight === '') {
      config.displayHeight = -1
    }
    return config
  }

  function isValidConfig(config) {
    return (regexRules.frameBufferRegex.test(config.frameBuffer) || config.frameBuffer === '')
    && (regexRules.packageNameRegex.test(config.kioskApp) || config.kioskApp === '')
    && (regexRules.packageNameRegex.test(config.homeApp) || config.homeApp === '')
    && (regexRules.otaUrlRegex.test(config.otaUpdateUrl) || config.otaUpdateUrl === '')
    && (!config.displayWidth  || (config.displayWidth >= 200 && config.displayWidth <= 4096))
    && (!config.displayHeight || (config.displayHeight >= 200 && config.displayHeight <= 4096))
  }

  function setDeviceConfigDefaults(deviceConfig) {
    if (!deviceConfig.appChannels) {
      deviceConfig['appChannels'] = {}
    }
    if (!deviceConfig.appInstalls) {
      deviceConfig['appInstalls'] = {}
    }
    if (deviceConfig.locationEnabled === undefined) {
      deviceConfig['locationEnabled'] = true
    }
    if (deviceConfig.ignoreAccelerometer === undefined) {
      deviceConfig['ignoreAccelerometer'] = false
    }
    if (deviceConfig.hideNavBar=== undefined) {
      deviceConfig['hideNavBar'] = false
    }
    if (!deviceConfig.wifiNetworks) {
      deviceConfig['wifiNetworks'] = {}
    }
    if (deviceConfig.scheduledReboot === undefined) {
      deviceConfig['scheduledReboot'] = false
    }
    if (!deviceConfig.restartTime) {
      deviceConfig['restartTime'] = '02:00'
    }
    if (deviceConfig.restartDay === undefined) {
      deviceConfig['restartDay'] = 1
    }
    if (deviceConfig.autoLaunchHome === undefined) {
      deviceConfig['autoLaunchHome'] = false
    }
    if (!deviceConfig.otaBuildType) {
      deviceConfig['otaBuildType'] = 'user'
    }
    if (!deviceConfig.frameBuffer) {
      deviceConfig['frameBuffer'] = ''
    }
    if (!deviceConfig.kioskApp) {
      deviceConfig['kioskApp'] = ''
    }
    if (!deviceConfig.homeApp) {
      deviceConfig['homeApp'] = ''
    }
    if (!deviceConfig.otaUpdateUrl) {
      deviceConfig['otaUpdateUrl'] = ''
    }
    if (deviceConfig.disallowUsb === undefined) {
      deviceConfig['disallowUsb'] = true
    }
    if (!deviceConfig.heartbeatIntervalMinutes) {
      deviceConfig['heartbeatIntervalMinutes'] = 30
    }
    if (deviceConfig.bluetoothEnabled === undefined) {
      deviceConfig['bluetoothEnabled'] = false
    }
    if (!deviceConfig.density) {
      deviceConfig['density'] = -1
    }
    if (!deviceConfig.otaBuildFlavor) {
      deviceConfig['otaBuildFlavor'] = 'skykit'
    }
    if (deviceConfig.displayWidth === -1) {
      deviceConfig.displayWidth = ''
    }
    if (deviceConfig.displayHeight === -1) {
      deviceConfig.displayHeight = ''
    }

    return deviceConfig
  }

  function getOfflineStatus(deviceState) {
    const message = 'Device is '
    if (deviceState.isOffline) {
      if (deviceState.event === 'prereg') {
        return message + 'Offline (Pre-Registered)'
      } else {
        return message + 'Offline'
      }
    } else {
      return message + 'Online'
    }
  }

  return {
    isDisabled,
    isSavingDeviceConfig,
    setDeviceConfigDefaults,
    updatedDeviceConfigProperties,
    onChangeDeviceConfig,
    saveDeviceConfig,
    getOfflineStatus
  }
}
