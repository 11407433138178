export function removeEdgesAndNodes(response) {
  if (response !== null && typeof response === 'object') {
      if (Array.isArray(response)) {
          return response.map(removeEdgesAndNodes);
      } else if (Array.isArray(response.edges)) {
          return response.edges.map(({ node }) => removeEdgesAndNodes(node));
      } else {
          const newObj = {};
          for (const key in response) {
              if (key !== 'edges' && key !== 'node') {
                  newObj[key] = removeEdgesAndNodes(response[key]);
              }
          }
          return newObj;
      }
  }
  return response;
}