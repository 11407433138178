import script from "./DownloadEventReportComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./DownloadEventReportComponent.vue?vue&type=script&setup=true&lang=js"

import "./DownloadEventReportComponent.vue?vue&type=style&index=0&id=1706166e&lang=css"

const __exports__ = script;

export default __exports__
import QSpace from 'quasar/src/components/space/QSpace.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QDialog,QCard,QCardSection,QIcon,QSeparator,QItemLabel,QSelect,QInput,QPopupProxy,QDate,QBtn,QCardActions});qInstall(script, 'directives', {ClosePopup});
