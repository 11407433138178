<script setup>
import { ref, computed, watch } from 'vue'
import AppBtn from '../components/AppBtn'
import regexRules from '../constants/regex'
import useAlerts from '../composables/useAlerts'

// eslint-disable-next-line
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: false
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  editNotificationGroup: {
    type: Object,
    default: () => {}
  }
})

// eslint-disable-next-line
const emit = defineEmits([
  'close-dialog'
])

const showNotifyDialog = computed(() => {
  return props.showDialog
})
const emailInput = ref('')
const isValidForm = computed(() => {
  return !!(
    notificationGroupModel.value?.name &&
    notificationGroupModel.value?.name?.length < 120 &&
    notificationGroupModel.value?.emails?.length >= 1 &&
    (notificationGroupModel.value?.description?.length < 256 || !notificationGroupModel.value?.description?.length)
  )
})
const isValidEmail = computed(() => {
  return !!(
    emailInput.value &&
    emailInput.value.match(regexRules.emailRegex) &&
    !notificationGroupModel.value.emails.find((el) => el === emailInput.value)
  )
})
const rules = ref({
  emailRule: v => !!(v && v.match(regexRules.emailRegex)) || !v || 'Not a valid email address',
  required: v => !!v || 'This is a required field',
  maxNameRule: v =>(v && v.length < 120) || !v || 'Must be less than 120 characters',
  maxDescriptionRule: v => (v && v.length < 256) || !v || 'Must be less than 256 characters',
  duplicateEmailRule: v => !notificationGroupModel.value.emails.find((el) => el === v) || 'This email address has already been added to the list'
})
const notificationGroupModel = ref({
  notificationGroupId: '',
  name: '',
  createdAt: '',
  description: '',
  emails: []
})

watch(() => props.editNotificationGroup, (cur) => {
  if (cur) {
    notificationGroupModel.value = {...cur}
  }
}, { deep: true, immediate: true })

async function onClickSaveNotificationGroup(notificationGroupModel) {
  emit('close-dialog')
  await saveNotificationGroup(notificationGroupModel, props.isEdit)
  resetNotificationGroupModel()
}

function addRecipient() {
  if (emailInput.value) {
    if (!isValidEmail.value) {
      return
    }
    notificationGroupModel.value?.emails?.push(emailInput.value)
    emailInput.value = ''
  }
}

function removeRecipient(val) {
  const idx = notificationGroupModel.value?.emails?.findIndex(el => el === val)
  notificationGroupModel.value?.emails?.splice(idx, 1)
}

function getDialogHeader() {
  return props.isEdit ? 'Edit Alert Notification Group' : 'Create Alert Notification Group'
}

function getButtonlabel() {
  return props.isEdit ? 'edit notification group' : 'create notification group'
}

function resetNotificationGroupModel() {
  notificationGroupModel.value.notificationGroupId = ''
  notificationGroupModel.value.name = ''
  notificationGroupModel.value.createdAt = ''
  notificationGroupModel.value.description = ''
  notificationGroupModel.value.emails = []
}

const {
  saveNotificationGroup,
} = useAlerts()
</script>

<template>
  <q-dialog v-model="showNotifyDialog" persistent>
    <q-card dark style="width:80%;max-width:800px;" data-test="generate-report-dialog">
      <q-toolbar>
        <q-toolbar-title class="text-bold q-pa-md q-ml-md">{{ getDialogHeader() }}</q-toolbar-title>
        <q-btn flat @click="emit('close-dialog')" round dense icon="close" />
      </q-toolbar>

      <q-separator dark></q-separator>
      
      <q-card-section class="q-px-xl">
        <q-card dark class="q-px-lg">

          <q-item-label class="q-pt-md q-pb-md text-bold text-body1">
            Name your group
          </q-item-label>
          <div class="row q-mb-md">
            <q-input
              dark
              label="Enter alert name"
              color="white"
              dense
              outlined
              class="col"
              clearable
              v-model="notificationGroupModel.name"
              :rules="[rules.required, rules.maxNameRule]"
              no-error-icon
              hide-bottom-space
              :disable="isEdit"
            ></q-input>
          </div>

          <q-item-label class="q-pt-md q-pb-md text-bold text-body1">
            Who should receive alert email notifications?
          </q-item-label>

          <div class="row q-mb-md">
            <q-input
              dark
              label="Enter email address"
              color="white"
              dense
              outlined
              class="col"
              v-model="emailInput"
              @keydown.enter.prevent="addRecipient"
              :rules="[rules.emailRule, rules.duplicateEmailRule]"
              hide-bottom-space
              no-error-icon
            ></q-input>
            <q-space></q-space>
            <q-btn
              class="on-right shadow-7"
              color="green-13"
              text-color="black"
              label="add"
              @click="addRecipient"
              :disable="!isValidEmail"
              style="height:40px;"
            />
          </div>
          
          <div id="emailChipContainer">
            <span v-if="!notificationGroupModel?.emails?.length" id="recipientListLabel" class="q-pl-xs block">
              Recipient list
            </span>
            <q-chip 
              v-for="email in notificationGroupModel?.emails.sort()"
              :key="email"
              :label="email"
              color="white"
              outline
              removable
              @remove="removeRecipient(email)"
            ></q-chip>
          </div>

          <div class="q-mt-md">
            <q-item-label class="q-pt-md q-pb-md text-bold text-body1">
              Group description
            </q-item-label>

            <div class="row q-mb-md">
              <q-input
                dark
                label="Enter a description"
                color="white"
                dense
                outlined
                class="col"
                clearable
                type="textarea"
                v-model="notificationGroupModel.description"
                :rules="[rules.maxDescriptionRule]"
                hide-bottom-space
                no-error-icon
              ></q-input>
            </div>
          </div>
        </q-card>
      </q-card-section>

      <q-separator dark></q-separator>
      <q-card-actions align="right" class="q-pa-md">
        <app-btn
          @click="onClickSaveNotificationGroup(notificationGroupModel)"
          :label="getButtonlabel()"
          :isDisabled="!isValidForm"
        >
        </app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>

</template>

<style >
#emailChipContainer {
  min-height: 150px;
  max-height: 200px;
  background-color: rgb(29,29,29);
  border: .5px solid rgb(200,200,200);
  border-radius: 4px;
  padding: 8px;
  overflow-y: scroll;
  padding-bottom: 24px;
}
#recipientListLabel {
  font-size: 14px;
  color: rgb(180,180,180);
}
div label div div div textarea {
  resize: none !important;
}
</style>
