<script setup>
import { ref, onMounted } from "vue"
import { formatDateTimeString } from "../utils/dateUtils"
import { capitalizeFirstLetter } from '../utils/stringUtils'

// eslint-disable-next-line
const props = defineProps({
  device: {
    type: Object,
    default: () => {}
  }
})
const events = ref({})
const eventType = 'commands'

onMounted(() => {
  fetchDeviceEvents()
})

function fetchDeviceEvents() {
  props.device.listenToEvents(eventType, (data) => {
    try {
      if (data) {
        const validEvents = Object.keys(data).filter(el => el.startsWith('-')).map(i => i = data[i])
        if (Object.keys(validEvents).length) {
          validEvents.forEach((event) => {
            if (event.description?.includes('muted until')) {
              const stringArr = event.description?.split('muted until ')
              const dateString = stringArr[1]
              const formattedDate = formatDateTimeString(dateString)
              event.description = `${stringArr[0]} muted until ${formattedDate}`
            }
          })
          events.value = validEvents.reverse()
        } else {
          events.value = 'noEvents'
        }
      }
    } catch (err) {
      console.error(err)
    }
  })
}

function getEventDescription(event) {
  return event?.description?.name
    ? event?.description?.name
    : event?.description
}

</script>

<template>
  <div>
    <q-item>
      <q-item-section class="col items-start q-ml-md">
        <span class="text-left text-bold">
          Command
        </span>
      </q-item-section>
      <q-item-section class="col items-start q-ml-md">
        <span class="text-left text-bold">
          Event Timestamp
        </span>
      </q-item-section>
      <q-item-section class="col items-start">
        <span class="text-bold">
          Issued By
        </span>
      </q-item-section>
    </q-item>
    
    <q-separator dark></q-separator>

    <div v-if="events.length && events !== 'noEvents'">
      <div v-for="event in events" :key="event" class="event-rows">
        <q-item class="text-white">
          <q-item-section class="col items-start q-ml-md">
            <span class="text-left">
              {{ capitalizeFirstLetter(getEventDescription(event)) }}
            </span>
          </q-item-section>
          <q-item-section class="col items-start q-ml-md">
            <span class="text-left">
              {{ formatDateTimeString(event.datetime) }}
            </span>
          </q-item-section>
          <q-item-section class="col items-start">
            <span>
              {{ event.user || 'System' }}
            </span>
          </q-item-section>
        </q-item>
      </div>
    </div>
    <div v-else class="flex justify-center items-center" style="height:30vh;">
      <q-item>
        <span>
          No command events have been recorded 
        </span>
      </q-item>  
    </div>
  </div>
</template>


<style>
.event-rows:nth-child(even) {
  background-color: rgb(35, 35, 35);
}
</style>