<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useQuasar } from 'quasar'
import useAlerts from '../composables/useAlerts'
import AppBtn from '../components/AppBtn'
import CreateAlertNotificationGrougDialog from './CreateAlertNotificationGrougDialog.vue'
import { formatDateTimeString } from '../utils/dateUtils'
import { useRoute, useRouter } from 'vue-router'
import deviceServices from '../service/deviceServices'
import { auth } from '@/store'

//eslint-disable-next-line
const props = defineProps({
  sourceLocation: {
    type: String,
    default: '',
    required: true
  }
})
const showNotifyDialog = ref(false)
const selectedGroup = ref(null)
const $q = useQuasar()
const route = useRoute()
const router = useRouter()
const isNotifyGroupsListView = computed(() => {
  return route.path.includes('/notificationGroups')
})

watch(auth, (cur) => {
  if (cur.isLoggedIn) {
    deviceServices.onAuthStateChanged((user) => {
      if (user) {
        fetchAlertNotificationGroups()
      } else {
        console.debug('Unable to fetch notification groups');
      }
    });
  }
}, { immediate: true, deep: true })

onMounted(() => {
  showKnightRider.value = true
})

// Create/edit notifiaction groups
function showCreateNotificationGroupDialog(editGroup) {
  selectedGroup.value = editGroup
  showNotifyDialog.value = true
}

function onCloseCreateDialog() {
  showNotifyDialog.value = false
  selectedGroup.value = null
}
 
// Recipients
const recipientsDialog = ref(false)

function showRecipientsDialog(notifyGroup) {
  selectedGroup.value = notifyGroup
  recipientsDialog.value = true
}

// Delete notification group
const deleteDialog = ref(false)
const selectedDeleteGroup = ref({})

function showDeleteDialog(notificationGroup) {
  selectedDeleteGroup.value = notificationGroup
  deleteDialog.value = true
}

function onClickDeleteAlertNotificationGroup() {
  try {
    deleteAlertNotificationGroup(selectedDeleteGroup.value?.notificationGroupId)
  } catch (err) {
    console.error(err)
    $q.notify({
      type: 'negative',
      message: 'Failed to delete notification group'
    })
  } finally {
    deleteDialog.value = false
  }
}

// Column sizes and classes for each location we call the list view
const isListView = computed(() => {
  return props.sourceLocation === 'deviceDetailList' || props.sourceLocation === 'alertsListView' 
})

function getSourceLocationColumnSize(col) {
  const { sourceLocation } = props

  switch (col) {
    case 'name':
      return sourceLocation === 'bulkAlerts' || sourceLocation === 'deviceDetailDialog' ? 'col-4' : 'col q-pl-md'
    case 'timestamp':
      return sourceLocation === 'bulkAlerts' || sourceLocation === 'deviceDetailDialog' ? 'col-3' : 'col q-pl-md'
    case 'recipients':
    return sourceLocation === 'bulkAlerts' || sourceLocation === 'deviceDetailDialog' ? 'col-3' : 'col-3 q-pl-md'
  }
}

function getSourceLocationListWrapperClass() {
  switch (props.sourceLocation) {
    case 'bulkAlerts' :
      return 'alerts-list-wrapper'
    case 'deviceDetailDialog' :
      return 'alerts-list-wrapper-lg'
    case 'alertsListView' :
      return 'alerts-list-wrapper-xl'
    case 'deviceDetailList' :
      return 'alerts-list-wrapper'
  }
}

function getSourceLocationListItemClass() {
  switch (props.sourceLocation) {
    case 'bulkAlerts' :
      return 'bulk-alerts-line-item'
    case 'deviceDetailDialog' :
      return 'device-detail-dialog-line-item'
    case 'alertsListView' :
      return 'alerts-list-view-line-item'
    case 'deviceDetailList' :
      return 'device-detail-line-item'
  }
}

function getSourceLocationHeaderClass() {
  switch (props.sourceLocation) {
    case 'deviceDetailList' :
      return 'device-detail-list-header'
  }
} 

function getNameColumnHeader() {
  switch (props.sourceLocation) {
    case 'bulkAlerts' :
      return 'Alert Notification Group'
    case 'deviceDetailDialog' :
      return 'Alert Notification Group'
    case 'alertsListView' :
      return 'Group Name'
    case 'deviceDetailList' :
      return 'Group Name'
  }
}

const {
  alertNotificationGroups,
  showKnightRider,
  fetchAlertNotificationGroups,
  deleteAlertNotificationGroup,
  selectedAlertGroups,
} = useAlerts()
</script>

<template>
  <q-card
    :class="['full-width', 'q-pb-sm', 'rounded-borders', getSourceLocationHeaderClass()]"
    dark
  >
    <q-item :class="[getSourceLocationHeaderClass(), 'q-pb-none', 'text-left', 'text-subtitle1', 'text-bold', 'items-center']">
      <span class="col-1" v-if="!isListView"></span>
      <span :class="[getSourceLocationColumnSize('name'), 'items-start', 'ellipsis', 'q-mr-md']"> 
        {{ getNameColumnHeader() }}
      </span>
      <span v-if="isListView" :class="[getSourceLocationColumnSize('timestamp'), 'items-start', 'ellipsis', 'q-mr-md']">
        Created Timestamp
      </span>
      <span :class="[getSourceLocationColumnSize('recipients'), 'items-center', 'ellipsis', 'q-mr-md']">
        Number of Recipients
      </span>
      <span class="col items-start ellipsis q-mr-md">
        Description
      </span>
      <span v-if="isListView" class="col-1 items-center">
      </span>
    </q-item>

    <q-separator dark></q-separator>
    
    <div>
      <q-linear-progress
        v-if="showKnightRider"
        color="green-13"
        indeterminate
      ></q-linear-progress>
    </div>

    <div v-if="alertNotificationGroups.length && !showKnightRider" :class="getSourceLocationListWrapperClass()">
      <div
        v-for="group in alertNotificationGroups"
        :key="group.notificationGroupId"
        class="notify-group-list-items"
      >
        <q-item
          data-test="group"
          :class="[getSourceLocationListItemClass(), 'items-center']"
        >
          <div v-if="!isListView" class="col-1">
            <q-checkbox
              dark
              size="sm"
              color="green-13"
              v-model="selectedAlertGroups"
              :val="group"
            ></q-checkbox>
          </div>
          <span :class="[getSourceLocationColumnSize('name'), 'text-left', 'items-start', 'q-mr-md', 'ellipsis']">
            {{ group.name }}
            <q-tooltip :delay="500" anchor="bottom middle" self="center middle">{{ group.name }}</q-tooltip>
          </span>
          <span v-if="isListView" :class="[getSourceLocationColumnSize('timestamp'), 'text-left', 'items-start', 'q-mr-md', 'ellipsis']">
              {{ formatDateTimeString(group.createdAt) }}
          </span>
          <span :class="[getSourceLocationColumnSize('recipients'), 'items-center', 'q-mr-md']">
            <span @click="showRecipientsDialog(group)" class="text-green-13 cursor text-left text-no-wrap">
              {{ group.emails?.length || 0 }}
            </span>
          </span>
          <span class="col items-start text-left ellipsis q-mr-md">
            {{ group.description }}
            <q-tooltip :delay="500" anchor="bottom middle" self="center middle">{{ group.description }}</q-tooltip>
          </span>
          <div v-if="isListView" class="col-1">
            <q-btn-dropdown
              flat
              round
              dense
              icon="more_horiz"
            >
              <q-card dark>
                <q-list>
                  <q-item clickable v-close-popup @click="showCreateNotificationGroupDialog(group)">
                    <q-item-section>
                      <q-item-label>Edit</q-item-label>
                    </q-item-section>
                  </q-item>
                  
                  <q-item clickable v-close-popup @click="showDeleteDialog(group)">
                    <q-item-section>
                      <q-item-label>Delete</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-card>
            </q-btn-dropdown>
          </div>
        </q-item>
      </div>
    </div>
    <q-card
      v-if="!alertNotificationGroups.length && !showKnightRider"
      class="full-width q-mt-lg q-px-lg q-py-sm rounded-borders"
      :class="isNotifyGroupsListView ? 'no-groups' : 'no-groups-detail'"
      dark
    >
      <span v-if="isNotifyGroupsListView" class="flex justify-center items-center text-body1">
        Click on the
        <b>
          <q-icon size="sm" name="add"></q-icon>NOTIFICATION GROUP&nbsp;
        </b>
        button to create your first group
      </span>
      <span v-else class="flex justify-center items-center text-body1">
        Navigate to the&nbsp;
        <span @click="router.push('/notificationGroups')" class="text-bold cursor-pointer notification-group-link">
          NOTIFICATION GROUP
        </span>
        &nbsp;page to create your first group
      </span>
    </q-card>
  </q-card>

  <create-alert-notification-groug-dialog
    :showDialog="showNotifyDialog"
    @close-dialog="onCloseCreateDialog"
    :isEdit="true"
    :editNotificationGroup="selectedGroup"
  ></create-alert-notification-groug-dialog>

  <q-dialog v-model="recipientsDialog">
    <q-card
      class="q-py-md"
      style="width:40%;max-width:400px;"
      dark
    >
      <q-item-label class="text-h6 q-px-lg text-bold q-mb-sm">Recipients</q-item-label>
      <q-separator dark class="q-mb-xs"></q-separator>
      <div id="recipientsWrapper">
        <div v-if="selectedGroup.emails?.length">
          <div
            v-for="email in selectedGroup.emails.sort()" :key="email"
            class="block q-px-lg q-py-sm q-pb-sm recipient"
          >
            <span>{{ email }}</span>
          </div>
        </div>
        <div v-else class="block q-px-lg q-pb-sm recipient">
          <span>This notification group has no emails associated with it. You can click the 3 dot menu and edit this group to add your first recipients.</span>
        </div>
      </div>
    </q-card>
  </q-dialog>

  <q-dialog v-model="deleteDialog">
    <q-card dark id="deleteDiaolg">
      <div class="row">
        <q-space></q-space>
        <q-btn flat v-close-popup round dense icon="close" />
      </div>
      <q-card-section class="q-px-lg q-pb-md q-pt-none">
        <span class="text-h6">Are you sure you want to delete this notification group?</span>
      </q-card-section>

      <q-separator color="grey-9"></q-separator>

      <q-card-section class="row items-center q-pa-lg">
        <span class="text-body1">You are about to permanently delete the alert notification group named <b>{{ selectedDeleteGroup.name }}</b>. Do you want to continue?</span>
      </q-card-section>

      <q-separator color="grey-9"></q-separator>

      <q-card-actions class="q-px-lg q-py-md" align="right">
        <q-btn flat label="Cancel" color="white" v-close-popup />
        <app-btn
          color="red"
          text-color="white"
          label="Delete Notification Group"
          @click="onClickDeleteAlertNotificationGroup()"
        ></app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>
.alerts-list-wrapper {
  height: calc(40vh * .7);
  overflow-y: auto;
}
.alerts-list-wrapper-lg {
  height: calc(65vh * .7);
  overflow-y: auto;
}
.alerts-list-wrapper-xl {
  height: calc(80vh * .7);
  overflow-y: auto;
}
.device-detail-list-header {
  background-color: rgb(45,45,45);
}
.device-detail-line-item:nth-child(even) {
  background-color: rgb(45,45,45);
}
.bulk-alerts-line-item:nth-child(even) {
  background-color: rgb(35,35,35);
}
.alerts-list-view-line-item:nth-child(even) {
  background-color: rgb(35,35,35);
}
.device-detail-dialog-line-item:nth-child(even) {
  background-color: rgb(35,35,35);
}
#deleteDialog {
  min-width: 30rem;
}
.no-groups-detail {
  padding: 14vh 0vh;
}
.no-groups {
  padding: 27vh 0vh;
}
.notification-group-link {
  text-decoration: underline;
}
.notify-group-list-items:nth-child(even) {
  background-color: rgb(40,40,40);
}
</style>