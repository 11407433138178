<template>
  <q-card class="card q-mt-md q-py-md q-px-md" bordered>
    <q-item class="q-mb-md">
      <q-item-label class="text-weight-bold text-h6">
        Peripherals
      </q-item-label>
      <q-space></q-space>
      <PeripheralCreateForm
        @on-create="addPeripheral"
        type="create">
      </PeripheralCreateForm>
    </q-item>
    <div class="q-px-lg">
      <div 
        v-if="!config.peripherals" 
        class="flex justify-center text-subtitle1 items-center"
        style="min-height:40vh;"
      >
        <q-item-label class="q-pb-xl">
          Click the <span class="text-bold">
          <q-icon name="add" size="sm" class="q-pb-xs"></q-icon> 
          PERIPHERAL</span> button to add your first peripheral.
        </q-item-label>
      </div>
      <q-card
        v-else
        v-bind:key="peripheral"
        v-for="(peripheral, key) in config.peripherals"
        class="q-mt-none q-pa-lg q-mb-md"
        style="background-color: rgb(35, 35, 35);"
      >
        <q-list>
          <q-item class="q-pa-none q-mb-lg">
            <q-item-section>
              <q-item-label class="item-label">
                {{ peripheral.name }}
              </q-item-label>
              <div class="text-bold text-body2 q-mt-sm">
                <q-icon
                  style="margin-top:-5px;margin-left:-3px;"
                  size="sm" 
                  :color="getPeripheralState(key)?.isConnected ? 'green' : 'red'" 
                  name="fiber_manual_record"
                >
                </q-icon>
                {{ getPeripheralState(key)?.isConnected ? 'Connected' : 'Not Connected'  }}
              </div>
            </q-item-section>

            <q-item-section style="display:contents;">
              <q-btn-group rounded class="q-px-md">
                <PeripheralCreateForm
                  @on-create="addPeripheral"
                  :model="peripheral"
                  type="edit"
                ></PeripheralCreateForm>
                <q-separator class="q-mx-md" vertical inset dark></q-separator>
                <confirm-dialog
                  :value="key"
                  title="Remove Peripheral"
                  :tooltip="`Remove ${peripheral.name}`"
                  :message="`Are you sure you want to remove ${peripheral.name} from device?`"
                  buttonText="remove peripheral"
                  :dialogType="'delete'"
                  @confirm-selection="removePeripheral(peripheral)"
                ></confirm-dialog>
              </q-btn-group>
              <app-btn
                @click="onClickSendCommand(key)"
                label="Send Command"
                :isDisabled="isDisabled || isOffline"
                isSecondary
                color="green-13"
                class="q-ml-md"
              ></app-btn>
            </q-item-section>
          </q-item>

          <q-item class="items-center">
            <div class="col">
              <q-item-label class="text-subtitle2 text-weight-bold">
                Vendor ID
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label class="text-subtitle2 text-weight-bold">
                Baud Rate
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label class="text-subtitle2 text-weight-bold">
                Stop Bit
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label class="text-subtitle2 text-weight-bold">
                Flow Control
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label class="text-subtitle2 text-weight-bold">
                Parity
              </q-item-label>
            </div>
          </q-item>

          <q-separator dark></q-separator>

          <q-item class="items-center bg-grey-9" style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
            <div class="col">
              <q-item-label class="text-body1">
                {{ peripheral.vendorId }}
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label class="text-body1">
                {{ peripheral.baudRate }}
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label class="text-body1">
                {{ peripheral.stopBit }}
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label class="text-body1">
                {{ peripheral.flowControl }}
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label class="text-body1">
                {{ peripheral.parity }}
              </q-item-label>
            </div>
          </q-item>
        </q-list>

        <q-card-section class="q-mt-md" v-if="getPeripheralState(key)?.lastRequestAt">
          <div class="row q-mb-sm">
            <span class="col-auto">Last Request</span>
            <span class="col-1 flex justify-center q-ml-0"><q-separator dark vertical style="height:20px;" /></span>
            <span class="col-2">{{ formatDateTimeString(getPeripheralState(key)?.lastRequestAt) }}</span>
          </div>
          <div class="row" v-if="getPeripheralState(key)?.lastResponse">
            <span class="col-auto">Last Response</span>
            <span class="col-1 flex justify-center"><q-separator dark vertical style="height:20px;" /></span>
            <span class="col-2"> {{ getPeripheralState(key)?.lastResponse }}</span>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </q-card>
  
  <q-dialog v-model="commandDialog"  @hide="onHideDialog">
    <q-card 
      dark 
      class="q-pa-md"  
      style="width:80%;max-width:700px;"
    >
      <q-form>
        <q-card-section>
          <span class="text-h6 text-bold">
            Send Command To Peripheral
          </span>
        </q-card-section>
        <q-card-section tag="form">
          <q-input
            filled
            dark
            v-model="commandString"
            color="green-13"
            label="Enter Command"
            type="text"
            :rules="[rules.commandRule]"
            :loading="isLoading"
          >
          </q-input>
        </q-card-section>
        <q-card-actions align="right" class="q-px-md">
          <q-btn flat dark label="Cancel" v-close-popup />
          <app-btn
            label="send command"
            @click="sendCommand(key)"
            :isDisabled="!isValidCommand"
          >
          </app-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, toRef, computed } from 'vue'
import ConfirmDialog from '../components/ConfirmDialog.vue';
import { rules } from '@/utils/rulesUtils';
import { formatDateTimeString } from '@/utils/dateUtils';
import { parityOptions, flowControlOptions } from '../constants/constants'
import useCommands from '../composables/useCommands';
import AppBtn from '@/components/AppBtn';
import PeripheralCreateForm from '@/components/forms/PeripheralCreateForm';
import deviceServices from '../service/deviceServices';
import deviceJobs from '../service/deviceJobs';
import { useQuasar } from 'quasar';

export default {
  props: {
    state: Object,
    deviceConfig: Object,
    device: Object
  },
  components: {
    ConfirmDialog,
    AppBtn,
    PeripheralCreateForm,
  },
  setup(props) {
    const $q = useQuasar();
    const deviceState = toRef(props, "state");
    const config = ref(props.deviceConfig);
    const commandDialog = ref(false);
    let commandKey = ref('');
    let commandString = ref('');
    const isLoading = ref(false);
    
    const isValidCommand = computed(() => {
      return commandString.value.length >= 1;
    })
    
    const isOffline = computed(() => {
      return deviceState.value?.isOffline
    })

    function getPeripheralState(key) {
      if (deviceState.value?.peripheralState) {
        return deviceState.value?.peripheralState[key];
      } else {
        return {};
      }
    }

    async function addPeripheral(modelValue, isEdit) {
      isDisabled.value = true
      const notify = $q.notify({
        type: 'ongoing',
        message: getUpdatePeripheralMessage('ongoing', isEdit),
        spinner: true
      })

      const deviceQuery = {
        deviceKeys: [props?.device?.deviceKey]
      }

      try {
        const resp = await deviceJobs.addDevicePeripheral(deviceQuery, modelValue)
        if (resp.jobTraceId) {
          deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
            if (data){
              if (data.status === 'incomplete') {
                return
              }
              if (data.status === 'complete' && !data.error) {
                notify({
                  type: 'positive',
                  message: getUpdatePeripheralMessage('positive', isEdit),
                  spinner: false
                })
                config.value = await props.device.fetchDeviceConfig()
                isDisabled.value = false
                return
              }
              if (data.status === 'complete' && data.error) {
                notify({
                  type: 'negative',
                  message: getUpdatePeripheralMessage('negative', isEdit),
                  spinner: false
                })
                isDisabled.value = false
                return
              }
            }
          })
        } else {
          notify({
            type: 'negative',
            message: getUpdatePeripheralMessage('negative', isEdit),
            caption: `${resp.message}`,
            spinner: false
          })
          isDisabled.value = false
        }
      } catch (err) {
        console.error(err)
        notify({
          type: 'negative',
          message: getUpdatePeripheralMessage('negative', isEdit),
          spinner: false
        })
        isDisabled.value = false
      }
    }

    async function removePeripheral(peripheral) {
      const notify = $q.notify({
        type: 'ongoing',
        message: `Removing peripheral ${peripheral.name} from device...`,
        spinner: true
      })

      const deviceQuery = {
        deviceKeys: [props?.device?.deviceKey]
      }

      try {
        const resp = await deviceJobs.removeDevicePeripheral(deviceQuery, { name: peripheral.name })
        if (resp.jobTraceId) {
          deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
            if (data){
              if (data.status === 'incomplete') {
                return
              }
              if (data.status === 'complete' && !data.error) {
                notify({
                  type: 'positive',
                  message: `Successfully removed ${peripheral.name} from device`,
                  spinner: false
                })
                config.value = await props.device.fetchDeviceConfig()
                onHideDialog()
                isDisabled.value = false
                return
              }
              if (data.status === 'complete' && data.error) {
                notify({
                  type: 'negative',
                  message: `Failed to remove ${peripheral.name} from device`,
                  spinner: false
                })
                isDisabled.value = false
                return
              }
            }
          })
        } else {
          notify({
            type: 'negative',
            message: `Failed to remove ${peripheral.name} from device`,
            caption: `${resp.message}`,
            spinner: false
          })
          isDisabled.value = false
        }
      } catch (err) {
        console.error(err)
        notify({
          type: 'negative',
          message: `Failed to remove ${peripheral.name} from device`,
          spinner: false
        })
        isDisabled.value = false
      }
    }

    function onClickSendCommand(key) {
      commandKey.value = `peripheral/${key.trim()}/`;
      commandDialog.value = true;
    }

    async function sendCommand() {
      isLoading.value = true;
      const command = `${commandKey.value}${commandString.value.trim()}`;
      const deviceId = props.device.deviceId;
      const deviceQuery = {
        deviceIds: [deviceId],
      };
      await sendDeviceCommand(deviceQuery, command)
      commandDialog.value = false;
      isLoading.value = false;
    }

    function onHideDialog() {
      commandString.value = '';
      commandKey.value = '';
    }

    function getUpdatePeripheralMessage(type, isEdit) {
      if (isEdit) {
        switch (type) {
          case 'ongoing' :
            return `Updating peripheral...`
          case 'positive' :
            return `Successfully updated peripheral`
          case 'negative' :
            return `Failed to update peripheral`
        }
        return
      }
      switch (type) {
        case 'ongoing' :
          return 'Adding peripheral to device...'
        case 'positive' :
          return 'Successfully added peripheral to device'
        case 'negative' :
          return 'Failed to add peripheral to device'
      }
    }

    function getFlowControlLabel(flowControl) {
      switch (flowControl) {
        case 0 : 
          return 'Off'
        case 1 : 
          return 'rts_cts'
        case 2 : 
          return 'dsr_dtr'
        case 3 : 
          return 'xon_xoff'
      }
    }

    function getParityLabel(parity) {
      switch (parity) {
        case 0 : 
          return 'None'
        case 1 : 
          return 'Odd'
        case 2 : 
          return 'Even'
        case 3 : 
          return 'Mark'
        case 4 : 
          return 'Space'
      }
    }

    const {
      isDisabled,
      sendDeviceCommand
    } = useCommands()

    return {
      deviceState,
      config,
      isDisabled,
      isOffline,
      commandDialog,
      commandString,
      commandKey,
      rules,
      isValidCommand,
      addPeripheral,
      removePeripheral,
      onClickSendCommand,
      sendCommand,
      onHideDialog,
      parityOptions,
      flowControlOptions,
      getFlowControlLabel,
      getParityLabel,
      isLoading,
      getPeripheralState,
      formatDateTimeString,
    }
  },
};
</script>

<style scoped>
.card {
  text-align: left;
  color: white;
  background-color: rgb(30, 30, 30);
  margin-top: 1rem;
}
.item-label {
  font-weight: bolder;
  font-size: 1.3rem;
  color: white;
  min-width: 9rem;
}
.q-focus-helper {
  visibility: hidden;
}
.select-background {
  background-color: rgb(40,40,40);
}
</style>
