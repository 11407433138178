import useLabelFilters from './useLabelFilters'

export default function useBulkConfig() {

  function getDeviceQueryObject(queuedDisplays, isAllInTenantSelected, activeSearchString, activeLabelFilters) {
    let deviceQuery = {};
    if (
      isAllInTenantSelected &&
      !activeSearchString &&
      !activeLabelFilters.length
    ) {
      deviceQuery = {
        searchText: 'skAllDisplays',
      };
    } else if (
      isAllInTenantSelected &&
      (activeSearchString || activeLabelFilters)
    ) {
      const labelIds = getActiveLabelIds(activeLabelFilters);
      deviceQuery = {
        searchText: activeSearchString,
        labelIds,
      };
    } else {
      const displayIds = getQueuedDisplayIds(queuedDisplays);
      deviceQuery = {
        deviceKeys: displayIds,
      };
    }
    return deviceQuery;
  }

  function getQueuedDisplayIds(queuedDisplays) {
    if (queuedDisplays) {
      const arr = queuedDisplays.map((display) => display.displayId);
      return arr;
    }
    return [];
  }

  const {
    getActiveLabelIds
  } = useLabelFilters()

  return {
    getDeviceQueryObject
  }
}
