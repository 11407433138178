<script>
  // declare additional options
  export default {
    inheritAttrs: false,
    customOptions: {}
  }
</script>
<script setup>
import { ref, watch } from 'vue';
import { reportTypes } from '../constants/reports';
import deviceJobs from '../service/deviceJobs';
import deviceServices from '../service/deviceServices';
import { useQuasar } from 'quasar';
import AppBtn from '@/components/AppBtn'

const $q = useQuasar()
const showDialog = ref(false);
const step = ref(1);
const reportType = ref('');
const isDisabled = ref(false)

watch(() => reportType.value, (cur) => {
    if (cur) {
      step.value = 2;
    }
  }
)

async function onClickGenerateReport() {
  isDisabled.value = true
  showDialog.value = false;
  const notify = $q.notify({
    type: 'ongoing',
    message: 'Your report is being generated. This may take a while. You can download it once it\'s complete.',
    spinner: true
  })

  try {
    const resp = await deviceJobs.generateDeviceReport({
      reportName: reportType.value.value
    })
    if (resp.code === 400) {
      notify({
        type: 'negative',
        message: 'Unable to generate report',
        caption: resp.message,
        spinner: false
      })
      return
    }
    if (resp.jobTraceId) {
      deviceServices.listenForJobChanges(resp.jobTraceId, (data) => {
        if (data){
          if (data.status === 'incomplete') {
            return
          }
          if (data.status === 'complete' && !data.error) {
            notify({
              type: 'positive',
              message: `Successfully generated ${reportType.value.label}`,
              spinner: false
            })
            isDisabled.value = false
            return
          }
          if (data.status === 'complete' && data.error) {
            notify({
              type: 'negative',
              message: 'Unable to generate report',
              spinner: false
            })
            isDisabled.value = false
            return
          }
        }
      })
    }
  } catch(err) {
    console.error(err)
    notify({
      type: 'negative',
      message: 'Unable to generate report',
      spinner: false,
      caption: err
    })
    isDisabled.value = false
  }
}

function openReportsDialog() {
  showDialog.value = true
} 

function closeReportsDialog() {
  showDialog.value = false
  reportType.value = ''
}
</script>

<template>
  <app-btn
    @click="openReportsDialog"
    label="generate report"
    :isDisabled="isDisabled"
  >
  </app-btn>

  <q-dialog v-model="showDialog">
    <q-card dark style="width:80%;max-width:800px;" data-test="generate-report-dialog">
      <q-toolbar>
        <q-toolbar-title class="text-bold q-pa-md q-ml-md">Create Report</q-toolbar-title>
        <q-btn flat @click="closeReportsDialog" round dense icon="close" />
      </q-toolbar>

      <q-separator dark></q-separator>
      
      <q-card-section class="q-pa-none">
        <div class="row full-height">
          <div class="col-3">
            <q-stepper
              v-model="step"
              dark
              vertical
              color="green-13"
              flat
              animated
            >
              <q-step
                :name="1"
                title="Choose report Type"
                icon="one"
                :done="step > 1"
                color="green-13"
                class="q-pt-lg"
              >
              </q-step>

              <!-- Will uncomment in v2 as more steps are needed -->

              <!-- <q-step
                :name="2"
                title="Choose your character"
                icon="one"
                :done="step > 2"
                color="green-13"
              >
              </q-step>
              <q-step :name="3" title="Finish him" icon="one" color="green-13">
              </q-step> -->
            </q-stepper>
          </div>
          
          <q-separator class="q-pa-none" vertical dark></q-separator>

          <div class="col q-px-xl q-pt-lg">
            <q-select
              dark
              label="Choose a report type"
              :options="reportTypes"
              v-model="reportType"
              outlined
              color="green-13"
              style="margin-bottom:45vh"
              class="q-pt-md"
            >
              <template v-slot:selected>
                <span> {{ reportType.label }}</span>
              </template>
              <template v-slot:option="{ itemProps, opt }">
                <q-item v-bind="itemProps"  style="background-color: rgb(45,45,45);">
                  <q-item-section>
                    <q-item-label
                      class="text-bold text-body1"
                      v-html="opt.label"
                    />
                    <q-item-label
                      class="text-caption"
                      v-html="opt.description"
                    />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>
      </q-card-section>

      <q-separator dark></q-separator>
      <q-card-actions align="right" class="q-pa-md">
        <app-btn
          @click="onClickGenerateReport"
          label="generate"
          :isDisabled="!reportType || isDisabled"
        >
        </app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>
</style>
