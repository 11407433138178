<script setup>
import { ref } from 'vue'
import useBulkConfig from '@/composables/useBulkConfig'
import AppBtn from '../components/AppBtn'

// eslint-disable-next-line
const emit = defineEmits([
  'on-update-configs'
])
// eslint-disable-next-line
const props = defineProps({
  isValidInput: {
    type: Boolean,
    default: false
  }
})

const layout = ref(false)

function onClickUpdate() {
  isSaving.value = true
  setTimeout(() => {
    emit('on-update-configs')
    goBack()
    isSaving.value = false
  }, 1000)
}

function onClickGoBack() {
  if (!isDisabled.value) {
    layout.value = true
  } else {
    goBack()
  }
}

const {
  isDisabled,
  isSaving,
  goBack,
} = useBulkConfig()
</script>

<template>
  <div>
    <div>
      <q-separator dark></q-separator>
      <q-item class="q-pa-lg">
        <app-btn
        isSecondary
        icon="arrow_back"
        label="back"
        @click="onClickGoBack"
        ></app-btn>
        <q-space></q-space>
        <app-btn
        label="update"
        @click="onClickUpdate"
        :isDisabled="isDisabled || !props.isValidInput"
        :isLoading="isSaving"
        ></app-btn>
      </q-item>
    </div>

    <q-dialog v-model="layout">
      <q-card dark id="dialogCard" class="q-pa-sm">
        <div class="row">
          <q-space></q-space>
          <q-btn v-close-popup flat>
            <q-icon size="sm" name="close"></q-icon>
          </q-btn>
        </div>
        <q-card-section class="row q-pb-xs q-pt-none">
          <span class="text-h6">Are you sure you want to navigate away?</span>
        </q-card-section>

        <q-card-section class="row items-center q-mb-lg">
          <span class="text-body1">Your changes will not be saved</span>
        </q-card-section>

        <q-separator dark></q-separator>

        <q-card-actions class="q-py-md q-pl-xl" align="right">
          <q-btn class="q-mr-xl" flat label="Cancel" color="white" v-close-popup />
          <app-btn
            color="red"
            text-color="white"
            label="Yes, continue to navigate away"
            @click="goBack()"
          ></app-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style scoped>
#dialogCard {
  height: auto;
  width: fit-content;
}
</style>