<template>
  <!--State-->
  <q-card class="card q-mt-md" bordered>
    <div class="q-pa-md row justify-content">
      <q-list class="col" dense>

        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Property
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="text-weight-bold text-h6 text-right">
                Reported
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
        <q-separator dark class="q-mb-lg" />

        <!-- Network Type -->
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Network Type
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ deviceState.networkType }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
        <!-- IPv4 Address-->
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              IPv4 Address
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ deviceState.ip4Address }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
        <!-- MAC Address
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              MAC Address
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ deviceState.macAddress }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>-->
        <!-- MAC Address Ethernet -->
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              MAC Address Ethernet
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ deviceState.macAddressEthernet }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
        <!-- MAC Address Wifi -->
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              MAC Address Wifi
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ deviceState.macAddressWifi }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
        <!-- Wifi SSID -->
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Wifi SSID / Signal
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ deviceState.wifiSsid }} / {{ deviceState.wifiSignal }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
        <!-- Data Usage -->
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Data Usage <span class="text-body1">(In the last 24 hours)</span>
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ `${deviceState.dataUseMb24h} mb` }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </q-card>

  <!--Configuration-->
  <q-card class="card q-mt-md" bordered>
    <wifi-networks-component
      :deviceConfig="deviceConfig"
      :device="device"
    ></wifi-networks-component>
  </q-card>

  <!--State-->
  <q-card class="card q-mt-md" bordered>
    <div class="q-pa-md row justify-content">
      <q-list class="col" dense>
        <q-item class="q-mb-sm">
          <q-item-section class="col-4">
            <q-item-label class="text-weight-bold text-h6">
              Property
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-4">
            <q-item>
              <q-item-section class="text-weight-bold text-h6">
                Reported
              </q-item-section>
            </q-item>
          </q-item-section>
          <q-item-section class="col-4">
            <q-item>
              <q-item-section class="text-weight-bold text-right text-h6">
                Configure
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
        <q-separator dark class="q-mb-lg" />

        <q-item style="margin-bottom:auto;">
          <q-item-section class="col-4">
            <q-item-label class="text-weight-bold text-h6">
              Wifi Enabled
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-4 ellipsis">
            <q-item>
              <q-item-section class="stateValue">
                {{ deviceState.wifiEnabled }}
              </q-item-section>
            </q-item>
          </q-item-section>
          <q-item-section avatar class="col-4">
            <q-toggle
              size="lg"
              v-model="config.wifiEnabled"
              toggle-order="tf"
              checked-icon="check"
              color="green-13"
              :disable="isSavingDeviceConfig"
            />
          </q-item-section>
        </q-item>
        
        <q-item style="margin-bottom:auto;">
          <q-item-section class="col-4">
            <q-item-label class="text-weight-bold text-h6">
              Bluetooth
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-4 ellipsis">
            <q-item>
              <q-item-section class="stateValue">
                {{ deviceState.bluetoothEnabled }}
              </q-item-section>
            </q-item>
          </q-item-section>
          <q-item-section avatar class="col-4">
            <q-toggle
              size="lg"
              v-model="config.bluetoothEnabled"
              toggle-order="tf"
              checked-icon="check"
              color="green-13"
              :disable="isSavingDeviceConfig"
            />
          </q-item-section>
        </q-item>

        <q-item style="margin-bottom:auto;">
          <q-item-section class="col-4">
            <q-item-label class="text-weight-bold text-h6">
              Location Enabled
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-4 ellipsis">
            <q-item>
              <q-item-section class="stateValue">
                {{ deviceState.locationEnabled }}
              </q-item-section>
            </q-item>
          </q-item-section>
          <q-item-section avatar class="col-4">
            <q-toggle
              size="lg"
              v-model="config.locationEnabled"
              toggle-order="tf"
              checked-icon="check"
              color="green-13"
              :true-value="!config.locationEnabled || config.locationEnabled === true"
              :disable="isSavingDeviceConfig"
            />
          </q-item-section>
        </q-item>
        <q-item class="q-mb-md">
          <q-item-section>
            <q-item-label class="item-label">
              FOTA Setup
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-3">
            <div class="text-right">
              <confirm-dialog
                title="FOTA Setup"
                message="Are you sure you want to setup FOTA?"
                label="setup"
                dialogType="command"
                value="fotaSetup"
                @confirm-selection="sendCommand"
                :isDisabled="isOffline || isDisabled"
              >
              </confirm-dialog>
            </div>
          </q-item-section>
        </q-item>
        <q-item class="q-mb-md">
          <q-item-section>
            <q-item-label class="item-label">
              Modem Reset
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-3">
            <div class="text-right">
              <confirm-dialog
                title="Modem Reset"
                message="Are you sure you want to reset the modem?"
                label="reset"
                dialogType="command"
                value="resetModem"
                @confirm-selection="sendCommand"
                :isDisabled="isOffline || isDisabled"
              >
              </confirm-dialog>
            </div>
          </q-item-section>
        </q-item>
        <!-- IMEI -->
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              IMEI
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ deviceState.imei }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
        <!-- Sim -->
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Sim
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ deviceState.sim }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
        <!-- Modem Firmware -->
        <q-item class="q-mb-sm">
          <q-item-section>
            <q-item-label class="text-weight-bold text-h6">
              Modem Firmware
            </q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item>
              <q-item-section class="stateValue text-right">
                {{ deviceState.modemRilDriver }}
              </q-item-section>
            </q-item>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </q-card>
</template>

<script>
import { ref, toRef, watch, computed } from "vue";
import WifiNetworksComponent from "./WifiNetworksComponent.vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import useCommands from '../composables/useCommands';
import useDeviceConfig from '../composables/useDeviceConfig';

export default {
  components: {
    WifiNetworksComponent,
    ConfirmDialog,
  },
  props: {
    state: Object,
    deviceConfig: Object,
    device: Object,
  },
  emits: ["device-config-updated"],
  setup(props, { emit }) {
    const deviceState = toRef(props, "state");
    const config = ref(props.deviceConfig);
    const isOffline = computed(() => {
      return deviceState.value.isOffline
    })

    watch(
      config,
      (cur) => {
        emit("device-config-updated", cur);
      },
      { deep: true }
    );

    async function sendCommand(val) {
      let command = "";
      switch (val) {
        case "fotaSetup":
          command = "fotasetup";
          break;
        case "resetModem":
          command = "modemreset";
          break;
      }
      const deviceId = props.device.deviceId;
      const deviceQuery = {
        deviceIds: [deviceId],
      };
      sendDeviceCommand(deviceQuery, command)
    }

    const {
      isDisabled,
      sendDeviceCommand
    } = useCommands();

    const {
      isSavingDeviceConfig,
    } = useDeviceConfig();

    return {
      deviceState,
      config,
      props,
      isOffline,
      isDisabled,
      sendCommand,
      isSavingDeviceConfig,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
  color: white;
  background-color: rgb(30, 30, 30);
  margin-top: 1rem;
}
.item-label {
  font-weight: bolder;
  font-size: 1.2rem;
  color: white;
  min-width: 9rem;
}
.stateValue {
  font-size: 1.2em;
}
.command-button {
  height: 10px !important;
}
</style>
