import script from "./ActiveLabelFilterComponent.vue?vue&type=script&setup=true&lang=js"
export * from "./ActiveLabelFilterComponent.vue?vue&type=script&setup=true&lang=js"

import "./ActiveLabelFilterComponent.vue?vue&type=style&index=0&id=1acce2ca&lang=css"

const __exports__ = script;

export default __exports__
import QItem from 'quasar/src/components/item/QItem.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QChip});
