import { gql } from 'apollo-boost';

const listDisplays = gql`
  query listDisplays ($tenantGid: String!, $sort: String, $after: String, $first: Int) {
    displays(tenantGid: $tenantGid, sort: $sort, after: $after, first: $first) {
      edges {
        node {
          labelGids
          displayId
          fallbackMode
          id
          location
          name
          serialNumber
          unmanaged
          tenantGid
          status {
            id
            heartbeatIntervalMs
            playingMode
            stateUpdateTime
            isOffline
            controlVersionCode
            controlVersionName
            playerVersion
            playerVersionCode
            playingContentGid
            model
            manufacturer
          }
          controlState {
            isOffline
            macAddress
            macAddressEthernet
            macAddressWifi
            osVersion
            networkType
            manufacturer
            model
            serialNumber
            stateReceiveTime
            stateUpdateTime
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const findDisplayListQuery = gql`
  query FindDisplays ($tenantGid: String!, $query: String, $sort: String, $after: String, $first: Int, $labelGids: [String]) {
    findDisplays(tenantGid: $tenantGid, query: $query, sort: $sort, after: $after, first: $first, labelGids: $labelGids) {
      edges {
        node {
          labelGids
          displayId
          fallbackMode
          id
          location
          name
          serialNumber
          unmanaged
          tenantGid
          status {
            id
            heartbeatIntervalMs
            playingMode
            stateUpdateTime
            isOffline
            controlVersionCode
            controlVersionName
            playerVersion
            playerVersionCode
            playingContentGid
            model
            manufacturer
          }
          controlState {
            isOffline
            macAddress
            macAddressEthernet
            macAddressWifi
            osVersion
            networkType
            manufacturer
            model
            serialNumber
            stateReceiveTime
            stateUpdateTime
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`

const updateDisplayMutation = gql`
  mutation updateDisplay ($input: UpdateDisplayInput!) {
    updateDisplay(input: $input) {
      labelGids
      displayId
      fallbackMode
      id
      location
      name
      serialNumber
      unmanaged
      tenantGid
      status {
        id
        heartbeatIntervalMs
        playingMode
        stateUpdateTime
        isOffline
        controlVersionCode
        controlVersionName
        playerVersion
        playerVersionCode
        playingContentGid
        model
        manufacturer
      }
      controlState {
        isOffline
        macAddress
        macAddressEthernet
        macAddressWifi
        osVersion
        networkType
        manufacturer
        model
        serialNumber
        stateReceiveTime
        stateUpdateTime
      }
    }
  }
`

export {
  listDisplays,
  findDisplayListQuery,
  updateDisplayMutation
}