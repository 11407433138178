<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import AppBtn from '../components/AppBtn'
import TimezoneSelect from '../components/TimezoneSelect'
import { rotationOptions } from '../constants/constants.js'
import get from 'lodash/get'
import * as Papa from 'papaparse'
import deviceJobs from '../service/deviceJobs'
import deviceServices from '../service/deviceServices'
import { useQuasar } from 'quasar'
import { getTenantGid } from '@/store'

// eslint-disable-next-line no-undef
const emit = defineEmits([
  'refresh-device-list'
])

const $q = useQuasar()
const router = useRouter()
const tenantGid = computed(() => {
  return getTenantGid()
})
const models = ref([])
const flavors = ref([])
const preregDialog = ref(false)
const options = ref ({
  model: '',
  timezone: '',
  rotation: 0,
  flavor: '',
  contentGid: '',
  csvName: ''
})
const validCsvHeaders = ([
  'serial',
  'name',
  'location'
])
const parsedCsvResults = ref([])
const isValidForm = computed(() => {
  if (!options.value.model || !parsedCsvResults.value?.length) {
    return false
  }
  return true
})
const rules = ref({
  required: v => !!v || 'This is a required field'
})

onMounted(() => {
  deviceServices.onAuthStateChanged(async (user) => {
    if (user) {
      deviceServices.fetchDeviceModels((data) => {
        models.value = data
      })
      deviceServices.fetchDeviceFlavors((data) => {
        flavors.value = data
      })
    } else {
      console.debug('Unable to fetch device models, user not ready');
    }
  })
})

onBeforeUnmount(() => {
  closePreregDialog()
})

function showPreregDialog() {
  preregDialog.value = true
}

function closePreregDialog() {
  preregDialog.value = false
  options.value = {
    model: '',
    timezone: '',
    rotation: 0,
    flavor: '',
    contentGid: '',
    csvName: ''
  }
  clearSelectedCsv()
}

function clearSelectedCsv() {
  options.value.csvName = ''
  parsedCsvResults.value = []
  const input = document.getElementById('fileInput')
  if (input) input.value = ''
}

function updateTimezone(val) {
  options.value.timezone = val
}

async function handleSelectedFile(data) {
  const file = get(data, 'target.files[0]')
  parseCsvFile(file, (csvLineItems) => {
    if (csvLineItems.length) {
      options.value.csvName = file.name
      parsedCsvResults.value = csvLineItems
    } else {
      $q.notify({
        type: 'negative',
        message: 'Either there are no items in the CSV or the headers don\'t match the required format',
        caption: '<span>Please make sure that each line item has a valid serial, and that only <span class="text-body2"><b>serial</b>, <b>name</b>, and <b>location</b></span> are used as headers</span>',
        html: true
      })
      clearSelectedCsv()
    }

  })
}

function parseCsvFile(file, callback) {
  Papa.parse(file, {
    header: true,
    complete: function (results) {
      const validatedItems = validateCsvLineItems(results.data)
      callback(validatedItems)
    }
  })
}

function validateCsvLineItems(arr) {
  const validLineItems = []
  if (arr && arr.length) {
    arr.forEach(lineItem => {
      // check that serial has a value and if all line item headers match expected values (serial, name, location)
      if (lineItem.serial) {
        if (Object.keys(lineItem).every(el => validCsvHeaders.includes(el))) {
          validLineItems.push(lineItem)
        }
      }
    })
    return validLineItems
  } else {
    return []
  }
}

async function registerDevices() {
  preregDialog.value = false
  const notify = $q.notify({
    type: 'ongoing',
    spinner: true,
    message: 'Starting pre-registration process...'
  })

  try {
    options.value['tenantGid'] = tenantGid.value
    const resp = await deviceJobs.preRegisterDevices(options.value, parsedCsvResults.value)
    if (resp.jobTraceId) {
      deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
        if (data){
          if (data.status === 'incomplete') {
            return
          }
          if (data.status === 'complete' && !data.error) {
            notify({
              type: 'positive',
              message: 'Pre-registration is in progress. You can view your progress in the Bulk Job View page',
              actions: [{ label: 'View Job Status', color: 'white', handler: () => { router.push({name: 'JobStatus'}) } }],
              spinner: false
            })
            closePreregDialog()
            // Give PS enought time to create the device before refetching
            setTimeout(() => {
              emit('refresh-device-list')
            }, 750)
            return
          }
          if (data.status === 'complete' && data.error) {
            notify({
              type: 'negative',
              message: 'Unable to pre-register devices',
              caption: data.error,
              spinner: false
            })
            console.error(data.error)
            return
          }
        }
      })
    } else {
      notify({
        type: 'negative',
        message: 'Unable to pre-register devices',
        spinner: false
      })
      console.error(resp)
    }
  } catch (err) {
    notify({
      type: 'negative',
      message: 'Unable to pre-register devices',
      spinner: false
    })
    console.error(err)
  }
}
</script>

<template>
  <app-btn
    label="pre-register devices"
    :isSecondary="true"
    @click="showPreregDialog"
  >
  </app-btn>

  <q-dialog v-model="preregDialog" persistent>
    <q-card dark style="width:80%;max-width:1000px;">
      <q-toolbar>
        <q-toolbar-title class="text-bold q-pa-md q-ml-md">Pre-Register Devices</q-toolbar-title>
        <q-btn flat @click="closePreregDialog" round dense icon="close" />
      </q-toolbar>

      <q-separator dark></q-separator>
      
      <div class="q-px-lg">

        <q-card-section class="q-px-xl">
          <div class="q-mb-sm q-mt-md">
            <span class="text-subtitle1 text-bold">Enter the configurations you would like your device to have</span>
          </div>

          <q-card dark>
            <div class="row q-col-gutter-md q-my-lg">
              <div class="col-6">
                <q-item-label class="q-mb-sm">
                  Device Model*
                </q-item-label>
                <q-select
                  dark
                  label="Select device model"
                  color="white"
                  dense
                  outlined
                  :options="models"
                  v-model="options.model"
                  :rules="[rules.required]"
                  no-error-icon
                  hide-bottom-space
                ></q-select>
              </div>
              <div class="col-6">
                <q-item-label class="q-mb-sm">
                  Timezone
                </q-item-label>
                <timezone-select @tzUpdated="updateTimezone"></timezone-select>
              </div>
            </div>
            
            <div class="row q-col-gutter-md q-my-lg">
              <div class="col-6">
                <q-item-label class="q-mb-sm">
                  Rotation 
                </q-item-label>
                <q-select
                  dark
                  label="Select a rotation"
                  color="white"
                  dense
                  outlined
                  :options="rotationOptions"
                  v-model="options.rotation"
                  no-error-icon
                  hide-bottom-space
                  map-options
                  emit-value
                ></q-select>
              </div>
              <div class="col-6">
                <q-item-label class="q-mb-sm">
                  Flavor
                </q-item-label>
                <q-select
                  dark
                  label="Select flavor"
                  color="white"
                  dense
                  outlined
                  :options="flavors"
                  v-model="options.flavor"
                  hide-bottom-space
                ></q-select>
              </div>
            </div>
            
            <div class="row q-col-gutter-md q-my-lg">
              <div class="col">
                <q-item-label class="q-mb-sm">
                  Content GID
                </q-item-label>
                <q-input
                  dark
                  label="Enter Content GID"
                  color="white"
                  dense
                  outlined
                  v-model="options.contentGid"
                  hide-bottom-space
                  no-error-icon
                ></q-input>
              </div>
            </div>
          </q-card>
        </q-card-section>

        <q-card-section class="q-px-xl">
          <div class="q-mb-sm q-mt-sm">
            <span class="text-subtitle1 text-bold">Upload a list of devices you would like to pre-register</span>
          </div>
          <div class="q-my-md">
            <q-icon
              name="upload_file"
              size="sm"
              class="q-mr-sm"
              style="margin-left: -4px;"
            >
            </q-icon>
            <span class="text-bold text-body2">Upload a CSV</span>
          </div>
          <div class="row q-col-gutter-sm q-my-md items-center">
            <div class="col-auto">
                <label class="manual-file-input-container">
                  <input
                    type="file"
                    class="manual-file-input"
                    @change="handleSelectedFile"
                    accept=".csv"
                    id="fileInput"
                  >
                  <span class="text-subtitle2">SELECT FILE</span>
                </label>
            </div>
            <div class="col full-height">
              <q-input
                dark
                label="Upload a CSV File"
                color="white"
                dense
                outlined
                v-model="options.csvName"
                hide-bottom-space
                no-error-icon
                clearable
                readonly
              >
                <template v-slot:append>
                  <q-icon v-if="options.csvName" name="close" @click.stop="clearSelectedCsv" class="cursor-pointer" />
                </template>
              </q-input>
            </div>
          </div>
        </q-card-section>
      </div>

      <q-separator dark class="q-mt-xl"></q-separator>
      <q-card-actions align="right" class="q-pa-md">
        <app-btn
          label="next"
          :isDisabled="!isValidForm"
          @click="registerDevices"
        >
        </app-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>
.manual-file-input {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
}
.manual-file-input-container {
  background: #00e576;
  cursor: pointer;
  padding: 12px 20px;
  border-radius: 3px;
  color: black;
}
</style>