<script setup>
import { ref, watch } from 'vue';

//eslint-disable-next-line no-undef
const props = defineProps({ 
  displays: {
    type: Array
  },
  queuedDisplays: {
    type: Array
  }
});

//eslint-disable-next-line no-undef
const emit = defineEmits({ 
  selectAllClicked: {
    default: ''
  }
});

watch(() => props.queuedDisplays, (cur, prev) => {
  if (cur?.length && cur?.length < props.displays?.length && prev?.length === props.displays?.length) {
    iconClass.value = 'indeterminate'
    emit('selectAllClicked', 'indeterminate')
  }
  if (prev?.length && !cur?.length) {
    iconClass.value = 'unchecked'
    emit('selectAllClicked', 'unchecked')
  }
})

const iconClass = ref('unchecked')

function handleClick() {
  if (iconClass.value === 'unchecked') {
    iconClass.value = 'checked'
    emit('selectAllClicked', 'checked')
  } else if (iconClass.value === 'checked') {
    iconClass.value = 'unchecked'
    emit('selectAllClicked', 'unchecked')
  } else if (iconClass.value === 'indeterminate') {
    iconClass.value = 'unchecked'
    emit('selectAllClicked', 'unchecked')
  }
}

function getIconContainerClass() {
  return iconClass.value !== 'checked' ? 'icon-container' : 'icon-container-checked'
}
</script>

<template>
  <div :class="iconClass === 'checked' ? 'app-check-box-checked' : 'app-check-box'" @click="handleClick()">
    <div :class="getIconContainerClass()">
      <span
        class="icon-checked"
        v-if="iconClass === 'checked'"
      >
        <q-icon name="done" class="checked-icon"></q-icon>
      </span>
      <span
        class="icon-indeterminate"
        v-if="iconClass === 'indeterminate'"
      >
       -
      </span>
      <span
        class="icon-unchecked"
        v-if="iconClass === 'unchecked'"
      ></span>
    </div>
  </div>
</template>

<style scoped>
.app-check-box {
  display: flex;
  justify-content: flex-end;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.app-check-box:hover {
  background: #5c5c5c;
  transition: 500ms;
  cursor: pointer;
}
.app-check-box-checked {
  display: flex;
  justify-content: flex-end;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.app-check-box-checked:hover {
  background: rgb(48,211,116,.5);
  transition: 500ms;
}
.icon-container {
  border: 2px solid;
  border-radius: 2px;
  border-color: rgb(205,205,205);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 7px;
  margin-top: 8px;
  height: 5px;
  width: 5px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  margin-left: 1px;
  transform: scale(.9);
}
.icon-container-checked {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-top: 8px;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  margin-left: 1px;
  background-color: rgb(48,211,116);
}
.icon-container-checked:hover {
  cursor: pointer;
}
.checked-icon {
  transform: scale(1.3);
}
</style>