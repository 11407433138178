import { ref, computed, reactive } from 'vue';
import { getLabelCategories } from '../store';

const activeLabelFilters = ref([]);
const queuedLabels = ref([]);
const labelCategories = computed(() => {
  return getLabelCategories();
});
const allLabels = computed(() => {
	const labelsArr = [];
	labelCategories.value?.forEach(cat => {
    labelsArr.push(...cat?.labels);
	})
	return labelsArr
})
let labelGidsHashObj = reactive({})

export default function useLabelFilters() {
	function getCategoryNameByLabel(label) {
		return labelCategories.value?.filter(cat =>cat?.id === label.categoryGid)[0]?.name;
	}

  function getLabelsAssignedToDisplay(display) {
    const labelsArr = [];
    if (display?.labelGids?.length) {
      display.labelGids.forEach(gid => {
        allLabels.value.forEach((el) => {
          if (el.id === gid) {
            labelsArr.push(el)
          }
        })
      });
      // returns labels in alphabetical order
      labelsArr.sort((a, b) => a.name.localeCompare(b.name))
    }
    
    return labelsArr.map(el => el.id);
  }

  function getActiveLabelIds(labels) {
    if (labels) {
     const arr = labels.map(label =>  label.id)
     return arr
    }
    return []
  }

  function getBulkAddLabelGids() {
    return Object.keys(labelGidsHashObj).filter(k => labelGidsHashObj[k] === 'add')
  }
  
  function getBulkDeleteLabelGids() {
    return Object.keys(labelGidsHashObj).filter(k => labelGidsHashObj[k] === 'delete')
  }

  function clearLabelHashObj() {
    labelGidsHashObj = {}
  }

  function clearActiveLabelFilters() {
    activeLabelFilters.value = [];
  }
	
	return {
		allLabels,
    activeLabelFilters,
    queuedLabels,
    labelGidsHashObj,
    getCategoryNameByLabel,
    getLabelsAssignedToDisplay,
    getActiveLabelIds,
    getBulkAddLabelGids,
    getBulkDeleteLabelGids,
    clearLabelHashObj,
    clearActiveLabelFilters
  };
}
