import { ref } from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';
import deviceJobs from "../service/deviceJobs"
import deviceServices from '../service/deviceServices'
import { commandMappings } from '../constants/constants'
import { useRouter } from 'vue-router'

export default function useCommands() {
  const $q = useQuasar()
  const isDisabled = ref(false)
  const commandMap = ref('')
  const appName = ref(false)
  const router = useRouter()
  
  async function sendDeviceCommand(deviceQuery, command, bulkCommandObj, directAppData) {
    isDisabled.value = true

    // Determines if command came from bulk action dialog and if more than one device was in the list.
    // Helps set the appropriate messaging
    const isBulkCommand = bulkCommandObj?.isBulkCommand
    const isMultipleDevices = getIsMultipleDevices(deviceQuery) ||  bulkCommandObj?.isMultipleDevices

    setCommandMessageMapKey(command)

    const notify = $q.notify({
      type: 'ongoing',
      message: setMessage('start', isBulkCommand, isMultipleDevices),
      html: true,
      spinner: true
    })
    
    try {
      const resp = await deviceJobs.sendDeviceCommand(deviceQuery, command, directAppData)
      if (resp && resp.jobTraceId) {
        deviceServices.listenForJobChanges(resp.jobTraceId, (data) => {
          if (data){
            if (data.status === 'incomplete') {
              return
            }
            if (data.status === 'complete' && !data.error) {
              notify({
                type: 'positive',
                message: setMessage('success', isBulkCommand, isMultipleDevices),
                actions: setActions(isMultipleDevices),
                html: true,
                spinner: false
              })
              isDisabled.value = false
              return
            }
            if (data.status === 'complete' && data.error) {
              notify({
                type: 'negative',
                message: setMessage('fail', isBulkCommand, isMultipleDevices),
                spinner: false
              })
              isDisabled.value = false
              return
            }
          }
        })
      } else {
        notify({
          type: 'negative',
          message: setMessage('fail', isBulkCommand, isMultipleDevices),
          spinner: false
        })
        isDisabled.value = false
      }
    } catch(err) {
      console.error(err)
      notify({
        type: 'negative',
        message: 'Unable to send command',
        spinner: false,
        caption: err
      })
      isDisabled.value = false
      appName.value = ''
    }
  }

  function getIsMultipleDevices(deviceQuery) {
    return deviceQuery?.deviceKeys?.length > 1
  }

  function setMessage(type, isBulkCommand, isMultipleDevices) {
    if (isBulkCommand) {
      switch (type) {
        case 'start':
          return `<span>Sending command <b>${commandMappings[commandMap.value]?.name?.toUpperCase()}</b> to selected ${isMultipleDevices ? 'devices' : 'device'}</span>`

        case 'success':
          return `<span>Successfully sent command <b>${commandMappings[commandMap.value]?.name?.toUpperCase()}</b> to selected ${isMultipleDevices ? 'devices' : 'device'}</span>`

        case 'fail':
          return `<span>Failed to send command <b>${commandMappings[commandMap.value]?.name?.toUpperCase()}</b> to selected ${isMultipleDevices ? 'devices' : 'device'}</span>`
      }
      return;
    }
    switch (type) {
      case 'start':
        return appName.value
          ? `${commandMappings[commandMap.value].start} ${appName.value}`
          : commandMappings[commandMap.value].start;

      case 'success':
        return appName.value
          ? `${commandMappings[commandMap.value].success} ${appName.value}`
          : commandMappings[commandMap.value].success;

      case 'fail':
        return appName.value
          ? `${commandMappings[commandMap.value].fail} ${appName.value}`
          : commandMappings[commandMap.value].fail;
    }
  }

  function setActions(isMultipleDevices) {
    return isMultipleDevices ?
    [
      { label: 'View Job Status', color: 'white', handler: () => { router.push({name: 'JobStatus'}) } }
    ] 
    : []
  }

  async function fetchDeviceCommands() {
    const resp = await deviceServices.fetchCommands();
    if (resp) {
      let arr = [];
      Object.keys(resp.data).forEach(key => {
        resp.data[key]['value'] = key.replace(/%2F/g, '/').replace(/%2E/g, '.')
        resp.data[key]['label'] = resp.data[key].name
        arr.push(resp.data[key])
      })
      return arr
    } else {
      $q.notify({
        type: 'negative',
        message: 'Unable to fetch list of commands'
      })
    }
  }

  function setCommandMessageMapKey(command) {
    switch (command) {
      case 'ota/download' :
        commandMap.value = 'otaDownload'
        break
      case 'ota/install' :
        commandMap.value = 'otaInstall'
        break
      case 'ota/update' :
        commandMap.value = 'otaUpdate'
        break
      case 'ota/delete' :
        commandMap.value = 'otaDelete'
        break
      case 'apk/all' :
        commandMap.value = 'apkAll'
        break
      case 'apk/control' :
        commandMap.value = 'apkControl'
        break
      case 'apk/player' :
        commandMap.value = 'apkPlayer'
        break
      case 'apk/home' :
        commandMap.value = 'apkHome'
        break
      case 'reset/all' :
        commandMap.value = 'resetAll'
        break
      case 'reset/control' :
        commandMap.value = 'resetControl'
        break
      case 'reset/player' :
        commandMap.value = 'resetPlayer'
        break
      case 'reset/home' :
        commandMap.value = 'resetHome'
        break
      case 'start/com.skykit.control' :
        commandMap.value = 'launchControl'
        break
      case 'start/com.skykit.home' :
        commandMap.value = 'launchHome'
        break
      case 'start/com.skykit.player' :
        commandMap.value = 'launchPlayer'
        break
      case 'start/com.teamviewer.host.market' :
        commandMap.value = 'launchTeamViewerHost'
        break
      case 'start/com.teamviewer.quicksupport.market' :
        commandMap.value = 'launchTeamViewerQuick'
        break
      default : 
        commandMap.value = command
      break
    }

    if (command?.substr(0, command?.indexOf('/')) === 'uninstall') {
      commandMap.value = 'uninstall'
      appName.value = command.split('/')[1]
    }

    if (!commandMappings[commandMap.value]) {
      commandMap.value = 'default'
    }
  }
 
  return {
    isDisabled,
    sendDeviceCommand,
    fetchDeviceCommands
  }
}
