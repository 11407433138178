export const channelOptions = [
  {
    label: "Alpha",
    value: "alpha",
    index: 0
  },
  {
    label: "Beta",
    value: "beta",
    index: 1
  },
  {
    label: "Production",
    value: "prod",
    index: 2
  }
];
export const otaBuildTypeOptions = [
  {
    label: "User",
    value: "user"
  },
  {
    label: "User Debug",
    value: "userdebug"
  }
];
export const rotationOptions = [
  {label: '0 degrees', value: 0}, {label: '90 degrees', value: 90}, {label: '180 degrees', value: 180}, {label: '270 degrees', value: 270}
];

export const logLevelOptions = [
  {label: '100 - Debug', value: 100, description: 'Report all logs'},
  {label: '200 - Info', value: 200, description: 'Report info, notice, warning and error logs'},
  {label: '300 - Notice', value: 300, description: 'Report notice, warning and error logs'},
  {label: '400 - Warning', value: 400, description: 'Report warning and error logs'},
  {label: '500 - Error', value: 500, description: 'Report error logs only'}
];

export const commandMappings = {
  // Status
  reboot: {
    name: 'Reboot System',
    start: 'Rebooting the system',
    success: 'Successfully rebooted the system',
    fail: 'Failed to reboot the system'
  },
  resetpw: {
    name: 'Reset Password',
    start: 'Resetting password',
    success: 'Successfully reset password',
    fail: 'Failed to reset password'
  },
  // Network
  fotasetup: {
    name: 'Setup FOTA',
    start: 'Setting up FOTA',
    success: 'Successfully set up FOTA',
    fail: 'Failed to set up FOTA'
  },
  resetmodem: {
    name: 'Reset Modem',
    start: 'Resetting modem',
    success: 'Successfully reset modem',
    fail: 'Failed to reset modem'
  },
  // Firmware
  otaDownload: {
    name: 'Download Firmware',
    start: 'Downloading firmware',
    success: 'Successfully downloaded firmware',
    fail: 'Failed to download firmware'
  },
  otaUpdate: {
    name: 'Update Firmware',
    start: 'Updating firmware',
    success: 'Successfully updated firmware',
    fail: 'Failed to update firmware'
  },
  otaInstall: {
    name: 'Install Firmware',
    start: 'Installing firmware',
    success: 'Successfully installed firmware',
    fail: 'Failed to install firmware'
  },
  otaDelete: {
    name: 'Delete Firmware',
    start: 'Deleting firmware',
    success: 'Successfully deleted firmware',
    fail: 'Failed to delete firmware'
  },
  // Apps
  apkAll: {
    name: 'Update All Apps',
    start: 'Updating all available apps',
    success: 'Command received. Starting to update all apps',
    fail: 'Failed to start updating all apps'
  },
  apkControl: {
    name: 'Update Control',
    start: 'Updating Control',
    success: 'Command received. Starting to update Control',
    fail: 'Failed to start updating Control'
  },
  apkPlayer: {
    name: 'Update Player',
    start: 'Updating Player',
    success: 'Command received. Starting to update Player',
    fail: 'Failed to start updating Player'
  },
  apkHome: {
    name: 'Update Home',
    start: 'Updating Home',
    success: 'Command received. Starting to update Home',
    fail: 'Failed to start updating Home'
  },
  launchControl: {
    name: 'Launch Control',
    start: 'Launching Control',
    success: 'Successfully launched Control',
    fail: 'Failed to launch Control'
  },
  launchPlayer: {
    name: 'Launch Player',
    start: 'Launching Player',
    success: 'Successfully launched Player',
    fail: 'Failed to launch Player'
  },
  launchHome: {
    name: 'Launch Home',
    start: 'Launching Home',
    success: 'Successfully launched Home',
    fail: 'Failed to launch Home'
  },
  launchTeamViewerHost: {
    name: 'Launch Team Viewer Host',
    start: 'Launching Team Viewer Host',
    success: 'Successfully launched Team Viewer Host',
    fail: 'Failed to launch Team Viewer Host'
  },
  launchTeamViewerQuick: {
    name: 'Launch Team Viewer Quick Support',
    start: 'Launching Team Viewer Quick Support',
    success: 'Successfully launched Team Viewer Quick Support',
    fail: 'Failed to launch Team Viewer Quick Support'
  },
  reset: {
    name: 'Reset App',
    start: 'Resetting',
    success: 'Successfully reset',
    fail: 'Failed to reset'
  },
  resetAll: {
    name: 'Reset All Apps',
    start: 'Resetting all apps',
    success: 'Successfully reset all apps',
    fail: 'Failed to reset all apps'
  },
  resetHome: {
    name: 'Reset Home',
    start: 'Resetting Home',
    success: 'Successfully reset Home',
    fail: 'Failed to reset Home'
  },
  resetPlayer: {
    name: 'Reset Player',
    start: 'Resetting Player',
    success: 'Successfully reset Player',
    fail: 'Failed to reset Player'
  },
  resetControl: {
    name: 'Reset Control',
    start: 'Resetting Control',
    success: 'Successfully reset Control',
    fail: 'Failed to reset Control'
  },
  uninstall: {
    name: 'Uninstall App',
    start: 'Uninstalling',
    success: 'Successfully uninstalled',
    fail: 'Failed to uninstall'
  },
  // Screenshot
  screenshot: {
    name: 'Take Screenshot',
    start: 'Taking screenshot',
    success: 'Successfully took screenshot',
    fail: 'failed to take screenshot'
  },
  // Default
  default: {
    name: '',
    start: 'Sending command to device',
    success: 'Successfully sent command to device',
    fail: 'Failed to send command to device'
  },
  bulkDefault: {
    name: '',
    start: 'Sending command to selected devices',
    success: 'Successfully sent command to selected devices',
    fail: 'Failed to send command to selected devices'
  },
}

export const rebootOptions = [
  {
    label: 'Reboot Daily',
    value: 0,
  },
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
  },
  {
    label: 'Thursday',
    value: 4,
  },
  {
    label: 'Friday',
    value: 5,
  },
  {
    label: 'Saturday',
    value: 6,
  },
  {
    label: 'Sunday',
    value: 7,
  },
]

export const parityOptions = [
  {label: 'None', value: 0},
  {label: 'Odd', value: 1},
  {label: 'Even', value: 2},
  {label: 'Mark', value: 3},
  {label: 'Space', value: 4},
]

export const flowControlOptions = [
  {label: 'Off', value: 0},
  {label: 'rts_cts', value: 1},
  {label: 'dsr_dtr', value: 2},
  {label: 'xon_xoff', value: 3},
]

export const densityOptions = [
  {label: 'Device Default', value: -1},
  {label: '120 dpi', value: 120},
  {label: '160 dpi', value: 160},
  {label: '240 dpi', value: 240},
  {label: '320 dpi', value: 320},
  {label: '480 dpi', value: 480},
  {label: '640 dpi', value: 640}
]

// Value is length of label in minutes / 2
export const heartbeatOptions = [
  {label: '10 minutes', value: 5},
  {label: '30 minutes', value: 15},
  {label: '1 hour', value: 30},
  {label: '4 hours', value: 120},
  {label: '12 hours', value: 360},
  {label: '24 hours', value: 720}
]

export const alertActionOptions = [
  {label: 'Add Alert Notification Groups to Selected Devices', value: 'addNotificationGroups'},
  {label: 'Remove Alert Notification Groups to Selected Devices', value: 'removeNotificationGroups'},
  {label: 'Mute Alerts', value: 'muteAlerts'},
  {label: 'Unmute Alerts', value: 'unmuteAlerts'}
]

export const alertEventTypes = [
  // Removing for initial release. Will be re-added in v2 

  // {
  //   label: 'Device: Boot',
  //   value: 'boot',
  //   description: 'Alert when device is booted'
  // },
  {
    label: 'Device: Offline',
    value: 'offline',
    description: 'Notify users when devices go offline past the Offline Status Threshold.'
  },
  {
    label: 'Device: Online',
    value: 'online',
    description: 'Notify users when devices come back online.'
  },
  // {
  //   label: 'Firmware Change',
  //   value: 'os',
  //   description: 'Alert when a the firmware changes on the device'
  // },
  // {
  //   label: 'High Data Use',
  //   value: 'highDataUse',
  //   description: 'Alert when device uses more than 1G of data in a 24 hour period'
  // },
  {
    label: 'Memory Storage',
    value: 'highMemory',
    description: 'Notify users when memory storage is 85% full.'
  },
  // {
  //   label: 'Network Change',
  //   value: 'network',
  //   description: 'Alert when the network type changes or the wifi ssid changes'
  // },
  // {
  //   label: 'Timezone Change',
  //   value: 'timezone',
  //   description: 'Alert when device timezone changes'
  // },
]

export const alertMuteDurationMsOptions = [
  {label: '1 Hour', value: (1000 * 60 * 60)},
  {label: '6 Hour', value: (1000 * 60 * 60 * 6)},
  {label: '12 Hour', value: (1000 * 60 * 60 * 12)},
  {label: '24 Hour', value: (1000 * 60 * 60 * 24)},
]
