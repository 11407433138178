<script setup>
import { ref, watch, onBeforeUnmount } from 'vue'
import useLabelFilters from '../composables/useLabelFilters'
import { orderBy } from 'lodash'

const emit = defineEmits({ // eslint-disable-line
  labelFilterRemoved: {
    default: ''
  }
})

const sortedFilters = ref([])

const {
  activeLabelFilters,
  clearActiveLabelFilters
} = useLabelFilters()

watch(() => activeLabelFilters.value, (cur) => {
  // sort label filters by name
  if (cur) {
    sortedFilters.value = orderBy(cur, 'name')
  }
}, { immediate: true, deep: true})

function removeLabelFilter(label) {
  emit('labelFilterRemoved', label)
}

onBeforeUnmount(() => {
  clearActiveLabelFilters()
})
</script>

<template>
    <q-item dense class="chips-wrapper q-pl-none">
      <div class="q-ml-lg q-mr-lg" style="width: 20px;"></div>
      <div class="flex justify-start on-left items-center">
        <span class="text-white">Filters: </span>
        <q-chip
          v-for="label in sortedFilters"
          :key="label"
          outline
          dense
          removable
          :label="label.labelName || label.name"
          dark
          @remove="removeLabelFilter(label)"
        >
        </q-chip>
      </div>
    </q-item>
</template>

<style>
  .chips-wrapper {
    max-height: 70px;
    overflow-y: auto;
  }
</style>
