import script from "./ReportsDialog.vue?vue&type=script&setup=true&lang=js"
export * from "./ReportsDialog.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QToolbar,QToolbarTitle,QBtn,QSeparator,QCardSection,QStepper,QStep,QSelect,QItem,QItemSection,QItemLabel,QCardActions});
