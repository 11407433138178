import script from "./FilterLabelDialog.vue?vue&type=script&setup=true&lang=js"
export * from "./FilterLabelDialog.vue?vue&type=script&setup=true&lang=js"

import "./FilterLabelDialog.vue?vue&type=style&index=0&id=389847dc&lang=css"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QList from 'quasar/src/components/item/QList.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog,QCard,QCardSection,QSpace,QItemLabel,QItem,QSelect,QList,QSeparator,QItemSection,QScrollArea,QChip,QCardActions});qInstall(script, 'directives', {Ripple,ClosePopup});
