<script setup>
import { ref } from 'vue'
import timezones from "timezones-list";

// eslint-disable-next-line
const props = defineProps({
  isDisabled: {
    type: Boolean,
    default: false
  }
})

// eslint-disable-next-line
const emit = defineEmits(['tz-updated'])

const model = ref(null)
const timezonesList = ref(timezones);
const filteredList = ref([]);

function filterTimezones (val, update) {
  update(() => {
    const filterString = val.toLowerCase()
    filteredList.value = timezonesList.value.filter(v => v.label.toLowerCase().indexOf(filterString) > -1)
  })
}
</script>

<template>
  <div>
    <q-select
      @filter="filterTimezones"
      use-input
      fill-input
      hide-selected
      dark
      outlined
      dense
      v-model="model"
      :options="filteredList"
      label="select timezone"
      color="white"
      option-label="label"
      option-value="tzCode"
      emit-value
      map-option
      :disable="props.isDisabled"
      @update:model-value="emit('tz-updated', model)"
    />
  </div>
</template>

<style></style>