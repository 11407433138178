<script setup>
import { computed, ref } from 'vue';
import { getLabelCategories } from '../store';
import useLabelFilters from '../composables/useLabelFilters';

const selectLabelsDialog = ref(false);
const labelCategories = computed(() => {
  return getLabelCategories();
})
const disableAddBtn = computed(() => {
  const activeLabelIds = activeLabelFilters.value.map(label => label.id);
  const queuedLabelIds = queuedLabels.value.map(label => label.id);
  const containsAll = activeLabelIds.every(id => queuedLabelIds.includes(id));
  const sameLength = queuedLabelIds.length === activeLabelIds.length
  
  return containsAll && sameLength ? true : false;
})

const {
  queuedLabels,
  activeLabelFilters
} = useLabelFilters();

</script>
  
<template>
  <div class="q-pa-md q-gutter-sm">
    <q-btn
      outlined
      no-wrap
      color="transparent"
      label="view all Labels"
      @click="selectLabelsDialog = true"
    ></q-btn>

    <q-dialog v-model="selectLabelsDialog">
      <q-card 
        dark 
        style="height:60%;min-width:500px;width:30%;" 
        class="q-pa-md"
      >
        <q-card-section>
          <div class="text-h6">Labels</div>
        </q-card-section>

        <q-card-section class="q-pa-none q-mx-md"  id="categoryWrapper">
          <q-scroll-area class="full-height q-mb-xl">
            <q-expansion-item 
              dark
              switch-toggle-side
              v-for="category in labelCategories" 
              :key="category.id"
              expand-separator
            >
            <template v-slot:header>
              <q-item-section class="text-subtitle1 text-bold">
                {{ category.name }}
              </q-item-section>
            </template>
              <q-list 
                v-for="label in category.labels"
                :key="label"
              >
                <q-item 
                  dark  
                  class="q-ml-xl"
                >
                  <q-checkbox 
                    v-model="queuedLabels" 
                    :val="label"
                    color="green-13"
                    dark
                    size="sm"
                  >
                  </q-checkbox>
                  <q-item-section>
                    <q-item-label class="text-body1 ellipsis">
                      {{ label.name }}
                    </q-item-label> 
                  </q-item-section>
                </q-item>
              </q-list>

            </q-expansion-item>
          </q-scroll-area>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn 
            flat 
            label="cancel" 
            color="white" 
            v-close-popup
          />
          <q-btn
            rounded
            label="add"
            color="green-13"
            text-color="black"
            style="padding:5px 30px"
            v-close-popup
            :disable="disableAddBtn"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style>
#categoryWrapper {
  height: 75%;
  border: 1px solid white;
}
</style>