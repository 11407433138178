 import { createRouter, createWebHistory } from 'vue-router'
import Device from '../views/Device.vue'
import DeviceList from '../views/DeviceList'
import AppsList from '../views/AppsList'
import AppDetail from '../views/AppDetail'
import Token from '../views/Token.vue'
import LandingPage from '../views/LandingPage.vue'
import MainPage from '../views/MainPage.vue'
import JobStatus from '../views/JobStatus.vue'
import Reports from '../views/Reports.vue'
import Alerts from '../views/Alerts.vue'

const routes = [
  {
    path: '/token/:token',
    name: 'Token',
    props: true,
    component: Token
  },
  {
    path: '',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/devices',
    component: MainPage,
    children: [
      {
        path: '',
        name: 'DeviceList',
        component: DeviceList
      },
      {
        path: ':deviceId/:location?',
        name: 'Device',
        props: true,
        component: Device
      },
      {
        path: '/apps',
        name: 'AppsList',
        props: true,
        component: AppsList
      },
      {
        path: '/app/:apkKey',
        name: 'AppDetail',
        props: true,
        component: AppDetail
      },
      {
        path: '/jobStatus',
        name: 'JobStatus',
        props: true,
        component: JobStatus
      },
      {
        path: '/reports',
        name: 'Reports',
        component: Reports
      },
      {
        path: '/notificationGroups',
        name: 'NotificationGroups',
        component: Alerts
      }
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router
