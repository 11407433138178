export const reportTypes = [
  {
    label: 'Device State Report',
    value: 'deviceState',
    description:
      'Generate a report that details the last heartbeat of all of your devices',
  },
  {
    label: 'Device Config Report',
    value: 'deviceConfig',
    description:
      'Generate a report that details the current configuration set on all of your devices',
  },
  {
    label: 'Beam State Report',
    value: 'beamState',
    description:
      'Generate a report that details the the last heartbeat given back from Skykit Beam',
  },
  {
    label: 'Beam Config Report',
    value: 'beamConfig',
    description:
      'Generate a report that details the current configuration set for the Beam player',
  },
  {
    label: 'Installed Apps Report',
    value: 'installedAppsReport',
    description:
      'Generate a report that shows every app installed on all of your devices',
  },
];
