import script from "./Reports.vue?vue&type=script&setup=true&lang=js"
export * from "./Reports.vue?vue&type=script&setup=true&lang=js"

import "./Reports.vue?vue&type=style&index=0&id=531be33a&lang=css"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCard,QItem,QItemSection,QSeparator,QLinearProgress,QSpinner,QIcon,QBtn});
