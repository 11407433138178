<script setup>
import { computed } from 'vue';

// eslint-disable-next-line no-undef
const props = defineProps({
  usbDevices: {
    type: Object,
    default: () => {}
  }
});

const devices = computed(() => {
  return Object.values(props.usbDevices);
})

</script>

<template>
  <q-card dark class="q-px-md q-pb-lg">
    <q-card-section class="text-left q-py-lg">
      <span class="text-h6 text-bold">Attached Devices</span>
    </q-card-section>
    <q-card-section dark class="q-mx-md bg-grey-10 text-left">
      <q-list>
        <q-item class="text-bold text-subtitle1">
          <q-item-section>
            Device Name
          </q-item-section>
          <q-item-section>
            Manufacturer
          </q-item-section>
          <q-item-section>
            Product ID
          </q-item-section>
          <q-item-section>
            Vendor ID
          </q-item-section>
          <q-item-section>
            Version
          </q-item-section>
        </q-item>

        <q-separator dark></q-separator>

        <q-item v-for="device of devices" :key="device.vendorId" class="attached-device-list-item">
          <q-item-section>
            {{ device.deviceName }}
          </q-item-section>
          <q-item-section>
            {{ device.manufacturerName }}
          </q-item-section>
          <q-item-section>
            {{ device.productId }}
          </q-item-section>
          <q-item-section>
            {{ device.vendorId }}
          </q-item-section>
          <q-item-section>
            {{ device.version }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
  </q-card>
</template>

<style>
.attached-device-list-item:nth-child(even) {
  background-color: rgb(35, 35, 35);
}
</style>