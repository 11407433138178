<script setup>
import { computed, ref } from 'vue';
import { getLabelCategories } from '../store';
import SelectLabelsDialog from '../components/SelectLabelsDialog.vue';
import useLabelFilters from '../composables/useLabelFilters';

const emit = defineEmits({ //eslint-disable-line
  filterByLabels: {
    default: '',
  },
});

const props = defineProps({ //eslint-disable-line
  loading: {
    type: Boolean,
    default: false,
  },
});

const labelFilterDialog = ref(false);
const labelCategories = computed(() => {
  return getLabelCategories();
});
const labelsList = computed(() => {
  let list = [];
  labelCategories.value?.forEach((cat) => {
    cat?.labels?.forEach((label) => {
      list.push(label);
    });
  });
  return list;
});
const filteredList = ref([]);

const disableApplyBtn = computed(() => {
  const activeLabelIds = activeLabelFilters.value.map(label => label.id);
  const queuedLabelIds = queuedLabels.value.map(label => label.id);
  const containsAll = activeLabelIds.every(id => queuedLabelIds.includes(id));
  const sameLength = queuedLabelIds.length === activeLabelIds.length
  
  return containsAll && sameLength;
})

function openLabelDialog() {
  queuedLabels.value.push(...activeLabelFilters.value);
  labelFilterDialog.value = true
}

function onCloseDialog() {
  labelFilterDialog.value = false;
  queuedLabels.value = [];
}

function filterLabels(val, update, abort) {
  if (val.length < 1) {
    abort()
    return
  }
  
  update(() => {
    const filterString = val.toLowerCase();
    filteredList.value = labelsList.value.filter(
      (v) => v.name.toLowerCase().indexOf(filterString) > -1
    );
  });
}

function removeLabelFilter(label) {
  const idx = queuedLabels.value.indexOf(label);
  queuedLabels.value.splice(idx, 1)
}

function onClickFilterLabels() {
  emit('filterByLabels', [...new Set(queuedLabels.value)]);
  onCloseDialog()
}

const {
  queuedLabels,
  activeLabelFilters,
  getCategoryNameByLabel
} = useLabelFilters();

</script>

<template>
  <div class="self-center">
    <q-btn
      class="on-left shadow-7"
      dark
      text-color="white"
      label="Filters"
      no-caps
      @click="openLabelDialog"
      icon="filter_list"
      :disable="loading"
    >
    <span v-if="activeLabelFilters.length" id="filterCount">
      {{ activeLabelFilters.length }}
    </span>
    </q-btn>
  </div>

  <q-dialog v-model="labelFilterDialog" persistent>
    <q-card 
      dark 
      style="min-width:500px; width:30%;" 
      class="q-pa-sm"
    >
      <q-card-section class="row justify-content">
        <span class="text-h6">Filter</span>
        <q-space></q-space>
        <q-btn
          dark
          icon="close"
          flat
          dense
          @click="onCloseDialog"
          color="grey-7"
        >

        </q-btn>

      </q-card-section>

      <q-card-section class="q-pb-none">
        <q-item-label class="text-bold">Labels</q-item-label>
        <q-item class="row q-pa-none items-center">
          <div class="col">
            <q-select
              v-model="queuedLabels"
              class="full-width"
              dark
              outlined
              multiple
              dense
              use-input
              hide-dropdown-icon
              menu-anchor="bottom left"
              menu-self="top left"
              hide-selected
              :options="filteredList"
              label="Search label name"
              option-label="labelName"
              color="green-13"
              @filter="filterLabels"
              emit-value
              map-option
              popup-content-style="height:350px;"
            >
              <template v-slot:option="scope">
                <q-item v-ripple dark v-bind="scope.itemProps" style="background-color:rgb(50,50,50)">
                  <q-list class="full-width">
                    <q-item class="items-end">
                      <span class="text-bold">
                        {{ getCategoryNameByLabel(scope.opt) }}
                      </span>
                    </q-item>
                    
                    <q-separator inset dark></q-separator>
  
                    <q-item class="items-center">
                      <q-item-label>
                        {{ scope.opt.name }}
                      </q-item-label>
                    </q-item>
                  </q-list>
                </q-item>
              </template>
            </q-select>
          </div>

          <q-item-section class="col-4">
            <select-labels-dialog></select-labels-dialog>
          </q-item-section>
        </q-item>
      </q-card-section>

      <q-card-section>
        <q-item-label class="text-bold q-py-xs"
          >Currently Selected Labels</q-item-label
        >
        <q-item dark class="q-px-none">
          <q-scroll-area
            style="height:100px;background:black;"
            class="full-width q-py-none"
          >
            <span>
              <q-chip
                outline
                removable
                @remove="removeLabelFilter(label)"
                dark
                v-for="label in queuedLabels"
                :key="label"
                :label="label.name"
              >
              </q-chip>
            </span>
          </q-scroll-area>
        </q-item>
      </q-card-section>

      <q-separator dark></q-separator>

      <q-card-actions align="right">
        <q-btn flat label="reset" color="white" @click="queuedLabels = []" />
        <q-btn
          rounded
          label="apply"
          color="green-13"
          text-color="black"
          style="padding:5px 30px"
          @click="onClickFilterLabels"
          v-close-popup
          :disable="disableApplyBtn"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style>
#filterCount {
  margin-left: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  background: red;
  color: white;
  border-radius: 50%;
}
</style>
