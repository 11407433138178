<template>
  <q-card dark class="q-pa-md" bordered>
    <q-list>
      <q-item class="row">
        <q-item-label class="text-weight-bold text-h6">
          Wifi Networks
        </q-item-label>
        <q-space></q-space>
        <app-btn
          :isSecondary="true"
          icon="add"
          label="new network"
          @click="onClickShowNetworkDialog()"
        >
        </app-btn>
      </q-item>
      <q-item class="q-pl-md">
        <q-item-section class="col-5 on-right">
          <q-item-label class="text-body1 text-bold">
            SSID
          </q-item-label>
        </q-item-section>
        <q-item-section class="col-3 on-right">
          <q-item-label class="text-body1 text-bold">
            Type
          </q-item-label>
        </q-item-section>
        <q-item-section>
        </q-item-section>
      </q-item>

      <q-separator inset dark></q-separator>

      <div class="network-container" v-if="hasWifiNetworks">
        <q-item
        v-bind:key="network"
        v-for="(network, key) in config.wifiNetworks"
        >
          <q-item-section class="col-5 on-right">
            <q-item-label class="text-body1">
              {{ network.ssid }}
            </q-item-label>
          </q-item-section>
          <q-item-section class="col-3 on-right">
            <q-item-label class="text-body1">
              {{ network.type }}
            </q-item-label>
          </q-item-section>
          <q-item-section class="flex" style="flex-direction:unset;">
            <q-space></q-space>
            <q-btn
              @click="onClickShowNetworkDialog(key, network)"
              round style="margin-left:-30px"
              class="q-mr-xs"
              flat
            >
              <q-icon
                name="edit"
                size="sm"
                clickable
              >
                <q-tooltip
                  class="text-subtitle2"
                  :delay="1000"
                  anchor="top middle"
                  self="bottom middle"
                >
                  <span>Edit {{ network.ssid }}</span>
                </q-tooltip>
              </q-icon>
            </q-btn>
            <confirm-dialog
              :value="key"
              title="Remove This Network"
              :tooltip="`Remove ${network.ssid}`"
              :message="`Are you sure you want to remove the ${key} network?`"
              :dialogType="'delete'"
              buttonText='remove network'
              @confirm-selection="removeWifiNetwork"
            >
            </confirm-dialog>
          </q-item-section>
        </q-item>
      </div>
      <div v-else class="flex justify-center no-network">
        <q-item-label class="text-body1"> No Wifi networks have been added to this device. Click the 
          <b><q-icon class="q-pb-xs" size="20px" name="add"></q-icon> NEW NETWORK </b> 
           button to add your first network.</q-item-label>
      </div>
    </q-list>

    <q-dialog v-model="showNetworkDialog" persistent>
      <q-card 
        dark
        class="q-pa-sm"
        style="width: 80%; max-width: 600px;"
      >
        <q-card-section class="row items-center">
          <div class="text-h6">Wifi Network</div>
          <q-space />
          <q-btn icon="close" flat round dense @click="closeDialog" />
        </q-card-section>

        <q-form>
        <q-card-section>
          <q-list>
            <q-item-label class="q-mb-sm">Enter SSID</q-item-label> 
            <q-input
              dark
              dense
              label="SSID"
              :color="networkModel.ssid ? 'green-13' : 'white'"
              outlined
              :lazy-rules="true"
              :rules="[rules.required, rules.characterRangeRule]"
              v-model="networkModel.ssid"
              class="q-mb-sm"
              no-error-icon
              :readonly="isEdit"
              :disable="isEdit"
            >
            </q-input>

            <q-item-label class="q-mb-sm">Enter Password</q-item-label>
            <q-input 
              :type="isPwd ? 'password' : 'text'"
              dark
              dense
              label="Password"
              :color="networkModel.password ? 'green-13' : 'white'"
              outlined
              :lazy-rules="true"
              :rules="[rules.required, rules.characterRangeRule]"
              v-model="networkModel.password"
              class="q-mb-sm"
              no-error-icon
              autocomplete="on"
            >
              <template v-slot:append>
                <q-icon
                  :name="!isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>

            <q-item-label class="q-mb-sm">Type</q-item-label> 
            <q-select
              dark
              dense
              :color="networkModel.type ? 'green-13' : 'white'"
              outlined
              :rules="[rules.required, rules.firebaseRule]"
              v-model="networkModel.type"
              :options="typeOptions"
              class="q-mb-sm"
              no-error-icon
            >
            </q-select>

            <q-item-label class="q-mb-sm">Is Hidden</q-item-label> 
            <q-select
              dark
              dense
              :color="networkModel.isHidden !== null ? 'green-13' : 'white'"
              outlined
              v-model="networkModel.isHidden"
              :options="isHiddenOptions"
              class="q-mb-lg"
              no-error-icon
            >
            </q-select>

            <q-item-label class="q-mb-sm">Enter Identity/Username</q-item-label> 
            <q-input
              dark
              dense
              label="Identity/Username"
              :color="networkModel.identity ? 'green-13' : 'white'"
              outlined
              :rules="[rules.firebaseRule, rules.maxLengthRule]"
              v-model="networkModel.identity"
              class="q-mb-sm"
              no-error-icon
            >
            </q-input>
          </q-list>
        </q-card-section>

        <q-card-actions align="right" class="q-mb-md q-px-md">
          <q-btn 
            flat
            label="cancel"
            color="white"
            @click="closeDialog"
          />
          <app-btn
            :label="isEdit ? 'update network' : 'add network'"
            @click="addWifiNetwork"
            :isDisabled="isValidForm"
          >
          </app-btn>
        </q-card-actions>
      </q-form>
      </q-card>
    </q-dialog>
    
  </q-card> 
</template>

<script>
import { ref, computed } from "vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import regexRules from "../constants/regex.js";
import AppBtn from '@/components/AppBtn';
import deviceJobs from '../service/deviceJobs';
import deviceServices from '../service/deviceServices';
import { useQuasar } from 'quasar'

export default {
  components: {
    ConfirmDialog,
    AppBtn,
  },
  props: {
    deviceConfig: Object,
    device: Object
  },
  setup(props) {
    const $q = useQuasar();
    const config = ref(props.deviceConfig);
    const networkModel = ref({
        ssid: '',
        password: '',
        isHidden: 'false',
        identity: '',
        type: 'WPA',
      });
    const typeOptions = ref(['WPA', 'PEAP']);
    const isHiddenOptions = ref(['false', 'true']);
    const isPwd = ref(true);
    const showNetworkDialog = ref(false);
    const rules = ref({
      firebaseRule: v => !!(v && v.match(regexRules.firebaseRegex) || v.match(/^$/)) || 'Field should not include restricted characters',
      required: v => !!v || 'This is a required field',
      characterRangeRule: v => v.length >= 4 && v.length <= 64 || 'Must be between 4 and 64 characters',
      maxLengthRule: v => v.length <= 64 || 'Must be less than 64 characters'
    });
    const isEdit = ref(false);
    const prevNetworkKey = ref('');
    const hasWifiNetworks = computed(() => {
        return config.value.wifiNetworks && Object.keys(config.value.wifiNetworks).length
    })

    const isValidForm = computed(() => {
      const requiredFormValues = Object.fromEntries(Object.entries(networkModel.value).filter(([key]) => !key.includes('identity')));
      const hasValues = Object.values(requiredFormValues).every(
        value => value !== null && value !== ''
      );
      const restrictedCharFormValues = Object.fromEntries(Object.entries(networkModel.value).filter(([key]) => !key.includes('password')));
      const restrictedChars = Object.values(restrictedCharFormValues).every(
        value => JSON.stringify(value).match(/^[^*.$#[\]\\]+$/)
      );
      const hasValidPassword = networkModel.value.password.length >= 4;
      const hasValidSsid = networkModel.value.ssid.length >= 4;
      const hasValidIdentity = networkModel.value.identity ? networkModel.value.identity.length <= 64 : true;

      return !hasValues || !restrictedChars || !hasValidPassword || !hasValidIdentity || !hasValidSsid
    })

    function onClickShowNetworkDialog(networkKey) {
      if (networkKey) {
        isEdit.value = true;
        networkModel.value = config.value.wifiNetworks[networkKey];
        prevNetworkKey.value = networkKey;
      }
      showNetworkDialog.value = true;
    }

    async function addWifiNetwork() {
      showNetworkDialog.value = false
      const notify = $q.notify({
        type: 'ongoing',
        message: getUpdateNetworkMessaging('ongoing'),
        spinner: true
      })
      const deviceQuery = {
        deviceKeys: [props.device.deviceKey]
      }

      // Convert isHidden prop to Bool before making the rquest
      networkModel.value.isHidden === 'true'
        ? (networkModel.value.isHidden = true)
        : (networkModel.value.isHidden = false);

      // Early networks could have a name prop that PS no longer accepts in the req. SSID is used as the key instead
      if (networkModel.value.name) {
        delete networkModel.value.name
      }

      try {
        const resp = await deviceJobs.addDeviceWifi(deviceQuery, networkModel.value)
        if (resp.jobTraceId) {
          deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
            if (data){
              if (data.status === 'incomplete') {
                return
              }
              if (data.status === 'complete' && !data.error) {
                notify({
                  type: 'positive',
                  message: getUpdateNetworkMessaging('positive'),
                  spinner: false
                })
                closeDialog()
                config.value = await props.device.fetchDeviceConfig()
                return
              }
              if (data.status === 'complete' && data.error) {
                notify({
                  type: 'negative',
                  message: getUpdateNetworkMessaging('negative'),
                  spinner: false
                })
                return
              }
            }
          })
        } else {
          notify({
            type: 'negative',
            message: getUpdateNetworkMessaging('negative'),
            caption: `${resp.message}`,
            spinner: false
          })
        }
      } catch (err) {
        console.error(err)
        notify({
          type: 'negative',
          message: getUpdateNetworkMessaging('negative'),
          spinner: false
        })
      }
    }

    async function removeWifiNetwork(val) {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Removing network from device...',
        spinner: true
      })
      const deviceQuery = {
        deviceKeys: [props.device.deviceKey]
      }
      const credential = {
        ssid: val
      }

      try {
        const resp = await deviceJobs.removeDeviceWifi(deviceQuery, credential)
        if (resp.jobTraceId) {
          deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
            if (data){
              if (data.status === 'incomplete') {
                return
              }
              if (data.status === 'complete' && !data.error) {
                notify({
                  type: 'positive',
                  message: 'Successfully removed network from device',
                  spinner: false
                })
                const freshConfig = await props.device.fetchDeviceConfig()
                config.value = freshConfig
                return
              }
              if (data.status === 'complete' && data.error) {
                notify({
                  type: 'negative',
                  message: 'Unable to remove network from device',
                  spinner: false
                })
                return
              }
            }
          })
        } else {
          notify({
            type: 'negative',
            message: 'Unable to remove network from device',
            caption: `${resp.message}`,
            spinner: false
          })
        }
      } catch (err) {
        console.error(err)
        notify({
          type: 'negative',
          message: 'Unable to remove network from device',
          spinner: false
        })
      }
    }

    function closeDialog() {
      networkModel.value = {
        ssid: '',
        password: '',
        isHidden: false,
        identity: '',
        type: 'WPA',
      };
      isEdit.value = false;
      prevNetworkKey.value = '';
      showNetworkDialog.value = false;
    }

    function getUpdateNetworkMessaging(type) {
      if (isEdit.value) {
        switch (type) {
          case 'ongoing' :
            return 'Updating network...'
          case 'positive' :
            return 'Successfully updated network'
          case 'negative' :
            return 'Failed to update network'
        }
      } else {
        switch (type) {
          case 'ongoing' :
            return 'Adding network to device...'
          case 'positive' :
            return 'Successfully added network to device'
          case 'negative' :
            return 'Failed to add network to device'
        }
      }
    }

    return {
      config,
      networkModel,
      typeOptions,
      isHiddenOptions,
      isPwd,
      showNetworkDialog,
      rules,
      isEdit,
      isValidForm,
      hasWifiNetworks,
      addWifiNetwork,
      removeWifiNetwork,
      onClickShowNetworkDialog,
      closeDialog,
    };
  },
};
</script>

<style lang="scss">
.network-container > div:nth-child(odd) {
  background-color: rgb(35,35,35);
}
.no-network {
  padding: 7em;
}
</style>
