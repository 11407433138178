const regexRules = {
    firebaseRegex: /^[^*.$#[\]\\]+$/,
    commandRegex: /^[^ *.$#[\]\\]+$/,
    numLetterRegex: /^[ a-zA-Z0-9_-]*$/,
    numRegex: /^[0-9]*$/,
    frameBufferRegex: /^$|^[0-9]*x[0-9]*@[0-9]*$/,
    packageNameRegex: /^$|^(?:[a-zA-Z]+(?:\d*[a-zA-Z_]*)*)(?:\.[a-zA-Z]+(?:\d*[a-zA-Z_]*)*)+$/,
    urlRegex: /^$|https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
    otaUrlRegex: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)/, //eslint-disable-line
    emailRegex: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/ 

}

export default regexRules