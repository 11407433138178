import script from "./CreateAlertNotificationGrougDialog.vue?vue&type=script&setup=true&lang=js"
export * from "./CreateAlertNotificationGrougDialog.vue?vue&type=script&setup=true&lang=js"

import "./CreateAlertNotificationGrougDialog.vue?vue&type=style&index=0&id=02c790a9&lang=css"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QToolbar,QToolbarTitle,QBtn,QSeparator,QCardSection,QItemLabel,QInput,QSpace,QChip,QCardActions});
