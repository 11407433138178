import script from "./AppBtn.vue?vue&type=script&setup=true&lang=js"
export * from "./AppBtn.vue?vue&type=script&setup=true&lang=js"

import "./AppBtn.vue?vue&type=style&index=0&id=5f45dd54&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon});
