import { get } from 'lodash';
import { removeEdgesAndNodes } from '../utils/responseUtils';

export default function useCommands() {

  function displaysResponseHelper(resp) {
    const arr = removeEdgesAndNodes(resp.data);
    const controlDevices = _getControlDevices(arr.displays || arr.findDisplays);
    const deviceList = controlDevices.map((item) => _mapDisplayItem(item));

    return deviceList;
  }

  function _getControlDevices(displays) {
    return displays.filter((el) => get(el, 'status.controlVersionCode'));
  }

  function _mapDisplayItem(item = {}) {
    if (!item) return;
    let device = {
      isOffline: item.controlState?.isOffline || item.status.isOffline,
      serialNUmber: item.controlState?.serialNUmber || item.status.serialNUmber,
      manufacturer: item.controlState?.manufacturer || item.status.manufacturer,
      model: item.controlState?.model || item.status.model,
      
    };

    return { ...item, ...device };
  }

  return {
    displaysResponseHelper
  }
}