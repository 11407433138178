<template>
  <div>
    <div class="text-right">
      <confirm-dialog
        label="Update All Apps"
        value="apk/all"
        title="Update All Apps"
        message="Are you sure you want to update all managed and device apps?"
        dialogType="updateAllApps"
        @confirm-selection="sendCommand"
        :isDisabled="isOffline"
      >
      </confirm-dialog>
    </div>

    <!-- Managed Apps Section -->
  
    <q-card class="card q-pa-md q-mt-lg" bordered>
      <q-item class="q-mb-md">
        <q-item-label class="text-weight-bold text-h6">
          Managed Apps
        </q-item-label>
        <q-space></q-space>
        <app-btn
          @click="managedAppDialog = true"
          label="Add Managed App"
        >
        </app-btn>
      </q-item>
      <div v-if="hasAppChannels">
        <q-card
          v-bind:key="key"
          v-for="(value, key) in config.appChannels"
          class="card q-mt-none"
        >
          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label class="item-label">
                  {{ getManagedAppDetails(key,value)?.packageName || key }}
                </q-item-label>
              </q-item-section>
              <confirm-dialog
                v-if="getManagedAppDetails(key,value)?.versionCode"
                label="launch"
                :value="`start/${getManagedAppDetails(key,value)?.packageName}`"
                title="Launch App"
                :message="`Are you sure you want to launch <b>${key}</b>?`"
                dialogType="launchApp"
                @confirm-selection="sendCommand"
                :isDisabled="isOffline"
              >
              </confirm-dialog>
            </q-item>
            <div class="inner-card">
              <q-item>
                <q-item-section class="col-auto">
                  <q-item-label class="text-weight-bolder text-subtitle-2">
                    Channel
                  </q-item-label>
                </q-item-section>
                <q-item-section class="col">
                  <q-select
                    dense
                    dark
                    outlined
                    v-model="config.appChannels[key]"
                    :options="getAvailableAppChannels(key)"
                    color="white"
                    @update:model-value="val => onChangeAppChannel(val, key)"
                    map-options
                    emit-value
                    :disable="isOffline"
                  >
                  </q-select>
                </q-item-section>
              </q-item>
            </div>
            <div class="inner-card q-mb-lg">
              <q-item-label
                class="q-pl-md q-mt-sm text-weight-bold text-subtitle-2"
              >
                Installed Version
              </q-item-label>
              <q-item>
                <q-item-label class="q-mt-sm">
                  {{ `Version: ${getManagedAppDetails(key,value)?.versionName || "N/A"} \u00A0` }}
                  <b> | </b>
                  {{ `\u00A0 version Code: ${getManagedAppDetails(key,value)?.versionCode || "N/A"}` }}
                </q-item-label>
                <q-space></q-space>
                <confirm-dialog
                  v-if="getManagedAppDetails(key,value)?.versionCode"
                  label="reset"
                  :value="`reset/${getManagedAppDetails(key,value)?.packageName}`"
                  title="Reset App"
                  :message="`Are you sure you want to reset <b>${getManagedAppDetails(key,value)?.packageName}</b>?`"
                  dialogType="resetApp"
                  @confirm-selection="sendCommand($event, key)"
                  :isDisabled="isOffline"
                >
                </confirm-dialog>
                <confirm-dialog
                  v-if="getManagedAppDetails(key,value)?.versionCode"
                  label="uninstall"
                  :value="`uninstall/${getManagedAppDetails(key,value)?.packageName}`"
                  title="Uninstall App"
                  :message="getUninstallMessage(key,value)"
                  dialogType="uninstallApp"
                  @confirm-selection="sendCommand($event, key)"
                  :isDisabled="isOffline"
                >
                </confirm-dialog>
              </q-item>

              <q-item v-if="hasNewerVersion(key) && !isInstalling(key)">
                <q-card class="new-version">
                  <q-item class="row items-center q-pa-md">
                    <q-item-section class="col-6">
                      <q-item-label class=" text-weight-bold text-subtitle-2">
                        Available Version
                      </q-item-label>
                      <q-item-label class="q-mt-sm">
                        {{ `Version: ${getAvailableManagedAppVersion(key, value).version} \u00A0` }}
                        <b> | </b>
                        {{ `\u00A0 version Code: ${getAvailableManagedAppVersion(key, value).versionCode}` }}
                      </q-item-label>
                    </q-item-section>
                    <q-item-section class="col">
                      <div class="text-right items-center">
                        <confirm-dialog
                          :label="setCommandString(key)"
                          :value="`apk/${key}`"
                          :title="$filters.toTitleCase(setCommandString(key))"
                          :message="`Are you sure you want to ${setCommandString(key)} <b>${key}</b>?`"
                          dialogType="command"
                          @confirm-selection="sendCommand($event, key)"
                          :isDisabled="isOffline"
                        >
                        </confirm-dialog>
                      </div>
                    </q-item-section>
                    <q-item-section class="col-auto">
                      <div class="text-right items-center">
                        <confirm-dialog
                          v-if="!getManagedAppDetails(key,value)?.versionCode"
                          :value="key"
                          :tooltip="`Delete ${key}`"
                          title="Delete Managed App"
                          :message="`Are you sure you want to delete <b>${key}</b>`"
                          dialogType="delete"
                          @confirm-selection="deleteManagedApp"
                        >
                        </confirm-dialog>
                      </div>
                    </q-item-section>
                  </q-item>
                </q-card>
              </q-item>
              <q-item v-if="isInstalling(key) && !isStuckOnInstallingStatus(key) && !hasError(key)">
                <q-card class="flex items-center q-pa-md bg-grey-8 status-toast">
                  <span class="text-capitalize text-subtitle2 on-right"> 
                    {{ getManagedAppStatusString(key, value) }} 
                  </span>
                  <q-space></q-space>
                  <q-spinner
                    v-if="appStatus[key]?.status !== 'installed'"
                    color="grey-5"
                    size="2em"
                    class="on-left"
                  />
                  <q-icon
                    v-if="appStatus[key]?.status === 'installed'"
                    name="check_circle"
                    color="green-13"
                    size="md"
                    class=""
                  >
                  </q-icon>
                </q-card>
              </q-item>
              <q-item v-if="hasError(key)">
                <q-card class="flex items-center bg-red-4 status-toast">
                  <span class="full-height bg-red error-tag"></span>
                  <q-list class="q-px-lg q-py-sm">
                    <div class="text-capitalize text-subtitle2 q-py-none"> 
                      Error 
                    </div>
                    <div class="text-capitalize q-py-none">
                      {{ formatDateTimeString(appStatus[key]?.timestamp) }}
                    </div>
                    <div class="text-capitalize q-py-none">
                      {{appStatus[key]?.error}}
                    </div>
                  </q-list>
                  <q-icon
                    class="q-pa-sm absolute-top-right"
                    name="close"
                    @click="deleteInstallStatus(key)"
                  ></q-icon>
                </q-card>
              </q-item>
            </div>
          </q-list>
        </q-card>
      </div>
      <q-card v-else class="inner-card">
        <q-item-label class="item-label q-pa-lg">
          No managed apps installed
        </q-item-label>
      </q-card>
    </q-card>

    <InstalledApps :deviceId="device.deviceId" :isOffline="isOffline"></InstalledApps>

    <q-card dark class="q-pa-lg q-px-lg q-mb-lg">
      <q-card dark class="q-px-lg q-py-md" style="background:rgb(35,35,35)">
        <q-card-section class="q-px-none text-left">
          <span class="text-h6">
              Direct App Install
          </span>
        </q-card-section>

        <q-card-section class="q-px-none">
          <q-item class="q-px-none">
            <q-item-section>
              <q-input
                required
                outlined
                dark
                v-model="directAppUrl"
                label="Enter application URL"
                color="white"
                :rules="[rules.urlRule]"
                lazy-rules
                hide-bottom-space
                no-error-icon
              />
            </q-item-section>
          </q-item>
        </q-card-section>

        <q-card-section 
          v-if="directAppStatus.status && !directAppStatus.error"
          class="q-pl-none"
        >
          <q-card class="flex items-center q-pa-md bg-grey-8 status-toast">
            <span v-if="directAppStatus?.status === 'downloading'"> {{ directAppStatus?.status }} {{ directAppStatus?.progress }} </span>
            <span v-else class="text-capitalize text-subtitle2 on-right"> 
              {{ directAppStatus?.status }} {{ directAppStatus?.pkg }}
            </span>
            <q-space></q-space>
            <q-spinner
              v-if="directAppStatus?.status !== 'installed'"
              color="grey-5"
              size="2em"
              class="on-left"
            />
            <q-icon
              v-if="directAppStatus?.status === 'installed'"
              name="check_circle"
              color="green-13"
              size="md"
              class=""
            >
            </q-icon>
          </q-card>
        </q-card-section>

        <q-card-section
          v-if="directAppStatus?.error"
          class="q-pl-none"
        >
          <q-card class="flex items-center bg-red-4 status-toast">
            <span class="full-height bg-red error-tag"></span>
            <q-list class="q-px-lg q-py-sm  text-left">
              <div class="text-capitalize text-subtitle2 q-py-none">
                <span>
                  Error: Unable to install app
                </span>
              </div>
              <div class="text-capitalize q-py-none">
                {{ directAppStatus?.error }}
              </div>
            </q-list>
          </q-card>
        </q-card-section>

        <q-card-actions align="center" class="q-py-md">
          <app-btn
            label="install"
            @click="onClickAddDirectApp"
            :isDisabled="!isValidUrl"
          >
          </app-btn>
        </q-card-actions>
      </q-card>
    </q-card>

    <q-dialog v-model="managedAppDialog">
      <q-card 
        dark 
        style="width:70%;max-width:600px;"
        class="q-pa-sm"
      >
        <q-card-section class="q-pa-none">
          <div class="q-pa-md q-pl-lg">
            <span class="text-h6"> Available Managed Apps </span>
          </div>
        </q-card-section>

        <q-separator dark></q-separator>

        <q-scroll-area
          style="height:50vh;"
        >
          <q-list>
            <q-item
              v-ripple
              v-bind:key="item.id"
              v-for="item in availableManagedAppsList"
            >
              <q-item-section avatar>
                <q-checkbox
                  dark
                  :false-value="null"
                  v-model="queuedApps"
                  :val="item"
                  color="green-13"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label class=" text-h6 q-mb-xs">
                  {{ item.name }}
                </q-item-label>
                <q-item-label class="text-body2">
                  Package Name:
                  <span style="color:lightgrey;">
                    {{ `${item.packageName}` }}
                  </span>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>

        <q-separator dark></q-separator>

        <q-card-actions align="right" class="q-pa-md">
          <q-btn flat dark label="Cancel" color="white" v-close-popup />
          <app-btn
            label="Add Managed Apps"
            :isDisabled="!queuedApps.length"
            @click="addManagedApp"
          >
          </app-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref, reactive, toRef, onMounted, computed } from "vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import { channelOptions } from "../constants/constants";
import { formatDateTimeString } from "../utils/dateUtils";
import { toTitleCase } from "../utils/stringUtils"
import { useQuasar } from 'quasar';
import useCommands from '../composables/useCommands';
import AppBtn from '@/components/AppBtn';
import InstalledApps from '@/components/InstalledApps';
import deviceJobs from "../service/deviceJobs";
import deviceServices from "../service/deviceServices";
import { getTenantApks } from '../store/index';
import { generateRandomId } from '../utils/encryption.helper';
import regexRules from '../constants/regex';

export default {
  components: {
    ConfirmDialog,
    AppBtn,
    InstalledApps,
  },
  props: {
    state: Object,
    deviceConfig: Object,
    managedApps: Object,
    device: Object
  },
  setup(props) {
    const $q = useQuasar();
    const disableInstallFor = reactive({});
    const deviceState = toRef(props, "state");
    const config = ref(props.deviceConfig);
    const isLoading = ref(false);
    const managedAppDialog = ref(false);
    const allManagedAppsList = ref(props.managedApps);
    const queuedApps = ref([]);
    const appStatus = ref({});
    const prevAppStatus = ref({});
    const isOffline = computed(() => {
      return deviceState.value.isOffline
    })
    const tenantApks = computed(() => {
      return getTenantApks()
    })
    const hasAppChannels = computed(() => {
      return config.value.appChannels && Object.keys(config.value.appChannels).length > 0
    })
    const hasAppInstalls = computed(() => {
      return config.value.appInstalls && Object.keys(config.value.appInstalls).length > 0
    })
    const availableManagedAppsList = computed(() => {
      let filtered = []
      if (allManagedAppsList.value) {
        filtered = Object.keys(allManagedAppsList.value)
          .filter(key => !config.value.appChannels[key] && allManagedAppsList.value[key].packageName)
          .reduce((obj, key) => {
            obj[key] = allManagedAppsList.value[key]
            obj[key].appKey = key
            return obj
        }, {})
      }
      return filtered
    })
    const directAppStatus = ref({})
    const directAppUrl = ref('');
    const rules = ref({
      urlRule: v => !!(v && v.match(regexRules.urlRegex)) || v === '' || 'The URL must begin with http://www. or https://www.'
    })
    const isValidUrl = computed(() => {
      return directAppUrl.value && regexRules.urlRegex.test(directAppUrl.value)
    })

    onMounted(() => {
      Object.keys(config.value.appChannels).forEach(app => {
        // Prevents installed toast from appearing on load
        prevAppStatus.value[app] = 'installed';
        listenForAppStatusChanges(app);
      })

      Object.keys(config.value.appInstalls).forEach(app => {
        // Prevents installed toast from appearing on load
        prevAppStatus.value[app] = 'installed';
        listenForAppStatusChanges(app);
      })  
    });

    async function addManagedApp(updateAppObj) {
      const notify = $q.notify({
        type: 'ongoing',
        message: getUpdateAppMessage(updateAppObj, 'ongoing'),
        spinner: true
      })

      managedAppDialog.value = false
      const apps = []
      
      if (!updateAppObj) {
        const queuedAppsArr = [...queuedApps.value]
        queuedAppsArr.forEach(app => {
          // Add the app with it's latest available channel which is found on the app obj
          if (app.prod) {
            apps.push({package: app.packageName, channel: 'prod'})
            return
          }
          if (app.beta) {
            apps.push({package: app.packageName, channel: 'beta'})
            return
          }
          apps.push({package: app.packageName, channel: 'alpha'})
          return
        })
      } else {
        apps.push(updateAppObj)
      }

      const deviceQuery = {
        deviceKeys: [props?.device?.deviceKey]
      }

      try {
        const resp = await deviceJobs.addManagedApps(deviceQuery, apps)
        if (resp.jobTraceId) {
          deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
            if (data){
              if (data.status === 'incomplete') {
                return
              }
              if (data.status === 'complete' && !data.error) {
                notify({
                  type: 'positive',
                  message: getUpdateAppMessage(updateAppObj, 'positive'),
                  spinner: false
                })
                config.value = await props.device.fetchDeviceConfig()
                queuedApps.value.forEach(app => {
                  listenForAppStatusChanges(app.appKey)
                })
                return
              }
              if (data.status === 'complete' && data.error) {
                notify({
                  type: 'negative',
                  message: getUpdateAppMessage(updateAppObj, 'negative'),
                  spinner: false
                })
                return
              }
            }
          })
        } else {
          notify({
            type: 'negative',
            message: getUpdateAppMessage(updateAppObj, 'negative'),
            caption: `${resp.message}`,
            spinner: false
          })
        }
      } catch (err) {
        console.error(err)
        notify({
          type: 'negative',
          message: getUpdateAppMessage(updateAppObj, 'negative'),
          spinner: false
        })
      }
    }

    async function deleteManagedApp(app) {
      const notify = $q.notify({
        type: 'ongoing',
        message: `Removing ${app} from device...`,
        spinner: true
      })
      const packageName = [ getManagedAppDetails(app)?.packageName ]
      const deviceQuery = {
        deviceKeys: [props?.device?.deviceKey]
      }

      try {
        const resp = await deviceJobs.removeManagedApps(deviceQuery, packageName)
        if (resp.jobTraceId) {
          deviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
            if (data){
              if (data.status === 'incomplete') {
                return
              }
              if (data.status === 'complete' && !data.error) {
                notify({
                  type: 'positive',
                  message: `Successfully removed ${app} from device`,
                  spinner: false
                })
                config.value = await props.device.fetchDeviceConfig()
                return
              }
              if (data.status === 'complete' && data.error) {
                notify({
                  type: 'negative',
                  message: `Unable to remove ${app} from device`,
                  spinner: false
                })
                return
              }
            }
          })
        } else {
          notify({
            type: 'negative',
            message: `Unable to remove ${app} from device`,
            caption: `${resp.message}`,
            spinner: false
          })
        }
      } catch (err) {
        console.error(err)
        notify({
          type: 'negative',
          message: `Unable to remove ${app} from device `,
          spinner: false
        })
      }
    }

    function onChangeAppChannel(val, key) {
      const packageName = allManagedAppsList.value[key]?.packageName
      const updateAppObj = { package: packageName, channel: val}
      addManagedApp(updateAppObj)
    }

    function getManagedAppDetails(app, currentChannel) {
      const apks = deviceState.value.installedApks;
      let filteredApp = []
      
      if (apks) {
        filteredApp = Object.keys(apks)
          .filter((key) => key === app)
          .reduce((obj, key) => {
            obj = apks[key];
            return obj;
          }, {});
        if (filteredApp && allManagedAppsList.value[app])  {
          if (!filteredApp.packageName) {
            filteredApp.packageName = allManagedAppsList.value[app].packageName;
          }
          if (allManagedAppsList.value[app][currentChannel]) {
            filteredApp.availableVersionName = allManagedAppsList.value[app][currentChannel].version;
            filteredApp.availableVersionCode = allManagedAppsList.value[app][currentChannel].versionCode;
          }
        }
      }
      return filteredApp;
    }

    function getAvailableManagedAppVersion(app, env) {
      let version = {};
      if (allManagedAppsList.value[app] && allManagedAppsList.value[app][env]) {
        version = {
          version: allManagedAppsList.value[app][env].version,
          versionCode: allManagedAppsList.value[app][env].versionCode
        }
      } else {
        version = {
          version: 'N/A',
          versionCode: 'N/A'
        }
      }
      return version
    }

    function getAppVersion(app) {
      let version = {};
      if(deviceState.value.installedApks && deviceState.value.installedApks[app]) {
        version = {
          version: deviceState.value.installedApks[app].version,
          versionCode: deviceState.value.installedApks[app].versionCode
        }
      } else {
        version = {
          version: 'N/A',
          versionCode: 'N/A'
        }
      }
      return version
    }

    async function sendCommand(val, key) {
      let command = val;
      let commandType = command.substring(0, command.indexOf("/"));
      try {
        const deviceId = props.device.deviceId;
        const deviceQuery = {
          deviceIds: [deviceId],
        };
        sendDeviceCommand(deviceQuery, command)

        if (commandType === 'apk')  {
          prevAppStatus.value[key] = 'requesting';
          if (appStatus.value[key] && appStatus.value[key].status) {
            appStatus.value[key].status = 'requesting'
          } else {
            appStatus.value[key] = {};
            appStatus.value[key]['status'] = 'requesting';
          }
          
          listenForAppStatusChanges(key);
        }
      } catch (e) {
        console.warn(e);
        $q.notify({
          type: 'negative',
          message: 'Unable to send command to device',
          caption: `Error: ${e}`,
          spinner: false
        })
      }
    }

    function isInstalling(key) {
      let isInstalling = false;
      if (appStatus.value[key]?.status) {
        isInstalling = 
          appStatus.value[key]?.status &&
          appStatus.value[key]?.status !== 'uninstalled' &&
          (
            prevAppStatus.value[key] === 'downloading' ||
            prevAppStatus.value[key] === 'installing' ||
            prevAppStatus.value[key] === 'requesting'
          )
      }

      return isInstalling && !isStuckOnInstallingStatus(key)
    }

    function isInstalled(key) {
      return getAppVersion(key).versionCode !== 'N/A'
    }

    // App installs can fail and get stuck on install status forever. This hides that status after
    // 30 minutes and re-enables the install button. We wait at least 30 minutes to account for slow
    // internet when installing an app.
    function isStuckOnInstallingStatus(key) {
      if (!appStatus.value[key]?.timestamp) return
      
      let differenceMs = Date.parse(new Date) - Date.parse(appStatus.value[key]?.timestamp)
      const status = appStatus.value[key]?.status
      return status === 'installing' || status === 'downloading' && differenceMs > (60 * 1000 * 30)
    }

    function hasError(key) {
      return appStatus.value[key]?.appKey === key && appStatus.value[key]?.error
    }

    function hasNewerVersion(key) {
      return getManagedAppDetails(key)?.availableVersionCode > getManagedAppDetails(key)?.versionCode ||
        !getManagedAppDetails(key)?.versionCode
    }

    function getManagedAppStatusString(key, env) {
      switch (appStatus.value[key]?.status) {
        case 'downloading' :
          return `${appStatus.value[key]?.status}: ${appStatus.value[key]?.progress}`
        case 'installing' :
          return `${appStatus.value[key]?.status} ${getAvailableManagedAppVersion(key, env)?.version}`
        case 'installed' :
          return `${appStatus.value[key]?.status}`
        case 'requesting' :
          return `${appStatus.value[key]?.status}...`
      }
    }

    function setCommandString(key) {
      return isInstalled(key) ? 'update app' : 'install app'
    }

    function getUninstallMessage(app) {
      if (app === 'player' || app === 'control' || app === 'home') {
        return `<div class="q-mb-sm">Are you sure you want to uninstall <b>${toTitleCase(app)}</b>?</div>
                <span class="text-body2">(Uninstalling ${toTitleCase(app)} on Skykit devices will reset to factory version)</div>`
      } else {
        return `<span>Are you sure you want to uninstall <b>${app}</b>?</span>`
      }
    }

    function listenForAppStatusChanges(app) {
      props.device.listenForAppStatusChanges(app, (data) => {
        if (data?.status === 'installing') {
          prevAppStatus.value[app] = data.status;
        }
        if (data?.status === 'downloading') {
          prevAppStatus.value[app] = data.status;
        }       
        if (data?.status === 'installed' && prevAppStatus.value[app] === 'requesting') {
          data.status = 'requesting'
        }
        appStatus.value[app] = data;

        if (data?.status === 'installed') {
          setTimeout(() => {
            appStatus.value[app] = {}
          }, 7000)
        }
      })
    }

    function getUpdateAppMessage(updateAppObj, type) {
      if (updateAppObj) {
        switch (type) {
          case 'ongoing' :
            return `Setting channel to ${updateAppObj.channel} on ${updateAppObj.package}...`
          case 'positive' :
            return `Successfully set channel to ${updateAppObj.channel} on ${updateAppObj.package}`
          case 'negative' :
            return `Failed to set channel to ${updateAppObj.channel} on ${updateAppObj.package}`
        }
        return
      }
      switch (type) {
        case 'ongoing' :
          return 'Adding selected managed apps to device...'
        case 'positive' :
          return 'Successfully added selected apps to device'
        case 'negative' :
          return 'Failed to add selected apps to device'
      }
    }

    function getAppEnv(app) {
      if (!tenantApks.value[app]) {
        return channelOptions.filter(el => el.value === 'prod')[0]
      } else {
        if (!tenantApks.value[app].prod && !tenantApks.value[app].beta) {
          return channelOptions.filter(el => el.value === 'alpha')[0]
        }
        if (!tenantApks.value[app].prod && tenantApks.value[app].beta) {
          return channelOptions.filter(el => el.value === 'beta')[0]
        }
        if (tenantApks.value[app].prod) {
          return channelOptions.filter(el => el.value === 'prod')[0]
        }
      }
    }

    function getAvailableAppChannels(app) {
      const env = getAppEnv(app)?.value
      let options = []
      switch(env) {
        case 'alpha' :
          options = channelOptions.filter(el => el.index === 0)
          break
        case 'beta' :
          options = channelOptions.filter(el => el.index <= 1)
          break
        case 'prod' :
          options = channelOptions.filter(el => el.index <= 2)
          break
      }
      return options
    }

    function onClickAddDirectApp() {
      const command = 'apk/install'
      const id = generateRandomId()
      const data = {
        url: directAppUrl.value,
        id: id
      }
      const deviceId = props.device.deviceId;
      const deviceQuery = {
        deviceIds: [deviceId],
      };
      try {
        sendDeviceCommand(deviceQuery, command, false, data)
        const appKey = `direct-${data.id}`
        listenForDirectAppStatus(appKey)
      } catch(err) {
        console.error(err)
      } finally {
        directAppUrl.value = ''
      }
    }

    function listenForDirectAppStatus(appKey) {
      props.device.listenForAppStatusChanges(appKey, (data) => {
        try {
          if (data) {
            directAppStatus.value = data
            if (data.status === 'installed') {
              setTimeout(() => {
                directAppStatus.value = {}
              }, 5000)
            }
            if (data.error) {
              console.error(data.error)
              setTimeout(() => {
                directAppStatus.value = {}
              }, 5000)
            }
          }
        } catch(err) {
          console.error(err)
          directAppStatus.value = {}
        }
      })
    }

    function deleteInstallStatus(key) {
      try {
        deviceJobs.deleteAppInstallStatus({deviceId: props.device.deviceId, appKey: key})
      } catch(err) {
        console.error(err)
      }
    }

    const {
      sendDeviceCommand
    } = useCommands();

    return {
      deviceState,
      config,
      isLoading,
      managedAppDialog,
      channelOptions,
      availableManagedAppsList,
      allManagedAppsList,
      queuedApps,
      directAppUrl,
      appStatus,
      prevAppStatus,
      getManagedAppDetails,
      getAvailableManagedAppVersion,
      addManagedApp,
      deleteManagedApp,
      onChangeAppChannel,
      getAppVersion,
      sendCommand,
      formatDateTimeString,
      isInstalling,
      isInstalled,
      isStuckOnInstallingStatus,
      hasError,
      hasNewerVersion,
      setCommandString,
      getManagedAppStatusString,
      listenForAppStatusChanges,
      disableInstallFor,
      isOffline,
      getUpdateAppMessage,
      getAvailableAppChannels,
      getUninstallMessage,
      hasAppChannels,
      hasAppInstalls,
      onClickAddDirectApp,
      isValidUrl,
      directAppStatus,
      rules,
      deleteInstallStatus
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
  color: white;
  background-color: rgb(30, 30, 30);
  margin-bottom: 32px;
  padding-bottom: 24px;
}
.inner-card {
  padding: 10px;
  margin: 0 1rem 0.5rem;
  background-color: rgb(40, 40, 40);
}
.item-label {
  font-weight: bolder;
  font-size: 1.3rem;
  color: white;
  min-width: 9rem;
}
.new-version {
  background: rgba(42, 158, 92, 0.2);
  border: 2px solid rgba(42, 158, 92);
  width: 100%;
}
#deleteBtn {
  margin-top: -18px;
  margin-left: 20px;
}
#modalDeleteBtn {
  margin-top: 17px;
}
.status-toast {
  width:50%;
  max-width:700px;
}
.error-tag {
  width:7px;
  position: absolute;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>
