import { isArray, isObject, transform, isEqual } from 'lodash';

export function getObjectDiff(origObj, newObj) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return transform(newObj, function (result, value, key) {
      if (
        (value !== undefined) &&
        !isObject(value) &&
        !isEqual(JSON.stringify(value), JSON.stringify(origObj[key]))
      ) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  }
  return changes(newObj, origObj);
}
