<script setup>
import { ref, onBeforeMount, computed, watch } from 'vue'
import useEvents from '../composables/useEvents'
import { sub } from 'date-fns'
import { date } from 'quasar'
import { getAvailableProducts } from '../store'
import AppBtn from '@/components/AppBtn'

// eslint-disable-next-line
const props = defineProps({
  device: {
    type: Object,
    default: () => {},
  },
  activeTab: {
    type: String,
    default: ''
  }
})

const device = ref(props.device)
const showReportDialog = ref(false)
const eventTypeOptions = ref([
  { value: 'control', label: 'Device Events' },
  { value: 'player', label: 'Beam Events' },
])
const eventType = ref(eventTypeOptions.value[0])
const startDate = ref('')
const endDate = ref('')
const hasBeamPermission = computed(() => {
  return getAvailableProducts().includes('beam')
})

watch(() => props.activeTab, (cur) => {
  if (cur) {
    switch (cur) {
      case 'deviceEvents':
        eventType.value = eventTypeOptions.value.filter(option => option.value === 'control')[0]
        break
      case 'playerEvents':
        eventType.value = eventTypeOptions.value.filter(option => option.value === 'player')[0]
        break
    }
  }
})

onBeforeMount(() => {
  setDefaultDates()
})

function setDefaultDates() {
  const todayDate = new Date()
  const yesterdayDate = sub(new Date(), { days: 1 })
  startDate.value = date.formatDate(yesterdayDate, 'MM/DD/YYYY')
  endDate.value = date.formatDate(todayDate, 'MM/DD/YYYY')
}

function onClickDownloadCsv() {
  loading.value = true
  showReportDialog.value = false
  const formattedEndDate = `${endDate.value} 23:59:59`
  const startDateISO = date.formatDate(startDate.value, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
  const endDateISO = date.formatDate(formattedEndDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
  
  const reqObj = {
    deviceQuery: {
      deviceKeys: [props.device.deviceKey],
    },
    startDate: startDateISO,
    endDate: endDateISO,
    serviceId: setServiceId(),
  }
  downloadEventCsv(reqObj, props.device.deviceId)
}

function limitStartDate(d) {
  return d <= date.formatDate(Date.now(), 'YYYY/MM/DD')
  && d <= date.formatDate(endDate.value, 'YYYY/MM/DD')
}

function limitEndDate(d) {
  return d <= date.formatDate(Date.now(), 'YYYY/MM/DD')
  && d >= date.formatDate(startDate.value, 'YYYY/MM/DD')
}

function setNavigationLimit() {
  return date.formatDate(Date.now(), 'YYYY/MM')
}
 
function closeReportDialog() {
  showReportDialog.value = false
  setDefaultDates()
}

function disableCreateBtn() {
  return (!startDate.value || !endDate.value) ? true : false
}

function setServiceId() {
  switch (props.activeTab) {
    case 'deviceEvents':
      return 'control'
    case 'playerEvents':
      return 'player'
    default:
      return ''
  }
}

const { downloadEventCsv, loading } = useEvents()
</script>

<template>
  <div class="row">
    <q-space></q-space>
    <app-btn
      :isSecondary="true"
      label="download csv"
      :isDisabled="loading"
      @click="showReportDialog = true"
    >
    </app-btn>

    <q-dialog v-model="showReportDialog">
      <q-card dark style="width:60%;max-width: 600px;" class="q-pa-sm">
        <q-card-section class="q-pb-sm">
          <div class="text-h6 row">
            <span v-if="hasBeamPermission">Download CSV</span>
            <span v-else>Download Device Events CSV</span>
            <q-space></q-space>
            <q-icon class="cursor-pointer" name="close" @click="closeReportDialog"></q-icon>
          </div>
        </q-card-section>

        <q-separator dark class="q-mb-sm"></q-separator>

        <q-card-section v-if="hasBeamPermission">
          <q-item-label class="q-mb-sm text-caption">
            Report Type
          </q-item-label>
          <q-select
            :options="eventTypeOptions"
            v-model="eventType"
            dark
            bg-color="black"
            color="white"
            dense
            borderless
          >
            <template v-slot:selected>
              <span class="q-pl-sm"> {{ eventType.label }}</span>
            </template>
          </q-select>
        </q-card-section>

        <q-card-section class="row">
          <div class="col q-pr-xs">
            <q-item-label class="q-mb-sm text-caption">
              Start Date
            </q-item-label>
            <q-input
              borderless
              dark
              dense
              hide-bottom-space
              bg-color="black"
              v-model="startDate"
            >
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer q-px-md">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      subtitle="select start date"
                      color="green-13"
                      dark
                      v-model="startDate"
                      mask="MM/DD/YYYY"
                      :options="limitStartDate"
                      :navigation-max-year-month="setNavigationLimit()"
                    >
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="ok" color="green-13" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="col q-pl-xs">
            <q-item-label class="q-mb-sm text-caption">
              End Date
            </q-item-label>
            <q-input
              borderless
              dark
              dense
              hide-bottom-space
              bg-color="black"
              v-model="endDate"
            >
              <template v-slot:prepend>
                <q-icon name="event" class="cursor-pointer q-px-md">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      subtitle="select end date"
                      color="green-13"
                      dark
                      v-model="endDate"
                      mask="MM/DD/YYYY"
                      :options="limitEndDate"
                      :navigation-max-year-month="setNavigationLimit()"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="ok"
                          color="green-13"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </q-card-section>

        <div class="row text-caption q-pl-md q-pt-md q-pb-xl">
          <span>Note: Reports will show up to 100 of the most recent events</span>
        </div>
        <q-separator dark class="q-mb-sm"></q-separator>

        <q-card-actions class="q-px-md q-pb-md" align="right">
          <q-btn
            label="clear"
            color="white"
            dense
            flat
            @click="setDefaultDates()"
          />
          <app-btn
            label="download csv"
            @click="onClickDownloadCsv(device, eventType)"
            :isDisabled="disableCreateBtn()"
          >
          </app-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<style>
div.q-date__header {
  background-color: rgb(45, 45, 45) !important;
}
.q-date__header > div {
  padding-bottom: 10px;
}
</style>
