<template>
  <q-page v-if="isPageLoading">
    <q-linear-progress indeterminate color="green-13"/>
  </q-page>
  <q-page v-else padding class="page">
    <div class="row">
      <div class="col text-left text-white">
        <h3 class="text-weight-bolder">Apps</h3>
      </div>
      <div class="col self-center text-right">

        <!-- Will be re-added in V2 -->

        <!-- <q-btn
          text-color="grey-5"
          rounded 
          size="md" 
          class="q-mr-md"
          icon="delete"
          color="grey-14"
          :disable="isDisabled"
          @click="onClickDeleteApks"
        >
        </q-btn> -->
        <span class="q-pr-md">
          <app-btn
            :isSecondary="true"
            label="download report"
            @click="downloadAppsReport"
          ></app-btn>
        </span>
        <add-apps-dialog @apks-updated="updateApkList" label="upload app"></add-apps-dialog>
      </div>
    </div>

    <q-card dark class="full-width q-mt-lg q-px-lg q-py-sm rounded-borders">
      <div>
        <q-item class="text-subtitle1 text-left q-pb-none items-center" dark>
          <q-item-section class="col-3 text-bold">
            <span>App Name</span>
          </q-item-section>
          <q-item-section class="col-2 text-center text-bold">
            <span>Alpha</span>
          </q-item-section>
          <q-item-section class="col text-center text-bold">
            <span>Beta</span>
          </q-item-section>
          <q-item-section class="col text-center text-bold">
            <span>Production</span>
          </q-item-section>
        </q-item>
      </div>
      <q-separator dark></q-separator>
      <div v-if="apkList && Object.keys(apkList).length" id="scroll-target-id" style="max-height: 496px; overflow: auto;">
        <q-infinite-scroll @load="onLoad" :offset="500" scroll-target="#scroll-target-id">
          <div
            dark
            v-bind:key="key"
            v-for="(app, key) in apkList"
            class="apps-rows"
            style="height:4em;"
            @click="onSelectApp(key)"
          >
            <q-item class="text-left" clickable dark>
              <q-item-section class="col-3">
                <span class="q-mt-sm app-name">{{ app.name }}</span>
              </q-item-section>
              <q-item-section class="col-2">
                <span :class="['text-center', 'q-mt-sm', {'show-updated-version': key === updatedAppKey }]">{{ app.alpha.version }}</span>
              </q-item-section>
              <q-item-section class="col">
                <span class="text-center q-mt-sm">{{ app.beta?.version || 'N/A' }}</span>
              </q-item-section>
              <q-item-section class="col">
                <span class="text-center q-mt-sm">{{ app.prod?.version || 'N/A' }}</span>
              </q-item-section>
            </q-item>
          </div>
          <template v-slot:loading>
            <div class="row justify-center q-my-md">
              <q-spinner-dots color="green-13" size="40px" />
            </div>
          </template>
        </q-infinite-scroll>
      </div>
      <q-card dark v-else class="row items-center justify-center q-pa-xl" style="height:45vh;">
        <span>No apps have been uploaded yet. Press the <b>UPLOAD APP</b> button to upload your first app.</span>
      </q-card>

    </q-card>
  </q-page>
</template>

<script>
import { useQuasar } from 'quasar';
import { ref, reactive, onMounted, computed } from 'vue';
import { useRouter } from "vue-router";
import AddAppsDialog from '../components/AddAppsDialog.vue';
import DeviceServices from "../service/deviceServices";
import deviceJobs from "../service/deviceJobs";
import { formatDateString } from '../utils/dateUtils';
import { setTenantApks } from '../store/index';
import AppBtn from '../components/AppBtn.vue';

export default {
  components: {
    AddAppsDialog,
    AppBtn
  },
  setup() {
    // const $q = useQuasar();
    const route = useRouter();
    let isPageLoading = ref(false);
    const apkList = ref({});
    let deleteAppsQueue = reactive({});
    const isDisabled = computed(() => {
      return Object.values(deleteAppsQueue).every(x => x === null) ? true : false;
    });
    const updatedAppKey = ref(null);
    const $q = useQuasar();

    onMounted(() => {
      isPageLoading.value = true;

      DeviceServices.onAuthStateChanged((user) => {
        if (user) {
          loadApks();
        } else {
          console.debug('Unable to load Apps, user not ready');
        }
      });
    })

    async function loadApks() {
      try {
        await DeviceServices.getCurrentUser();
        apkList.value = await DeviceServices.fetchTenantApks();
        setTenantApks(apkList.value)
      } catch(err) {
        $q.notify({
          type: 'negative',
          message: err.message
        });
      } finally {
        isPageLoading.value = false;
      }
    }

    function onLoad(index, done) {
      done();
    }

    function onSelectApp(appKey) {
      route.push({ name: 'AppDetail', params: { apkKey: appKey }})
    }

    function updateApkList(data) {
      // fires CSS highlghing in UI when apk uploaded with higher version
      if (data.appKey) {
        if (Object.keys(apkList.value).includes(data.appKey)) {
          updatedAppKey.value = data.appKey;
          setTimeout(() => {
            updatedAppKey.value = null
          }, 1500)
        }
      }
        
      apkList.value = data.data;
      setTenantApks(apkList.value);
    }

    function getApkRelease(app = {}) {
      if (Object.keys(app).includes('prod')) {
        return 'Production'
      }
      if (Object.keys(app).includes('beta')) {
        return 'Beta'
      }
      return 'Alpha'
    }

    async function downloadAppsReport() {
      const notify = $q.notify({
        type: 'ongoing',
        message: 'Generating Installed Apps report...',
        ongoing: true
      })
      try {
        const resp = await deviceJobs.generateDeviceReport({
          reportName: 'installedAppsReport'
        })
        if (resp.jobTraceId) {
          DeviceServices.listenForJobChanges(resp.jobTraceId, async (data) => {
            if (data.status === 'complete') {
              notify({
                type: 'ongoing',
                message: 'Downloading CSV file...',
                ongoing: true
              })
              const downloadUrl = await DeviceServices.fetchFileDownloadUrl(data.reportFile)
              if (downloadUrl) {
                const link = document.createElement('a')
                link.href = downloadUrl
                link.download = `Device Report: ${data.type}.csv`
                link.class = 'download-csv-anchor'
                document.body.appendChild(link)
                link.click()
                notify()
              }
            }
          })
        }
      } catch(err) {
        console.error(err)
        notify({
          type: 'negative',
          message: 'Unable to download Installed Apps report'
        })
      }
    }

    // Will be re-added in V2 

    // async function onClickDeleteApks() {
    //   const notify = $q.notify({
    //     type: 'ongoing',
    //     message: 'Deleting APK...',
    //     spinner: true
    //   })
    //   let selectedApks = Object.fromEntries(Object.entries(deleteAppsQueue).filter(([_, v]) => v != null)); // eslint-disable-line
    //   deleteAppsQueue = {};
    //   try {
    //     await DeviceServices.deleteApks(Object.keys(selectedApks))
    //     DeviceServices.listenForTenantApkChanges((data) => {
    //       apkList.value = data;
    //     })
    //     notify({
    //       type: 'positive',
    //       message: 'Successfully deleted APK',
    //       spinner: false
    //     })
    //   } catch(err) {
    //     notify({
    //       type: 'negative',
    //       message: `Unable to delete delete APK 
    //       ${err}`,
    //       spinner: false
    //     })
    //   }
    // }

    return {
      isPageLoading,
      deleteAppsQueue,
      apkList,
      isDisabled,
      updatedAppKey,
      onSelectApp,
      updateApkList,
      getApkRelease,
      onLoad,
      formatDateString,
      downloadAppsReport
      // onClickDeleteApks
    }
  },
}
</script>

<style>
.app-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 100%;
  width: 100%;
}
.apps-rows:nth-child(even) {
  background-color: rgb(35, 35, 35);
} 
.show-updated-version {
  border-radius: 12px;
  /* animation: highlight 1000ms ease-in; */
  animation: highlight 1500ms ease-out;
}
@keyframes highlight {
  50% {
    background-color: rgb(100, 250, 200);
  }
  100% {
    background-color: rgb(48, 211,116);
  }
  50% {
    background-color: rgb(100, 250, 200);
  }
}
</style>