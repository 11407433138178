import script from "./ConfigProperty.vue?vue&type=script&setup=true&lang=js"
export * from "./ConfigProperty.vue?vue&type=script&setup=true&lang=js"

import "./ConfigProperty.vue?vue&type=style&index=0&id=4c7d258e&lang=css"

const __exports__ = script;

export default __exports__
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSlider,QToggle,QInput,QSelect,QItem});
