import { resetAppStates, clearPersistedState } from "@/store";
import DeviceServices from "@/service/deviceServices";
import { LoginAuth } from "@skykit-dev/skykit_login_utils";
import { onRedirect } from "@/utils/redirect";
import router from "../router";

async function logOut() {
  resetAppStates();
  clearPersistedState();

  await DeviceServices.signOut();
  await LoginAuth.destroySessionCookie();

  router
    .push({ name: "LandingPage" })
    .catch(() => {})
    .finally(onRedirect);
}

export { logOut };
